import { AxiosResponse } from "axios";
import { GraphSettings } from "types/graph";
import { graphV3 as route } from "http/urls";

import api from "http/api";

type graphData = {
  tops: [
    {
      id: number;
      name: string;
    }
  ];
  edges: [
    {
      parentObjectId: number;
      objectId: number;
      count: number;
    }
  ];
};

function GraphsV3Service() {
  function getAll(body: any): Promise<AxiosResponse<any>> {
    const url = route.getAll;
    return api.post<graphData>(url, body);
  }

  return Object.freeze({
    getAll,
  });
}

export default GraphsV3Service();
