import React, { ChangeEvent, FC } from "react";
import useStorages from "hooks/useStorages";

// material ui
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

interface Props {
  disabled?: boolean;
  storageId: number;
  setStorageId: (id: number) => void;
}

const SelectStorage: FC<Props> = ({ disabled, storageId, setStorageId }) => {
  const { rowData: storages } = useStorages();
  const storageIdStr = storageId === 0 ? "" : String(storageId);

  const handleSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStorageId(event.target.value as number);
  };

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id="select-diskId-label">Хранилище</InputLabel>
      <Select labelId="select-diskId-label" value={storageIdStr} onChange={handleSelectChange} fullWidth>
        {storages.map((s) => (
          <MenuItem key={s.id} value={s.id}>
            {s.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectStorage;
