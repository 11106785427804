import { AxiosResponse } from "axios";
import { Module } from "types/admin";
import { admin } from "http/urls";
import api from "http/api";

export interface BodySpace {
  id: string;
  isActive: boolean;
}

const { modules: route } = admin;

function ModuleService() {
  function getAll(): Promise<AxiosResponse<Module[]>> {
    return api.get<Module[]>(route.getAll);
  }

  function getById(id: string): Promise<AxiosResponse<Module>> {
    const url = route.getById.replace(":id", id);
    return api.get(url);
  }

  function update(body: BodySpace): Promise<AxiosResponse> {
    const url = route.getById.replace(":id", body.id);
    return api.put(url, body);
  }

  return Object.freeze({
    getAll,
    getById,
    update,
  });
}

export default ModuleService();
