import React, { FC, useMemo } from "react";
import { getCyrillicLangName } from "functions/common";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  flag: {},
}));

interface Props {
  lang: string;
  height?: number;
}

const Flag: FC<Props> = ({ lang }) => {
  const classes = useStyles();
  const url = useMemo(() => {
    if (lang === "" || lang === "unknown_language") {
      return "/img/flags/auto.png";
    }

    return "/img/flags/" + lang + ".png";
  }, [lang]);
  const cyrillicLangName = useMemo(() => getCyrillicLangName(lang), [lang]);

  return <img src={url} alt="Флаг" className={classes.flag} title={cyrillicLangName} />;
};

export default Flag;
