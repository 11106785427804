import {
  SET_SYSTEM_SOURCES,
  SET_SYSTEM_SERVICES,
  SET_SYSTEM_LID,
  SHOW_SYSTEM_ERROR,
  HIDE_SYSTEM_ERROR,
  SystemState,
  SystemAction,
  SystemService,
} from "redux/types/systemTypes";
import { Lang } from "types";

const initialState: SystemState = {
  sources: [],
  services: [],
  lidLanguages: [],
  error: {
    display: false,
    message: "",
  },
};

export default (state = initialState, action: SystemAction) => {
  const { type, payload } = action;

  if (type === SET_SYSTEM_SOURCES) {
    return {
      ...state,
      sources: payload,
    };
  }

  if (type === SET_SYSTEM_SERVICES) {
    return {
      ...state,
      services: payload as SystemService[],
    };
  }

  if (type === SET_SYSTEM_LID) {
    return {
      ...state,
      lidLanguages: payload as Lang[],
    };
  }

  if (type === SHOW_SYSTEM_ERROR) {
    return {
      ...state,
      error: {
        display: true,
        message: payload,
      },
    };
  }

  if (type === HIDE_SYSTEM_ERROR) {
    return {
      ...state,
      error: {
        display: false,
        message: "",
      },
    };
  }

  return state;
};
