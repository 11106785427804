import React, { useState, FC, MouseEvent } from "react";
import { AdminTypeFilter } from "types/admin";
import { Group } from "types/queryBuilder";

// components
import Filter from "components/filter/Filter";
import Keyboard from "components/keyboard/Keyboard";

// icons
import CloseIcon from "@material-ui/icons/Close";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import { grey } from "@material-ui/core/colors";
import HistoryFilters from "./HistoryFilters";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  search: {
    display: "flex",
    alignItems: "center",
  },
  filter: {
    flexGrow: 1,
    marginRight: 10,
  },
  closeKeyboard: {
    position: "absolute",
    right: 10,
    top: 10,
    color: grey[100],
  },
  keyboard: {
    position: "fixed",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: theme.zIndex.drawer + 5,
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    padding: theme.spacing(2),
    opacity: 0.9,
  },
}));

interface Props {
  filterString: string | undefined;
  availableFilters: AdminTypeFilter[];
  filter: Group;
  setFilter: Function;
  handleSearch: (f: Group) => void;
}

const Search: FC<Props> = ({ handleSearch, availableFilters, filter, setFilter }) => {
  const classes = useStyles();
  const [showKeyboard, setShowKeyboard] = useState(false);

  const handleKeyboardClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <HistoryFilters setFilter={setFilter} />

        <div className={classes.filter}>
          <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
        </div>

        <Button variant="contained" color="primary" onClick={() => handleSearch(filter)}>
          Поиск
        </Button>

        <Fade in={showKeyboard}>
          <div className={classes.keyboard}>
            <Keyboard onMouseDown={handleKeyboardClick} />
            <IconButton
              size="small"
              onClick={() => setShowKeyboard(false)}
              className={classes.closeKeyboard}
              color="primary"
              title="Закрыть"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Search;
