import React, { FC } from "react";
import clsx from "clsx";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import { ChartData, LineSeries, PieSeries, TableSeries } from "types/chart";
import TableChart from "./TableChart";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import AreaChart from "./AreaChart";
import NoData from "components/NoData";

type ChartRenderProps = {
  data: any;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    justifyContent: "center",
    width: "100%",
    overflow: "auto",
    height: "calc(100vh - 200px)",
  },
  chartWrapper: {
    display: "flex",
  },
  flexBasis100: {
    flexBasis: "calc(100% - 0.25rem)",
  },
  flexBasis50: {
    flexBasis: "calc(50% - 0.5rem)",
  },
  marginTop20: {
    marginTop: "20%",
  },
}));

const ChartRender: FC<ChartRenderProps> = ({ data }) => {
  const classes = useStyles();
  if (data === null) {
    return (
      <div className={classes.marginTop20}>
        <NoData />
      </div>
    );
  }
  return (
    <>
      <div className={classes.root}>
        {data.map((item: ChartData) => {
          if (Array.isArray(item.series) && item.series.length === 0) {
            return null;
          }

          if (item.type === "line") {
            return (
              <div className={clsx(classes.chartWrapper, classes.flexBasis100)}>
                <LineChart
                  key={item.id}
                  series={item.series as LineSeries}
                  name={item.name}
                  description={item.description}
                />
              </div>
            );
          }

          if (item.type === "area") {
            return (
              <div className={clsx(classes.chartWrapper, classes.flexBasis100)}>
                <AreaChart
                  key={item.id}
                  series={item.series as LineSeries}
                  name={item.name}
                  description={item.description}
                />
              </div>
            );
          }

          if (item.type === "pie") {
            return (
              <div className={clsx(classes.chartWrapper, classes.flexBasis50)}>
                <PieChart
                  key={item.id}
                  series={item.series as PieSeries[]}
                  name={item.name}
                  description={item.description}
                />
              </div>
            );
          }

          return (
            <div className={clsx(classes.chartWrapper, classes.flexBasis100)}>
              <TableChart key={item.id} series={item.series as TableSeries[]} name={item.name} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ChartRender;
