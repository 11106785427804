import React, { useState, FC } from "react";
import { languages } from "./languages";
import Flag from "components/Flag";

// icons
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 3,
    padding: 10,
    width: 960,
    background: "rgb(238, 238, 238)",
  },
  btn: {
    height: 55,
    borderRadius: 3,
    background: "#FFF",
    cursor: "pointer",
    position: "relative",
    color: grey[700],
    textAlign: "center",
    paddingTop: 17,
    "&:hover": {
      outline: "1px solid " + grey[400],
    },
  },
  keys: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    gridGap: 10,
    marginBottom: 10,
  },
  key: {
    fontSize: theme.typography.fontSize + 2,
    textTransform: "none",
    backgroundColor: "#FFF",
    height: 50,
  },
  space: {
    flexGrow: 1,
    fontSize: theme.typography.fontSize,
  },
  backspace: {
    marginLeft: 10,
    width: 200,
    justifyContent: "flex-end",
  },
  backspaceIcon: {},
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  formControl: {
    width: 200,
    marginRight: 10,
    backgroundColor: "#FFF",
  },
  selectLang: {
    width: 200,
  },
}));

interface Props {
  onMouseDown: (e: any, key: string) => void;
}

const Keyboard: FC<Props> = ({ onMouseDown }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [lang, setLang] = useState("russian");
  const keys: string[] = languages[lang] ?? languages.russian;

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const openSelectLang = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleListItemClick = (e: any, lang: string) => {
    e.preventDefault();
    e.stopPropagation();
    setLang(lang);
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div className={classes.keys}>
        {keys.map((k) => (
          <Button
            variant="contained"
            className={classes.key}
            key={k}
            onClick={handleClick}
            onMouseDown={(e) => onMouseDown(e, k)}
          >
            {k}
          </Button>
        ))}
      </div>
      <div className={classes.row}>
        <Button
          variant="contained"
          className={clsx(classes.key, classes.formControl)}
          onClick={openSelectLang}
          onMouseDown={(e) => e.preventDefault()}
        >
          <Flag lang={lang} />
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.key, classes.space)}
          onClick={handleClick}
          onMouseDown={(e) => onMouseDown(e, "space")}
        >
          Пробел
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.key, classes.backspace)}
          onClick={handleClick}
          onMouseDown={(e) => onMouseDown(e, "backspace")}
        >
          <KeyboardBackspaceIcon className={classes.backspaceIcon} />
        </Button>
      </div>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <div className={classes.selectLang}>
          <List component="nav">
            <ListItem button selected={lang === "english"} onClick={(event) => handleListItemClick(event, "english")}>
              <ListItemIcon>
                <Flag lang="english" />
              </ListItemIcon>
              <ListItemText primary="Английский" />
            </ListItem>
            <ListItem button selected={lang === "polish"} onClick={(event) => handleListItemClick(event, "polish")}>
              <ListItemIcon>
                <Flag lang="polish" />
              </ListItemIcon>
              <ListItemText primary="Польский" />
            </ListItem>
            <ListItem button selected={lang === "russian"} onClick={(event) => handleListItemClick(event, "russian")}>
              <ListItemIcon>
                <Flag lang="russian" />
              </ListItemIcon>
              <ListItemText primary="Русский" />
            </ListItem>
            <ListItem button selected={lang === "turkish"} onClick={(event) => handleListItemClick(event, "turkish")}>
              <ListItemIcon>
                <Flag lang="turkish" />
              </ListItemIcon>
              <ListItemText primary="Турецкий" />
            </ListItem>
            <ListItem
              button
              selected={lang === "ukrainian"}
              onClick={(event) => handleListItemClick(event, "ukrainian")}
            >
              <ListItemIcon>
                <Flag lang="ukrainian" />
              </ListItemIcon>
              <ListItemText primary="Украинский" />
            </ListItem>
          </List>
        </div>
      </Menu>
    </div>
  );
};

export default Keyboard;
