import { AxiosResponse } from "axios";
import { Object as T } from "types/record";
import { objects as route } from "http/urls";
import api from "http/api";

function ObjectService() {
  function getAll(root: number, offset: number, perPage: number): Promise<AxiosResponse<T[]>> {
    const url = route.getAll
      .replace(":root", String(root))
      .replace(":offset", String(offset))
      .replace(":count", String(perPage));
    return api.get<T[]>(url);
  }

  function getCount(root: number): Promise<AxiosResponse<{ count: number }>> {
    const url = route.getCount.replace(":root", String(root));
    return api.get<{ count: number }>(url);
  }

  function create(body: { name: string; comment: string; color: string; filter: string; clusterBy: string }) {
    return api.post(route.create, body);
  }

  function update(body: T) {
    return api.put(route.create, body);
  }

  function remove(ids: string) {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  return Object.freeze({
    getAll,
    getCount,
    create,
    update,
    remove,
  });
}

export default ObjectService();
