import { humanDuration } from "functions/common";

const ages: any = {
  youngs: "Молодой",
  middles: "Взрослый",
  olds: "Пожилой",
};

const reverb: any = {
  none: "Нет",
  small: "Низкая",
  medium: "Средняя",
  high: "Высокая",
  extreme: "Экстремальная",
};

const genders: any = {
  male: "Мужчина",
  female: "Женщина",
};

export const columnDefs: any = [
  {
    headerName: "Идентификатор",
    field: "id",
    width: 100,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Статус",
    field: "status",
    cellRenderer: "RecordStatusRenderer",
    width: 100,
  },
  {
    headerName: "Маркер",
    field: "labels",
    cellRenderer: "LabelsRenderer",
    width: 250,
  },
  {
    headerName: "Дата сеанса",
    field: "dateRecord",
    cellRenderer: "DateRenderer",
  },
  {
    headerName: "Имя сеанса",
    field: "name",
  },
  {
    headerName: "Длительность файла",
    field: "realDurationRecord",
    valueGetter: (params: any) => {
      const { realDurationRecord } = params.data;
      return humanDuration(realDurationRecord);
    },
  },
  {
    headerName: "Исходящий: Длительность речи",
    field: "srcDurationSpeech",
    valueGetter: (params: any) => {
      const { srcDurationSpeech } = params.data;
      return humanDuration(srcDurationSpeech);
    },
  },
  {
    headerName: "Входящий: Длительность речи",
    field: "dstDurationSpeech",
    valueGetter: (params: any) => {
      const { dstDurationSpeech } = params.data;
      return humanDuration(dstDurationSpeech);
    },
  },
  {
    headerName: "Количество слов",
    field: "wordCount",
    valueGetter: (params: any) => {
      const { wordCount } = params.data;
      return wordCount === 0 ? "" : wordCount;
    },
    width: 120,
  },
  {
    headerName: "Достоверность транскрибирования",
    field: "sttConfidence",
    valueGetter: (params: any) => {
      const { sttConfidence } = params.data;
      return sttConfidence === 0 ? "" : sttConfidence.toFixed(2);
    },
    width: 120,
  },
  {
    headerName: "Релевантность",
    field: "wordRating",
    valueGetter: (params: any) => {
      const { wordRating } = params.data;
      return wordRating === 0 ? "" : wordRating.toFixed(2);
    },
    width: 120,
  },
  {
    headerName: "Количество переведённых слов",
    field: "translateWordCount",
    valueGetter: (params: any) => {
      const { translateWordCount } = params.data;
      return translateWordCount === 0 ? "" : translateWordCount;
    },
    width: 120,
  },
  {
    headerName: "Достоверность перевода",
    field: "translateConfidence",
    valueGetter: (params: any) => {
      const { translateConfidence } = params.data;
      return translateConfidence === 0 ? "" : translateConfidence.toFixed(2);
    },
    width: 120,
  },
  {
    headerName: "Язык речевого сообщения",
    field: "lang",
    cellRenderer: "LangRenderer",
  },
  {
    headerName: "Достоверность языка речевого сообщения",
    field: "langConfidence",
    valueGetter: (params: any) => {
      const { langConfidence } = params.data;
      return langConfidence === 0 ? "" : langConfidence.toFixed(2);
    },
  },
  {
    headerName: "Смена языка",
    field: "isLidChange",
    cellRenderer: "BooleanRenderer",
  },
  {
    headerName: "Исходящий: Пол диктора",
    field: "srcGender",
    valueGetter: (params: any) => {
      const { srcGender } = params.data;
      return genders[srcGender] ?? "";
    },
  },
  {
    headerName: "Входящий: Пол диктора",
    field: "dstGender",
    valueGetter: (params: any) => {
      const { dstGender } = params.data;
      return genders[dstGender] ?? "";
    },
  },
  {
    headerName: "Исходящий: Качество речи",
    field: "srcQuality",
    valueGetter: (params: any) => {
      const { srcQuality } = params.data;
      return srcQuality === 0 ? "" : srcQuality.toFixed(2);
    },
  },
  {
    headerName: "Входящий: Качество речи",
    field: "dstQuality",
    valueGetter: (params: any) => {
      const { dstQuality } = params.data;
      return dstQuality === 0 ? "" : dstQuality.toFixed(2);
    },
  },
  {
    headerName: "Исходящий: Возраст диктора",
    field: "srcAge",
    valueGetter: (params: any) => {
      const { srcAge } = params.data;
      return ages[srcAge] ?? "";
    },
  },
  {
    headerName: "Входящий: Возраст диктора",
    field: "dstAge",
    valueGetter: (params: any) => {
      const { dstAge } = params.data;
      return ages[dstAge] ?? "";
    },
  },
  {
    headerName: "Исодящий: Реверберация",
    field: "srcReverb",
    valueGetter: (params: any) => {
      const { srcReverb } = params.data;
      return reverb[srcReverb] ?? "";
    },
  },
  {
    headerName: "Входящий: Реверберация",
    field: "dstReverb",
    valueGetter: (params: any) => {
      const { dstReverb } = params.data;
      return reverb[dstReverb] ?? "";
    },
  },
  {
    headerName: "Исходящий: Количество дикторов",
    field: "srcDiarizationCount",
    valueGetter: (params: any) => {
      const { srcDiarizationCount } = params.data;
      return srcDiarizationCount === 0 ? "" : srcDiarizationCount;
    },
  },
  {
    headerName: "Входящий: Количество дикторов",
    field: "dstDiarizationCount",
    valueGetter: (params: any) => {
      const { dstDiarizationCount } = params.data;
      return dstDiarizationCount === 0 ? "" : dstDiarizationCount;
    },
  },
  {
    headerName: "Исходящий: Диктор",
    field: "srcSpeakersName",
    valueGetter: (params: any) => {
      const { srcSpeakersName } = params.data;
      return srcSpeakersName.join(",");
    },
  },
  {
    headerName: "Входящий: Диктор",
    field: "dstSpeakersName",
    valueGetter: (params: any) => {
      const { dstSpeakersName } = params.data;
      return dstSpeakersName.join(",");
    },
  },
  {
    headerName: "Исходящий: Автоинформатор",
    field: "srcAutoinformatorName",
  },
  {
    headerName: "Входящий: Автоинформатор",
    field: "dstAutoinformatorName",
  },
  {
    headerName: "Количество страниц в факсимильном сообщении",
    field: "faxCount",
  },
  {
    headerName: "Факсимильное сообщение",
    field: "isFaxExist",
    cellRenderer: "BooleanRenderer",
  },
  {
    headerName: "Исходящий: Соотношение сигнал/шум",
    field: "srcSnr",
    valueGetter: (params: any) => {
      const { srcSnr } = params.data;
      return srcSnr === 0 ? "" : srcSnr.toFixed(2);
    },
  },
  {
    headerName: "Входящий: Соотношение сигнал/шум",
    field: "dstSnr",
    valueGetter: (params: any) => {
      const { dstSnr } = params.data;
      return dstSnr === 0 ? "" : dstSnr.toFixed(2);
    },
  },
  {
    headerName: "Количество каналов",
    field: "channelCount",
  },
  {
    headerName: "Длительность файла (по паспорту)",
    field: "durationRecord",
    valueGetter: (params: any) => {
      const { durationRecord } = params.data;
      return humanDuration(durationRecord);
    },
  },
  {
    headerName: "Исходящий: Имя терминала",
    field: "srcName",
  },
  {
    headerName: "Входящий: Имя терминала",
    field: "dstName",
  },
  {
    headerName: "Исходящий: Пользовательский номер",
    field: "srcNumber",
  },
  {
    headerName: "Входящий: Пользовательский номер",
    field: "dstNumber",
  },
  {
    headerName: "Исходящий: IP-адрес",
    field: "srcIpAddress",
  },
  {
    headerName: "Входящий: IP-адрес",
    field: "dstIpAddress",
  },
  {
    headerName: "Исходящий: Порт",
    field: "srcPort",
    valueGetter: (params: any) => {
      const { srcPort } = params.data;
      return srcPort === 0 ? "" : srcPort;
    },
  },
  {
    headerName: "Входящий: Порт",
    field: "dstPort",
    valueGetter: (params: any) => {
      const { dstPort } = params.data;
      return dstPort === 0 ? "" : dstPort;
    },
  },
  {
    headerName: "Исходящий: MAC-адрес",
    field: "srcMac",
  },
  {
    headerName: "Входящий: MAC-адрес",
    field: "dstMac",
  },
  {
    headerName: "Исходящий: Призводитель MAC-адрес",
    field: "srcMacName",
  },
  {
    headerName: "Входящий: Призводитель MAC-адрес",
    field: "dstMacName",
  },
  {
    headerName: "Исходящий: Название страны",
    field: "srcCountry",
  },
  {
    headerName: "Входящий: Название страны",
    field: "dstCountry",
  },
  {
    headerName: "Исходящий: Название города",
    field: "srcCity",
  },
  {
    headerName: "Входящий: Название города",
    field: "dstCity",
  },
  {
    headerName: "Адрес исходящего абонента",
    field: "srcAddress",
  },
  {
    headerName: "Адрес входящего абонента",
    field: "dstAddress",
  },
  {
    headerName: "Имя КПС",
    field: "nameKps",
  },
  {
    headerName: "iDirectChan",
    field: "iDirectChan",
  },
  {
    headerName: "iDirectAdr1",
    field: "iDirectAdr1",
  },
  {
    headerName: "skinnyAddr",
    field: "skinnyAddr",
  },
  {
    headerName: "skinnyChan",
    field: "skinnyChan",
  },
  {
    headerName: "SINCKEY",
    field: "sinckey",
  },
  {
    headerName: "Входящий: IMEI",
    field: "dstIMEI",
  },
  {
    headerName: "Входящий: IMSI",
    field: "dstIMSI",
  },
  {
    headerName: "Входящий: TMSI",
    field: "dstTMSI",
  },
  {
    headerName: "Исходящий: IMEI",
    field: "srcIMEI",
  },
  {
    headerName: "Исходящий: IMSI",
    field: "srcIMSI",
  },
  {
    headerName: "Исходящий: TMSI",
    field: "srcTMSI",
  },
  {
    headerName: "Стандарт",
    field: "standard",
  },
  {
    headerName: "Сеть",
    field: "network",
  },
  {
    headerName: "Принадлежность",
    field: "belong",
  },
  {
    headerName: "Тип вызова",
    field: "callType",
  },
  {
    headerName: "Имя источника(PostWorks)",
    field: "bSSourceName",
  },
  {
    headerName: "Базовая станция",
    field: "baseStation",
  },
  {
    headerName: "БС LAC",
    field: "bSLac",
  },
  {
    headerName: "БС СID",
    field: "bSCid",
  },
  {
    headerName: "Пост",
    field: "postId",
  },
  {
    headerName: "Частота",
    field: "frequency",
  },
  {
    headerName: "Имя источника данных(PostWorks)",
    field: "bSDataSourceName",
  },
  {
    headerName: "Тип данных",
    field: "codec",
  },
  {
    headerName: "Источник данных",
    field: "dataSource",
  },
  {
    headerName: "Тематика речевого сообщения",
    field: "textTheme",
  },
  {
    headerName: "Идентификатор документа",
    field: "esbDocUuid",
  },
  {
    headerName: "Алиас документа",
    field: "esbDocAlias",
  },
  {
    headerName: "Автор документа",
    field: "esbDocAuthor",
  },
  {
    headerName: "Идентификатор машины ИШД",
    field: "esbMachineId",
  },
  {
    headerName: "Дата создания",
    field: "dateCreated",
    cellRenderer: "DateRenderer",
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
  {
    headerName: "Postwin: Устройство",
    field: "postwinDevice",
  },
  {
    headerName: "Postwin: Начало",
    field: "postwinTimestamp",
    cellRenderer: "DateRenderer",
  },
  {
    headerName: "Postwin: Источник",
    field: "postwinSource",
  },
  {
    headerName: "Postwin: Номер собеседника",
    field: "postwinTalkerNumber",
  },
];
