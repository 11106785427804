import React, { useState, FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  contentItem: {
    marginLeft: "3rem",
  },
  contentDescription: {
    marginBottom: "1px",
  },
  button: {
    width: 100,
  },
}));

interface Props {
  onClose: (value: boolean) => void;
  selectedFilter: string | null;
}

const InfoDialog: FC<Props> = ({ onClose, selectedFilter }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose(true);
  };

  let title: string | null;
  let content: string | null;
  let imageBefore: string | null;
  let imageAfter: string | null;
  let audioBefore: string | null;
  let audioAfter: string | null;

  switch (selectedFilter) {
    case "Панорамирование":
      title = "Панорамирование";
      content =
        "Панорамирование используется для объединения левого и правого канала аудио с целью воспроизведения в левом и правом наушнике одних и тех же звуков.";
      imageBefore = "/hints/img/panoramBefore.png";
      imageAfter = "/hints/img/panoramAfter.jpeg";
      audioBefore = "/hints/audio/panoramBefore.wav";
      audioAfter = "/hints/audio/panoramAfter.wav";

      break;
    case "Нормализация":
      title = "Нормализация";
      content = "Нормализация звука используется для усиления амплитуды и громкости записи.";
      imageBefore = "/hints/img/normalizeBefore.jpeg";
      imageAfter = "/hints/img/normalizeAfter.jpeg";
      audioBefore = "/hints/audio/normalizeBefore.wav";
      audioAfter = "/hints/audio/normalizeAfter.wav";

      break;
    case "Эхо компенсация":
      title = "Эхо компенсация";
      content = "Эхо компенсация используется для удаления отраженного сигнала.";
      imageBefore = "/hints/img/echoBefore.png";
      imageAfter = "/hints/img/echoAfter.png";
      audioBefore = "/hints/audio/echoBefore.wav";
      audioAfter = "/hints/audio/echoAfter.wav";

      break;
    case "Фильтр широкополосного шума":
      title = "Фильтр широкополосного шума";
      content = "Фильтр широкополосного шума используется для удаления белого шума.";
      imageBefore = "/hints/img/NoiseBefore.jpg";
      imageAfter = "/hints/img/NoiseAfter.jpg";
      audioBefore = "/hints/audio/NoiseBefore.wav";
      audioAfter = "/hints/audio/NoiseAfter.wav";

      break;
    case "Фильтр импульсных помех":
      title = "Фильтр импульсных помех";
      content = "Фильтр импульсных помех используется для удаления характерных звуков треска и щелчков.";
      imageBefore = "/hints/img/declickBefore.png";
      imageAfter = "/hints/img/declickAfter.png";
      audioBefore = "/hints/audio/declickBefore.wav";
      audioAfter = "/hints/audio/declickAfter.wav";

      break;
    case "Фильтр канала тональной частоты":
      title = "Фильтр канала тональной частоты";
      content = "Фильтр канала тональной частоты используется для выравнивания по фазе, ниже или выше нулевого уровня.";
      imageBefore = "/hints/img/kt4Before.png";
      imageAfter = "/hints/img/kt4After.png";
      audioBefore = "/hints/audio/kt4Before.wav";
      audioAfter = "/hints/audio/kt4After.wav";

      break;
    default:
      title = null;
      content = null;
      imageBefore = null;
      imageAfter = null;
      audioBefore = null;
      audioAfter = null;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography> {content} </Typography>
        {imageBefore && audioBefore && (
          <div className={classes.contentWrapper}>
            <div>
              <DialogContentText variant="body2" className={classes.contentDescription}>
                Пример осциллограммы до применения:
              </DialogContentText>
              <DialogContentText>
                <img alt="до применения фильтра" src={imageBefore} />
              </DialogContentText>
            </div>
            <div className={classes.contentItem}>
              <DialogContentText variant="body2" className={classes.contentDescription}>
                Пример записи до применения:
              </DialogContentText>
              <audio controls src={audioBefore} />
            </div>
          </div>
        )}
        {imageAfter && audioAfter && (
          <div className={classes.contentWrapper}>
            <div>
              <DialogContentText variant="body2" className={classes.contentDescription}>
                Пример осциллограммы после применения:
              </DialogContentText>
              <DialogContentText>
                <img alt="после применения фильтра" src={imageAfter} />
              </DialogContentText>
            </div>
            <div className={classes.contentItem}>
              <DialogContentText variant="body2" className={classes.contentDescription}>
                Пример записи после применения:
              </DialogContentText>
              <audio controls src={audioAfter} />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="small" color="primary" onClick={handleClose} className={classes.button}>
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
