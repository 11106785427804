import React, { ChangeEvent, FC, useMemo } from "react";
import { AdminTypeFilter, NumberParams, UrlParams, EnumsParams } from "types/admin";
import { FilterType } from "types/queryBuilder";

// components
import TextAriaInput from "./TextAriaInput";
import BooleanInput from "./BooleanInput";
import NumberInput from "./NumberInput";
import DateInput from "./DateInput";
import ObjectInput from "./ObjectInput";
import EnumInput from "./EnumInput";
import TreeInput from "./TreeInput";
import FloatInput from "./FloatInput";

interface Props {
  ruleType: any;
  value: any;
  filterName: string;
  changeTextInput: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  changeBooleanInput: (value: string) => void;
  changeNumberInput: (value: number | string) => void;
  changeStringInput: (value: string) => void;
  availableFilters: AdminTypeFilter[];
}

const RenderInput: FC<Props> = ({
  ruleType,
  value,
  changeTextInput,
  changeBooleanInput,
  changeNumberInput,
  changeStringInput,
  availableFilters,
  filterName,
}) => {
  const availableFilter = useMemo(() => availableFilters.find((f) => f.name === filterName), [
    availableFilters,
    filterName,
  ]);
  if (availableFilter === undefined) return null;

  // Date
  if (ruleType === FilterType.Date) {
    return <DateInput value={value} changeValue={changeStringInput} />;
  }
  // Number
  if (ruleType === FilterType.Number) {
    return (
      <NumberInput value={value} changeValue={changeNumberInput} params={availableFilter.params as NumberParams} />
    );
  }
  // Float
  if (ruleType === FilterType.Float) {
    return <FloatInput value={value} changeValue={changeNumberInput} params={availableFilter.params as NumberParams} />;
  }
  // Boolean
  if (ruleType === FilterType.Boolean) {
    return <BooleanInput value={value} changeValue={changeBooleanInput} />;
  }
  // Object
  if (ruleType === FilterType.Object) {
    return <ObjectInput value={value} changeValue={changeStringInput} params={availableFilter.params as UrlParams} />;
  }
  // Enums
  if (ruleType === FilterType.Enums) {
    return <EnumInput value={value} changeValue={changeStringInput} params={availableFilter.params as EnumsParams} />;
  }
  // Tree
  if (ruleType === FilterType.Tree) {
    return <TreeInput value={value} changeValue={changeStringInput} params={availableFilter.params as UrlParams} />;
  }
  // String
  if (ruleType === FilterType.String) {
    return <TextAriaInput value={value} changeValue={changeTextInput} />;
  }

  return null;
};

export default RenderInput;
