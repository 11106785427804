import React, { FC, useEffect, useMemo, useState } from "react";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { Group } from "types/queryBuilder";
import { generateUuid } from "functions/common";

// components
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogActions } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  fields: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 10,
  },
  filter: {
    display: "flex",
    alignItems: "center",
    marginBottom: 28,
  },
  input: {
    flexGrow: 1,
  },
  filter2: {
    display: "flex",
    alignItems: "center",
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  handleSaveEdit: (filter?: any) => void;
  startFilter: any;
}

const UpdateCleanUpDialog: FC<Props> = ({ open, onClose, handleSaveEdit, startFilter }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();
  const { filters: availableFilters } = useAvailableFilters("records");

  const [filter, setFilter] = useState<Group>(defaultFilter);

  function handleSave() {
    handleSaveEdit(JSON.stringify(filter));
    // setFilter(defaultFilter);
  }

  useEffect(() => {
    setFilter(startFilter);
  }, [startFilter]);

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="md">
      <DialogTitle>Редактировать фильтр очистки</DialogTitle>
      <DialogContent>
        <div className={classes.filter2}>
          <div className={classes.input}>
            <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
          </div>
          <HistoryFilters setFilter={setFilter} />
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => handleSaveEdit()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCleanUpDialog;
