import React, { ChangeEvent, FC, useEffect, useState } from "react";
import clsx from "clsx";
import { AdminTech, AdminTechLid } from "types/admin";
import { Lang, TaskTechLid, TechType } from "types/task";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(() => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
  langs: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: 10,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  adminTechs: AdminTech[];
  tech: TaskTechLid;
}

const Lid: FC<Props> = ({ open, onClose, adminTechs, tech }) => {
  const classes = useStyles();

  const [langs, setLangs] = useState<Lang[]>([]);
  const [state, setState] = useState(tech);

  const handleSave = () => {
    onClose(state);
  };

  const isActive = (l: Lang) => {
    const { langs } = state;
    const { id } = l;
    return langs.includes(id);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, l: Lang) => {
    const { checked } = event.target;
    const { id } = l;
    if (checked) {
      if (state.langs.includes(id)) return;
      setState((prev: any) => ({
        ...prev,
        langs: [...prev.langs, id],
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        langs: prev.langs.filter((l: string) => l !== id),
      }));
    }
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      const allLangs = langs.map((l) => l.id);
      setState((prev: any) => ({
        ...prev,
        langs: allLangs,
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        langs: [],
      }));
    }
  };

  useEffect(() => {
    for (let i = 0; i < adminTechs.length; i++) {
      if (adminTechs[i].techDetail.type === TechType.LID) {
        const techLid: AdminTechLid = adminTechs[i].techDetail as AdminTechLid;
        setLangs(techLid.languages);
      }
    }
  }, [adminTechs]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Выбрать язык</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={langs.length === state.langs.length}
                  onChange={handleSelectAll}
                  name="isSelectAll"
                  color="primary"
                />
              }
              label="Выбрать все"
            />
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.langs}>
              {langs.map((l) => {
                const checked = isActive(l);
                return (
                  <FormControlLabel
                    control={
                      <Checkbox checked={checked} onChange={(e) => handleChange(e, l)} name={l.id} color="primary" />
                    }
                    label={l.name}
                  />
                );
              })}
            </div>
          </Grid>
        </Grid>

        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Lid;
