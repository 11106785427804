import React, { FC, useEffect, useState } from "react";
import { FileUploadProcess } from "services/FileUploadService";
import { BehaviorSubject } from "rxjs";
import NoData from "components/NoData";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import PublishIcon from "@material-ui/icons/Publish";
import Badge from "@material-ui/core/Badge";
import ListItem from "./ListItem";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    color: "#FFF",
  },
  body: {
    padding: "0 15px 20px 15px",
    width: 600,
  },
  noData: {
    padding: "10px 5px",
  },
}));

export const subscriber = new BehaviorSubject<FileUploadProcess | undefined>(undefined);
// const testProcess: FileUploadProcess = {
//   status: "progress",
//   uuid: "lfajsdflофалдвоажфоывжаофыжвдаофжыдвоафждывоажфдыловаждфлоываджлофывдалофыджвлоафджывлоаждфыловажд",
//   name: "lfajsdflофалдвоажфоывжаоasdlfjalskdfjjjfalsdjflajsdflajlsdf",
//   loaded: 34,
//   total: 100,
// };

const FileUpload: FC = () => {
  const classes = useStyles();
  const [process, setProcess] = useState<FileUploadProcess[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    subscriber.subscribe((process) => {
      if (process === undefined) return;
      setProcess((prev) => {
        const pr = prev.find((p) => p.uuid === process.uuid);
        if (pr === undefined) return [...prev, process];
        return prev.map((pr) => (pr.uuid === process.uuid ? process : pr));
      });
    });
  }, []);

  return (
    <div className={classes.root}>
      <IconButton className={classes.button} onClick={(e) => setAnchorEl(e.currentTarget)} title="Загрузки">
        <Badge
          badgeContent={process.reduce((acc, curr) => (curr.status === "progress" ? acc + 1 : acc), 0)}
          color="secondary"
          max={1000}
        >
          <PublishIcon fontSize="inherit" />
        </Badge>
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <div className={classes.body}>
          <Typography gutterBottom variant="h6">
            Менеджер загрузки
          </Typography>
          {process.length === 0 && (
            <div className={classes.noData}>
              <NoData />
            </div>
          )}

          {process
            .slice()
            .reverse()
            .map((p) => (
              <ListItem key={p.uuid} process={p} />
            ))}
        </div>
      </Menu>
    </div>
  );
};

export default FileUpload;
