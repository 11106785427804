import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const TwitchCloseIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M16 16V4h2v12h-2zM6 9l2.501-2.5-1.5-1.5-5 5 5 5 1.5-1.5-2.5-2.5h8V9H6z" />
    </SvgIcon>
  );
};

export default TwitchCloseIcon;
