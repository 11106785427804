import React, { FC, useRef } from "react";
import clsx from "clsx";
import { TableSeries } from "types/chart";
import { Statistic } from "types/statistic";

// material ui
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";

// ag-grid
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";

import { processCellForClipboard } from "components/agGrid/functions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(1100)]: {
      minWidth: "500px",
    },
  },
  tableName: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "#464646",
  },
  tableWrapper: {
    height: "315px",
    width: "100%",
  },
}));

type Table = {
  name?: string;
  series: TableSeries[];
  description?: string;
  statistic?: Statistic;
};

const TableChart: FC<Table> = ({ series, name }) => {
  const classes = useStyles();
  const gridRef = useRef<any>(null);
  const overlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">< Нет данных для отображения ></span>';

  const columnDefs = [
    { field: "name", headerName: "Параметр" },
    { field: "value", headerName: "Значение", initialSort: "desc" },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    flex: 1,
    suppressMenu: true,
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.tableName}>
        {name}
      </Typography>
      <div className={clsx(classes.tableWrapper, "ag-theme-balham")}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={series}
          ref={gridRef}
          defaultColDef={defaultColDef}
          suppressCopyRowsToClipboard
          processCellForClipboard={processCellForClipboard}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
        />
      </div>
    </Paper>
  );
};

export default TableChart;
