import React, { FC } from "react";
import { SpeakerType } from "types/speaker";

// icons
import PersonIcon from "@material-ui/icons/Person";
import FolderIcon from "@material-ui/icons/Folder";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    color: grey[600],
  },
}));

const SpeakerTypeRenderer: FC<{ value: SpeakerType }> = ({ value }) => {
  const classes = useStyles();

  // stopped | wait | starting | started | error
  if (value === "speaker") {
    return (
      <div className={classes.wrapper} title="Диктор">
        <PersonIcon />
      </div>
    );
  }

  if (value === "autoinformator") {
    return (
      <div className={classes.wrapper} title="Автоинформатор">
        <GraphicEqIcon />
      </div>
    );
  }

  return (
    <div className={classes.wrapper} title="Папка">
      <FolderIcon />
    </div>
  );
};

export default SpeakerTypeRenderer;
