import React, { FC, useCallback, useEffect, useState } from "react";
import useQueryString from "hooks/useQueryString";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "redux/actions/alertActions";
import { useHistory } from "react-router-dom";

// components
import GraphView from "./components/GraphView";
import NoData from "../../components/NoData";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import useGraphNew from "hooks/useGraphNew";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  barPaper: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  noData: {
    height: "820px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  progress: {
    position: "absolute",
    top: "70px",
    left: "10px",
    height: "820px",
    width: "calc(100% - 16px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
  opacityZero: {
    opacity: 0,
  },
  opacityFull: {
    opacity: 100,
    position: "absolute",
  },
  loadingSign: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "14px!important",
    textAlign: "center",
  },
  nodesCount: {
    position: "absolute",
    top: "14px",
    right: "20px",
    fontSize: 14,
    zIndex: 5,
  },
}));

interface Props {
  data: any;
}

const Graph: FC<Props> = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryParams = useQueryString();

  const ids = queryParams.get("ids");

  const { graphData, loading, error } = useGraphNew(data);
  const [network, setNetwork] = useState<any>(null);
  const [graphLoading, setGraphLoading] = useState<boolean>(false);
  const [graphLoaded, setGraphLoaded] = useState<boolean>(false);

  const [selectedNodes, setSelectedNodes] = useState<any[]>([]);
  const [selectedEdges, setSelectedEdges] = useState<any[]>([]);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const graphEvents = {
    select: function (event: any) {
      const { nodes: nodesIds, edges: edgesIds } = event;
      if (nodesIds.length === 0 && edgesIds.length === 0) {
        setSelectedNodes([]);
        setSelectedEdges([]);
      }

      const nodes = network.body.data.nodes.get(nodesIds);
      const edges = network.body.data.edges.get(edgesIds);
      setSelectedNodes(nodes);
      setSelectedEdges(edges);
    },
    startStabilizing: function (event: any) {
      if (graphLoaded) {
        return;
      }
      setGraphLoading(true);
    },
    stabilized: function (event: any) {
      if (graphLoaded) {
        return;
      }
      setGraphLoading(false);
      setGraphLoaded(true);
    },
  };

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [catchError, error]);

  return (
    <div className={classes.root}>
      {(graphLoading || loading) && (
        <Paper className={classes.noData}>
          <div className={classes.loadingSign}>{"< Пожалуйста подождите, идёт построение графа >"}</div>
        </Paper>
      )}
      {graphData.graphNodes.length !== 0 && (
        <Paper className={graphLoading ? classes.opacityZero : classes.opacityFull} style={{ width: "100%" }}>
          <div className={classes.nodesCount}>Кол-во вершин: {graphData.graphNodes.length}</div>
          <GraphView
            graphData={graphData}
            setNetwork={setNetwork}
            graphEvents={graphEvents}
            key={JSON.stringify(graphData)}
          />
        </Paper>
      )}
      {graphData.graphNodes.length === 0 && !loading && !graphLoading && (
        <Paper className={classes.noData}>
          <NoData />
        </Paper>
      )}
    </div>
  );
};

export default Graph;
