import React, { FC } from "react";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Диапазон: от",
    field: "fromString",
    width: 200,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    field: "from",
    hide: true,
  },
  {
    headerName: "Диапазон: до",
    field: "toString",
    width: 200,
  },
  {
    field: "to",
    hide: true,
  },
  {
    headerName: "Производитель",
    field: "manufacturer",
    width: 250,
  },
  {
    headerName: "Страна",
    field: "country",
    width: 100,
  },
  {
    headerName: "Адрес",
    field: "address",
    width: 250,
  },
  {
    headerName: "Комментарий",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

interface Props {
  rowData: any[];
  setGridApi: Function;
  onSelection: Function;
  height: string | number;
}

const MacAddressesTable: FC<Props> = ({ rowData, setGridApi, onSelection, height }) => {
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: any) => String(data.id);

  return (
    <div className={"ag-theme-balham"} style={{ height }}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        frameworkComponents={{
          DateRenderer,
        }}
      />
    </div>
  );
};

export default MacAddressesTable;
