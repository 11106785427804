import React, { FC, useState, useEffect, useMemo } from "react";
import ExtendedFilter from "components/extendedFilter/ExtendedFilter";
import { AdminTypeFilter } from "types/admin";
import { Group, Group as TGroup } from "types/queryBuilder";
import { generateUuid } from "functions/common";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(() => ({
  body: {},
  button: {
    width: 100,
  },
}));

interface Props {
  filter: TGroup;
  open: boolean;
  onClose: (data?: TGroup) => void;
  availableFilters: AdminTypeFilter[];
}

const FilterDialog: FC<Props> = ({ open, onClose, availableFilters, filter }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );

  const classes = useStyles();
  const [query, setQuery] = useState<TGroup>(defaultFilter);

  const handleSave = () => {
    onClose(query);
  };

  useEffect(() => {
    if (!open) return;
    setQuery((prev) => ({
      ...prev,
      filters: [...filter.filters],
    }));
  }, [filter, open]);

  return (
    <Dialog maxWidth="xl" fullWidth open={open} onClose={() => onClose()}>
      <DialogTitle>Расширенный фильтр</DialogTitle>
      <DialogContent>
        <div className={classes.body}>
          <ExtendedFilter query={query} setQuery={setQuery} availableFilters={availableFilters} />
        </div>
      </DialogContent>

      <DialogActions>
        <Button size="small" onClick={() => onClose()} className={classes.button}>
          Отмена
        </Button>
        <Button variant="contained" size="small" onClick={handleSave} color="primary" className={classes.button}>
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
