import { BehaviorSubject } from "rxjs";
import { WsDataType, WsDataTypeNewMessage } from "types/ws";

const initial: WsDataTypeNewMessage = {
  type: WsDataType.MESSAGE_NEW,
  data: {
    count: 0,
    message: undefined,
  },
};

const subscriber = new BehaviorSubject(initial);

const MessageSubscriber = {
  send: (curr: WsDataTypeNewMessage) => subscriber.next(curr),
};

export { subscriber, MessageSubscriber };
