export interface Passport {
  id: number;
  name: string;
  comment: string;
  isActive: boolean;
  ownerId: number;
  detail: FileFormatDetail;
  isCanChangeOnCreate: boolean;
  isCanDelete: boolean;
  dateChanged: string;
  dateCreated: string;
}

export type FileFormatDetail = FormatCtor | FormatFile | FormatPostworks | FormatSPR | FormatSPRTXTWIN1251 | FormatPSP;

export enum Format {
  FILES = "files",
  POSTWORKS = "postworks",
  CTOR = "ctor",
  SPR = "spr",
  SPRTXTWIN1251 = "sprTxtWin1251",
  PSP = "pst",
}

export interface FormatFile {
  type: Format.FILES;
  codec: string;
  ext: string;
  isAutoDetect: boolean;
}

export const defaultFormatFile: FormatFile = {
  type: Format.FILES,
  ext: "",
  isAutoDetect: true,
  codec: "",
};

export interface FormatCtor {
  type: Format.CTOR;
}

export interface FormatPostworks {
  type: Format.POSTWORKS;
}

export interface FormatSPR {
  type: Format.SPR;
}

export interface FormatSPRTXTWIN1251 {
  type: Format.SPRTXTWIN1251;
}

export interface FormatPSP {
  type: Format.PSP;
}
