export const phones: any = {
  russian: [
    "1",
    "1:",
    "a",
    "a:",
    "b",
    "b:",
    "d",
    "d:",
    "e:",
    "f",
    "f:",
    "g",
    "g:",
    "gx",
    "i",
    "i:",
    "j",
    "k",
    "k:",
    "l",
    "l:",
    "m",
    "m:",
    "n",
    "n:",
    "o:",
    "p",
    "p:",
    "r",
    "r:",
    "s",
    "s:",
    "S",
    "Ss",
    "t",
    "t:",
    "ts",
    "tS:",
    "u",
    "u:",
    "v",
    "v:",
    "x",
    "x:",
    "z",
    "z:",
    "Z",
  ],
  english: [
    "aa",
    "ae",
    "ah",
    "ao",
    "aw",
    "ay",
    "b",
    "ch",
    "d",
    "dh",
    "eh",
    "er",
    "ey",
    "f",
    "g",
    "hh",
    "ih",
    "iy",
    "jh",
    "k",
    "l",
    "m",
    "n",
    "ng",
    "ow",
    "oy",
    "p",
    "r",
    "s",
    "sh",
    "t",
    "th",
    "uh",
    "uw",
    "v",
    "w",
    "y",
    "z",
    "zh",
  ],
  azerbaijani: [
    "1",
    "2",
    "a",
    "b",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "S",
    "t",
    "tS",
    "u",
    "v",
    "y",
    "z",
    "Z",
  ],
  georgian: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "h:",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "T",
    "u",
    "v",
    "y",
    "z",
  ],
  german: [
    "@",
    "a",
    "A",
    "ai",
    "amp",
    "au",
    "b",
    "d",
    "e",
    "E",
    "f",
    "g",
    "G",
    "h",
    "i",
    "I",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "O",
    "oi",
    "p",
    "r",
    "s",
    "S",
    "t",
    "ts",
    "u",
    "U",
    "v",
    "W",
    "x",
    "y",
    "Y",
    "z",
  ],
  hebrew: [
    "-",
    "?",
    "?\\",
    "a",
    "b",
    "d",
    "dZ",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "R",
    "s",
    "S",
    "t",
    "ts",
    "tS",
    "u",
    "v",
    "X",
    "X\\",
    "z",
    "Z",
  ],
  turkish: [
    "1",
    "2",
    "5",
    "a",
    "b",
    "c",
    "d",
    "dZ",
    "e",
    "f",
    "g",
    "gj",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "S",
    "t",
    "tS",
    "u",
    "v",
    "y",
    "z",
    "Z",
  ],
  ukrainian: [
    "1",
    "1:",
    "a",
    "a:",
    "b",
    "b:",
    "d",
    "d:",
    "e:",
    "f",
    "f:",
    "g",
    "g:",
    "gx",
    "i",
    "i:",
    "j",
    "k",
    "k:",
    "l",
    "l:",
    "m",
    "m:",
    "n",
    "n:",
    "o:",
    "p",
    "p:",
    "r",
    "r:",
    "s",
    "s:",
    "S",
    "Ss",
    "t",
    "t:",
    "ts",
    "tS:",
    "u",
    "u:",
    "v",
    "v:",
    "x",
    "x:",
    "z",
    "z:",
    "Z",
    "е:",
  ],
  farsi: [
    "a",
    "ā",
    "b",
    "č",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "š",
    "t",
    "u",
    "v",
    "x",
    "y",
    "z",
    "ž",
  ],
  arabic: [
    "$",
    "a",
    "A",
    "b",
    "d",
    "D",
    "E",
    "f",
    "g",
    "G",
    "h",
    "H",
    "i",
    "I",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "S",
    "t",
    "T",
    "u",
    "U",
    "v",
    "V",
    "w",
    "x",
    "y",
    "z",
    "Z",
  ],
};
