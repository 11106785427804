import React, { FC } from "react";
import { Label } from "types/label";
import { routes } from "routes";
import { Group } from "types/queryBuilder";
import { useHistory } from "react-router-dom";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 3,
    display: "flex",
    alignItems: "center",
  },
  label: {
    cursor: "pointer",
    maxWidth: 70,
    borderRadius: 3,
    padding: "0 5px",
    height: 20,
    color: "white",
    marginRight: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: 1.6,
  },
}));

const LabelsRenderer: FC<{ value: Label[] }> = ({ value }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (event: any, labelId: number) => {
    event.preventDefault();

    const { path } = routes.results;

    const group: Group = {
      type: "group",
      uuid: "1",
      operation: "AND",
      filters: [{ type: "filter", uuid: "2", filter: "Маркер", condition: "=", value: String(labelId) }],
    };

    const url = path + "?f=" + JSON.stringify(group);
    history.push(encodeURI(url));
  };

  if (value) {
    return (
      <div className={classes.root}>
        {value.map((l) => (
          <div
            onClick={(e) => handleClick(e, l.id)}
            key={l.id}
            title={l.name + "\n\n" + l.comment}
            className={classes.label}
            style={{
              backgroundColor: l.color,
            }}
          >
            {l.name}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default LabelsRenderer;
