import React, { FC, MouseEvent, useEffect, useState } from "react";
import { HistoryFilter } from "types/admin";
import FilterService from "services/admin/FilterService";
import { convertFilterName } from "functions/common";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import Divider from "@material-ui/core/Divider";
import { Group as TGroup } from "types/queryBuilder";

const useStyles = makeStyles(() => ({
  root: {},
  button: {},
  clear: {
    display: "flex",
    alignItems: "center",
  },
  clearText: {
    marginLeft: 10,
  },
  list: {
    minWidth: 300,
    maxHeight: 400,
    overflow: "auto",
  },
  empty: {
    height: 22,
    width: 300,
  },
}));

interface Props {
  setFilter: Function;
}

const HistoryFilters: FC<Props> = ({ setFilter }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filters, setFilters] = useState<HistoryFilter[]>([]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearHistory = () => {
    FilterService.clearHistory().then(() => {
      setFilters([]);
      handleClose();
    });
  };

  const handleSetFilter = (id: number) => {
    const filter = filters.find((f) => f.id === id);
    if (filter) {
      try {
        const f: TGroup = JSON.parse(filter.filter);
        if (f.type === "group") setFilter(f);
      } catch (e) {}
      handleClose();
    }
  };

  useEffect(() => {
    FilterService.getHistory().then(({ data }) => {
      setFilters(data);
    });
  }, [anchorEl]);

  return (
    <div className={classes.root}>
      <IconButton className={classes.button} onClick={handleClick} title="История запросов">
        <RestoreIcon fontSize="inherit" />
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <div className={classes.list}>
          {filters.map((f) => {
            return (
              <MenuItem key={f.id} onClick={() => handleSetFilter(f.id)}>
                {convertFilterName(f.filter)}
              </MenuItem>
            );
          })}
          {filters.length === 0 && (
            <MenuItem disabled>
              <div className={classes.empty} />
            </MenuItem>
          )}
        </div>
        <Divider />
        <MenuItem disabled={filters.length === 0}>
          <div className={classes.clear} onClick={handleClearHistory}>
            <DeleteIcon color="inherit" />
            <div className={classes.clearText}>Очистить</div>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HistoryFilters;
