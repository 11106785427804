import { useCallback, useEffect, useState } from "react";
import { RecordFile } from "types";
import FileService from "services/FileService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  rowData: RecordFile[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  rowData: [],
  error: undefined,
};

const useRecordFiles = (recordId: number) => {
  const [state, setState] = useState<State>(initialState);

  const polling = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));
    FileService.getByRecordId(recordId)
      .then(({ data }) => {
        setState(() => ({ rowData: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ rowData: [], loading: false, error: err }));
      });
  }, [recordId]);

  useEffect(() => {
    polling();
  }, [polling]);

  return { ...state, polling };
};

export default useRecordFiles;
