import React, { FC } from "react";
import AudioEditor from "components/audioEditor/AudioEditor";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles(() => ({
  body: {
    // padding: 20,
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
}

const UploadFileDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="lg">
      <div className={classes.body}>
        <AudioEditor onClose={onClose} f={undefined} />
      </div>
    </Dialog>
  );
};

export default UploadFileDialog;
