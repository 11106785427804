export const SET_REMOVE_RECORDS_ACCESS = "SET_REMOVE_RECORDS_ACCESS";
export const SET_SHOW_NAMESPACE_NAME = "SET_SHOW_NAMESPACE_NAME";
export const SET_SHOW_SPEAKERS_NAME = "SET_SHOW_SPEAKERS_NAME";
export const SET_SHOW_AUTOINFORMATORS_NAME = "SET_SHOW_AUTOINFORMATORS_NAME";

export interface AccessState {
  isCanDelete: boolean;
  isShowNameSpaceName: boolean;
  isShowSpeakersName: boolean;
  isShowAutoInformatorsName: boolean;
}

export type AccessAction =
  | { type: typeof SET_REMOVE_RECORDS_ACCESS; payload: boolean }
  | { type: typeof SET_SHOW_NAMESPACE_NAME; payload: boolean }
  | { type: typeof SET_SHOW_SPEAKERS_NAME; payload: boolean }
  | { type: typeof SET_SHOW_AUTOINFORMATORS_NAME; payload: boolean };
