import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { GridApi } from "ag-grid-community";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "redux/actions/alertActions";
import useLidModels from "hooks/admin/useLidModels";
import { removeRows } from "components/agGrid/functions";
import { LidModel } from "types/admin/lidModel";
import LidModelService from "services/admin/LidModelService";

// components
import ConfirmationDialog from "components/ConfirmationDialog";
import LidModelsTable from "./components/LidModelsTable";
import CreateLidModelDialog from "./components/CreateLidModelDialog";
import EditLidModelDialog from "./components/EditLidModelDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
}));

const LidModels: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { lidModels: rowData, getLidModels } = useLidModels();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<LidModel[]>([]);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi === undefined) return;
    const rows = gridApi.getSelectedRows();
    setSelectedRows(rows);
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows
        .filter((el) => !el.isSystem)
        .map((el) => el.id)
        .join(",");
      LidModelService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data !== undefined) {
      data.similarity = parseFloat(data.similarity);
      LidModelService.create(data)
        .then(getLidModels)
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseUpdateDialog = (data?: LidModel) => {
    setOpenUpdateDialog(false);
    if (data !== undefined) {
      data.similarity = parseFloat(String(data.similarity));
      LidModelService.update(data)
        .then(getLidModels)
        .catch((err) => catchError(err.response.data));
    }
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.buttons}>
          <div>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => getLidModels()}
            >
              Обновить
            </Button>
            <TextField
              className={classes.search}
              placeholder="Имя"
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={selectedRows.length === 0}
            >
              Удалить
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => setOpenCreateDialog(true)}
            >
              Создать
            </Button>
          </div>
        </div>
      </div>

      <LidModelsTable rowData={rowData} setGridApi={setGridApi} onSelection={onSelection} />

      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />

      <CreateLidModelDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />

      {selectedRows.length === 1 && (
        <EditLidModelDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} lidModel={selectedRows[0]} />
      )}
    </Paper>
  );
};

export default LidModels;
