import React, { FC, useEffect, useRef, useState } from "react";
import { Message as MessageType } from "types/message";
import moment from "moment";
import clsx from "clsx";
import MessageService from "services/MessageService";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { green, grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  isViewed: {
    backgroundColor: grey[300],
  },
  pos: {
    marginBottom: 12,
  },
  green: {
    color: green[300],
  },
  grey: {
    color: grey[800],
  },
}));

interface Props {
  m: MessageType;
  scrollToId: number | undefined;
}

const Message: FC<Props> = ({ m, scrollToId }) => {
  const classes = useStyles();
  const ref = useRef<null | HTMLDivElement>(null);
  const [Message, setMessage] = useState(m);
  const { id, message, dateCreated, isViewed } = Message;

  const date = moment(dateCreated);

  const onMessageClick = () => {
    if (isViewed) return;

    MessageService.setViewed(id).then(({ data }) => {
      setMessage((prev) => ({ ...prev, isViewed: true }));
    });
  };

  useEffect(() => {
    if (!ref) return;
    if (!ref.current) return;
    if (scrollToId === undefined) return;
    if (scrollToId !== m.id) return;
    ref.current.scrollIntoView();
  }, [scrollToId, m]);

  useEffect(() => {
    setMessage(m);
  }, [m]);

  return (
    <Card
      className={clsx(classes.root, { [classes.isViewed]: !isViewed })}
      variant="outlined"
      onClick={onMessageClick}
      ref={ref}
    >
      <CardContent>
        <div className={classes.title}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {date.isValid() ? date.format("DD-MM-YYYY HH:mm:ss") : ""}
          </Typography>
          <div>
            {isViewed && <DoneAllIcon className={classes.grey} />}
            {!isViewed && <CheckIcon />}
          </div>
        </div>

        <Typography variant="body2" component="p">
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Message;
