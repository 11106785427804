import React, { FC } from "react";
import { Passport } from "types/admin";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import CheckboxRenderer from "components/agGrid/renderers/CheckboxRenderer";
import BooleanRender from "components/agGrid/renderers/BooleanRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 151px)",
  },
}));

const defaultColDef = {
  resizable: true,
  flex: 1,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Имя",
    field: "name",
    width: 200,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Комментарий",
    field: "comment",
  },
  {
    headerName: "Системный",
    field: "isDeletable",
    cellRenderer: "BooleanRender",
  },
  {
    headerName: "Активен",
    field: "isActive",
    cellRenderer: "CheckboxRenderer",
  },
];

interface Props {
  rowData: Passport[];
  setGridApi: Function;
  onSelection: Function;
}

const PassportsTable: FC<Props> = ({ rowData, setGridApi, onSelection }) => {
  const classes = useStyles();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: Passport) => String(data.id);

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        frameworkComponents={{
          CheckboxRenderer,
          BooleanRender,
        }}
      />
    </div>
  );
};

export default PassportsTable;
