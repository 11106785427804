import { dictionaries } from "http/urls";
import { Dictionary, DictWord } from "types/dictionary";
import api from "http/api";
import { AxiosResponse } from "axios";

interface BodyDict {
  id: number;
  name: string;
  color: string;
  comment: string;
}

interface BodyWord {
  id: number;
  name: string;
  dictId: number;
  comment: string;
}

function DictionaryService() {
  function getAll(offset = 0, count = 1000, q = ""): Promise<AxiosResponse<Dictionary[]>> {
    const url = dictionaries.getAll.replace(":offset", String(offset)).replace(":count", String(count)) + q;
    return api.get<Dictionary[]>(url);
  }

  function getCount(query = "") {
    const url = dictionaries.count + query;
    return api.get(url);
  }

  function create(body: BodyDict): Promise<AxiosResponse> {
    const url = dictionaries.create;
    return api.post(url, body);
  }

  function update(body: BodyDict): Promise<AxiosResponse> {
    const url = dictionaries.update;
    return api.put(url, body);
  }

  function remove(ids: string): Promise<AxiosResponse> {
    const url = dictionaries.remove.replace(":ids", ids);
    return api.delete(url);
  }

  function getWords(dictId: string, offset = 0, count = 100000): Promise<AxiosResponse<DictWord[]>> {
    const url = dictionaries.words.getAll
      .replace(":dictId", String(dictId))
      .replace(":offset", String(offset))
      .replace(":count", String(count));

    return api.get<DictWord[]>(url);
  }

  function createWord(dictId: number, body: BodyWord): Promise<AxiosResponse> {
    const url = dictionaries.words.add.replace(":dictId", String(dictId));
    return api.post(url, body);
  }

  function updateWord(dictId: number, body: BodyWord): Promise<AxiosResponse> {
    const url = dictionaries.words.add.replace(":dictId", String(dictId));
    return api.put(url, body);
  }

  function removeWord(dictId: number, ids: string): Promise<AxiosResponse> {
    const url = dictionaries.words.remove.replace(":dictId", String(dictId)).replace(":ids", ids);
    return api.delete(url);
  }

  return Object.freeze({
    getAll,
    getCount,
    create,
    update,
    remove,
    getWords,
    createWord,
    updateWord,
    removeWord,
  });
}

export default DictionaryService();
