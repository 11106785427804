import React, { FC } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ruRU } from "@material-ui/core/locale";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

const MaterialUiThemeProvider: FC = ({ children }) => {
  const palette = useSelector((state: RootState) => state.theme);
  const theme = createMuiTheme(palette, ruRU);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialUiThemeProvider;
