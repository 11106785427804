import React, { ChangeEvent, FC, useEffect, useState } from "react";
import useQueryString from "hooks/useQueryString";
import ListService from "services/ListService";

// components
import List from "./components/List";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    height: "calc(100vh - 90px)",
    display: "flex",
    justifyContent: "space-between",
  },
  rightPanel: {
    flexBasis: 300,
  },
  leftPanel: {
    overflow: "auto",
    flexBasis: "fit-content",
  },
  lButtons: {
    textAlign: "left",
  },
  lists: {
    display: "flex",
    flexWrap: "wrap",
  },
  mr10: {
    marginRight: 10,
  },
  rButtons: {
    textAlign: "right",
    marginBottom: 10,
  },
  textarea: {
    border: "1px solid " + grey[300],
    height: "calc(100vh - 170px)",
    width: "100%",
    padding: 10,
  },
  count: {
    fontSize: theme.typography.fontSize,
  },
}));

interface ListEl {
  id: number;
  value: string;
}

const Compare: FC = () => {
  const NO_DATA_TEXT = "Нет данных для отображения";
  const classes = useStyles();
  const queryParams = useQueryString();

  const [lists, setLists] = useState<ListEl[]>([{ id: 0, value: "" }]);

  const [result, setResult] = useState("");

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setResult(event.target.value);
  };

  const removeList = (id: number) => {
    setLists((prev) => prev.filter((el) => el.id !== id));
  };

  const setListValue = (id: number, val: string) => {
    setLists((prev) => prev.map((el) => (el.id === id ? { id: el.id, value: val } : el)));
  };

  // находит уникальные элементы во всех списках
  const handleUnique = () => {
    const arr: string[] = [];
    for (let i = 0; i < lists.length; i++) {
      arr.push(...lists[i].value.split("\n").filter((el) => el !== ""));
    }
    const unique = arr.filter((item, i, ar) => ar.indexOf(item) === i);
    const res = unique.join("\n");
    setResult(res.length === 0 ? NO_DATA_TEXT : res);
  };

  // находит общие или нет элементы в списках
  const handleSameOrNot = (op: "same" | "notSame") => {
    const res: string[] = [];
    const map = new Map();
    for (let i = 0; i < lists.length; i++) {
      const l = lists[i];
      const arr = l.value
        .split("\n")
        .filter((el) => el !== "")
        .filter((item, i, ar) => ar.indexOf(item) === i);
      for (let i = 0; i < arr.length; i++) {
        const w = arr[i];
        const v = map.get(w);
        if (v !== undefined) {
          map.set(w, v + 1);
        }
        if (v === undefined) {
          map.set(w, 1);
        }
      }
    }
    const z = op === "same" ? lists.length : 1;
    map.forEach((v, k) => {
      if (v === z) {
        res.push(k);
      }
    });
    setResult(res.length === 0 ? NO_DATA_TEXT : res.join("\n"));
  };

  const getCount = () => {
    if (result === NO_DATA_TEXT || result === "") {
      return 0;
    }
    return result.split("\n").filter((el) => el !== "").length;
  };

  useEffect(() => {
    const ids = queryParams.get("lists");
    if (ids) {
      const idsArr = ids.split(",").map((el) => Number(el));
      const res: ListEl[] = [];
      ListService.getAll().then(({ data }) => {
        data.forEach((l) => {
          if (idsArr.includes(l.id)) {
            res.push({ id: l.id, value: l.list });
          }
        });
        setLists(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.root}>
      <div className={classes.leftPanel}>
        <div className={classes.lButtons}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setLists((prev) => [...prev, { id: Date.now(), value: "" }])}
          >
            Добавить
          </Button>
        </div>
        <div className={classes.lists}>
          {lists.map((l) => (
            <List key={l.id} list={l} setListValue={setListValue} remove={removeList} />
          ))}
        </div>
      </div>

      <div className={classes.rightPanel}>
        <div className={classes.rButtons}>
          <Button
            title="В результате будет показан список, из которого убраны все повторяющиеся елементы во ВСЕХ списках"
            size="small"
            variant="contained"
            color="primary"
            className={classes.mr10}
            onClick={handleUnique}
          >
            Уникальные
          </Button>
          <Button
            title="В результате будет показан список из элементов, которые встречаются во ВСЕХ списках"
            size="small"
            variant="contained"
            color="primary"
            className={classes.mr10}
            onClick={() => handleSameOrNot("same")}
          >
            Общие
          </Button>
          <Button
            title="В результате будет показан список из элементов, которые НЕ встречаются хотябы в одном списке"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleSameOrNot("notSame")}
          >
            Не общие
          </Button>
        </div>

        <textarea
          className={classes.textarea}
          rows={20}
          value={result}
          onChange={handleChange}
          placeholder="Результат"
        />
        <div className={classes.count}>Количество: {getCount()}</div>
      </div>
    </Paper>
  );
};

export default Compare;
