import { useEffect, useState } from "react";
import { LmModel } from "types/admin/lmModel";
import RecordService from "services/RecordService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  lmModels: LmModel[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  lmModels: [],
  error: undefined,
};

const usePublicLmModels = (shouldFetch?: boolean) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    if (shouldFetch) {
      setState((prev) => ({ ...prev, loading: true }));
      RecordService.getAllLmModels()
        .then(({ data }) => {
          setState({ lmModels: data, loading: false, error: undefined });
        })
        .catch((err) => {
          setState({ lmModels: [], loading: false, error: err });
        });
    }
  }, [shouldFetch]);

  return state;
};

export default usePublicLmModels;
