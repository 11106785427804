import { Statistic } from "types/statistic";
import { humanDuration } from "functions/common";
import { StatisticsDescription } from "./statisticsDescription";

export function createStatisticsFile(statistic: Statistic, filter: string) {
  let lidStr = "";
  for (let i = 0; i < statistic.langs.length; i++) {
    lidStr += `${statistic.langs[i].nameRus}: ${statistic.langs[i].count}\t${Math.floor(
      (statistic.langs[i].count * 100) / statistic.recordsCount
    )}%\n`;
  }

  return new Blob(
    [
      `
Статистика по выбранному фильтру: ${filter}

${StatisticsDescription.recordsCount} ${statistic.recordsCount}
${StatisticsDescription.recordsDuration} ${humanDuration(statistic.recordsDuration)}
${StatisticsDescription.speechDuration} ${humanDuration(statistic.speechDuration)}
${StatisticsDescription.speakersCount} ${statistic.speakersCount}
${StatisticsDescription.faxCount} ${statistic.faxCount}
${StatisticsDescription.srcPhoneNumbersCount} ${statistic.srcPhoneNumbersCount}
${StatisticsDescription.dstPhoneNumbersCount} ${statistic.dstPhoneNumbersCount}
${StatisticsDescription.srcIpAddressesCount} ${statistic.srcIpAddressesCount}
${StatisticsDescription.dstIpAddressesCount} ${statistic.dstIpAddressesCount} 
${StatisticsDescription.wordCount} ${statistic.wordCount} 

Статистика по языкам:
${lidStr}
    `,
    ],
    {
      type: "text/plain",
    }
  );
}
