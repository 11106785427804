import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Transcription as Tr } from "types/admin/lmModel";
import LmModelService from "services/admin/LmModelService";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";

// components
import Transcriptions from "./Transcriptions";
import SelectLang from "./SelectLang";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme) => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  textArea: {
    backgroundColor: "#f6f6ee",
    padding: theme.spacing(1),
    border: "none",
    outline: "none",
    width: "100%",
  },
  textAlignRight: {
    textAlign: "right",
  },
}));

interface State {
  lang: string;
  name: string;
  comment: string;
  text: string;
  transcriptions: string;
  isDefault: boolean;
}

const initialState: State = {
  lang: "russian",
  name: "",
  comment: "",
  text: "",
  transcriptions: "",
  isDefault: false,
};

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
}

const CreateLmModelDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState<State>(initialState);
  const [transcriptions, setTranscriptions] = useState<Tr[]>([]);
  const [loadingTranscriptions, setLoadingTranscriptions] = useState(false);
  const { name, comment, text, lang, isDefault } = state;

  const disabled = name.length === 0 || lang === "";

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = useCallback(() => {
    let tr = "";
    transcriptions.forEach((t) => {
      tr += `${t.word}\t${t.transcription}\n`;
    });
    onClose({ ...state, transcriptions: tr });
  }, [onClose, state, transcriptions]);

  const setLanguage = (lang: string) => {
    setState((prev) => ({ ...prev, lang }));
  };

  const addTranscriptions = (tr: Tr) => {
    setTranscriptions((prev) => [...prev, tr]);
  };

  const updateTranscriptions = (tr: Tr) => {
    setTranscriptions((prev) => prev.map((t) => (t.word === tr.word ? tr : t)));
  };

  const removeTranscriptions = (transcriptions: Tr[]) => {
    transcriptions.forEach((tr) => {
      setTranscriptions((prev) => prev.filter((t) => t.word !== tr.word));
    });
  };

  const handleChangeIsDefault = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, isDefault: event.target.checked });
  };

  const generateTranscription = () => {
    setLoadingTranscriptions(true);
    LmModelService.generateTranscriptions(state)
      .then(({ data }) => {
        const tr: Tr[] = [];
        const strTranscription = data.transcriptions ?? "";
        if (strTranscription === "") {
          dispatch(showErrorAlert("Транскрипции всех слов уже есть в системе"));
          return;
        }
        const splitNewLine = strTranscription.split("\n");
        splitNewLine.forEach((str: string) => {
          const splitByTab = str.split("\t");
          if (splitByTab.length === 2) {
            const [word, transcription] = splitByTab;
            tr.push({ word, transcription });
          }
        });
        setTranscriptions(tr);
      })
      .catch((err) => catchError(err.response.data))
      .finally(() => {
        setLoadingTranscriptions(false);
      });
  };

  useEffect(() => {
    if (!open) {
      setState(initialState);
      setTranscriptions([]);
    }
  }, [open]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Создать языковую модель</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField label="Имя" fullWidth name="name" onChange={handleChange} value={name} autoFocus />
          </Grid>
          <Grid item xs={6}>
            <SelectLang language={lang} setLanguage={setLanguage} excludeAuto />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isDefault} onChange={handleChangeIsDefault} color="primary" />}
              label="Использовать по умолчанию"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Данные</Typography>
            <textarea className={classes.textArea} rows={15} name="text" onChange={handleChange} value={text} />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Транскрипции новых слов</Typography>
            <Transcriptions
              lang={lang}
              transcriptions={transcriptions}
              addTranscriptions={addTranscriptions}
              updateTranscriptions={updateTranscriptions}
              removeTranscriptions={removeTranscriptions}
              generateTranscription={generateTranscription}
              disabled={text.length === 0}
              loading={loadingTranscriptions}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateLmModelDialog;
