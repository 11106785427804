import { useEffect, useState } from "react";
import { Module } from "types/admin";
import ModuleService from "services/admin/ModuleService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  rowData: Module[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  rowData: [],
  error: undefined,
};

const useModules = () => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    ModuleService.getAll()
      .then(({ data }) => {
        setState({ rowData: data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ rowData: [], loading: false, error: err });
      });
  }, []);

  return state;
};

export default useModules;
