import React, { useState, FC, ChangeEvent, useEffect } from "react";
import { GridApi } from "ag-grid-community";
import clsx from "clsx";
import useWords from "hooks/dictionary/useWords";
import { DictWord } from "types/dictionary";
import { useParams } from "react-router-dom";
import { checkError } from "functions/common";

// components
import ConfirmationDialog from "components/ConfirmationDialog";
import DictWordsTable from "./components/DictWordsTable";
import CreateDictWordDialog from "./components/CreateDictWordDialog";
import UpdateDictWordDialog from "./components/UpdateDictWordDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {},
  empty: {
    color: theme.palette.text.disabled,
    textAlign: "center",
  },
  progress: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  ml4: {
    marginLeft: theme.spacing(4),
  },
}));

const DictWords: FC = () => {
  const classes = useStyles();
  const { dictId } = useParams();

  const { loading, rowData, error } = useWords(dictId);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<DictWord[]>([]);

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const agGridAddRows = (rows: any[]) => {
    if (gridApi) {
      gridApi.applyTransaction({
        add: rows,
      });
    }
  };

  const agGridUpdateRow = (row: any) => {
    if (gridApi) {
      const node = gridApi.getRowNode(String(row.id));
      node.setData(row);
    }
  };

  const handleCloseCreateDictionaryDialog = (data?: any) => {
    setOpenCreateDialog(false);

    if (data !== undefined) {
      // todo: исправить после подключения настоящего бэка
      const newDict: any = {
        id: Date.now(),
        ...data,
      };
      agGridAddRows([newDict]);
    }
  };

  const handleDeleteDict = () => {
    setOpenDeleteConfirmationDialog(true);
  };

  const onCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteConfirmationDialog(false);
    if (confirm) {
      agGridRemoveSelectedRows();
    }
  };

  const agGridRemoveSelectedRows = () => {
    if (gridApi) {
      const selectedData = gridApi.getSelectedRows();
      gridApi.applyTransaction({ remove: selectedData });
      setSelectedRows([]);
    }
  };

  const handleCloseUpdateDictionaryDialog = (dict?: any) => {
    setOpenUpdateDialog(false);
    if (dict !== undefined) {
      agGridUpdateRow(dict);
    }
  };

  useEffect(() => {
    checkError(error);
  }, [error]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.actions}>
              <div className={classes.buttons}>
                <div>
                  <TextField
                    className={classes.search}
                    placeholder="Слово"
                    onChange={onQuickFilterChanged}
                    InputProps={{
                      className: classes.searchInput,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={selectedRows.length === 0}
                    onClick={handleDeleteDict}
                  >
                    Удалить
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={selectedRows.length !== 1}
                    onClick={() => setOpenUpdateDialog(true)}
                  >
                    Редактировать
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={clsx(classes.button, classes.ml4)}
                    onClick={() => setOpenCreateDialog(true)}
                  >
                    Создать
                  </Button>
                </div>
              </div>
            </div>

            {loading ? (
              <div className={classes.progress}>
                <CircularProgress />
              </div>
            ) : (
              <DictWordsTable rowData={rowData} setGridApi={setGridApi} onSelection={onSelection} />
            )}
          </Paper>
        </Grid>
      </Grid>

      <ConfirmationDialog open={openDeleteConfirmationDialog} onClose={onCloseDeleteDialog} />
      <CreateDictWordDialog open={openCreateDialog} onClose={handleCloseCreateDictionaryDialog} />

      {selectedRows.length === 1 && (
        <UpdateDictWordDialog
          open={openUpdateDialog}
          onClose={handleCloseUpdateDictionaryDialog}
          word={selectedRows[0]}
        />
      )}
    </div>
  );
};

export default DictWords;
