import { AxiosResponse } from "axios";
import { serviceMenu } from "http/urls";
import api from "http/api";
import { MenuItem } from "types";

function MenuService() {
  async function getAll(baseURL: string): Promise<AxiosResponse<MenuItem[]>> {
    try {
      return api.get<MenuItem[]>(serviceMenu.getAll, { baseURL });
    } catch (err) {
      throw err;
    }
  }

  return Object.freeze({
    getAll,
  });
}

export default MenuService();
