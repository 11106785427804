import React, { FC, useMemo } from "react";
import Filter from "components/filter/Filter";
import { Group } from "types/queryBuilder";
import { AdminTypeFilter } from "types/admin";
import { defaultFilter } from "functions/common";

// material ui
import Typography from "@material-ui/core/Typography";

interface PropsSourceFilter {
  title: string;
  filter: Group;
  setFilter: Function;
  availableFilters: AdminTypeFilter[];
}

const SourceFilter: FC<PropsSourceFilter> = ({ title, filter, setFilter, availableFilters }) => {
  return (
    <div>
      <Typography gutterBottom>{title}</Typography>
      <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
    </div>
  );
};

interface PropsPrepare2 {
  title: string;
  filter: string;
  setFilter: Function;
  availableFilters: AdminTypeFilter[];
}

const PrepareStage2: FC<PropsPrepare2> = ({ title, filter, setFilter, availableFilters }) => {
  const group: Group = useMemo(() => {
    let f = defaultFilter();
    // если фильтр не пустая строка, пробуем его распарсить
    if (filter !== "") {
      try {
        f = JSON.parse(filter);
      } catch (err) {}
    }
    return f;
  }, [filter]);
  return <SourceFilter title={title} filter={group} setFilter={setFilter} availableFilters={availableFilters} />;
};

interface PropsPrepare1 {
  title: string;
  filter: string;
  setFilter: Function;
  availableFilters: AdminTypeFilter[];
}

const PrepareStage1: FC<PropsPrepare1> = ({ title, filter, setFilter, availableFilters }) => {
  if (availableFilters.length === 0) return null;
  return <PrepareStage2 title={title} filter={filter} setFilter={setFilter} availableFilters={availableFilters} />;
};

export default PrepareStage1;
