import React, { FC, ChangeEvent, ReactNode, useState, useEffect, MouseEvent } from "react";
import clsx from "clsx";
import useFilters from "hooks/useFilters";
import { GridApi } from "ag-grid-community";
import { Filter } from "types";
import { HistoryFilter } from "types/admin";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import FilterNameRenderer from "components/agGrid/renderers/FilterNameRenderer";
import IsCanWriteRenderer from "components/agGrid/renderers/IsCanWriteRenderer";
import FilterService from "services/admin/FilterService";
import { convertFilterName } from "functions/common";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 500,
  },
  buttons: {
    textAlign: "right",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
  table: {
    height: 436,
    paddingTop: 5,
  },
  list: {
    overflow: "auto",
    height: 436,
  },
  listItem: {
    fontSize: theme.typography.fontSize,
    borderBottom: "1px solid " + grey[300],
  },
  empty: {},
}));

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Имя",
    field: "name",
    width: 200,
    cellRenderer: "FilterNameRenderer",
    checkboxSelection: true,
  },
  {
    headerName: "Комментарий",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

interface Props {
  open: boolean;
  onClose: Function;
}

const UploadFilterDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const { filters, getFilters } = useFilters();
  const [historyFilters, setHistoryFilters] = useState<HistoryFilter[]>([]);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Filter[]>([]);

  const [activeTab, setActiveTab] = useState(0);

  const [selectedHistoryFilterId, setSelectedHistoryFilterId] = useState(-1);

  const handleListItemClick = (event: MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedHistoryFilterId(index);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
      if (selectedHistoryFilterId !== -1) {
        setSelectedHistoryFilterId(-1);
      }
    }
  };

  const handleChangeActiveTab = (event: ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
    if (selectedRows.length !== 0) {
      setSelectedRows([]);
      gridApi && gridApi.deselectAll();
    }
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const getRowNodeId = (data: Filter) => String(data.id);

  const handleSave = () => {
    if (selectedHistoryFilterId === -1 && selectedRows.length === 1) {
      onClose(...selectedRows);
      return;
    }

    if (selectedHistoryFilterId !== -1 && selectedRows.length === 0) {
      const historyFilter = historyFilters.find((f) => f.id === selectedHistoryFilterId);
      if (historyFilter !== undefined) {
        onClose(historyFilter);
      }
      return;
    }
    onClose();
  };

  useEffect(() => {
    if (!open) return;
    FilterService.getHistory().then(({ data }) => {
      setHistoryFilters(data);
    });
    getFilters();
  }, [open, getFilters]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Загрузить фильтр</DialogTitle>

      <DialogContent className={classes.root}>
        <Tabs value={activeTab} indicatorColor="primary" textColor="primary" onChange={handleChangeActiveTab} centered>
          <Tab label="Фильтры" />
          <Tab label="История" />
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <div className={clsx(classes.table, "ag-theme-balham")}>
            <AgGridReact
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              getRowNodeId={getRowNodeId}
              rowData={filters}
              columnDefs={columnDefs}
              localeText={localization}
              rowSelection="single"
              enableRangeSelection
              onSelectionChanged={onSelectionChanged}
              suppressDragLeaveHidesColumns
              frameworkComponents={{
                DateRenderer,
                FilterNameRenderer,
                IsCanWriteRenderer,
              }}
            />
          </div>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <List className={classes.list}>
            {historyFilters.map((f) => {
              // const filter = f.filter.length > 100 ? f.filter.slice(0, 100) + "..." : f.filter;
              const name = convertFilterName(f.filter);
              if (name === "") return null;
              return (
                <ListItem
                  className={classes.listItem}
                  button
                  key={f.id}
                  title={f.filter}
                  selected={selectedHistoryFilterId === f.id}
                  onClick={(event) => handleListItemClick(event, f.id)}
                >
                  {name}
                </ListItem>
              );
            })}
            {filters.length === 0 && (
              <ListItem disabled>
                <div className={classes.empty} />
              </ListItem>
            )}
          </List>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={selectedRows.length === 0 && selectedHistoryFilterId === -1}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFilterDialog;
