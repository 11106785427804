export type Operation = "AND" | "OR" | "NOT";
export type Condition = "=" | "!=" | "<" | ">";

export type Rule = {
  type: "filter";
  uuid: string;
  filter: string;
  condition: Condition;
  value: string;
};

export type Group = {
  type: "group";
  uuid: string;
  operation: Operation;
  filters: Array<Rule | Group>;
};

export interface HtmlSelectCondition {
  name: string;
  value: Condition;
  printValue: string;
}

export interface SelectedGroup {
  group: Group | undefined;
  parent: Group | undefined;
}

export interface SelectedRule {
  rule: Rule | undefined;
  parent: Group | undefined;
}

export enum FilterType {
  Date = "date",
  String = "string",
  Number = "number",
  Float = "float",
  Boolean = "boolean",
  Object = "object",
  Enums = "enums",
  Tree = "tree",
}
