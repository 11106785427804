import { useEffect, useState } from "react";
import { NbsCategory as T } from "types/nbsCategory";
import { AxiosError } from "axios";
import NbsCategoryService from "services/NbsCategoryService";

interface State {
  loading: boolean;
  nbsCategory: T[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: true,
  nbsCategory: [],
  error: undefined,
};

const useNbsCategory = () => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    NbsCategoryService.getAll()
      .then(({ data }) => {
        setState(() => ({ nbsCategory: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ nbsCategory: [], loading: false, error: err }));
      });
  }, []);

  return state;
};

export default useNbsCategory;
