import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    color: theme.palette.text.primary,
  },
}));

const CheckboxRenderer: FC<{ value: boolean }> = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {value ? (
        <CheckBoxIcon className={classes.checkbox} />
      ) : (
        <CheckBoxOutlineBlankIcon className={classes.checkbox} />
      )}
    </div>
  );
};

export default CheckboxRenderer;
