import React, { FC, useEffect, useMemo, useState } from "react";
import { RecordFileViewType } from "types";
import RecordService from "services/RecordService";
import NoData from "components/NoData";
import Loader from "components/Loader";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

// material ui
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  textarea: {
    width: "100%",
    border: "none",
    height: "calc(100vh - 237px)",
    padding: theme.spacing(2),
  },
  empty: {
    height: "calc(100vh - 237px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface Props {
  queryFilter: string;
  viewType: RecordFileViewType.BITS;
}

interface State {
  loading: boolean;
  text: string;
  error: undefined | Error;
}

const initialState: State = {
  loading: false,
  text: "",
  error: undefined,
};

const PlainText: FC<Props> = ({ queryFilter, viewType }) => {
  const classes = useStyles();
  const { sttBackgroundColor } = useSelector((state: RootState) => state.settings);
  const [state, setState] = useState(initialState);
  const { text, loading, error } = state;

  const style = useMemo(
    () => ({
      backgroundColor: sttBackgroundColor,
    }),
    [sttBackgroundColor]
  );

  useEffect(() => {
    const config = {
      responseType: "text",
    };
    const q = "?q=" + queryFilter;
    setState({ loading: true, error: undefined, text: "" });
    RecordService.getFileData(viewType, q, config)
      .then(({ data }) => {
        setState({ loading: false, error: undefined, text: data });
      })
      .catch((error) => {
        setState({ loading: false, error, text: "" });
      });
  }, [viewType, queryFilter]);

  if (loading) {
    return (
      <div className={classes.empty}>
        <Loader />
      </div>
    );
  }

  if (error || text === "") {
    return (
      <div className={classes.empty}>
        <NoData />
      </div>
    );
  }

  return (
    <div className={classes.root} style={style}>
      <textarea className={classes.textarea} defaultValue={text} disabled />
    </div>
  );
};

export default PlainText;
