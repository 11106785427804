import React, { ChangeEvent, FC, useMemo } from "react";
import { FilterType } from "types/queryBuilder";
import conditions from "./conditions";

// material ui
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

interface Props {
  condition: string;
  handleChange: (event: ChangeEvent<{ value: unknown }>) => void;
  filterType: string;
}

const Condition: FC<Props> = ({ condition, handleChange, filterType }) => {
  const filterConditions = useMemo(() => {
    // Date
    if (filterType === FilterType.Date) {
      return conditions.filter(({ value }) => value === "=");
    }
    // String, boolean, enums, object, tree
    if (
      filterType === FilterType.String ||
      filterType === FilterType.Boolean ||
      filterType === FilterType.Enums ||
      filterType === FilterType.Object ||
      filterType === FilterType.Tree
    ) {
      return conditions.filter(({ value }) => value === "=" || value === "!=");
    }
    // Number
    if (filterType === FilterType.Number) {
      return conditions.filter(({ value }) => value === "=" || value === "!=" || value === ">" || value === "<");
    }
    // Float
    if (filterType === FilterType.Float) {
      return conditions.filter(({ value }) => value === ">" || value === "<");
    }
    return conditions;
  }, [filterType]);

  return (
    <Select value={condition} onChange={handleChange} fullWidth>
      {filterConditions.map((condition, idx) => (
        <MenuItem key={idx} value={condition.value}>
          {condition.printValue}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Condition;
