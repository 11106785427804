import { Status } from "types/common";
import { FolderSftp, FolderSmb } from "./source";

export interface Task {
  id: number;
  name: string;
  comment: string;
  isActive: boolean;
  taskDetail: ProcessTaskDetail;
  ownerId: number;
  dateChanged: string;
  dateCreated: string;
  status: Status;
  message: string;
  taskInProcessed: number;
  taskWeight: number;
  isCanRead: boolean;
  isCanWrite: boolean;
}

export interface ExportTask {
  id: number;
  name: string;
  comment: string;
  isActive: boolean;
  taskDetail: ExportTaskDetail;
}

export interface ExportTaskDetail {
  type: TaskDetailType.Export;
  filterDetail: string; // filter всегда String
  storageDetail: FolderSftp | FolderSmb;
  isExportOrigFiles: boolean;
  formatAudioFile: "" | "wav" | "mp3";
  formatTextFile: "" | "txt" | "docx" | "pdf";
  formatText: "text" | "translate" | "textAndTranslate";
  mask: string;
}

export interface ProcessTaskDetail {
  type: TaskDetailType.Process;
  filterDetail: string;
  techDetail: TaskTechDetail;
  exports: ExportTask[];
}

export interface TaskTechDetail {
  isForce: boolean;
  techs: TaskTech[];
}

export interface CreateSpeakerTask {
  id: number;
  name: string;
  comment: string;
  isActive: boolean;
  taskDetail: {
    type: TaskDetailType.CreateSpeaker;
    ids: number[];
  };
}

export enum TaskDetailType {
  Process = "taskProcessRecord",
  Export = "taskExportRecord",
  CreateSpeaker = "taskCreateSpeaker",
  CreateLmModel = "taskCreateLanguageModel",
}

export type VadType = "allFile" | "vad" | "vadUkv";

export interface Lang {
  id: string;
  isActive: boolean;
  name: string;
}

export enum TechType {
  LID = "lid",
  VAD = "vad",
  STT = "stt",
  FAX = "fax",
  SID = "sid",
  GID = "gid",
  AGE = "age",
  TRANSLATE = "translate",
  QUALITY = "quality",
  NOISE = "noise",
  ECHO = "echo",
  DIARIZATION = "diarization",
  NORMILIZE = "normilize",
  FILTER_DECLICK = "filterDeclick",
  FILTER_KTCH = "filterKTCH",
  FILTER_DENOISE = "filterDenoise",
  FILTER_ECHO = "filterEcho",
  OCR = "ocr",
}

export type TaskTech =
  | TaskTechStt
  | TaskTechDiarization
  | TaskTechLid
  | TaskTechVad
  | TaskTechOcr
  | TaskTechFax
  | TaskTechSid
  | TaskTechTranslate;

export interface TaskTechStt {
  id: string;
  type: TechType.STT;
  lang: string;
  languageModelId: number;
  isEnableWord2Num: boolean;
  parasiteWordId: number;
}

export interface TaskTechDiarization {
  id: string;
  type: TechType.DIARIZATION;
  speakerCount: number;
}

export interface TaskTechLid {
  id: string;
  type: TechType.LID;
  langs: string[];
}

export interface TaskTechVad {
  id: string;
  type: TechType.VAD;
  vadType: VadType;
}

export interface TaskTechOcr {
  id: string;
  type: TechType.OCR;
  lang: string;
}

export interface TaskTechFax {
  id: string;
  type: TechType.FAX;
  isActiveOpticalRecognition: boolean;
}

export interface TaskTechSid {
  id: string;
  type: TechType.SID;
  ids: string | number[];
}

export interface TaskTechTranslate {
  id: string;
  type: TechType.TRANSLATE;
  lang: string;
}
