import { useEffect, useState } from "react";
import { Passport } from "types/admin";
import PassportsService from "services/admin/PassportsService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  passports: Passport[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  passports: [],
  error: undefined,
};

const usePublicPassports = () => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    PassportsService.getAllPublic()
      .then((res) => {
        setState({ passports: res.data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ passports: [], loading: false, error: err });
      });
  }, []);

  return state;
};

export default usePublicPassports;
