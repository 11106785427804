import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { App } from "types/admin/server";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 15px",
  },
  button: {
    width: 100,
  },
}));

interface Props {
  s: {
    open: boolean;
    app: App;
  };
  onClose: (data?: App) => void;
}

const UpdateDialog: FC<Props> = ({ s, onClose }) => {
  const classes = useStyles();
  const { open, app } = s;

  const [appPort, setAppPort] = useState("custom");
  const [ip, setIp] = useState("");
  const [port, setPort] = useState("");
  const [comment, setComment] = useState("");

  const disabled = useMemo(() => ip.length === 0 || port.length === 0, [ip, port]);

  // обработчик нажатия клавиш
  const handleKeyDown = useCallback(
    (event) => {
      if (!open) return;
      const { key } = event;

      if (key === "Enter" ?? disabled) {
        event.preventDefault();
        event.stopPropagation();

        const updated: App = {
          ...app,
          host: `${ip}:${port}`,
          comment,
        };
        onClose(updated);
      }
    },
    [open, onClose, app, ip, port, comment, disabled]
  );

  const handleChangeAppPort = (event: ChangeEvent<{ value: unknown }>) => {
    const port = event.target.value as string;
    setAppPort(port);
    if (port !== "custom") {
      setPort(port);
    }
  };

  const save = () => {
    const updated: App = {
      ...app,
      host: `${ip}:${port}`,
      comment,
    };
    onClose(updated);
  };

  useEffect(() => {
    const { host } = app;
    const arr = host.split(":");
    if (arr.length !== 2) return;

    const ip = arr[0];
    const port = arr[1];

    setIp(ip);
    setPort(port);

    const appPorts = ["1400", "1404", "1412"];
    setAppPort(appPorts.includes(port) ? port : "custom");
  }, [app]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      className={classes.root}
      fullWidth
      maxWidth="md"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Изменить программу</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Ip адрес" fullWidth name="ip" onChange={(e) => setIp(e.target.value)} value={ip} />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Порт</InputLabel>
              <Select labelId="select-label" value={appPort} onChange={handleChangeAppPort} fullWidth>
                <MenuItem value="1400">1400 (replica7-api-gateway)</MenuItem>
                <MenuItem value="1404">1404 (replica7-storage)</MenuItem>
                <MenuItem value="1412">1412 (replica7-worker)</MenuItem>
                <MenuItem value="custom">Задать вручную</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {appPort === "custom" && (
            <Grid item xs={12}>
              <TextField label="Порт" fullWidth name="port" onChange={(e) => setPort(e.target.value)} value={port} />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              name="comment"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={save}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDialog;
