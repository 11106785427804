import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { setSttConfidenceHighlight } from "redux/actions/settingsActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  t: {
    marginRight: 10,
  },
  v: {
    marginLeft: 10,
  },
}));

const ConfidenceHighlight: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { confidenceHighlight } = useSelector((state: RootState) => state.settings);

  const handleChange = (event: any, newValue: number | number[]) => {
    dispatch(setSttConfidenceHighlight(newValue as number));
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.t}>{"Порог: "}</Typography>
      <Slider
        defaultValue={confidenceHighlight}
        onChangeCommitted={handleChange}
        aria-labelledby="confidence-slider"
        valueLabelDisplay="auto"
        marks
        step={0.1}
        min={0.1}
        max={1}
      />
      <Typography className={classes.v}>{confidenceHighlight}</Typography>
    </div>
  );
};

export default ConfidenceHighlight;
