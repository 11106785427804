import React, { ChangeEvent, FC, useCallback, useState } from "react";
import useModules from "hooks/admin/useModules";
import { GridApi } from "ag-grid-community";
import ModulesTable from "./components/ModulesTable";
import EditModuleDialog from "./components/EditModuleDialog";
import { Module } from "types/admin";
import ModuleService from "services/admin/ModuleService";
import AgGridService from "services/AgGridService";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
}));

const Modules: FC = () => {
  const classes = useStyles();
  const { rowData } = useModules();
  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Module[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editModule, setEditModule] = useState<Module | undefined>(undefined);

  const gridService = AgGridService(gridApi);

  const alertError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseEditDialog = (updatedModule?: Module) => {
    setOpenEditDialog(false);
    if (updatedModule) {
      ModuleService.update(updatedModule)
        .then(() => {
          gridService.updateRow(updatedModule);
          gridService.deselectAll();
          setSelectedRows([]);
        })
        .catch(alertError);
    }
  };

  const handleEditButtonClick = () => {
    setEditModule(undefined);
    const { id } = selectedRows[0];
    ModuleService.getById(id)
      .then((res) => {
        setEditModule(res.data);
        setOpenEditDialog(true);
      })
      .catch((err) => {
        dispatch(showErrorAlert(err.response?.data.message));
      });
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.buttons}>
          <div>
            <TextField
              className={classes.search}
              placeholder="Имя модуля"
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              disabled={selectedRows.length !== 1}
              onClick={handleEditButtonClick}
            >
              Редактировать
            </Button>
          </div>
        </div>
      </div>

      <ModulesTable rowData={rowData} setGridApi={setGridApi} onSelection={onSelection} />
      {editModule && <EditModuleDialog open={openEditDialog} onClose={handleCloseEditDialog} module={editModule} />}
    </Paper>
  );
};

export default Modules;
