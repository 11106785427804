import { generateUuid } from "functions/common";
import NamespaceService from "./NamespaceService";
import { Namespace as TNamespace } from "types";
import { AxiosResponse } from "axios";
import { subscriber } from "layouts/user/header/fileUpload/FileUpload";

export interface FileUploadProcess {
  uuid: string;
  status: "ok" | "error" | "progress";
  name: string;
  loaded: number;
  total: number;
}

export function FileUpload(
  namespace: TNamespace,
  formData: FormData,
  onSuccessCallback?: (res: AxiosResponse) => void,
  onErrorCallback?: (e: AxiosResponse) => void
) {
  const uuid = generateUuid();
  const { id, name } = namespace;

  function onUploadProgress(data: any) {
    const { loaded, total } = data;
    const process: FileUploadProcess = {
      status: "progress",
      uuid,
      name,
      loaded,
      total,
    };
    subscriber.next(process);
  }

  function onSuccess(res: AxiosResponse, callback?: (r: AxiosResponse) => void) {
    const process: FileUploadProcess = {
      status: "ok",
      uuid,
      name,
      loaded: 1,
      total: 1,
    };
    subscriber.next(process);
    if (callback !== undefined) callback(res);
  }

  function onError(err: any, callback?: (e: any) => void) {
    const process: FileUploadProcess = {
      status: "error",
      uuid,
      name,
      loaded: 0,
      total: 1,
    };
    subscriber.next(process);
    if (callback !== undefined) callback(err);
  }

  NamespaceService.upload(id, formData, onUploadProgress)
    .then((res) => onSuccess(res, onSuccessCallback))
    .catch((err) => onError(err, onErrorCallback));
}
