import React, { FC, useCallback, useMemo, useState } from "react";
import { Keywords as T, WaveformWord, Word } from "types";
import { currTimeSubscriber } from "subscribers/PlayerSubscriber";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(() => ({
  word: {
    position: "absolute",
    top: 5,
    fontSize: 14,
    cursor: "pointer",
    outline: "1px solid " + grey[800],
    padding: "0 5px",
    color: "#FFF",
  },
  stick: {
    position: "absolute",
    top: 0,
    width: 2,
    height: "100%",
  },
  waveformWord: {
    position: "absolute",
    top: 5,
    fontSize: 14,
    cursor: "pointer",
    padding: "0 5px",
    color: "#FFF",
    border: "1px solid #000",
  },
  bx: {
    position: "absolute",
    top: 9,
    fontSize: 14,
    padding: "0 5px",
    border: "1px solid " + grey[800],
  },
  stack: {
    // minWidth: 150,
  },
  item: {
    padding: "5px 10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: grey[300],
    },
  },
}));

interface PropsRenderWord {
  waveformWord: WaveformWord;
  handleClick: (e: any, s: number) => void;
  zIndex: number;
}

const RenderStack: FC<PropsRenderWord> = ({ waveformWord, handleClick, zIndex }) => {
  const classes = useStyles();
  const { word, stack, left, color } = waveformWord;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleListItemClick = (e: any, start: number) => {
    setAnchorEl(null);
    handleClick(e, start);
  };

  const handleOpenStack = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className={classes.waveformWord}
        style={{
          zIndex: zIndex,
          left: left + 9,
          backgroundColor: color,
        }}
        onClick={handleOpenStack}
      >
        {word}
      </div>
      <div
        className={classes.bx}
        style={{
          zIndex: zIndex - 1,
          left: left + 12,
          backgroundColor: color,
          color,
        }}
      >
        {word}
      </div>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <div className={classes.stack}>
          {stack.map((w, i) => (
            <div key={i} className={classes.item} onClick={(event) => handleListItemClick(event, w.start)}>
              <ListItemText primary={w.word} />
            </div>
          ))}
        </div>
      </Menu>
    </>
  );
};

const RenderWord: FC<PropsRenderWord> = ({ waveformWord, handleClick, zIndex }) => {
  const classes = useStyles();
  const { left, color, word, start } = waveformWord;
  return (
    <div
      className={classes.word}
      style={{
        left: left + 8,
        zIndex,
        backgroundColor: color,
      }}
      onClick={(e) => handleClick(e, start)}
    >
      {word}
    </div>
  );
};

interface Props {
  keywords: T[];
  waveformWidth: number;
  duration: number;
}

const Keywords: FC<Props> = ({ keywords, duration, waveformWidth }) => {
  const classes = useStyles();
  const { playerKeywordsLayout: layout } = useSelector((state: RootState) => state.settings);

  const filterLayout = useCallback(
    (w: Word) => {
      if (layout === "origin") return w.type !== "stt";
      if (layout === "translate") return w.type === "translate";
      return true;
    },
    [layout]
  );

  const compare = (a: Word, b: Word) => {
    if (a.start > b.start) {
      return 1;
    }
    if (a.start < b.start) {
      return -1;
    }
    return 0;
  };

  const words: Word[] = useMemo(() => {
    let words: Word[] = [];
    for (let i = 0; i < keywords.length; i++) {
      const wds = keywords[i].words;
      for (let j = 0; j < wds.length; j++) {
        const w = wds[j];
        w.color = keywords[i].color;
        words.push(w);
      }
    }
    words = words.filter(filterLayout);
    const map = new Map();
    for (let i = 0; i < words.length; i++) {
      const { id } = words[i];
      const v = map.get(id);
      if (v === undefined) {
        map.set(id, words[i]);
      }
    }
    return Array.from(map, (el) => el[1]);
  }, [keywords, filterLayout]);

  const stackWords: WaveformWord[] = useMemo(() => {
    const sorted = words.sort(compare);
    const stackWords: WaveformWord[] = [];

    const addToStack = (id: number, w: Word) => {
      for (let i = 0; i < stackWords.length; i++) {
        if (stackWords[i].id === id) {
          stackWords[i].stack.push(w);
          return;
        }
      }
    };

    let end = -1;
    let stackId = -1;
    for (let i = 0; i < sorted.length; i++) {
      const { start, word, id } = sorted[i];
      const left = ((start / duration) * 100 * waveformWidth) / 100;
      const wordEnd = left + word.length * 10 + 20;
      if (left > end) {
        stackWords.push({ ...sorted[i], stack: [sorted[i]], left });
        end = wordEnd;
        stackId = id;
      } else {
        addToStack(stackId, sorted[i]);
      }
    }
    return stackWords;
  }, [words, duration, waveformWidth]);

  const handleClick = (event: any, start: number) => {
    event.preventDefault();
    event.stopPropagation();
    const seekTime = start - 0.3;
    currTimeSubscriber.next(seekTime <= 0 ? 0 : seekTime);
  };

  if (keywords.length === 0 || waveformWidth === 0 || duration === 0) {
    return null;
  }

  return (
    <>
      {stackWords.map((w, idx) => {
        if (w.stack.length > 1) {
          return <RenderStack key={idx} waveformWord={w} handleClick={handleClick} zIndex={idx + 10} />;
        }
        return <RenderWord key={idx} waveformWord={w} handleClick={handleClick} zIndex={idx + 10} />;
      })}

      {stackWords.map(({ color, start }, idx) => {
        return (
          <div
            key={idx}
            className={classes.stick}
            style={{
              zIndex: words.length - idx + 1,
              backgroundColor: color,
              left: ((start / duration) * 100 * waveformWidth) / 100,
            }}
          />
        );
      })}
    </>
  );
};

export default Keywords;
