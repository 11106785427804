import { FETCH_MODULES, NavigationState, NavigationActions } from "redux/types/navigationTypes";

const initialState: NavigationState = {
  modules: [],
};

export default (state = initialState, action: NavigationActions) => {
  switch (action.type) {
    case FETCH_MODULES:
      return {
        modules: action.payload,
      };
    default:
      return state;
  }
};
