import React, { FC, useRef, useState, useEffect } from "react";

// components
import { convertToString, convertToStringOneDate, InputDateEditComponent } from "./InputDateEditComponent";

// icons
import CloseIcon from "@material-ui/icons/Close";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() => ({
  root: {},
  divText: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "21px",
    lineHeight: "21px",
    cursor: "text",
    marginRight: "2px",
  },
  spanText: {
    minWidth: "20px",
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  StyleBtn: {
    color: grey[500],
    width: "16px",
    height: "16px",
  },
  StyleBtnSvg: {
    width: "16px",
    height: "16px",
  },
}));

export interface State {
  text: string;
}

interface Props {
  id?: string;
  value: string;
  color: string;
  isFocus: boolean;
  isVisibleDeleteButton?: boolean;
  dynamicDateString: string | null;
  onSelection?: Function;
  onOpen?: Function;
  onClickDelete?: Function;
  equals: boolean;
}

const InputDateComponent: FC<Props> = ({
  id,
  value,
  color,
  isFocus,
  isVisibleDeleteButton,
  onSelection,
  onOpen,
  onClickDelete,
  equals,
}) => {
  const classes = useStyles();
  const refDiv = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [dynamicText, setDynamicText] = useState<string | null>(null);
  const [text, setText] = useState<string>("");
  const [popoverOpen, setPopoverOpen] = useState<boolean>();

  // функция для склонения слова день в инпуте
  const dynamicDateStringChange = (dynamicDateString: string | null) => {
    if (dynamicDateString === null) {
      setDynamicText(null);
    } else if (dynamicDateString === "0") {
      setDynamicText("За сегодня");
    } else if (dynamicDateString === "1") {
      setDynamicText("За вчера");
    } else if (dynamicDateString === "2" || dynamicDateString === "3" || dynamicDateString === "4") {
      setDynamicText(`За ${dynamicDateString} дня`);
    } else if (dynamicDateString === "21") {
      setDynamicText(`За ${dynamicDateString} день`);
    } else if (dynamicDateString === "22" || dynamicDateString === "23" || dynamicDateString === "24") {
      setDynamicText(`За ${dynamicDateString} дня`);
    } else if (dynamicDateString === "31") {
      setDynamicText("За месяц");
    } else if (dynamicDateString === "90") {
      setDynamicText("За 3 месяца");
    } else if (dynamicDateString === "180") {
      setDynamicText("За полгода");
    } else if (dynamicDateString === "365") {
      setDynamicText("За год");
    } else if (dynamicDateString === "730") {
      setDynamicText("За 2 года");
    } else if (dynamicDateString === "1095") {
      setDynamicText("За 3 года");
    } else {
      setDynamicText(`За ${dynamicDateString} дней`);
    }
  };

  const onClose = () => {
    setPopoverOpen(false);
  };

  useEffect(() => {
    const dayPattern = /\[day\]-(\d+)/;
    const match = value.match(dayPattern);
    if (match) {
      const dayNumber = parseInt(match[1], 10);
      dynamicDateStringChange(String(dayNumber));
    }
  }, [value]);

  useEffect(() => {
    if (equals) {
      setText(convertToString(value));
    } else {
      if (dynamicText) {
        setText(dynamicText);
      } else {
        setText(convertToStringOneDate(value));
      }
    }
  }, [equals, dynamicText, value]);

  useEffect(() => {
    if (isFocus && refDiv) {
      setAnchorEl(refDiv.current);
      setPopoverOpen(true);
    } else {
      setAnchorEl(null);
      setPopoverOpen(false);
    }
  }, [isFocus, refDiv]);

  return (
    <div>
      <div
        ref={refDiv}
        className={classes.divText}
        style={{
          background: color,
        }}
        onClick={(e) => onOpen?.()}
      >
        <span className={classes.spanText} title={text}>
          {text}
        </span>

        {isVisibleDeleteButton && (
          <IconButton
            className={classes.StyleBtn}
            disableRipple
            color="primary"
            title="Удалить фильтр"
            onClick={(e) => onClickDelete?.()}
          >
            <CloseIcon className={classes.StyleBtnSvg} />
          </IconButton>
        )}
      </div>

      {anchorEl && popoverOpen && (
        <InputDateEditComponent
          anchorEl={anchorEl}
          id={id}
          value={value}
          onSelection={onSelection}
          dynamicDateString={dynamicDateStringChange}
          equals={equals}
          popoverOpen={popoverOpen}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default InputDateComponent;
