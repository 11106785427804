import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "14px!important",
    textAlign: "center",
  },
}));

const NoData: FC = () => {
  const classes = useStyles();
  return <div className={classes.root}>{"< Нет данных для отображения >"}</div>;
};

export default NoData;
