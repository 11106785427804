import React, { ChangeEvent, FC, useEffect, useState } from "react";
import clsx from "clsx";
import usePublicLmModels from "hooks/usePublicLmModels";
import useSttLanguages from "hooks/useSttLanguages";
import { LmModel } from "types/admin/lmModel";
import { VadType } from "types/task";
import useTranslateLanguages from "hooks/useTranslateLanguages";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  sttLang: string;
}

const CreateTaskSttDialog: FC<Props> = ({ open, onClose, sttLang }) => {
  const classes = useStyles();
  const disabled = false;
  const [shouldFetch, setShouldFetch] = useState(false);
  //вот эти фетчи должны срабатывать только при open
  const { error: errorLmModels, lmModels } = usePublicLmModels(shouldFetch);
  const { error: errorTranslateLanguages, languagesFrom } = useTranslateLanguages(shouldFetch);
  const { error: errorLanguages, languages } = useSttLanguages(shouldFetch);

  const [techSTT, setTechSTT] = useState({
    lang: sttLang === "" ? "auto" : sttLang,
    translate: false,
    languageModelId: 0,
  });
  const { lang, translate, languageModelId } = techSTT;
  const [techVad, setTechVad] = useState<{ vadType: VadType }>({ vadType: "vad" });
  const { vadType } = techVad;
  const [filteredLmModels, setFilteredLmModels] = useState<LmModel[]>([]);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setTechSTT((prev: any) => ({
      ...prev,
      lang: value,
      translate: languagesFrom.includes(value as string),
    }));
  };

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setTechSTT((prev) => ({
      ...prev,
      translate: checked,
    }));
  };

  const handleChangeLmModel = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setTechSTT((prev: any) => ({
      ...prev,
      languageModelId: Number(value),
    }));
  };

  const handleChangeVad = (event: ChangeEvent<{ value: unknown }>) => {
    const vadType = event.target.value as VadType;
    setTechVad((prev) => ({
      ...prev,
      vadType,
    }));
  };

  useEffect(() => {
    const filtered = lmModels.filter((lm) => lm.lang === lang);
    setFilteredLmModels(filtered);
    const defaultLmModel = filtered.find((m) => m.isDefault);
    if (defaultLmModel !== undefined) {
      setTechSTT((prev: any) => ({
        ...prev,
        languageModelId: Number(defaultLmModel.id),
      }));
    }
  }, [lmModels, lang]);

  useEffect(() => {
    setTechSTT((prev) => ({
      ...prev,
      translate: languagesFrom.includes(sttLang) && sttLang !== "russian",
    }));
  }, [languagesFrom, sttLang]);

  useEffect(() => {
    if (open) {
      setShouldFetch(true);
    }
  }, [open]);

  if (errorLanguages || errorLmModels || errorTranslateLanguages) {
    return (
      <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
        <DialogTitle>Выполнить транскрибирование речи</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>Ошибка</AlertTitle>
                Не удалось выполнить запрос
              </Alert>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttons}>
            <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
              Отмена
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => onClose()}
              disabled={disabled}
            >
              Ок
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Выполнить транскрибирование речи</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-vadType">Детектор активности речи</InputLabel>
              <Select labelId="select-vadType" value={vadType} onChange={handleChangeVad} fullWidth>
                <MenuItem value="vad">По умолчанию</MenuItem>
                <MenuItem value="allFile">Использовать все данные как один речевой сегмент</MenuItem>
                <MenuItem value="vadUkv">Для данных с низким отношением сигнал/шум</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-lang">Язык</InputLabel>
              <Select labelId="select-lang" name="lang" value={lang} onChange={handleChange} fullWidth>
                {languages.map((lang) => (
                  <MenuItem key={lang.id} value={lang.id}>
                    {lang.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-lang-model">Языковая модель</InputLabel>
              <Select
                labelId="select-lang-model"
                value={languageModelId}
                onChange={handleChangeLmModel}
                fullWidth
                disabled={filteredLmModels.length === 0}
              >
                <MenuItem value={0}>Базовая</MenuItem>
                {filteredLmModels.map((lm) => (
                  <MenuItem key={lm.id} value={lm.id}>
                    {lm.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!languagesFrom.includes(lang)}
                  checked={translate}
                  onChange={handleChecked}
                  name="translate"
                  color="primary"
                />
              }
              label="Выполнить перевод на русский язык"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => onClose({ techSTT, techVad })}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTaskSttDialog;
