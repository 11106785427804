import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

const LangRenderer: FC<{ value: string }> = ({ value }) => {
  const { lidLanguages } = useSelector((state: RootState) => state.system);

  let name = value;

  for (let i = 0; i < lidLanguages.length; i++) {
    const lang = lidLanguages[i];
    if (value === lang.id) {
      name = lang.name;
    }
  }

  return <span title={value}>{name}</span>;
};

export default LangRenderer;
