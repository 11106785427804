import React, { FC, useRef, useState, useEffect } from "react";

// components
import InputNumberEditComponent from "./InputNumberEditComponent";

// icons
import CloseIcon from "@material-ui/icons/Close";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  divText: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "21px",
    lineHeight: "21px",
    cursor: "text",
    marginRight: "2px",
  },
  spanText: {
    minWidth: "20px",
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  StyleBtn: {
    color: grey[500],
    width: "16px",
    height: "16px",
    // position: "absolute",
    // right: "0px",
  },
  StyleBtnSvg: {
    // color: grey[500],
    width: "16px",
    height: "16px",
  },
}));

export interface State {
  text: string;
}

interface Props {
  id?: string;
  value: string;
  color: string;
  // list: string[];
  isFocus: boolean;
  isVisibleDeleteButton?: boolean;
  params: any;

  onSelection?: Function;
  onOpen?: Function;
  // onClose?: Function;
  onClickDelete?: Function;
}

const InputNumberComponent: FC<Props> = ({
  id,
  value,
  color,
  isFocus,
  isVisibleDeleteButton,
  params,

  onSelection,
  onOpen,
  // onClose,
  onClickDelete,
}) => {
  const classes = useStyles();

  const refDiv = useRef<any>(null);

  //  const [state, setState] = useState<State>(initialState);
  // const getFilters = useCallback(() => {
  //   setState((prev) => ({ ...prev, loading: true }));
  //   FilterService.getAll()
  //     .then((res) => {
  //       setState(() => ({ filters: res.data ?? [], loading: false, error: undefined }));
  //     })
  //     .catch((err) => {
  //       setState(() => ({ filters: [], loading: false, error: err.response.data }));
  //     });
  // }, []);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  // const onLeftRightHelper = (pos: string) => {
  //   console.log(`InputStringComponent onKeyDownMainInput. pos: ${pos}`);

  //   setAnchorEl(null);
  //   onLeftRight?.(pos);
  // };

  // const onBlurHelper = () => {
  //   setAnchorEl(null)
  //   onClose?.();
  // };

  useEffect(() => {
    if (isFocus && refDiv) {
      setAnchorEl(refDiv.current);
    } else {
      setAnchorEl(null);
    }
  }, [isFocus, refDiv]);

  // console.log(`InputStringComponent color: ${color}`);
  return (
    <div>
      <div
        ref={refDiv}
        className={classes.divText}
        style={{
          background: color,
        }}
        onClick={(e) => onOpen?.()}
      >
        <span className={classes.spanText} title={value}>
          {value}
        </span>

        {isVisibleDeleteButton && (
          <IconButton
            className={classes.StyleBtn}
            disableRipple
            color="primary"
            title="Удалить фильтр"
            onClick={(e) => onClickDelete?.()}
          >
            <CloseIcon className={classes.StyleBtnSvg} />
          </IconButton>
        )}
      </div>

      {anchorEl && (
        <InputNumberEditComponent
          anchorEl={anchorEl}
          id={id}
          value={value}
          onSelection={onSelection}
          params={params}
          //  onClose={onClose}
        />
      )}
    </div>
  );
};

export default InputNumberComponent;
