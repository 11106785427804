import React, { ChangeEvent, FC, useEffect, useState, KeyboardEvent } from "react";
import clsx from "clsx";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { DialogContent, DialogTitle } from "@material-ui/core";
import { ColorResult, TwitterPicker } from "react-color";
import { blue, cyan, deepPurple, green, indigo, orange, pink, purple, red, yellow } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

const colors = [
  yellow[300],
  red[300],
  pink[300],
  purple[300],
  deepPurple[300],
  indigo[300],
  blue[300],
  cyan[300],
  orange[300],
  green[300],
];

interface Props {
  open: boolean;
  onClose: Function;
}

interface State {
  name: string;
  comment: "";
  color: string;
}

const initialState: State = {
  name: "",
  comment: "",
  color: "#000",
};

const CreateLabelDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [state, setState] = useState<State>(initialState);
  const { name, comment, color } = state;
  const disabled = name.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onClose(state);
  };

  const handleChangeColor = (c: ColorResult) => {
    setState((prev) => ({
      ...prev,
      color: c.hex,
    }));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setState(initialState);
  }, [open]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Создать маркер</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Имя"
              fullWidth
              variant="standard"
              name="name"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={name}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={comment}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Цвет</Typography>
            <TwitterPicker
              colors={colors}
              width="912px"
              color={color}
              onChangeComplete={handleChangeColor}
              triangle="hide"
            />
          </Grid>
        </Grid>

        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateLabelDialog;
