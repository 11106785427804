import React, { FC, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import { Lang } from "types/task";
import clsx from "clsx";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: 400,
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

interface Props {
  rowData: Lang[];
}

const LangTable: FC<Props> = ({ rowData }) => {
  const classes = useStyles();

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Имя",
        field: "name",
        flex: 1,
      },
    ],
    []
  );

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        enableRangeSelection
        suppressCopyRowsToClipboard
        suppressDragLeaveHidesColumns
      />
    </div>
  );
};

export default LangTable;
