import React, { FC } from "react";

// components
import InputComboComponent from "./InputComboComponent";
import InputStringComponent from "./InputStringComponent";
import InputTableComponent from "./InputTableComponent";
import InputDateComponent from "./InputDateComponent";
import InputNumberComponent from "./InputNumberComponent";
import InputTreeComponent from "./InputTreeComponent";

import { FilterType } from "types/queryBuilder";

interface Props {
  ruleType: any;
  id?: string;
  value: string;
  color: string;
  isEditStart: boolean;
  isFocus: boolean;
  isVisibleDeleteButton?: boolean;
  params: any;
  equals: boolean;
  dynamicDateString?: string | null;
  onSelection?: Function;
  onOpen?: Function;
  onClose?: Function;
  onClickDelete?: Function;
  doCustomGetQuery: Function;
  filter: string;
}

const RenderInput: FC<Props> = ({
  ruleType,
  id,
  value,
  color,
  isEditStart,
  isFocus,
  isVisibleDeleteButton,
  params,
  equals,
  onSelection,
  onOpen,
  onClose,
  onClickDelete,
  doCustomGetQuery,
  filter,
}) => {
  // список

  if (ruleType === FilterType.Boolean) {
    return (
      <InputComboComponent
        id={id}
        value={value}
        color={color}
        isVisibleDeleteButton={isVisibleDeleteButton}
        isFocus={isFocus}
        params={{ values: ["Да", "Нет"] }}
        onOpen={onOpen}
        onSelection={onSelection}
        onClickDelete={onClickDelete}
      />
    );
  }

  if (ruleType === FilterType.Enums) {
    return (
      <InputComboComponent
        id={id}
        value={value}
        color={color}
        isVisibleDeleteButton={isVisibleDeleteButton}
        isFocus={isFocus}
        params={params}
        onOpen={onOpen}
        onSelection={onSelection}
        onClickDelete={onClickDelete}
      />
    );
  }

  if (ruleType === FilterType.Date) {
    return (
      <InputDateComponent
        id={id}
        value={value}
        color={color}
        isFocus={isFocus}
        isVisibleDeleteButton={isVisibleDeleteButton}
        onOpen={onOpen}
        onSelection={onSelection}
        onClickDelete={onClickDelete}
        dynamicDateString={null}
        equals={equals}
      />
    );
  }

  if (ruleType === FilterType.Object) {
    return (
      <InputTableComponent
        id={id}
        color={color}
        value={value}
        isFocus={isFocus}
        isVisibleDeleteButton={isVisibleDeleteButton}
        params={params}
        onOpen={onOpen}
        onSelection={onSelection}
        onClickDelete={onClickDelete}
        doCustomGetQuery={doCustomGetQuery}
      />
    );
  }

  if (ruleType === FilterType.Tree) {
    return (
      <InputTreeComponent
        id={id}
        color={color}
        value={value}
        isFocus={isFocus}
        isVisibleDeleteButton={isVisibleDeleteButton}
        params={params}
        onOpen={onOpen}
        onSelection={onSelection}
        onClickDelete={onClickDelete}
        doCustomGetQuery={doCustomGetQuery}
      />
    );
  }

  if (ruleType === FilterType.Number || ruleType === FilterType.Float) {
    return (
      <InputNumberComponent
        id={id}
        value={value}
        color={color}
        isFocus={isFocus}
        isVisibleDeleteButton={isVisibleDeleteButton}
        params={params}
        onOpen={onOpen}
        onSelection={onSelection}
        onClickDelete={onClickDelete}
      />
    );
  }

  return (
    <InputStringComponent
      id={id}
      value={value}
      color={color}
      isFocus={isFocus}
      isVisibleDeleteButton={isVisibleDeleteButton}
      onOpen={onOpen}
      onSelection={onSelection}
      onClickDelete={onClickDelete}
      filter={filter}
    />
  );
};

export default RenderInput;
