import React, { FC, useEffect, useState } from "react";
import useRecordFiles from "hooks/records/useRecordFiles";
import FilesTable from "./FilesTable";
import { GridApi } from "ag-grid-community";
import { RecordDetail } from "types";
import { catchError } from "functions/common";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {},
  progress: {},
}));

interface Props {
  recordId: number;
  setRecordDetail: Function;
}

const Files: FC<Props> = ({ recordId, setRecordDetail }) => {
  const classes = useStyles();
  const { rowData, error } = useRecordFiles(recordId);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const onSelection = () => {
    if (gridApi) {
      const row = gridApi.getSelectedRows();
      const recordDetail: RecordDetail = {
        viewType: row[0].viewType,
        filter: JSON.stringify([{ storageFileId: row[0].storageFileId, codec: row[0].codec }]),
        format: row[0].codec,
      };
      setRecordDetail(recordDetail);
    }
  };

  useEffect(() => {
    if (error === undefined) return;
    catchError(error);
  }, [error]);

  return (
    <div className={classes.root}>
      <FilesTable rowData={rowData} setGridApi={setGridApi} onSelection={onSelection} />
    </div>
  );
};

export default Files;
