import React from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  empty: {
    // width: '100%',
    // position: 'absolute',
    // top: '40%',
    // textAlign: 'center',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "200px",
    color: "rgba(0, 0, 0, 0.5)",
  },
});

const Progress: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.empty}>
      {/*{'< Нет данных для отображения >'}*/}
      <CircularProgress />
    </div>
  );
};

export default Progress;
