import {
  SETTINGS_SET_ROWS_PER_PAGE,
  SETTINGS_SET_STT_FONT,
  SETTINGS_SET_STT_FONT_SIZE,
  SETTINGS_SET_STT_TEXT_COLOR,
  SETTINGS_SET_STT_BACKGROUND_COLOR,
  SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER,
  SETTINGS_SET_STT_SHOW_KEYWORDS,
  SETTINGS_SET_STT_SHOW_TRANSLATE,
  SETTINGS_SET_STT_EDITE_MODE,
  SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT,
  SETTINGS_SET_RESULT_PANEL_SIZE,
  SETTINGS_SET_ALL,
  SETTINGS_SET_PLAYER_IS_PAN,
  SETTINGS_SET_PLAYER_IS_ADE_CLICK,
  SETTINGS_SET_PLAYER_IS_NORMALIZE,
  SETTINGS_SET_PLAYER_IS_NOISE,
  SETTINGS_SET_PLAYER_IS_KTCH,
  SETTINGS_SET_PLAYER_IS_ECHO,
  SETTINGS_SET_PLAYER_CHANNEL,
  SETTINGS_SET_PLAYER_GAIN,
  SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS,
  SETTINGS_SET_PLAYER_WAVEFORM_TYPE,
  SETTINGS_SET_KEYWORDS_LAYOUT,
  SETTINGS_SET_ZOOM,
  SETTINGS_SET_SORT_NAME,
  SETTINGS_SET_SORT_ORDER,
  SETTINGS_SET_DIARIZATION_HIGHLIGHT,
  SETTINGS_SET_SHOW_LAYOUT_VAD,
  SettingsAction,
  SettingsState,
} from "redux/types/settingsTypes";
import { grey } from "@material-ui/core/colors";
import SettingsService from "services/admin/SettingsService";
import { KeywordsLayout, WaveformType } from "components/audioPlayer/types";
import { Key } from "types/settings";

export const defaultState: SettingsState = {
  rowsPerPage: 500,
  resultPanelSize: 1000,
  debounce: 1000,
  sttFont: "Arial, sans-serif",
  sttFontSize: 16,
  sttBackgroundColor: grey[200],
  sttTextColor: grey[800],
  sttSyncTextAndPlayer: false,
  sttShowKeywords: true,
  sttShowTranslate: true,
  playerIsPan: 0,
  playerIsAdeClick: 0,
  playerIsNormalize: 0,
  playerIsNoise: 0,
  playerIsKTCH: 0,
  playerIsEcho: 0,
  playerChannel: -1,
  playerGain: 1,
  playerPlayOnlyVoiceSegments: false,
  playerWaveformType: "waveform",
  playerKeywordsLayout: "all",
  playerZoom: 1,
  sortName: "Идентификатор",
  sortOrder: "asc",
  diarizationHighlight: true,
  isShowLayoutVad: 0,
  editMode: false,
  confidenceHighlight: 1,
};

function save(s: SettingsState) {
  SettingsService.setByKye({ key: Key.SETTINGS, value: JSON.stringify(s) }).catch(console.error);
}

export default (state = defaultState, action: SettingsAction) => {
  const { type, payload } = action;

  if (type === SETTINGS_SET_ALL) {
    return { ...(payload as SettingsState) };
  }

  if (type === SETTINGS_SET_PLAYER_IS_PAN) {
    const settings: SettingsState = {
      ...state,
      playerIsPan: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_ADE_CLICK) {
    const settings: SettingsState = {
      ...state,
      playerIsAdeClick: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_NORMALIZE) {
    const settings: SettingsState = {
      ...state,
      playerIsNormalize: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_NOISE) {
    const settings: SettingsState = {
      ...state,
      playerIsNoise: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_KTCH) {
    const settings: SettingsState = {
      ...state,
      playerIsKTCH: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_ECHO) {
    const settings: SettingsState = {
      ...state,
      playerIsEcho: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_CHANNEL) {
    const settings: SettingsState = {
      ...state,
      playerChannel: payload as 0 | 1 | -1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_GAIN) {
    const settings: SettingsState = {
      ...state,
      playerGain: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_WAVEFORM_TYPE) {
    const settings: SettingsState = {
      ...state,
      playerWaveformType: payload as WaveformType,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_KEYWORDS_LAYOUT) {
    const settings: SettingsState = {
      ...state,
      playerKeywordsLayout: payload as KeywordsLayout,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_ZOOM) {
    const settings: SettingsState = {
      ...state,
      playerZoom: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_ROWS_PER_PAGE) {
    const settings: SettingsState = {
      ...state,
      rowsPerPage: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_FONT) {
    const settings: SettingsState = {
      ...state,
      sttFont: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_FONT_SIZE) {
    const settings: SettingsState = {
      ...state,
      sttFontSize: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_TEXT_COLOR) {
    const settings: SettingsState = {
      ...state,
      sttTextColor: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_BACKGROUND_COLOR) {
    const settings: SettingsState = {
      ...state,
      sttBackgroundColor: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER) {
    const settings: SettingsState = {
      ...state,
      sttSyncTextAndPlayer: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_SHOW_KEYWORDS) {
    const settings: SettingsState = {
      ...state,
      sttShowKeywords: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_SHOW_TRANSLATE) {
    const settings: SettingsState = {
      ...state,
      sttShowTranslate: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_EDITE_MODE) {
    const settings: SettingsState = {
      ...state,
      editMode: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT) {
    const settings: SettingsState = {
      ...state,
      confidenceHighlight: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_RESULT_PANEL_SIZE) {
    const settings: SettingsState = {
      ...state,
      resultPanelSize: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS) {
    const settings: SettingsState = {
      ...state,
      playerPlayOnlyVoiceSegments: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_SORT_NAME) {
    const settings: SettingsState = {
      ...state,
      sortName: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_SORT_ORDER) {
    const settings: SettingsState = {
      ...state,
      sortOrder: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_DIARIZATION_HIGHLIGHT) {
    const settings: SettingsState = {
      ...state,
      diarizationHighlight: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_SHOW_LAYOUT_VAD) {
    const settings: SettingsState = {
      ...state,
      isShowLayoutVad: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  return state;
};
