import React, { ChangeEvent, FC, useCallback } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import ListSelect from "./ListSelect";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 300,
  },
  textarea: {
    border: "1px solid " + grey[300],
    width: "100%",
  },
  select: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  button: {},
  count: {
    fontSize: theme.typography.fontSize,
  },
}));

interface Props {
  list: { id: number; value: string };
  setListValue: Function;
  remove: (id: number) => void;
}

const List: FC<Props> = ({ list, setListValue, remove }) => {
  const classes = useStyles();
  const { id, value } = list;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setListValue(id, value);
  };

  const handleSetListValue = (value: string) => {
    setListValue(id, value);
  };

  const getCount = useCallback(() => {
    if (value === "") {
      return 0;
    }
    return value.split("\n").filter((el) => el !== "").length;
  }, [value]);

  return (
    <div className={classes.root}>
      <div className={classes.select}>
        <ListSelect setListValue={handleSetListValue} />
        <IconButton className={classes.button} onClick={() => remove(id)}>
          <HighlightOffIcon fontSize="small" />
        </IconButton>
      </div>

      <textarea className={classes.textarea} rows={30} value={value} onChange={handleChange} />

      <div className={classes.count}>Количество: {getCount()}</div>
    </div>
  );
};

export default List;
