import React, { FC, useCallback } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 15px",
  },
  button: {
    width: 100,
  },
}));

interface Props {
  open: boolean;
  onClose: (confirm?: boolean) => void;
}

const ConfirmTurnOffDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  // обработчик нажатия клавиш
  const handleKeyDown = useCallback(
    (event) => {
      if (!open) return;
      const { key } = event;

      if (key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        onClose(true);
        return;
      }
    },
    [open, onClose]
  );

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      className={classes.root}
      fullWidth
      maxWidth="md"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Выключение серверов</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="warning">Вы уверены, что хотите выключить все сервера?</Alert>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => onClose(true)}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmTurnOffDialog;
