import { useEffect, useState } from "react";
import { AdminTechTranslate } from "types/admin";
import TechService from "services/admin/TechService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  languagesFrom: string[];
  languagesTo: string[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  languagesFrom: [],
  languagesTo: [],
  error: undefined,
};

const useTranslateLanguages = (shouldFetch?: boolean) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    if (shouldFetch) {
      setState(() => ({ languagesFrom: [], languagesTo: [], error: undefined, loading: true }));
      TechService.getTechByName("translate")
        .then(({ data }) => {
          const techTranslate = data.techDetail as AdminTechTranslate;
          const { languagesFrom, languagesTo } = techTranslate;
          setState({ languagesFrom, languagesTo, loading: false, error: undefined });
        })
        .catch((err) => {
          setState({ languagesFrom: [], languagesTo: [], loading: false, error: err });
        });
    }
  }, [shouldFetch]);

  return state;
};

export default useTranslateLanguages;
