import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import WarningIcon from "@material-ui/icons/Warning";

const SystemError: FC = () => {
  const { error } = useSelector((state: RootState) => state.system);

  if (!error.display) return null;

  return (
    <div className="systemError">
      <WarningIcon />
      <span className="systemErrorShortText">Обнаружены ошибки</span>
      <pre className="systemErrorText">{error.message}</pre>
    </div>
  );
};

export default SystemError;
