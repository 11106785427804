import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import SourcesTable from "./components/SourcesTable";
import { SourceAdmin } from "types/source";
import SourceService from "services/admin/AdminSourceService";
import { catchError } from "functions/common";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
}));

const Sources: FC = () => {
  const classes = useStyles();

  const [sources, setSources] = useState<SourceAdmin[]>([]);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<SourceAdmin[]>([]);

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleEdit = () => {
    if (selectedRows.length === 0) return;

    const isActive = !selectedRows[0].isActive;
    const ids = selectedRows.map((s) => s.id);

    SourceService.setActive({ ids, isActive })
      .then(() => {
        if (!gridApi) return;

        gridApi.forEachNode((node) => {
          for (let i = 0; i < selectedRows.length; i++) {
            const selected = selectedRows[i];
            if (node.data.id === selected.id) {
              node.setData({ ...node.data, isActive });
              break;
            }
          }
        });
        gridApi.deselectAll();
      })
      .catch(catchError);
  };

  useEffect(() => {
    SourceService.getAll()
      .then(({ data }) => setSources(data))
      .catch(catchError);
  }, []);

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.buttons}>
          <div>
            <TextField
              className={classes.search}
              placeholder="Имя источника"
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              disabled={selectedRows.length === 0}
              onClick={handleEdit}
            >
              вкл/выкл
            </Button>
          </div>
        </div>
      </div>

      <SourcesTable rowData={sources} setGridApi={setGridApi} onSelection={onSelection} />
    </Paper>
  );
};

export default Sources;
