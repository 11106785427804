import axios from "axios";
import { API_URL, auth } from "./urls";
import { logout, refresh } from "redux/actions/authActions";
import { store } from "providers/ReduxProvider";

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: Error | null, token = null) => {
  if (!token) {
    failedQueue = [];
    return;
  }

  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const api = axios.create({
  // withCredentials: true,
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  if (config.headers) {
    const { accessToken } = store.getState().auth;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (err) => {
    const originalRequest = err.config;

    if (err.response?.status === 401 && err.config && !err.config._isRetry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const { accessToken } = store.getState().auth;

      return new Promise(function (resolve, reject) {
        axios
          .post(API_URL + auth.refresh, { accessToken })
          .then(({ data }) => {
            const { accessToken: newAccessToken } = data;
            store.dispatch(refresh(newAccessToken));
            axios.defaults.headers.common["Authorization"] = "Bearer " + newAccessToken;
            originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;
            processQueue(null, newAccessToken);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            store.dispatch(logout());
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

export default api;
