import React, { FC, useMemo } from "react";
import { Source } from "types/source";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {},
}));

const SourceTypeRenderer: FC<{ value: string; data: Source }> = ({ value }) => {
  const classes = useStyles();
  const { sources } = useSelector((state: RootState) => state.system);

  const name = useMemo(() => {
    for (let i = 1; i < sources.length; i++) {
      const source = sources[i];
      if (source.detail.type === value) {
        return source.name;
      }
    }
    return value;
  }, [value, sources]);

  return <span className={classes.root}>{name}</span>;
};

export default SourceTypeRenderer;
