import {
  SET_SYSTEM_SOURCES,
  SET_SYSTEM_LID,
  SET_SYSTEM_SERVICES,
  SHOW_SYSTEM_ERROR,
  HIDE_SYSTEM_ERROR,
  SystemAction,
  SystemService,
} from "redux/types/systemTypes";
import { Lang } from "types/task";
import { AvailableSource } from "types/source";

export function setSystemSources(payload: AvailableSource[]): SystemAction {
  return {
    type: SET_SYSTEM_SOURCES,
    payload,
  };
}

export function setSystemLidLanguages(lidLanguages: Lang[]): SystemAction {
  return {
    type: SET_SYSTEM_LID,
    payload: lidLanguages,
  };
}

export function setSystemServices(services: SystemService[]): SystemAction {
  return {
    type: SET_SYSTEM_SERVICES,
    payload: services,
  };
}

export function showSystemError(msg: string): SystemAction {
  return {
    type: SHOW_SYSTEM_ERROR,
    payload: msg,
  };
}

export function hideSystemError(): SystemAction {
  return {
    type: HIDE_SYSTEM_ERROR,
    payload: "",
  };
}
