import React, { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Column, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import { addRows, removeRows, setQuickFilter } from "components/agGrid/functions";

// icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
  table: {
    height: "calc(100vh - 266px)",
  },
  title: {
    marginBottom: 5,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    flexBasis: "46%",
  },
  btns: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  iconBtn: {
    marginBottom: 5,
  },
  search: {
    marginBottom: theme.spacing(1),
  },
  searchInput: {
    fontSize: 14,
  },
}));

export interface TableCol {
  id: string;
  headerName: string;
  field: string;
}

interface SelectNamespacesProps {
  open: boolean;
  onClose: (data?: any) => void;
  cols: { displayedColumns: Column[]; allGridColumns: Column[] };
}

const TableSettingsDialog: FC<SelectNamespacesProps> = ({ open, onClose, cols }) => {
  const classes = useStyles();
  const [displayedCols, setDisplayedCols] = useState<TableCol[]>([]);
  const [hiddenCols, setHiddenCols] = useState<TableCol[]>([]);

  const [displayedGridApi, setDisplayedGridApi] = useState<GridApi | undefined>(undefined);
  const [displayedSelectedRows, setDisplayedSelectedRows] = useState<TableCol[]>([]);

  const [hiddenGridApi, setHiddenGridApi] = useState<GridApi | undefined>(undefined);
  const [hiddenSelectedRows, setHiddenSelectedRows] = useState<TableCol[]>([]);

  const columnDefs1 = useMemo(
    () => [
      {
        headerName: "Отображаемые поля",
        field: "headerName",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        flex: 1,
        rowDrag: true,
      },
    ],
    []
  );

  const columnDefs2 = useMemo(
    () => [
      {
        headerName: "Доступные поля",
        field: "headerName",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        flex: 1,
      },
    ],
    []
  );

  const onDisplayedSelection = () => {
    if (displayedGridApi) {
      const rows = displayedGridApi.getSelectedRows();
      setDisplayedSelectedRows(rows);
    }
  };

  const onHiddenSelection = () => {
    if (hiddenGridApi) {
      const rows = hiddenGridApi.getSelectedRows();
      setHiddenSelectedRows(rows);
    }
  };

  const handleSave = () => {
    if (displayedGridApi !== undefined) {
      const rows: TableCol[] = [];
      displayedGridApi.forEachNode((node) => rows.push(node.data));
      onClose(rows);
    }
    onClose();
  };

  const removeCols = () => {
    addRows(displayedSelectedRows, hiddenGridApi);
    removeRows(displayedSelectedRows, displayedGridApi);
    setDisplayedSelectedRows([]);
  };

  const addCols = () => {
    addRows(hiddenSelectedRows, displayedGridApi);
    removeRows(hiddenSelectedRows, hiddenGridApi);
    setHiddenSelectedRows([]);
  };

  const getRowNodeId = (data: TableCol) => data.id;

  useEffect(() => {
    const include = (cols: TableCol[], col: TableCol) => {
      for (let i = 0; i < cols.length; i++) {
        if (cols[i].id === col.id) {
          return true;
        }
      }
      return false;
    };

    const { displayedColumns, allGridColumns } = cols;
    try {
      // отображаемые колонки
      const dCols: TableCol[] = [];
      // скрытые колонки
      const hCols: TableCol[] = [];
      for (let i = 0; i < displayedColumns.length; i++) {
        const col = displayedColumns[i];
        const colDef = col.getColDef();
        const field = colDef.field ?? "";
        const headerName = colDef.headerName ?? "";
        dCols.push({ field, headerName, id: field });
      }
      for (let i = 0; i < allGridColumns.length; i++) {
        const col = allGridColumns[i];
        const colDef = col.getColDef();
        const tCol: TableCol = {
          field: colDef.field ?? "",
          headerName: colDef.headerName ?? "",
          id: colDef.field ?? "",
        };
        // если колонки нет в отображаемых, значит она скрыта
        if (!include(dCols, tCol)) {
          hCols.push(tCol);
        }
      }
      setDisplayedCols(dCols);
      setHiddenCols(hCols);
    } catch (e) {}
  }, [cols]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Настройка таблицы</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.item}>
            <TextField
              className={classes.search}
              fullWidth
              placeholder="Поиск..."
              onChange={(e) => setQuickFilter(e.target.value, hiddenGridApi)}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={clsx(classes.table, "ag-theme-balham")}>
              <AgGridReact
                onSelectionChanged={onHiddenSelection}
                getRowNodeId={getRowNodeId}
                onGridReady={(params: any) => setHiddenGridApi(params.api)}
                rowData={hiddenCols}
                columnDefs={columnDefs2}
                localeText={localization}
                rowSelection="multiple"
                suppressCopyRowsToClipboard
                suppressDragLeaveHidesColumns
              />
            </div>
          </div>
          <div className={classes.btns}>
            <IconButton disabled={displayedSelectedRows.length === 0} onClick={removeCols} title="Удалить">
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              className={classes.iconBtn}
              disabled={hiddenSelectedRows.length === 0}
              onClick={addCols}
              title="Добавить"
            >
              <ArrowForwardIcon fontSize="inherit" />
            </IconButton>
          </div>
          <div className={classes.item}>
            <TextField
              className={classes.search}
              fullWidth
              placeholder="Поиск..."
              onChange={(e) => setQuickFilter(e.target.value, displayedGridApi)}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={clsx(classes.table, "ag-theme-balham")}>
              <AgGridReact
                onSelectionChanged={onDisplayedSelection}
                getRowNodeId={getRowNodeId}
                onGridReady={(params: any) => setDisplayedGridApi(params.api)}
                rowData={displayedCols}
                columnDefs={columnDefs1}
                localeText={localization}
                rowSelection="multiple"
                suppressCopyRowsToClipboard
                suppressDragLeaveHidesColumns
                rowDragManaged
                animateRows
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default TableSettingsDialog;
