import React, { FC } from "react";
import { Passport } from "types/admin";
import UpdateFile from "./updateFile";
import { Format } from "types/admin";

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  passport: Passport;
}

const UpdateDialog: FC<Props> = ({ open, onClose, passport }) => {
  const { detail } = passport;

  if (detail.type === Format.FILES) {
    return <UpdateFile open={open} onClose={onClose} passport={passport} />;
  }

  return null;
};

export default UpdateDialog;
