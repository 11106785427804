import React, { useState, useEffect, useRef, FC, ChangeEvent } from "react";
import useWords from "hooks/useWords";

// material ui
import { Dialog, DialogContent, DialogActions, Button, Tabs, Tab } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";

//components
import ListSearchSection from "./wordsFilter/ListSearchSection";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: 100,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  sectionButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  ml5: {
    marginLeft: "5px",
  },
  mb16: {
    marginBottom: theme.spacing(2),
  },
  marginTopLeft16: {
    margin: "16px 0px 0px 16px",
  },
  paddingRight0: {
    paddingRight: "0px",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sectionWrapper: {
    flex: 1,
    marginLeft: theme.spacing(2),
  },
  faBackground: {
    backgroundColor: "#fafafa",
  },
  flex: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
  input: {
    border: "1px solid #ced4da",
    padding: "10px 12px",
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    width: "100%",
    height: "calc(100vh - 469px)",
    marginTop: "8px",
    resize: "none",
    verticalAlign: "top",
    "& .MuiInputBase-root": {
      display: "flex",
      alignItems: "start",
    },
  },
}));

export interface SearchSettings {
  strong: string[];
  phrase: string[];
  vector: string[];
  dictionary: string[];
}

interface InputDialogWordSearchProps {
  onClose: (searchString: string) => void;
  value: string;
}

const parseSearchString = (value: string): SearchSettings => {
  try {
    return JSON.parse(value);
  } catch {
    return {
      strong: [],
      vector: [],
      phrase: [],
      dictionary: [],
    };
  }
};

const InputDialogWordsTab: FC<InputDialogWordSearchProps> = ({ onClose, value }) => {
  const classes = useStyles();
  const [searchSettings, setSearchSettings] = useState<SearchSettings>(parseSearchString(value));
  const { words } = useWords();
  const [activeTab, setActiveTab] = useState<"words" | "dictionary">("words");
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  // Сохранение (кнопка "ОК")
  const handleSave = () => {
    const vectorArray = inputValue.split("\n").filter((item) => item.trim() !== "");
    let newFilter = searchSettings;
    newFilter.vector = vectorArray;
    setSearchSettings((prevSettings) => ({
      ...prevSettings,
      vector: vectorArray,
    }));
    onClose(JSON.stringify(newFilter));
  };

  const handleCancel = () => {
    if (inputValue) {
      const vectorArray = inputValue.split("\n").filter((item) => item.trim() !== "");
      let newFilter = searchSettings;
      newFilter.vector = vectorArray;
      onClose(JSON.stringify(newFilter));
    } else {
      onClose("");
    }
  };

  // получение id выбранного списка ключевых слов
  const handleSearchSettingsChange = (newSettings: string[]) => {
    setSearchSettings((prevSettings) => ({
      ...prevSettings,
      dictionary: newSettings,
    }));
  };

  // обновление слов

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setInputValue(value);
  };

  useEffect(() => {
    const parsedSettings = parseSearchString(value);
    setSearchSettings(parsedSettings);
    if (parsedSettings.dictionary.length > 0) {
      setActiveTab("dictionary");
      setInputValue(parsedSettings.vector.join("\n"));
    } else if (parsedSettings.vector.length > 0) {
      setActiveTab("words");
      setInputValue(parsedSettings.vector.join("\n"));
    }
  }, [value]);

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Ключевые слова" value="words" />
          <Tab label="Список ключевых слов" value="dictionary" />
        </Tabs>
        {activeTab === "words" ? (
          <InputBase
            id="standard-multiline-flexible"
            placeholder="Ключевые слова"
            multiline
            fullWidth
            autoFocus
            className={classes.input}
            value={inputValue}
            onChange={handleChange}
            inputRef={inputRef}
            onKeyDown={(e) => e.stopPropagation()}
            style={{ display: "flex", alignItems: "flex-start" }} // здесь, иначе material перетирает почему то
          />
        ) : (
          <ListSearchSection
            words={words}
            handleSearchSettingsChange={handleSearchSettingsChange}
            idToSelect={searchSettings.dictionary}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="small" onClick={handleCancel} className={classes.button}>
          Отмена
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleSave}
          className={classes.button}
          disabled={inputValue.length < 1 && searchSettings.vector.length < 1 && searchSettings.dictionary.length < 1}
        >
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputDialogWordsTab;
