import React, { FC } from "react";
import { FileUploadProcess } from "services/FileUploadService";
import StatusRenderer from "components/agGrid/renderers/StatusRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LinearProgress, { LinearProgressProps } from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0",
  },
  name: {
    flexGrow: 1,
  },
  pr: {
    width: 200,
  },
  status: {
    width: 40,
  },
  err: {
    color: grey[400],
  },
}));

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

interface Props {
  process: FileUploadProcess;
}

const ListItem: FC<Props> = ({ process }) => {
  const classes = useStyles();
  const { status, name, loaded, total } = process;

  return (
    <div className={classes.root}>
      <div className={classes.status}>
        <StatusRenderer value={status} />
      </div>
      <Typography className={classes.name} title={name}>
        {name.length > 40 ? name.slice(0, 40) + " ..." : name}
      </Typography>
      <div className={classes.pr}>
        {(status === "progress" || status === "ok") && <LinearProgressWithLabel value={(loaded / total) * 100} />}
      </div>
    </div>
  );
};

export default ListItem;
