import React, { FC, useCallback, useState } from "react";
import { GridApi } from "ag-grid-community";
import { AdminTech } from "types/admin";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";
import useTechsProtected from "hooks/admin/useTechProtected";
import TechService from "services/admin/TechService";
import { deselectAll, updateRow } from "components/agGrid/functions";

// components
import TechsTable from "./components/TechsTable";
import EditTechDialog from "./components/EditTechDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
    textAlign: "right",
  },
}));

const Techs: FC = () => {
  const classes = useStyles();
  const { rowData } = useTechsProtected();
  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<AdminTech[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseEditDialog = (updatedTech?: AdminTech) => {
    setOpenEditDialog(false);
    if (updatedTech) {
      TechService.updateProtected(updatedTech)
        .then(() => {
          updateRow(updatedTech, gridApi);
          deselectAll(gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <Button
          color="primary"
          size="small"
          onClick={() => setOpenEditDialog(true)}
          disabled={selectedRows.length !== 1}
        >
          Редактировать
        </Button>
      </div>

      <TechsTable rowData={rowData} setGridApi={setGridApi} onSelection={onSelection} />
      {selectedRows.length === 1 && (
        <EditTechDialog open={openEditDialog} onClose={handleCloseEditDialog} tech={selectedRows[0]} />
      )}
    </Paper>
  );
};

export default Techs;
