import React, { ChangeEvent, FC, useEffect, useState } from "react";
import useTechs from "hooks/admin/useTechs";
import { AdminTechStt } from "types/admin";
import { Lang, TechType } from "types/task";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(() => ({
  root: {},
}));

interface SelectProps {
  language: string;
  setLanguage: (l: string) => void;
  langs: Lang[];
  disabled: boolean;
}

const SelectLang: FC<SelectProps> = ({ setLanguage, langs, language, disabled }) => {
  const classes = useStyles();

  // const [lang, setLang] = useState("");

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    // setLang(value as string);
    setLanguage(value as string);
  };

  return (
    <FormControl className={classes.root} fullWidth>
      <InputLabel id="select-lang">Язык</InputLabel>
      <Select labelId="select-lang" value={language} onChange={handleChange} fullWidth disabled={disabled}>
        {langs.map((lang) => (
          <MenuItem key={lang.id} value={lang.id}>
            {lang.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface Props {
  language: string;
  setLanguage: (l: string) => void;
  disabled?: boolean;
  excludeAuto?: boolean;
}

const LoadAdminLangs: FC<Props> = ({ setLanguage, language, disabled, excludeAuto }) => {
  const { rowData, loading, error } = useTechs();
  const [langs, setLangs] = useState<Lang[]>([]);

  useEffect(() => {
    for (let i = 0; i < rowData.length; i++) {
      if (rowData[i].techDetail.type === TechType.STT) {
        const techStt: AdminTechStt = rowData[i].techDetail as AdminTechStt;
        const langs = excludeAuto ? techStt.languages.filter((l) => l.id !== "auto") : techStt.languages;
        setLangs(langs);
      }
    }
  }, [rowData, excludeAuto]);

  if (error || loading) {
    return null;
  }

  return <SelectLang setLanguage={setLanguage} langs={langs} language={language} disabled={disabled ?? false} />;
};

export default LoadAdminLangs;
