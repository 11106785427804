import { useEffect, useState } from "react";
import { Segment } from "types/record";
import RecordService from "services/RecordService";
import { AxiosError } from "axios";

export interface State {
  loading: boolean;
  segments: Segment[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  segments: [],
  error: undefined,
};

const useSegments = (recordId: number | string, queryString?: string) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState(() => ({ error: undefined, segments: [], loading: true }));
    RecordService.getSegments(recordId, queryString)
      .then((res) => {
        setState({ segments: res.data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ segments: [], loading: false, error: err });
      });
  }, [recordId, queryString]);

  return { ...state, setState };
};

export default useSegments;
