import React, { FC, useState, MouseEvent, useEffect } from "react";
import MenuService from "services/MenuService";
import { MenuItem } from "types";
import { comparePriority } from "functions/serviceMenu";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import AppsIcon from "@material-ui/icons/Apps";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    color: "#FFF",
  },
  body: {
    padding: 20,
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 10,
  },
  item: {
    display: "block",
    textDecoration: "none",
    width: 100,
    height: 100,
    paddingTop: 16,
    cursor: "pointer",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#d9ddf2",
    },
  },
  icon: {
    width: 45,
    height: 45,
    margin: "0 auto",
    padding: 5,
    borderRadius: 10,
    marginBottom: 5,
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    paddingTop: 9,
  },
  name: {
    fontSize: 20,
    color: "#FFF",
  },
  h2: {
    margin: 0,
    textAlign: "center",
    fontSize: 14,
    fontWeight: "normal",
    color: "#404040",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AllServices: FC = () => {
  const classes = useStyles();
  const { services: replicaServices } = useSelector((state: RootState) => state.system);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [services, setServices] = useState<MenuItem[]>([]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    replicaServices.forEach(({ host, id }) => {
      if (id === "serviceMenu") {
        MenuService.getAll(host).then((res) => {
          setServices(res.data);
        });
      }
    });
  }, [replicaServices]);

  if (services.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <IconButton className={classes.button} onClick={handleClick} title="Все сервисы">
        <AppsIcon fontSize="inherit" />
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <div className={classes.body}>
          {services.sort(comparePriority).map((s) => (
            <a href={s.url} className={classes.item} target="_blank" rel="noopener noreferrer" key={s.id}>
              <div className={classes.icon}>
                <div className={classes.name}>{s.name[0].toUpperCase()}</div>
              </div>
              <h2 className={classes.h2}>{s.name}</h2>
            </a>
          ))}
        </div>
      </Menu>
    </div>
  );
};

export default AllServices;
