import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const TwitchOpenIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M4 16V4H2v12h2zM13 15l-1.5-1.5L14 11H6V9h8l-2.5-2.5L13 5l5 5-5 5z" />
    </SvgIcon>
  );
};

export default TwitchOpenIcon;
