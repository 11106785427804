import React, { FC, ChangeEvent, KeyboardEvent } from "react";

// material ui
import TextField from "@material-ui/core/TextField";

interface Props {
  value: string;
  changeValue: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  disabled?: boolean;
}

const TextAriaInput: FC<Props> = ({ value, changeValue, disabled }) => {
  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <TextField
      multiline
      rows={12}
      variant="outlined"
      value={value}
      onChange={changeValue}
      fullWidth
      onKeyDown={onKeyDown}
      disabled={disabled === undefined ? false : disabled}
    />
  );
};

export default TextAriaInput;
