import {
  AvailableSource,
  defaultSourceDetailImemo,
  defaultSourceDetailMag,
  defaultSourceDetailNppNttSprutPostgres,
  defaultSourceDetailParagraf,
  defaultSourceDetailSharedFolder,
  defaultSourceDetailSip,
  defaultSourceDetailSprutInterbase,
  defaultSourceDetailSprutOracle,
  defaultSourceDetailSprutPostgres,
  defaultSourceDetailVirtusPostgres,
  Source,
  SourceType,
} from "types/source";

export function checkSourceParagraf(sources: AvailableSource[]): boolean {
  if (sources.length === 0) return false;

  for (let i = 0; i < sources.length; i++) {
    if (sources[i].detail.type === SourceType.PARAGRAF) {
      return true;
    }
  }

  return false;
}

export function getDefaultSource(type: SourceType) {
  const source: Source = {
    id: 0,
    dateChanged: "",
    dateCreated: "",
    isCanRead: false,
    isCanWrite: false,
    message: "",
    ownerId: 0,
    status: "",
    name: "",
    comment: "",
    isActive: false,
    nameSpaceId: 0,
    sourceDetail: defaultSourceDetailSharedFolder,
  };

  if (type === SourceType.SPRUT_POSTGRES) {
    return { ...source, sourceDetail: defaultSourceDetailSprutPostgres };
  }

  if (type === SourceType.SPRUT_INTERBASE) {
    return { ...source, sourceDetail: defaultSourceDetailSprutInterbase };
  }

  if (type === SourceType.SPRUT_ORACLE) {
    return { ...source, sourceDetail: defaultSourceDetailSprutOracle };
  }

  if (type === SourceType.VIRTUS_POSTGRES) {
    return { ...source, sourceDetail: defaultSourceDetailVirtusPostgres };
  }

  if (type === SourceType.NPP_NTT_SPRUT_POSTGRES) {
    return { ...source, sourceDetail: defaultSourceDetailNppNttSprutPostgres };
  }

  if (type === SourceType.PARAGRAF) {
    return { ...source, sourceDetail: defaultSourceDetailParagraf };
  }

  if (type === SourceType.IMEMO) {
    return { ...source, sourceDetail: defaultSourceDetailImemo };
  }

  if (type === SourceType.SIP) {
    return { ...source, sourceDetail: defaultSourceDetailSip };
  }

  if (type === SourceType.MAG) {
    return { ...source, sourceDetail: defaultSourceDetailMag };
  }

  return source;
}
