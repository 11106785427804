import React, { FC } from "react";
import parse from "html-react-parser";
import { Keywords } from "types";

const ContextRender: FC<{ value: string; data: Keywords }> = ({ value, data }) => {
  const { color, words } = data;

  const getRenderString = () => {
    let r = value;
    words.forEach(({ word }) => {
      r = r.replace(word, `<span style="color: ${color}">${word}</span>`);
    });
    return r;
  };

  return <span>{parse(getRenderString())}</span>;
};

export default ContextRender;
