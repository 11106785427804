import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Group } from "types/queryBuilder";
import { WordsCloudWord } from "types";
import { generateUuid } from "functions/common";
import { showErrorAlert } from "redux/actions/alertActions";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import RecordService from "services/RecordService";

// components
import HistoryFilters from "components/search/HistoryFilters";
import Filter from "components/filter/Filter";
import WordsCloudMainWindow from "../results/resultDetail/tabWrodsCloud/WordsCloudMainWindow";
import Progress from "components/Progress";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    height: "calc(100vh - 90px)",
    overflow: "hidden",
  },
  search: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  input2: {
    flexGrow: 1,
    marginRight: 15,
  },
  wc: {
    height: "calc(100vh - 160px)",
    // overflow: "auto",
    // width: 1200,
    // margin: "0 auto",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface State {
  loading: boolean;
  words: WordsCloudWord[];
  error: undefined | Error;
}

const WordsCloud: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { filters: availableFilters } = useAvailableFilters("records");
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const [filter, setFilter] = useState<Group>(defaultFilter);

  const initialState: State = useMemo(
    () => ({
      loading: false,
      words: [],
      error: undefined,
    }),
    []
  );
  const [state, setState] = useState<State>(initialState);
  const { loading, words, error } = state;

  const isShowWordsCloud = useMemo(() => !loading && words.length !== 0, [loading, words]);

  const handleSearch = useCallback(() => {
    const queryString = "?filterv2=" + JSON.stringify(filter);
    setState((prev) => ({ ...prev, loading: true }));
    RecordService.getWordCloud(queryString)
      .then(({ data }) => {
        setState(() => ({ words: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState({ words: [], loading: false, error: err?.response?.data ?? { message: "неизвестная ошибка" } });
      });
  }, [filter]);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error, catchError]);

  return (
    <Paper className={classes.root}>
      <div className={classes.search}>
        <HistoryFilters setFilter={setFilter} />

        <div className={classes.input2}>
          <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
        </div>

        <Button variant="contained" color="primary" onClick={handleSearch}>
          Построить
        </Button>
      </div>

      {loading && (
        <div className={classes.loading}>
          <Progress />
        </div>
      )}

      <div className={classes.wc}>{isShowWordsCloud && <WordsCloudMainWindow words={words} />}</div>
    </Paper>
  );
};

export default WordsCloud;
