import React, { FC, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorAlert, showSuccessAlert } from "redux/actions/alertActions";
import { AdminTypeFilter } from "types/admin";
import { Group as TGroup } from "types/queryBuilder";

// components
import FilterDialog from "components/search/FilterDialog";
import SearchFilter from "components/searchFilter/SearchFilter";
import FilterService from "services/admin/FilterService";
import FilePasteIcon from "components/icons/FilePasteIcon";
import FileCopyIcon from "components/icons/FileCopyIcon";

// icons
import CloseIcon from "@material-ui/icons/Close";
import TuneIcon from "@material-ui/icons/Tune";
import IconButton from "@material-ui/core/IconButton";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {},
  search: {
    position: "relative",
    boxShadow: "inset 0 0 0 1px #868686",
    borderRadius: "0.25rem",
    padding: "5px",
  },
  input: {
    flexGrow: 1,
    font: "inherit",
    color: grey[500],
    padding: "0 120px 0 10px",
    width: "calc(100% - 130px)",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    height: 40,
    display: "block",
    background: "none",
    boxSizing: "content-box",
    borderRadius: 4,
    fontSize: "1rem",
    "&:focus": {
      border: "1px solid rgba(0, 0, 0, 0)",
      outline: "1px solid " + theme.palette.primary.main,
    },
  },
  styleBtn: {
    color: grey[500],
  },
  divBtn: {
    position: "absolute",
    right: "5px",
    top: "4px",
    background: "white",
  },
}));

interface Props {
  availableFilters: AdminTypeFilter[];
  filter: TGroup;
  setFilter: Function;
}

const Filter: FC<Props> = ({ filter, setFilter, availableFilters }) => {
  const classes = useStyles();
  const isShowClear = useMemo(() => filter.filters.length > 0, [filter]);
  const dispatch = useDispatch();

  const [showExtendedFilter, setShowExtendedFilter] = useState(false);

  const clearInput = () => {
    setFilter((prev: TGroup) => ({ ...prev, filters: [] }));
  };

  const handleCloseFilterDialog = (data?: TGroup) => {
    setShowExtendedFilter(false);
    if (data !== undefined) {
      setFilter(data);
    }
  };

  const saveFilter = () => {
    localStorage.setItem("APP_FILTER", JSON.stringify(filter));
    dispatch(showSuccessAlert("Фильтр скопирован."));
  };

  const pasteFilter = () => {
    const text = localStorage.getItem("APP_FILTER");
    if (text === null) return;
    try {
      const filter: TGroup = JSON.parse(String(text));
      if (filter.type === "group") {
        setFilter(filter);
      } else {
        dispatch(showErrorAlert("Неверный формат фильтра."));
      }
    } catch (e) {
      dispatch(showErrorAlert("Неверный формат фильтра."));
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <SearchFilter
          availableFilters={availableFilters}
          query={filter}
          setQuery={setFilter}
          doCustomGetQuery={FilterService.doCustomGetQuery}
        />
        <div className={classes.divBtn}>
          {isShowClear && (
            <IconButton
              size="small"
              onClick={clearInput}
              className={classes.styleBtn}
              disableRipple
              color="primary"
              title="Очистить поиск"
            >
              <CloseIcon />
            </IconButton>
          )}
          <IconButton
            size="small"
            onClick={() => setShowExtendedFilter(true)}
            className={classes.styleBtn}
            disableRipple
            color="primary"
            title="Расширенный фильтр"
          >
            <TuneIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={saveFilter}
            className={classes.styleBtn}
            disableRipple
            color="primary"
            title="Копировать"
          >
            <FileCopyIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={pasteFilter}
            className={classes.styleBtn}
            disableRipple
            color="primary"
            title="Вставить"
          >
            <FilePasteIcon />
          </IconButton>
        </div>
      </div>
      <FilterDialog
        open={showExtendedFilter}
        onClose={handleCloseFilterDialog}
        availableFilters={availableFilters}
        filter={filter}
      />
    </div>
  );
};

export default Filter;
