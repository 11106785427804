import React, { useState } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import ViewListIcon from "@material-ui/icons/ViewList";

const useStyles = makeStyles((theme) => ({
  customSlider: {
    width: 310,
    marginLeft: 20,
    marginRight: 20,

    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.text.primary,
    },
  },
  input: {
    width: 80,
  },
  customIconButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    width: "40px",
    height: "36px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "3px",
    color: "white",
    boxShadow: "0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    padding: "0px",
    margin: "4px",
    "& .MuiSvgIcon-root": {
      color: "white",
      fontSize: "24px",
    },
    "&$disabled .MuiSvgIcon-root": {
      color: theme.palette.text.disabled,
    },
    "&$disabled": {
      backgroundColor: "#D6D7DF",
    },
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  longMenu: {
    marginLeft: "auto",
    marginRight: "8px",
  },
  disabled: {},
}));

const WorldCloudMainWindowToolbar = ({
  toolbarWCActions,
  toogleView,
  mainWindowState,
  toogleThreshold,
  thresholdValueChanged,
}) => {
  const classes = useStyles();

  const { zoomIn, zoomOut, zoomReset, saveSvgToPng } = toolbarWCActions;

  return (
    <div className={classes.toolbar}>
      <InputSlider toogleThreshold={toogleThreshold} thresholdValueChanged={thresholdValueChanged} />

      <IconButton
        onClick={zoomIn}
        classes={{ root: classes.customIconButton, disabled: classes.disabled }}
        disabled={!mainWindowState.isWordsCloud}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        onClick={zoomOut}
        classes={{ root: classes.customIconButton, disabled: classes.disabled }}
        disabled={!mainWindowState.isWordsCloud}
      >
        <ZoomOutIcon />
      </IconButton>
      <IconButton
        onClick={zoomReset}
        classes={{ root: classes.customIconButton, disabled: classes.disabled }}
        disabled={!mainWindowState.isWordsCloud}
      >
        <ZoomOutMapIcon />
      </IconButton>

      <IconButton onClick={toogleView} className={classes.customIconButton}>
        <ViewListIcon />
      </IconButton>

      <LongMenu saveSvgToPng={saveSvgToPng} mainWindowState={mainWindowState} />
    </div>
  );
};

const InputSlider = ({ toogleThreshold, thresholdValueChanged }) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event) => {
    toogleThreshold(event.target.checked);
    setChecked(event.target.checked);
  };

  const handleSliderChange = (event, newValue) => {
    thresholdValueChanged(newValue);
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    let value = event.target.value === "" ? "" : Number(event.target.value);
    thresholdValueChanged(value);
    setValue(value);
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
      thresholdValueChanged(0);
    } else if (value > 100) {
      setValue(100);
      thresholdValueChanged(100);
    }
  };

  return (
    <div className={classes.customSlider}>
      <Typography
        id="input-slider"
        variant="caption"
        style={{ color: "rgba(0, 0, 0, 0.54)", marginLeft: "105px" }}
        gutterBottom
      >
        Confidence (%)
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label="Порог" />
        </Grid>
        <Grid item>
          <Input
            disabled={!checked}
            className={classes.input}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            style={{ fontSize: "14px", bottom: "6px" }}
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
        <Grid item xs>
          <Slider
            disabled={!checked}
            value={typeof value === "number" ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
      </Grid>
    </div>
  );
};

const LongMenu = ({ saveSvgToPng, mainWindowState }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div className={classes.longMenu}>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem
          disabled={!mainWindowState.isWordsCloud}
          onClick={() => {
            saveSvgToPng();
            handleClose();
          }}
        >
          Сохранить в JPEG
        </MenuItem>
      </Menu>
    </div>
  );
};

export default WorldCloudMainWindowToolbar;
