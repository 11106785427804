import React, { FC, useCallback, useEffect, useState } from "react";
import { Group as TGroup, Operation } from "types/queryBuilder";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {
    height: 465,
  },
  chip: {
    marginRight: 10,
    cursor: "pointer",
  },
}));

interface Props {
  group: TGroup;
  changeGroupOperation: (g: TGroup, o: Operation) => void;
}

const DetailGroup: FC<Props> = ({ group: g, changeGroupOperation }) => {
  const classes = useStyles();
  const [group, setGroup] = useState(g);
  const { operation } = group;

  const changeOperation = useCallback(
    (operation: Operation) => {
      changeGroupOperation(g, operation);
      setGroup((prev) => ({ ...prev, operation }));
    },
    [g, changeGroupOperation]
  );

  useEffect(() => {
    setGroup(g);
  }, [g]);

  return (
    <div className={classes.root}>
      <Typography gutterBottom>Объединять:</Typography>
      <div>
        <Chip
          className={classes.chip}
          label="И"
          color={operation === "AND" ? "primary" : "default"}
          onClick={() => changeOperation("AND")}
        />
        <Chip
          className={classes.chip}
          label="ИЛИ"
          color={operation === "OR" ? "primary" : "default"}
          onClick={() => changeOperation("OR")}
        />
      </div>
    </div>
  );
};

export default DetailGroup;
