import React, { ChangeEvent, FC, useState } from "react";
import ColorPicker from "./ColorPicker";
import clsx from "clsx";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { indigo } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {},
  body: {
    padding: "0 15px 15px 15px",
    position: "relative",
  },
  inputWrapper: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
  },
  input: {},
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  select: {
    width: 200,
  },
  title: {
    padding: "15px 15px 5px 15px",
  },
  color: {
    display: "flex",
    alignItems: "center",
  },
  colorPicker: {
    position: "absolute",
    backgroundColor: "#FFF",
    top: 30,
    right: -205,
    zIndex: theme.zIndex.drawer + 3,
  },
  currentColor: {
    width: 25,
    height: 25,
    marginRight: 10,
    borderRadius: 5,
    cursor: "pointer",
    border: "1px solid #CCC",
  },
  colorWrapper: {
    position: "relative",
  },
  hidden: {
    opacity: 0,
  },
  field: {
    paddingLeft: 35,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
}

interface State {
  name: string;
  color: string;
  comment: string;
}

const CreateDictionaryDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [state, setState] = useState<State>({
    name: "",
    color: indigo[500],
    comment: "",
  });
  const { name, color, comment } = state;
  const disabled = name.length === 0 || color.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onClose(state);
  };

  const handleChangeColor = (color: string) => {
    setState((prev) => ({ ...prev, color }));
  };

  const handleClickAway = () => {
    setShowColorPicker(false);
  };

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <div className={classes.title}>
        <Typography variant="h6">Добавить словарь</Typography>
      </div>

      <div className={classes.body}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.field}>
              <TextField label="Имя" fullWidth variant="standard" name="name" onChange={handleChange} value={name} />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.color}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className={classes.colorWrapper}>
                  <div
                    className={classes.currentColor}
                    style={{ backgroundColor: color }}
                    onClick={() => setShowColorPicker((prev) => !prev)}
                  />
                  {showColorPicker && (
                    <div className={classes.colorPicker}>
                      <ColorPicker onChangeColor={handleChangeColor} />
                    </div>
                  )}
                </div>
              </ClickAwayListener>
              <TextField label="Цвет" fullWidth variant="standard" name="color" onChange={handleChange} value={color} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.field}>
              <TextField
                label="Комментарий"
                fullWidth
                variant="standard"
                name="comment"
                onChange={handleChange}
                value={comment}
              />
            </div>
          </Grid>
        </Grid>

        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateDictionaryDialog;
