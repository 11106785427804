import React, { FC, useState, useEffect, ChangeEvent } from "react";

import Picker from "components/Picker";
import moment from "moment";
import { dateIsValid } from "functions/date";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Chip from "@material-ui/core/Chip";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  disableTransition: {
    transition: "none",
  },
  root: {
    margin: "20px",
    width: "min-content",
  },
  wrapper: {
    display: "flex",
  },
  mainContent: {},
  radio: {
    gap: "6rem",
    marginTop: "23px",
  },
  activeTitle: {
    marginTop: "15px",
  },
  disabledTitle: {
    marginTop: "15px",
    opacity: "30%",
  },
  valueLabel: {
    zIndex: 0,
  },
  pickers: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    justifyContent: "start",
  },
  from: {
    fontSize: theme.typography.fontSize,
    marginRight: 10,
  },
  to: {
    fontSize: theme.typography.fontSize,
    marginLeft: 30,
    marginRight: 10,
  },
  btns: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    marginRight: 10,
    marginBottom: 10,
    cursor: "pointer",
  },
  sliderWrapper: {
    margin: "40px 10px 10px 10px",
  },
}));

export interface State {
  text: string;
}

interface Props {
  anchorEl: any;

  id?: string;
  value: string;
  onSelection?: Function;
  dynamicDateString: (value: string | null) => void;
  equals: boolean;
  popoverOpen: boolean;
  onClose: () => void;
}

export function getDateByPeriod(period: string): any {
  const now = moment();
  let startDate;
  let endDate;

  startDate = new Date(now.format("YYYY-MM-DD") + " 00:00:00");
  endDate = new Date(now.clone().add(1, "day").format("YYYY-MM-DD") + " 00:00:00");

  if (period === "yesterday") {
    const yesterday = now.clone().subtract(1, "days");
    startDate = new Date(yesterday.format("YYYY-MM-DD") + " 00:00:00");
    endDate = new Date(now.format("YYYY-MM-DD") + " 00:00:00");
  }

  if (period === "this week") {
    const monday = now.clone().weekday(1);
    startDate = new Date(monday.format("YYYY-MM-DD") + " 00:00:00");
    endDate = new Date(monday.add(7, "day").format("YYYY-MM-DD") + " 00:00:00");
  }

  if (period === "this month") {
    startDate = new Date(now.startOf("month").format("YYYY-MM-DD") + " 00:00:00");
    endDate = new Date(now.add(1, "month").format("YYYY-MM-DD") + " 00:00:00");
  }

  if (period === "last month") {
    const lastMonth = now.clone().subtract(1, "month");
    startDate = new Date(lastMonth.startOf("month").format("YYYY-MM-DD") + " 00:00:00");
    endDate = new Date(now.startOf("month").format("YYYY-MM-DD") + " 00:00:00");
  }

  return { startDate, endDate };
}
// для кнопок с 1 датой
export function getOneDateByPeriod(period: string): any {
  const now = moment();
  let startDate;

  startDate = new Date(now.format("YYYY-MM-DD") + " 00:00:00");

  if (period === "yesterday") {
    const yesterday = now.clone().subtract(1, "days");
    startDate = new Date(yesterday.format("YYYY-MM-DD") + " 00:00:00");
  }

  if (period === "this week") {
    const monday = now.clone().weekday(1);
    startDate = new Date(monday.format("YYYY-MM-DD") + " 00:00:00");
  }

  if (period === "this month") {
    startDate = new Date(now.startOf("month").format("YYYY-MM-DD") + " 00:00:00");
  }

  if (period === "last month") {
    const lastMonth = now.clone().subtract(1, "month");
    startDate = new Date(lastMonth.startOf("month").format("YYYY-MM-DD") + " 00:00:00");
  }

  return { startDate };
}

export function convertToDates(value: string): any {
  const lines = value.replaceAll(" ", "+").split("\n");
  if (lines.length !== 2) {
    return getDateByPeriod("today");
  }

  let startDate = new Date(lines[0]);
  let endDate = new Date(lines[1]);

  // если начальная дата НЕ валидна
  if (!dateIsValid(startDate)) {
    startDate = new Date();
  }
  // если конечная дата НЕ валидна
  if (!dateIsValid(endDate)) {
    endDate = new Date();
  }

  return { startDate: startDate, endDate: endDate };
}

export function convertOneDate(value: string): any {
  const formattedValue = value.replaceAll(" ", "+");
  let startDate = new Date(formattedValue);
  // если начальная дата НЕ валидна
  if (!dateIsValid(startDate)) {
    startDate = new Date();
  }
  return { startDate: startDate };
}

export function convertToString(value: string) {
  const { startDate, endDate } = convertToDates(value);

  return `${moment(startDate).format("DD-MM-YYYY HH:mm:ss")} - ${moment(endDate).format("DD-MM-YYYY HH:mm:ss")}`;
}

// Для ">", "<", когда у нас 1 дата.
export function convertToStringOneDate(value: string) {
  const { startDate } = convertOneDate(value);
  return moment(startDate).format("DD-MM-YYYY HH:mm:ss");
}

export const InputDateEditComponent: FC<Props> = ({
  anchorEl,
  id,
  value,
  equals,
  onSelection,
  dynamicDateString,
  popoverOpen,
  onClose,
}) => {
  const classes = useStyles();

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [dynamicDate, setDynamicDate] = useState<string | null>(null);
  const [testValue, setTestValue] = useState<number>(0);
  const [radioValue, setRadioValue] = useState<string>("normal");
  const [activeDate, setActiveDate] = useState<boolean>(false);
  const [activeDynamicDate, setActiveDynamicDate] = useState<boolean>(true);
  const [soloStartDate, setSoloStartDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (value && value !== "") {
      const dayPattern = /\[day\]-(\d+)/;
      const match = value.match(dayPattern);
      if (match) {
        const dayNumber = parseInt(match[1], 10); // извлекаем число из выражения '[day]-число'
        // включаем радио кнопку
        setRadioValue("dynamic");
        setActiveDate(true);
        setActiveDynamicDate(false);
        // так как в строке фильтра у нас преобразованное число, преобразуем обратно для значения по умолчанию на слайдере
        if (dayNumber < 32) {
          setTestValue(dayNumber);
        } else if (dayNumber === 60) {
          setTestValue(32);
        } else if (dayNumber === 90) {
          setTestValue(33);
        } else if (dayNumber === 180) {
          setTestValue(34);
        } else if (dayNumber === 365) {
          setTestValue(35);
        } else if (dayNumber === 730) {
          setTestValue(36);
        } else if (dayNumber === 1095) {
          setTestValue(37);
        }
      } else {
        setRadioValue("normal");
        const parsedDate = new Date(value);
        if (!isNaN(parsedDate.getTime())) {
          setSoloStartDate(parsedDate);
        }
      }
    }
  }, [popoverOpen, value]);

  useEffect(() => {
    const { startDate } = convertOneDate(value);
    if (startDate) {
      setSoloStartDate(startDate);
    }
    const dayPattern = /\[day\]-(\d+)/;
    const match = value.match(dayPattern);
    if (match) {
      const dayNumber = parseInt(match[1], 10);
      setDynamicDate(String(dayNumber));
    }
  }, [value, popoverOpen]);

  useEffect(() => {
    if (equals) {
      const { startDate, endDate } = convertToDates(value);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, [value, equals]);

  const OnCloseCallback = () => {
    onClose();
    const text = moment(startDate).toISOString(true) + "\n" + moment(endDate).toISOString(true);
    const oneDate = moment(soloStartDate).toISOString(true);
    const day = "[day]-";
    if (!equals && !dynamicDate) {
      onSelection?.(oneDate, "editAndClose");
    } else if (!equals && dynamicDate) {
      onSelection?.(day + dynamicDate, "editAndClose");
    } else if (equals) {
      onSelection?.(text, "editAndClose");
    }
  };

  const onOtherDateChange = (dt: Date) => {
    setSoloStartDate(dt);
    setDynamicDate(null);
    dynamicDateString(null);
  };

  const onStartDateChange = (dt: Date) => {
    setStartDate(dt);
    setDynamicDate(null);
    dynamicDateString(null);
  };

  const onEndDateChange = (dt: Date) => {
    setEndDate(dt);
    setDynamicDate(null);
    dynamicDateString(null);
  };

  const setDateInterval = (period: string) => {
    const { startDate, endDate } = getDateByPeriod(period);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const setOneDateInterval = (period: string) => {
    const { startDate } = getOneDateByPeriod(period);
    setSoloStartDate(startDate);
  };

  const handleButtonClick = (value: number) => {
    setTestValue(value);
    setDynamicDate(String(value));
    dynamicDateString(String(value));
  };

  // Чтобы значения на слайдере следовали друг за другом, иначе будет визуальное расстояние между 31 и 60 и т.д.
  function calculateValue(value: number) {
    if (value <= 31) {
      return value;
    } else if (value === 32) {
      return 60;
    } else if (value === 33) {
      return 90;
    } else if (value === 34) {
      return 180;
    } else if (value === 35) {
      return 365;
    } else if (value === 36) {
      return 730;
    } else if (value === 37) {
      return 1095;
    } else {
      return 0;
    }
  }

  const handleTestChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setTestValue(newValue);
      dynamicDateString(String(newValue));
      if (newValue === 32) {
        setDynamicDate("60");
        dynamicDateString("60");
      } else if (newValue === 33) {
        setDynamicDate("90");
        dynamicDateString("90");
      } else if (newValue === 34) {
        setDynamicDate("180");
        dynamicDateString("180");
      } else if (newValue === 35) {
        setDynamicDate("365");
        dynamicDateString("365");
      } else if (newValue === 36) {
        setDynamicDate("730");
        dynamicDateString("730");
      } else if (newValue === 37) {
        setDynamicDate("1095");
        dynamicDateString("1095");
      } else {
        setDynamicDate(String(newValue));
        dynamicDateString(String(newValue));
      }
    }
  };

  const dateInterval = (value: string) => {
    setDateInterval(value);
    setDynamicDate(null);
    dynamicDateString(null);
  };

  // для кнопок с соло датой
  const oneDateInterval = (value: string) => {
    setOneDateInterval(value);
    setDynamicDate(null);
    dynamicDateString(null);
  };

  const handleRadioChange = (event: ChangeEvent) => {
    setRadioValue((event.target as HTMLInputElement).value);
    if (radioValue === "normal") {
      dynamicDateString(null);
      setActiveDate(true);
      setActiveDynamicDate(false);
    } else {
      setActiveDate(false);
      setActiveDynamicDate(true);
    }
  };

  return (
    <div>
      <Popover
        className={classes.disableTransition}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          OnCloseCallback();
        }}
      >
        {equals && (
          <div className={classes.root}>
            <div className={classes.pickers}>
              <div className={classes.from}>{"C"}</div>
              <Picker date={startDate} onDateChange={onStartDateChange} />
              <div className={classes.to}>{"по"}</div>
              <Picker date={endDate} onDateChange={onEndDateChange} />
            </div>
            <div className={classes.btns}>
              <Chip label="Сегодня" className={classes.chip} onClick={() => dateInterval("today")} />
              <Chip label="Вчера" className={classes.chip} onClick={() => dateInterval("yesterday")} />
              <Chip label="За эту неделю" className={classes.chip} onClick={() => dateInterval("this week")} />
              <Chip label="За этот месяц" className={classes.chip} onClick={() => dateInterval("this month")} />
            </div>
          </div>
        )}
        {!equals && (
          <div className={classes.root} style={{ width: "1000px" }}>
            <div className={classes.wrapper}>
              <RadioGroup value={radioValue} onChange={handleRadioChange} className={classes.radio}>
                <FormControlLabel value="normal" control={<Radio color="primary" />} label="" />
                <FormControlLabel value="dynamic" control={<Radio color="primary" />} label="" />
              </RadioGroup>
              <div className={classes.mainContent}>
                <div className={classes.pickers}>
                  <Picker date={soloStartDate} onDateChange={onOtherDateChange} disabled={activeDate} />
                </div>
                <div className={classes.btns}>
                  <Chip
                    label="Сегодня"
                    className={classes.chip}
                    onClick={() => oneDateInterval("today")}
                    disabled={activeDate}
                  />
                  <Chip
                    label="Вчера"
                    className={classes.chip}
                    onClick={() => oneDateInterval("yesterday")}
                    disabled={activeDate}
                  />
                  <Chip
                    label="За эту неделю"
                    className={classes.chip}
                    onClick={() => oneDateInterval("this week")}
                    disabled={activeDate}
                  />
                  <Chip
                    label="За этот месяц"
                    className={classes.chip}
                    onClick={() => oneDateInterval("this month")}
                    disabled={activeDate}
                  />
                </div>
                <Typography className={activeDate ? classes.activeTitle : classes.disabledTitle}>
                  Показывать дату за предыдущие N - дней:
                </Typography>
                <div className={classes.sliderWrapper}>
                  <Slider
                    value={testValue}
                    min={0}
                    step={1}
                    max={37}
                    marks
                    scale={calculateValue}
                    onChange={handleTestChange}
                    valueLabelDisplay="on"
                    disabled={activeDynamicDate}
                    classes={{
                      valueLabel: classes.valueLabel,
                    }}
                  />
                </div>
                <div className={classes.btns}>
                  <Chip
                    label="За сегодня"
                    className={classes.chip}
                    onClick={() => handleButtonClick(0)}
                    disabled={activeDynamicDate}
                  />
                  <Chip
                    label="За вчера"
                    className={classes.chip}
                    onClick={() => handleButtonClick(1)}
                    disabled={activeDynamicDate}
                  />
                  <Chip
                    label="За 3 дня"
                    className={classes.chip}
                    onClick={() => handleButtonClick(3)}
                    disabled={activeDynamicDate}
                  />
                  <Chip
                    label="За 7 дней"
                    className={classes.chip}
                    onClick={() => handleButtonClick(7)}
                    disabled={activeDynamicDate}
                  />
                  <Chip
                    label="За 30 дней"
                    className={classes.chip}
                    onClick={() => handleButtonClick(30)}
                    disabled={activeDynamicDate}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default InputDateEditComponent;
