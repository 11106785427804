import React, { ChangeEvent, FC } from "react";
import { FileFormatDetail, FormatFile } from "types/admin";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Fade from "@material-ui/core/Fade";

interface Props {
  fileFormatDetail: FormatFile;
  setFileFormatDetail: (fileFormatDetail: FileFormatDetail) => void;
}

const TypeFormatFile: FC<Props> = ({ fileFormatDetail, setFileFormatDetail }) => {
  const { codec, isAutoDetect } = fileFormatDetail;

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFileFormatDetail({
      ...fileFormatDetail,
      [name]: value,
    });
  };

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFileFormatDetail({
      ...fileFormatDetail,
      isAutoDetect: checked,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox checked={isAutoDetect} onChange={handleChangeCheckbox} name="isAutoDetect" color="primary" />
          }
          label="Определять вокодер  автоматически"
        />
      </Grid>
      <Fade in={!isAutoDetect} mountOnEnter unmountOnExit>
        <Grid item xs={12}>
          <TextField
            label="Задать вокодер"
            fullWidth
            name="codec"
            onChange={handleChangeInput}
            value={codec}
            disabled={isAutoDetect}
          />
        </Grid>
      </Fade>
    </Grid>
  );
};

export default TypeFormatFile;
