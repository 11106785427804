import React, { FC } from "react";

const Spinner: FC = () => {
  return (
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
