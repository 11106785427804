import React, { FC, useState, useEffect } from "react";

// components
import { FilterType as TFilterType, Group as TGroup, Rule as TRule, Condition as TCondition } from "types/queryBuilder";
import { EditModel } from "../types/types";
import { AdminTypeFilter } from "types/admin";
import InputComboComponent from "./InputComboComponent";
import RenderInput from "./RenderInput";
import { getConditionList } from "../common/helperFunctions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "5px",
    float: "left",
    height: "100%",
    display: "flex",
  },
  divText: {
    background: grey[100],
    display: "inline-flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginRight: "2px",
    minWidth: "max-content",
    height: "21px",
    lineHeight: "21px",
  },
  divTextSelected: {
    background: grey[300],
  },
  divValue: {
    background: grey[300],
    display: "inline-flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "0px",
    maxWidth: "100px",
    minWidth: "60px",
    height: "21px",
    lineHeight: "21px",
    position: "relative",
  },
  divValueSelected: {
    background: grey[400],
  },
  spanValue: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: "20px",
  },

  StyleBtn: {
    color: grey[500],
    width: "16px",
    height: "16px",
    position: "absolute",
    right: "0px",
  },
  StyleBtnSvg: {
    width: "16px",
    height: "16px",
  },
}));

interface State {
  listFilters: string[];
  listConditions: string[];

  baseFilter: AdminTypeFilter | null;
}

interface Props {
  availableFilters: AdminTypeFilter[];
  rule: TRule | TGroup;
  editModel: EditModel;
  onBlur: Function;
  onClickRule: Function;
  onChangeRule: Function;
  doCustomGetQuery: Function;
}

const RenderRule: FC<Props> = ({
  availableFilters,
  rule,
  editModel,
  onBlur,
  onClickRule,
  onChangeRule,
  doCustomGetQuery,
}) => {
  const classes = useStyles();

  const filter = rule as TRule;
  const [state, setState] = useState<State>({ listFilters: [], listConditions: [], baseFilter: null });
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [equals, setEquals] = useState<boolean>(false);

  let isFocusFilter = editModel.id === rule.uuid && editModel.editMode === 0;
  let isFocusOperation = editModel.id === rule.uuid && editModel.editMode === 1;
  let isFocusValue = editModel.id === rule.uuid && editModel.editMode === 2;
  let isEditStart = editModel.id === rule.uuid && editModel.isEditStart;

  const OnSelectedFilter = (selectedValue: string, reason: string) => {
    setIsMouseOver(false);

    let exist = availableFilters.find((p) => {
      return p.name === selectedValue;
    });
    if (exist) {
      filter.filter = exist.name;

      onChangeRule(reason, { ...filter });
    }
  };

  const OnSelectedCondition = (selectedValue: string, reason: string) => {
    setIsMouseOver(false);
    if (selectedValue === "=") {
      setEquals(true);
    } else {
      setEquals(false);
    }
    filter.condition = selectedValue as TCondition;
    onChangeRule(reason, { ...filter });
  };

  const OnSelectedValue = (selectedValue: string, reason: string) => {
    setIsMouseOver(false);

    filter.value = selectedValue;
    onChangeRule(reason, { ...filter });
  };

  const OnClickClear = (selectedValue: any) => {
    onChangeRule("delete", filter);
  };

  const onBlurCallback = () => {
    setIsMouseOver(false);
    onBlur();
  };

  useEffect(() => {
    // доступные фильтры
    let listFilters: string[] = [];
    for (let i = 0; i < availableFilters.length; i++) {
      listFilters.push(availableFilters[i].name);
    }

    let baseFilter = availableFilters.find((p) => p.name === filter.filter);
    filter.value = state.baseFilter?.type === TFilterType.Boolean && filter.value === "" ? "Да" : filter.value;
    if (!baseFilter) {
      baseFilter = availableFilters[0];
    }

    // условие
    let listConditions = getConditionList(baseFilter.type as TFilterType);

    setState({
      listFilters: listFilters,
      listConditions: listConditions,
      baseFilter: baseFilter,
    });
  }, [availableFilters, filter, rule, state.baseFilter]);

  return (
    <div className={classes.root} onMouseEnter={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
      {/* filter */}

      <InputComboComponent
        id="filter"
        value={filter.filter}
        isFocus={isFocusFilter}
        params={{ values: state.listFilters }}
        onOpen={() => onClickRule(filter.uuid, 0)}
        onSelection={OnSelectedFilter}
        color={isMouseOver ? grey[300] : grey[200]}
      />

      {/* operation */}
      <InputComboComponent
        id="condition"
        value={filter.condition}
        isFocus={isFocusOperation}
        params={{ values: state.listConditions }}
        onOpen={() => onClickRule(filter.uuid, 1)}
        onSelection={OnSelectedCondition}
        color={isMouseOver ? grey[300] : grey[200]}
      />

      {/* value */}
      <RenderInput
        ruleType={state.baseFilter?.type}
        id="value"
        color={isMouseOver ? grey[300] : grey[200]}
        value={filter.value}
        filter={filter.filter}
        isEditStart={isEditStart}
        isFocus={isFocusValue}
        isVisibleDeleteButton={true}
        equals={equals}
        params={state.baseFilter?.params}
        onOpen={() => onClickRule(filter.uuid, 2)}
        onClose={onBlurCallback}
        onSelection={OnSelectedValue}
        onClickDelete={OnClickClear}
        doCustomGetQuery={doCustomGetQuery}
      />
    </div>
  );
};

export default RenderRule;
