import React, { MouseEvent, FC, useState, useRef, useCallback, useEffect, useMemo } from "react";
import { normalizeTime } from "functions/common";
import { API_URL } from "http/urls";
import FileEditorService from "services/FileEditorService";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { Channel, ZoomStep } from "./types";
import Loader from "./Loader";

// icons
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import CropIcon from "@material-ui/icons/Crop";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import ChannelSelect from "./ChannelSelect";
import Typography from "@material-ui/core/Typography";

const defaultWidth = 1240;
const defaultHeight = 166;

const zoomStep: ZoomStep[] = [
  { width: defaultWidth, height: defaultHeight },
  { width: 1400, height: defaultHeight },
  { width: 1600, height: defaultHeight },
  { width: 1800, height: defaultHeight },
  { width: 2000, height: defaultHeight },
  { width: 2200, height: defaultHeight },
];

const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: 70,
  },
  oscillogram: {
    width: defaultWidth,
    overflowX: "scroll",
    position: "relative",
  },
  img: {
    height: defaultHeight,
  },
  range: {
    position: "absolute",
    top: 0,
    height: "100%",
    backgroundColor: "rgba(255, 255, 102, .5)",
  },
  currentPosition: {
    position: "absolute",
    top: 0,
    width: 1,
    height: "100%",
    backgroundColor: "rgb(77, 38, 0)",
  },
  timeline: {
    paddingTop: 20,
  },
  dashboard: {
    // paddingTop: 20,
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
  },
  channelSelect: {},
  buttons: {
    // paddingLeft: 40,
    display: "flex",
    alignItems: "center",
  },
  button: {
    // marginRight: 10,
  },
  htmlAudio: {
    display: "none",
  },
  time: {
    // paddingLeft: 20,
    fontSize: 14,
    padding: "2px 8px",
    border: "1px solid " + grey[300],
    marginRight: 10,
    width: 102,
  },
  save: {
    position: "absolute",
    right: 20,
    bottom: 20,
  },
  ok: {
    width: 100,
    marginLeft: 20,
  },
  zoomLevel: {
    margin: "0 3px",
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: theme.spacing(1),
    padding: "2px 8px",
    fontSize: theme.typography.fontSize,
    backgroundColor: "#b7e4ff",
    display: "inline-block",
  },
}));

const HOST = `${API_URL}/audioeditor`;

let isMouseDown = false;
let isMouseMove = false;
let isMouseDownScroll = false;

interface Props {
  file: File | string;
  onClose: (data?: any) => void;
}

const Editor: FC<Props> = ({ file, onClose }) => {
  const classes = useStyles();
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const fileName = useMemo(() => (typeof file === "string" ? file : file.name), [file]);

  const oscillogramRef = useRef<HTMLDivElement>(null);
  const rangeRef = useRef<HTMLDivElement>(null);
  const currentPositionRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  const [intervalId, setIntervalId] = useState<number | null>(null);
  const [audioSrc, setAudioSrc] = useState<string | null>(null);
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  // число пикселей, не проценты
  const [currentPosition, setCurrentPosition] = useState(0);

  const [sessionID, setSessionID] = useState<number | undefined>(undefined);
  const [channels, setChannels] = useState<Channel[]>([]);

  const [waveformImg, setWaveformImg] = useState<string | undefined>(undefined);
  const [currentZoomStep, setCurrentZoomStep] = useState(0);

  function showLoader() {
    setLoading(true);
  }

  function hideLoader() {
    setLoading(false);
  }

  function handleMouseDown(event: MouseEvent<HTMLDivElement>) {
    const { left, top } = oscillogramRef.current!.getBoundingClientRect();
    const { clientY, clientX } = event;
    if (clientY > top + zoomStep[currentZoomStep].height - 6) {
      isMouseDownScroll = true;
      return;
    }
    const currPosition = clientX - left;
    setCurrentPosition(currPosition);
    handleSeeking(currPosition);

    isMouseDown = true;
  }

  function handleMouseUp() {
    // если нажали на скролл
    if (isMouseDownScroll) {
      isMouseDownScroll = false;
      return;
    }
    // если это клик
    if (!isMouseMove) {
      setRangeStart(0);
      setRangeEnd(0);
    }
    // если отпустили кнопку после выделения
    if (isMouseMove) {
      // передвинуть курсор в начало выделения
      setCurrentPosition(rangeStart);
      isMouseMove = false;
    }
    isMouseDown = false;
  }

  function handleMouseMove(event: MouseEvent<HTMLDivElement>) {
    if (isMouseDown) {
      mouseMove(event);
    }
  }

  function mouseMove(event: MouseEvent<HTMLDivElement>) {
    const { left, width } = oscillogramRef.current!.getBoundingClientRect();
    const mouseX = event.clientX - left;
    isMouseMove = true;

    // движение мышки ВПРАВО
    if (mouseX > currentPosition) {
      setRangeStart(currentPosition);
      setRangeEnd(() => {
        return mouseX > width ? width : mouseX;
      });
    }

    // движение мышки ВЛЕВО
    if (mouseX < currentPosition) {
      setRangeStart(mouseX);
      setRangeEnd(currentPosition);
    }
  }

  const setChannel = (sessionId: number) => {
    clearSettings();
    setSessionID(sessionId);
    loadWaveform(sessionId, zoomStep[currentZoomStep].width);
    setAudioSrc(`${HOST}/audio/${sessionId}?authorization=${accessToken}`);
  };

  function handleZoomIn() {
    setCurrentZoomStep((prevStep) => {
      if (prevStep === zoomStep.length - 1) {
        return zoomStep.length - 1;
      }
      const currZoomStep = prevStep + 1;
      updateWaveformRangeCurrentPosition(prevStep, currZoomStep);
      return currZoomStep;
    });
  }

  function handleZoomOut() {
    setCurrentZoomStep((prevStep) => {
      if (prevStep === 0) {
        return 0;
      }
      const currZoomStep = prevStep - 1;
      updateWaveformRangeCurrentPosition(prevStep, currZoomStep);
      return currZoomStep;
    });
  }

  function updateWaveformRangeCurrentPosition(prevStep: number, currZoomStep: number) {
    if (sessionID) {
      loadWaveform(sessionID, zoomStep[currZoomStep].width);
    }
    updateCurrentPositionAfterZoom(prevStep, currZoomStep);
    updateRangeAfterZoom(prevStep, currZoomStep);
  }

  function updateRangeAfterZoom(prevStep: number, currZoomStep: number) {
    if (rangeStart !== 0) {
      setRangeStart((prev) => {
        const percent = (prev * 100) / zoomStep[prevStep].width;
        return (zoomStep[currZoomStep].width * percent) / 100;
      });
    }

    if (rangeEnd !== 0) {
      setRangeEnd((prev) => {
        const percent = (prev * 100) / zoomStep[prevStep].width;
        return (zoomStep[currZoomStep].width * percent) / 100;
      });
    }
  }

  function updateCurrentPositionAfterZoom(prevStep: number, currZoomStep: number) {
    setCurrentPosition((prev) => {
      const percent = (prev * 100) / zoomStep[prevStep].width;
      return (zoomStep[currZoomStep].width * percent) / 100;
    });
  }

  const clearSettings = useCallback(() => {
    setRangeStart(0);
    setRangeEnd(0);
    setCurrentZoomStep(0);
    setCurrentPosition(0);
  }, []);

  const loadWaveform = (sessionId: number, width = defaultWidth, height = defaultHeight) => {
    const url = `${HOST}/waveform/${sessionId}/${width}/${height}?authorization=${accessToken}`;
    setWaveformImg(url);
  };

  async function handleDeleteSegment() {
    const { duration } = audioRef.current!;
    const { width } = zoomStep[currentZoomStep];
    const start = (duration * ((rangeStart * 100) / width)) / 100;
    const end = (duration * ((rangeEnd * 100) / width)) / 100;

    FileEditorService.operationDelete(sessionID ?? 0, start, end).then(({ data }) => {
      const sessionId = Number(data);
      setSessionID(sessionId);
      setRangeStart(0);
      setRangeEnd(0);
      setAudioSrc(`${HOST}/audio/${sessionId}?authorization=${accessToken}`);
      loadWaveform(sessionId, zoomStep[currentZoomStep].width);
    });
  }

  function updateCursorPosition() {
    const { duration, currentTime } = audioRef.current!;
    const progress = currentTime / (duration / 100);

    setCurrentTime(currentTime);
    setCurrentPosition((zoomStep[currentZoomStep].width * progress) / 100);
  }

  function handleAudioPlay() {
    if (audioRef && audioRef.current) {
      audioRef.current.play().then(() => {
        setAudioIsPlaying(true);
        if (intervalId) {
          window.clearInterval(intervalId);
        }
        const id = window.setInterval(updateCursorPosition, 100);
        setIntervalId(id);
      });
    }
  }

  function handleAudioPause() {
    if (audioRef && audioRef.current) {
      audioRef.current.pause();
      if (intervalId) {
        window.clearInterval(intervalId);
      }
      setAudioIsPlaying(false);
    }
  }

  // перемотка
  function handleSeeking(currentPosition: number) {
    if (audioRef && audioRef.current) {
      const positionPercent = currentPosition / (zoomStep[currentZoomStep].width / 100);
      audioRef.current.currentTime = (audioRef.current.duration * positionPercent) / 100;
      setCurrentTime(audioRef.current.currentTime);
    }
  }

  // сохранение результата
  function handleSave() {
    const data = {
      audioSrc: audioSrc ?? "",
      sessionID: sessionID ?? "",
    };
    onClose(data);
  }

  const handleReceiveData = useCallback(
    (data: any) => {
      const channels: Channel[] = data.Channels;
      const sessionId = channels[0].SessionID;

      setChannels(channels);
      setSessionID(sessionId);
      loadWaveform(sessionId);
      setAudioSrc(`${HOST}/audio/${sessionId}?authorization=${accessToken}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken]
  );

  useEffect(() => {
    const isFile = file instanceof File;
    showLoader();
    if (isFile) {
      const fd = new FormData();
      fd.append("files", file);
      FileEditorService.upload(fd)
        .then(({ data }) => {
          handleReceiveData(data);
        })
        .finally(hideLoader);
    }

    if (!isFile) {
      FileEditorService.uploadUrl(file)
        .then(({ data }) => {
          handleReceiveData(data);
        })
        .finally(hideLoader);
    }
  }, [file, handleReceiveData]);

  useEffect(() => {
    clearSettings();
  }, [file, clearSettings]);

  // при размонтировании очищаем интервал
  useEffect(() => {
    return () => {
      if (intervalId) window.clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    if (audioRef && audioRef.current) {
      audioRef.current.onloadedmetadata = () => {
        if (currentTime !== 0) {
          const percent = (currentTime * 100) / audioRef.current!.duration;
          const { width } = zoomStep[currentZoomStep];
          setCurrentPosition((width * percent) / 100);
          audioRef.current!.currentTime = currentTime;
        }

        setDuration(audioRef.current!.duration);
      };
      audioRef.current.onended = () => {
        setAudioIsPlaying(false);
      };
      audioRef.current.ontimeupdate = () => {
        //setCurrentTime(audioRef.current!.currentTime);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSrc]);

  return (
    <div className={classes.root} ref={rootRef}>
      <Typography className={classes.title} title={fileName}>
        {fileName}
      </Typography>

      {loading && <Loader />}

      {audioSrc && <audio ref={audioRef} src={audioSrc} />}

      <div
        className={classes.oscillogram}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div className={classes.currentPosition} ref={currentPositionRef} style={{ left: currentPosition }} />
        <div className={classes.range} ref={rangeRef} style={{ left: rangeStart, width: rangeEnd - rangeStart }} />
        <div
          className={classes.img}
          style={{
            backgroundImage: waveformImg ? `url(${waveformImg})` : "none",
            width: zoomStep[currentZoomStep].width,
          }}
          ref={oscillogramRef}
        />
      </div>

      <div className={classes.dashboard}>
        <div className={classes.time} title="Текущая позиция : Длительность сеанса">
          {`${normalizeTime(currentTime)} : ${normalizeTime(duration)}`}
        </div>

        <div className={classes.channelSelect}>
          <ChannelSelect setChannel={setChannel} sessionID={sessionID} channels={channels} />
        </div>

        <div className={classes.buttons}>
          {audioIsPlaying ? (
            <IconButton
              onClick={handleAudioPause}
              className={classes.button}
              disableRipple
              color="primary"
              title="Остановить"
            >
              <PauseIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleAudioPlay}
              className={classes.button}
              disableRipple
              color="primary"
              title="Воспроизвести"
            >
              <PlayArrowIcon />
            </IconButton>
          )}

          <IconButton
            onClick={handleZoomIn}
            className={classes.button}
            disabled={currentZoomStep === zoomStep.length - 1}
            disableRipple
            color="primary"
            title="Увеличить масштаб"
          >
            <ZoomInIcon />
          </IconButton>

          <div className={classes.zoomLevel}>{currentZoomStep + 1}</div>

          <IconButton
            onClick={handleZoomOut}
            className={classes.button}
            disabled={currentZoomStep === 0}
            disableRipple
            color="primary"
            title="Уменьшить масштаб"
          >
            <ZoomOutIcon />
          </IconButton>

          <IconButton
            onClick={handleDeleteSegment}
            className={classes.button}
            disabled={rangeStart === 0 && rangeEnd === 0}
            disableRipple
            color="primary"
            title="Удалить выделенный сегмент"
          >
            <CropIcon />
          </IconButton>
        </div>
      </div>

      <div className={classes.save}>
        <Button variant="text" size="small" onClick={() => onClose()}>
          Отмена
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleSave} className={classes.ok}>
          ок
        </Button>
      </div>
    </div>
  );
};

export default Editor;
