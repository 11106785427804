export const colorPalette = [
  "#55B3A6",
  "#25D6BB",
  "#FFCBF2",
  "#02c3bd",
  "#4ea397",
  "#22c3aa",
  "#7bd9a5",
  "#d0648a",
  "#f58db2",
  "#f2b3c9",
];
