import React, { ChangeEvent, FC } from "react";

// material ui
import TextField from "@material-ui/core/TextField";

interface Props {
  sleepSec: number;
  setSleepSec: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SleepSec: FC<Props> = ({ setSleepSec, sleepSec }) => {
  return (
    <TextField
      label="Пауза ожидания, в секундах"
      fullWidth
      variant="standard"
      name="readBySec"
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ min: 0 }}
      onChange={setSleepSec}
      value={sleepSec}
    />
  );
};

export default SleepSec;
