import React, { FC } from "react";
import { Statistic } from "types/statistic";
import { humanDuration } from "functions/common";
import { createStatisticsFile } from "../createStatisticsFile";
import { StatisticsDescription } from "../statisticsDescription";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    overflow: "auto",
    height: "calc(100vh - 200px)",
    width: "100%",
  },
  fieldsWrapper: {
    width: "400px",
    fontSize: theme.typography.fontSize,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  fieldsWrapperLid: {
    width: "450px",
    fontSize: theme.typography.fontSize,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  description: {},
  button: {
    width: 100,
    marginBottom: theme.spacing(3),
  },
  value: {
    float: "right",
    opacity: "0.5",
  },
  valueLid: {
    textAlign: "right",
    width: "50px",
    float: "right",
    opacity: "0.5",
  },
}));

interface Props {
  statistic: Statistic;
  filter: string;
}

const StatisticsCard: FC<Props> = ({ statistic, filter }) => {
  const classes = useStyles();

  const downloadStatistics = () => {
    const element = document.createElement("a");
    const file = createStatisticsFile(statistic, filter);
    element.href = URL.createObjectURL(file);

    element.download = "statistic.txt";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" gutterBottom component="div">
        Статистика по выбранному фильтру
      </Typography>
      <div className={classes.fieldsWrapper}>
        <span>{StatisticsDescription.recordsCount}</span>
        <span className={classes.value}>{statistic.recordsCount}</span>
        <br />

        <span>{StatisticsDescription.recordsDuration}</span>
        <span className={classes.value}>{humanDuration(statistic.recordsDuration)}</span>
        <br />

        <span>{StatisticsDescription.speechDuration}</span>
        <span className={classes.value}>{humanDuration(statistic.speechDuration)}</span>
        <br />

        <span>{StatisticsDescription.speakersCount}</span>
        <span className={classes.value}>{statistic.speakersCount}</span>
        <br />

        <span>{StatisticsDescription.faxCount}</span>
        <span className={classes.value}>{statistic.faxCount}</span>
        <br />

        <span>{StatisticsDescription.srcPhoneNumbersCount}</span>
        <span className={classes.value}>{statistic.srcPhoneNumbersCount}</span>
        <br />

        <span>{StatisticsDescription.dstPhoneNumbersCount}</span>
        <span className={classes.value}>{statistic.dstPhoneNumbersCount}</span>
        <br />

        <span>{StatisticsDescription.srcIpAddressesCount}</span>
        <span className={classes.value}>{statistic.srcIpAddressesCount}</span>
        <br />

        <span>{StatisticsDescription.dstIpAddressesCount}</span>
        <span className={classes.value}>{statistic.dstIpAddressesCount}</span>
        <br />

        <span>{StatisticsDescription.wordCount}</span>
        <span className={classes.value}>{statistic.wordCount}</span>
      </div>

      <Typography variant="body1" gutterBottom component="div">
        Статистика по языкам
      </Typography>
      <div className={classes.fieldsWrapperLid}>
        {statistic.langs.map((lang, ind) => (
          <div key={ind}>
            <span>{lang.nameRus}</span>
            <span className={classes.valueLid}>{Math.floor((lang.count * 100) / statistic.recordsCount)}%</span>
            <span className={classes.value}>{lang.count}</span>
            <br />
          </div>
        ))}
      </div>

      <Button className={classes.button} variant="contained" color="primary" onClick={downloadStatistics}>
        Сохранить
      </Button>
    </Paper>
  );
};

export default StatisticsCard;
