import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Module } from "types/esb";
import { NbsCategory } from "types/nbsCategory";
import EsbService from "services/EsbService";
import NbsCategoryService from "services/NbsCategoryService";
import { catchError } from "functions/common";
import NoData from "components/NoData";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
    marginLeft: 10,
  },
}));

interface State {
  machineId: string; // айди машины
  moduleName: string; // имя Параграфа в ИШД
  category: string; // категория НБС
  comment: string;
}

const initialState: State = {
  machineId: "",
  moduleName: "",
  category: "",
  comment: "",
};

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
}

const SendToParagrafDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [state, setState] = useState<State>(initialState);
  const [onlineModules, setOnlineModules] = useState<Module[]>([]);
  const [nbsCategories, setNbsCategories] = useState<NbsCategory[]>([]);

  const { machineId, moduleName, comment, category } = state;

  const disabled = machineId === "";

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // обработчик нажатия клавиш
  const handleKeyDown = useCallback(
    (event) => {
      if (!open) return;
      const { key } = event;

      if (key === "Enter" && !disabled) {
        event.preventDefault();
        event.stopPropagation();
        onClose(state);
        return;
      }
    },
    [open, onClose, state, disabled]
  );

  const handleChangeParagrafName = (event: ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;

    const module = onlineModules.find((m) => m.machineId + m.id === val);
    if (module !== undefined) {
      setState((prev) => ({ ...prev, machineId: module.machineId, moduleName: module.id }));
    }
  };

  const handleChangeNbsCategory = (event: ChangeEvent<{ value: unknown }>) => {
    setState((prev) => ({ ...prev, category: event.target.value as string }));
  };

  useEffect(() => {
    if (open) return;
    setState(initialState);
  }, [open]);

  useEffect(() => {
    if (!open) return;
    NbsCategoryService.getAll()
      .then(({ data }) => setNbsCategories(data))
      .catch(catchError);

    EsbService.onlineModules()
      .then(({ data }) => setOnlineModules(data ?? []))
      .catch((err) => catchError(err, "Ошибка получения онлайн модулей ИШД: "));
  }, [open]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      className={classes.root}
      fullWidth
      maxWidth="md"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Отправить в Параграф</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="online-modules-label">Имя Параграфа в ИШД</InputLabel>
              <Select
                labelId="online-modules-label"
                value={machineId + moduleName}
                onChange={handleChangeParagrafName}
                fullWidth
              >
                {onlineModules.length > 0 ? (
                  onlineModules.map((m) => (
                    <MenuItem key={m.machineId + m.id} value={m.machineId + m.id}>
                      {`${m.id} (${m.machineName.trim()})`}
                    </MenuItem>
                  ))
                ) : (
                  <NoData />
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="nbs-label">Категория нарушения</InputLabel>
              <Select labelId="nbs-label" value={category} onChange={handleChangeNbsCategory} fullWidth>
                {nbsCategories.length > 0 ? (
                  nbsCategories.map((c) => (
                    <MenuItem key={c.id} value={c.name}>
                      {c.name}
                    </MenuItem>
                  ))
                ) : (
                  <NoData />
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => onClose(state)}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SendToParagrafDialog;
