import React, { FC, useMemo } from "react";
import ReactPaginate from "react-paginate";
import clsx from "clsx";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

const useStyles = makeStyles((theme) => ({
  ul: {
    display: "flex",
    margin: 0,
    padding: 0,
    listStyleType: "none",
    fontSize: theme.typography.fontSize,
  },
  li: {
    cursor: "pointer",
    margin: "0 2px",
    minWidth: 32,
    height: 32,
    textAlign: "center",
    borderRadius: 16,
    padding: "6px 5px 0 5px",
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  activeLi: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  a: {
    display: "block",
  },
  button: {
    cursor: "pointer",
    margin: "0 2px",
    minWidth: 32,
    height: 32,
    textAlign: "center",
    borderRadius: 16,
    padding: "4px 5px 0 5px",
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  previous: {
    marginRight: 10,
  },
  next: {
    marginLeft: 10,
  },
  disabled: {
    cursor: "auto",
    color: theme.palette.grey[400],
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

interface Props {
  count: number;
  setOffset: Function;
}

const Pagination: FC<Props> = ({ count, setOffset }) => {
  const classes = useStyles();
  const { rowsPerPage } = useSelector((state: RootState) => state.settings);
  const perPage = rowsPerPage ?? 500;
  const pageCount = useMemo(() => Math.ceil(count / perPage), [count, perPage]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % count;
    setOffset(newOffset);
  };

  return (
    <ReactPaginate
      className={classes.ul}
      pageClassName={classes.li}
      pageLinkClassName={classes.a}
      activeClassName={classes.activeLi}
      previousClassName={clsx(classes.button, classes.previous)}
      nextClassName={clsx(classes.button, classes.next)}
      disabledClassName={classes.disabled}
      breakLabel="..."
      nextLabel={<KeyboardArrowRightIcon />}
      previousLabel={<KeyboardArrowLeftIcon />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      renderOnZeroPageCount={undefined}
    />
  );
};

export default Pagination;
