import React, { FC, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRowsPerPage } from "redux/actions/settingsActions";
import { RootState } from "redux/types";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginRight: 10,
    fontSize: theme.typography.fontSize,
  },
  select: {
    width: 70,
  },
}));

const SelectPerPage: FC = () => {
  const classes = useStyles();
  const { rowsPerPage: perPage } = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(setRowsPerPage(Number(event.target.value)));
  };

  return (
    <div className={classes.root}>
      <div className={classes.label}>На странице:</div>
      <Select labelId="select-per-page-label" value={perPage} onChange={handleChange} className={classes.select}>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={500}>500</MenuItem>
        <MenuItem value={5000}>5000</MenuItem>
        <MenuItem value={10000}>10000</MenuItem>
      </Select>
    </div>
  );
};

export default SelectPerPage;
