import React, { FC } from "react";
import { EnumsParams } from "types/admin";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  input: {},
  item: {
    minHeight: 24,
  },
}));

interface Props {
  value: string;
  changeValue: (value: string) => void;
  params: EnumsParams;
}

const EnumInput: FC<Props> = ({ value, changeValue, params }) => {
  const classes = useStyles();
  const list = params.values;
  const defaultValue = value === "" ? null : value;

  return (
    <Autocomplete
      className={classes.input}
      disablePortal
      onKeyDown={(e) => e.stopPropagation()}
      size="small"
      options={list}
      value={defaultValue}
      onChange={(e, value) => changeValue(value === null ? "" : value)}
      renderInput={(params) => <TextField {...params} size="small" />}
      renderOption={(option) => (
        <Typography noWrap className={classes.item}>
          {option}
        </Typography>
      )}
    />
  );
};

export default EnumInput;
