import React, { ChangeEvent, FC } from "react";
import {
  defaultFolderSftp,
  defaultFolderSmb,
  FolderType,
  FolderSmb as FolderSmbType,
  SmbVersion,
  SourceDetailSip,
} from "types/source";
import { Group } from "types/queryBuilder";
import useAvailableFilters from "hooks/admin/useAvailableFilters";

// components
import SleepSec from "../SleepSec";
import SourceFilter from "../SourceFilter";
import SelectProtocol from "../SelectProtocol";

// material ui
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

interface Props {
  state: SourceDetailSip;
  setState: (item: SourceDetailSip) => void;
  disable: boolean;
}

const Sip: FC<Props> = ({ state, setState, disable }) => {
  const { filters: availableFilters } = useAvailableFilters("records");
  const { folderDetail, sleepSec, deleteFilter, dateChangedSec } = state;

  const onChangeNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState({ ...state, [name]: Number(value) });
  };

  const handleChangeFolderDetailType = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    if (value === FolderType.SFTP) {
      setState({ ...state, folderDetail: defaultFolderSftp });
    }

    if (value === FolderType.SMB) {
      setState({ ...state, folderDetail: defaultFolderSmb });
    }
  };

  const handleChangeSmbVersion = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    const folderDetail: FolderSmbType = {
      ...(state.folderDetail as FolderSmbType),
      smbVersion: value as SmbVersion,
    };
    setState({ ...state, folderDetail });
  };

  const onChangeTextFolderDetail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = event.target;
    setState({ ...state, folderDetail: { ...state.folderDetail, [name]: value } });
  };

  const setDeleteFilter = (f: Group) => {
    setState({ ...state, deleteFilter: JSON.stringify(f) });
  };

  const setPathValue = (value: string, name?: string) => {
    const pathName = name === undefined ? "path" : name;
    setState({ ...state, folderDetail: { ...state.folderDetail, [pathName]: value } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectProtocol
          disabled={disable}
          folderDetail={folderDetail}
          onChangeConnectionType={handleChangeFolderDetailType}
          onChangeSmbVersion={handleChangeSmbVersion}
          onChangeInputElement={onChangeTextFolderDetail}
          setPathValue={setPathValue}
        />
      </Grid>

      <Grid item xs={12}>
        <SourceFilter
          title="Исключить данные по фильтру"
          filter={deleteFilter}
          setFilter={setDeleteFilter}
          availableFilters={availableFilters}
        />
      </Grid>

      <Grid item xs={12}>
        <SleepSec sleepSec={sleepSec} setSleepSec={onChangeNumber} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Виртуальная задержка чтения сеанса"
          fullWidth
          variant="standard"
          name="dateChangedSec"
          onChange={onChangeNumber}
          value={dateChangedSec}
        />
      </Grid>
    </Grid>
  );
};

export default Sip;
