export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_VERSION = process.env.REACT_APP_API_VERSION;

export const URL_METHOD_OF_FORMING = process.env.REACT_APP_URL_METHOD_OF_FORMING;
export const LISTEN_TO_MICROPHONE = process.env.REACT_APP_LISTEN_TO_MICROPHONE;
export const WS_HOST = process.env.REACT_APP_WS_HOST;

export const API_URL = `${API_HOST}/api/${API_VERSION}`;

export const ws = "/api/v1/record/ws";

export const admin = {
  modules: {
    getAll: "/admin/modules",
    getById: "/admin/modules/:id",
    put: "/admin/modules/:id",
  },
  techs: {
    getAll: "/admin/techs",
    getTech: "/admin/techs/:tech",
    Protected: {
      getAll: "/admin/protected/techs",
      update: "/admin/protected/techs",
    },
  },
  settings: {
    getAll: "/setting/:offset/:count",
    count: "/setting/count",
    remove: "/setting/:ids",
    createOrUpdate: "/setting",
    getByKey: "/setting/get",
    setByKey: "/setting/set",
  },
  passports: {
    getAll: "/admin/fileFormats",
    Protected: {
      getAll: "/admin/protected/fileFormats",
      getById: "/admin/protected/fileFormats/:id",
      create: "/admin/protected/fileFormats",
      update: "/admin/protected/fileFormats",
      remove: "/admin/protected/fileFormats/:ids",
    },
  },
  filters: {
    getAll: "/admin/filters/:topic",
    getHistory: "/admin/historyFilter",
    clearHistory: "/admin/historyFilter/:ids",
    json2text: "/admin/filters/json2text",
    text2json: "/admin/filters/text2json",
  },
  diagnostic: {
    getAll: "/diagnostic/diagnostics",
    create: "/diagnostic/diagnostics",
    update: "/diagnostic/diagnostics",
    remove: "/diagnostic/diagnostics/:ids",
    removeCluster: "/diagnostic/clusters/:ids",
    updateCluster: "/diagnostic/clusters",
    checkApp: "/diagnostic/checkApp",
    sysInfo: "/admin/protected/systemInfo",
    upload: "/admin/protected/access",
  },
  access: {
    removeRecords: "/admin/protected/user/access/delete",
    setRemoveRecords: "/admin/protected/user/access/delete",
  },
  lmModels: {
    getAll: "/record/admin/languageModels",
    create: "/record/admin/languageModels",
    update: "/record/admin/languageModels",
    remove: "/record/admin/languageModels/:ids",
    generateTranscription: "/record/admin/languageModels/generateTranscriptions",
  },
  lidModels: {
    getAll: "/record/admin/lidModels",
    create: "/record/admin/lidModels",
    update: "/record/admin/lidModels",
    remove: "/record/admin/lidModels/:ids",
  },
  servers: {
    getAll: "/diagnostic/servers",
    addApp: "/diagnostic/apps",
    editApp: "/diagnostic/apps",
    remove: "/diagnostic/apps/:ids",
    updateIp: "/diagnostic/apps/update-ip",
    applyConfig: "/diagnostic/apps/apply-config",
  },
  sources: {
    available: "/admin/sources",
    getAll: "/admin/protected/sources",
    create: "/admin/protected/sources",
    update: "/admin/protected/sources",
    setActive: "/admin/protected/sources/set/active",
    remove: "/admin/protected/sources/:ids",
  },
  externalApi: {
    getStatus: "/extapi/state",
    setStatus: "/extapi/state",
  },
};

export const access = {
  getRemoveRecordAccess: "/admin/user/access/delete",
};

export const auth = {
  login: "/auth/login",
  logout: "/auth/logout",
  refresh: "/auth/refresh",
  services: "/auth/services",
};

export const dictionaries = {
  getAll: "/dictionary/topic/:topicId/dictionaries/:offset/:count",
  create: "/dictionary/topic/:topicId/dictionaries",
  count: "/dictionary/topic/:topicId/dictionaries/count",
  update: "/dictionary/topic/:topicId/dictionaries",
  remove: "/dictionary/topic/:topicId/dictionaries/:ids",
  words: {
    getAll: "/dictionary/topic/:topicId/dictionaries/:dictId/words/:offset/:count",
    add: "/dictionary/topic/:topicId/dictionaries/:dictId/words",
    update: "/dictionary/topic/:topicId/dictionaries/:dictId/words",
    remove: "/dictionary/topic/:topicId/dictionaries/:dictId/words/:ids",
  },
};

export const spaces = {
  getAll: "/record/namespaces",
  count: "/record/namespaces/count",
  create: "/record/namespaces",
  update: "/record/namespaces",
  upload: "/record/namespaces/:namespaceId/upload",
  remove: "/record/namespaces/:ids",
  getNamespaceAccess: "/record/namespaces/:id/access",
  setNamespaceAccess: "/record/namespaces/:id/access",
};

export const records = {
  getAll: "/record/records/:offset/:count",
  count: "/record/records/count",
  upload: "/record/records/upload",
  getDefaultData: "/record/records/:recordId/files/default",
  getFileData: "/record/decode/:viewType",
  getSegments: "/record/records/:recordId/result/stt/segments",
  getSTTResultAsText: "/record/records/:recordId/result/stt/text",
  getSTTResultAsFile: "/record/records/:recordId/result/stt/file",
  getTranslatedSegments: "/record/records/:recordId/result/translate/segments",
  viewed: "/record/records/set/viewed",
  getDetailLID: "/record/records/:recordId/result/lid",
  getLIDSegment: "/record/records/:recordId/result/lidSegment",
  getDetailVAD: "/record/records/:recordId/result/vad",
  getDetailDiarization: "/record/records/:recordId/result/diarization",
  getDetailSID: "/record/records/:recordId/result/sid",
  getDetailFax: "/record/records/:recordId/result/fax",
  getDetailSTT: "/record/records/:recordId/result/stt/stt",
  getLabels: "/record/records/get/labels",
  setLabels: "/record/records/set/labels",
  removeLabels: "/record/records/delete/labels",
  copy: "/record/records/copy",
  remove: "/record/records/delete",
  checkPush: "/record/push",
  getKeywords: "/record/records/:recordId/result/words",
  getAnalytics: "/record/analytics",
  getAnalyticsTimeline: "/record/analytics/timeline",
  getComment: "/record/records/:recordId/result/comment",
  setComment: "/record/records/:recordId/result/comment",
  getAutoCleanUp: "/record/autoDelete",
  setAutoCleanUp: "/record/autoDelete",
  executeAutoCleanUp: "/record/autoDelete/do",
  getStatLayer: "/record/stat/layer",
  getAllSpeakers: "/record/speakers/:root/:offset/:count",
  getSpeakersByIds: "/record/speakers/getByIds",
  createSpeaker: "/record/speakers",
  updateSpeaker: "/record/speakers",
  removeSpeakers: "/record/speakers/:ids",
  importSpeaker: "/record/speakers/:id/import",
  importSpeakerZip: "/record/speakers/:id/importZip",
  getSpeakerAudioFile: "/record/speakers/:speakerId/models/:modelId/audio",
  getSpeakerFile: "/record/speakers/:speakerId/files/:fileId/file",
  moveSpeakers: "/record/speakers/move",
  messagesCreate: "/record/messages",
  messagesNotViewedCount: "/record/messages/notViewedCount",
  messagesGetDefault: "/record/messages/default",
  messagesGet: "/record/messages/:startId/:order",
  messagesSetViewed: "/record/messages/set/viewed/:id",
  messagesSetViewedAll: "/record/messages/set/viewed/all",
  setDictionary: "/record/records/set/dictionary",
  getAllLmModels: "/record/languageModels",
  ro: "/record/static/docs.pdf",
  editWord: "/record/records/:recordId/result/stt",
  wordCloud: "/record/cloudwords",
  editorData: "/record/textedit/:id",
  textconverter: "/record/textconverter",
};

export const objects = {
  getAll: "/record/objects/:root/:offset/:count",
  getCount: "/record/objects/:root/count",
  create: "/record/objects",
  update: "/record/objects",
  remove: "/record/objects/:ids",
};

export const filters = {
  getAll: "/record/filters",
  create: "/record/filters",
  update: "/record/filters",
  remove: "/record/filters/:ids",
  getAccess: "/record/filters/:id/access",
  setAccess: "/record/filters/:id/access",
};

export const files = {
  getAll: "/record/records/:recordId/files/:offset/:count",
  count: "/record/records/:recordId/files/count",
};

export const sources = {
  getAll: "/source/sources",
  getById: "/source/sources/:id",
  create: "/source/sources",
  update: "/source/sources",
  remove: "/source/sources/:ids",
  getAccess: "/source/sources/:id/access",
  setAccess: "/source/sources/:id/access",
};

export const storages = {
  getAll: "/storage/storages",
  obzor: "/storage/explorer",
};

export const tasks = {
  getAll: "/task/tasks",
  create: "/task/tasks",
  update: "/task/tasks",
  remove: "/task/tasks/:ids",
  getAccess: "/task/tasks/:id/access",
  setAccess: "/task/tasks/:id/access",
};

export const labels = {
  getAll: "/record/labels",
  create: "/record/labels",
  update: "/record/labels",
  remove: "/record/labels/:ids",
};

export const lists = {
  getAll: "/list/lists",
  create: "/list/lists",
  update: "/list/lists",
  remove: "/list/lists/:ids",
};

export const baseStations = {
  getAll: "/record/baseStations/:offset/:count",
  count: "/record/baseStations/count",
  create: "/record/baseStations",
  update: "/record/baseStations",
  remove: "/record/baseStations/:ids",
};

export const phoneNumber = {
  getAll: "/record/phoneNumbers/:offset/:count",
  count: "/record/phoneNumbers/count",
  create: "/record/phoneNumbers",
  update: "/record/phoneNumbers",
  remove: "/record/phoneNumbers/:ids",
};

export const monitors = {
  getAll: "/record/monitors",
  create: "/record/monitors",
  update: "/record/monitors",
  remove: "/record/monitors/:ids",
  getAccess: "/record/monitors/:id/access",
  setAccess: "/record/monitors/:id/access",
};

export const ipAddress = {
  getAll: "/record/ipAddresses/:offset/:count",
  count: "/record/ipAddresses/count",
  create: "/record/ipAddresses",
  update: "/record/ipAddresses",
  remove: "/record/ipAddresses/:ids",
};

export const macAddress = {
  getAll: "/record/macAddresses/:offset/:count",
  count: "/record/macAddresses/count",
  create: "/record/macAddresses",
  update: "/record/macAddresses",
  remove: "/record/macAddresses/:ids",
};

export const statistics = {
  getAll: "/record/statistics",
};

export const words = {
  getAll: "/record/words",
  create: "/record/words",
  update: "/record/words",
  remove: "/record/words/:ids",
  download: "/record/words/:ids/download",
  upload: "/record/words/upload",
};

export const graph = {
  getAll: "/record/graph/:ids",
  settings: "/record/graph/settings",
  createLink: "/record/graph/link/:id1/:id2",
  removeLink: "/record/graph/link/:id1/:id2",
};

export const graphV3 = {
  getAll: "/record/graph/v3",
};

export const serviceMenu = {
  getAll: "/api/v1/service-menu/services",
};

export const viceAssistant = {
  push: "/record/voice",
};

export const fileEditor = {
  upload: "/audioeditor/upload",
  uploadUrl: "/audioeditor/upload/url",
  operationDelete: "/audioeditor/operation/delete/:sessionId/:start/:end",
};

export const esb = {
  sendDocument: "/record/esb/send/document",
  sendNbs: "/record/esb/send/nbs",
  availableDocuments: "/record/esb/available/documents",
  onlineModules: "/record/esb/online/modules",
};

export const nbsCategory = {
  getAll: "/record/nbsCategory",
  create: "/record/nbsCategory",
  update: "/record/nbsCategory",
  remove: "/record/nbsCategory/:ids",
};

export const synapse = {
  command: "/synapse/command",
};
