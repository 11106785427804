import React, { FC, useState } from "react";
import clsx from "clsx";
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import { LidClass } from "types/admin/lidModel";
import AddLidClassDialog from "./AddLidClassDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  root: {},
  table: {
    height: 200,
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  buttons: {},
  mr10: {
    marginRight: 10,
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Имя",
    field: "userName",
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: "Тип",
    field: "storageDetail",
    valueGetter: (params: any) => {
      const { storageDetail } = params.data;
      return storageDetail.type === undefined ? "" : storageDetail.type;
    },
  },
  {
    headerName: "Хост",
    field: "storageDetail",
    valueGetter: (params: any) => {
      const { storageDetail } = params.data;
      return storageDetail.host === undefined ? "" : storageDetail.host;
    },
  },
  {
    headerName: "Путь",
    field: "storageDetail",
    valueGetter: (params: any) => {
      const { storageDetail } = params.data;
      return storageDetail.path === undefined ? "" : storageDetail.path;
    },
    flex: 1,
  },
];

interface Props {
  lidClasses: LidClass[];
  addLidClass: (lidClass: LidClass) => void;
  removeLidClasses: (lc: LidClass[]) => void;
}

const LidClassesTable: FC<Props> = ({ lidClasses, addLidClass, removeLidClasses }) => {
  const classes = useStyles();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<LidClass[]>([]);

  const [openAddLidClassDialog, setOpenLidClassDialog] = useState(false);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseOpenAddLidClassDialog = (lidClass?: LidClass) => {
    setOpenLidClassDialog(false);
    if (lidClass !== undefined) {
      addLidClass(lidClass);
    }
  };

  const handleDelete = () => {
    removeLidClasses(selectedRows);
    setSelectedRows([]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Typography gutterBottom>Классы</Typography>
        <div className={classes.buttons}>
          <Button
            className={classes.mr10}
            color="primary"
            size="small"
            onClick={handleDelete}
            title="Удалить"
            disabled={selectedRows.length === 0}
          >
            удалить
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => setOpenLidClassDialog(true)}
            title="Загрузить аудио"
          >
            добавить
          </Button>
        </div>
      </div>

      <div className={clsx(classes.table, "ag-theme-balham")}>
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          rowData={lidClasses}
          columnDefs={columnDefs}
          rowSelection="multiple"
          localeText={localization}
          enableRangeSelection
          onSelectionChanged={onSelection}
          suppressDragLeaveHidesColumns
        />
      </div>

      <AddLidClassDialog open={openAddLidClassDialog} onClose={handleCloseOpenAddLidClassDialog} />
    </div>
  );
};

export default LidClassesTable;
