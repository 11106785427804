import React, { ChangeEvent, FC, useCallback, useState } from "react";
import clsx from "clsx";
import useLabels from "hooks/useLabels";
import { Label } from "types/label";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "redux/actions/alertActions";
import LabelService from "services/LabelService";
import CreateLabelDialog from "pages/labels/components/CreateLabelDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 15,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
  content: {
    display: "flex",
    alignItems: "self-end",
    justifyContent: "space-between",
  },
  formControl: {
    flexGrow: 1,
    marginRight: 10,
  },
}));

interface SetLabelProps {
  open: boolean;
  onClose: (label?: Label) => void;
  labels: Label[];
}

const SetLabel: FC<SetLabelProps> = ({ open, onClose, labels }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState(labels[0].id);
  const [markers, setMarkers] = useState(labels);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setState(() => event.target.value as number);
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data) {
      LabelService.create(data)
        .then((res) => {
          setMarkers((prev) => [...prev, res.data]);
          setState(res.data.id);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleSave = () => {
    onClose(markers.find((l) => l.id === state));
  };

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Отметить маркером</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="select-label-label">Mаркер</InputLabel>
            <Select labelId="select-label-label" value={state} onChange={handleChange} fullWidth>
              <MenuItem value={0}>Не выбран</MenuItem>
              {markers.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  <span
                    style={{
                      backgroundColor: l.color,
                      width: 20,
                      height: 20,
                      display: "inline-block",
                      marginRight: 10,
                    }}
                  />
                  {l.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => setOpenCreateDialog(true)}
            >
              Создать
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogActions>
      <CreateLabelDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
    </Dialog>
  );
};

interface Props {
  open: boolean;
  onClose: (label?: Label) => void;
}

const SetLabelDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const { labels, loading, error } = useLabels();
  if (loading) {
    return null;
  }
  if (error) {
    return (
      <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
        <DialogTitle>Отметить маркером</DialogTitle>
        <DialogContent>
          <Alert severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {error?.response?.data?.message}
          </Alert>
          <div className={classes.buttons}>
            <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
              Отмена
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => onClose()}
            >
              Ок
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  if (labels.length === 0) {
    return (
      <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
        <DialogTitle>Отметить маркером</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>Внимание</AlertTitle>
            {"В системе нет созданных маркеров"}
          </Alert>
          <div className={classes.buttons}>
            <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
              Отмена
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => onClose()}
            >
              Ок
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  return <SetLabel open={open} onClose={onClose} labels={labels} />;
};

export default SetLabelDialog;
