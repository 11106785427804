import React, { FC } from "react";

// components
import { Group as TGroup, Rule as TRule } from "types/queryBuilder";
import { EditModel } from "../types/types";
import { AdminTypeFilter } from "types/admin";
import RenderRule from "./RenderRule";

interface Props {
  availableFilters: AdminTypeFilter[];
  rule: TRule | TGroup;
  editModel: EditModel;
  onBlur: Function;
  onClickRule: Function;
  onChangeRule: Function;
  doCustomGetQuery: Function;
}
const RenderGroupRule: FC<Props> = ({
  availableFilters,
  rule,
  editModel,
  onBlur,
  onClickRule,
  onChangeRule,
  doCustomGetQuery,
}) => {
  if (!rule) {
    return null;
  }

  const isFilter = rule.type === "filter";
  const groupItem = rule as TGroup;

  // console.log(`SearchFilterFilterBase render id: ${filterBase.id}. mode: ${editModel.editMode}`);
  return (
    <div style={{ display: "flex" }}>
      {isFilter ? (
        <RenderRule
          availableFilters={availableFilters}
          rule={rule}
          editModel={editModel}
          onBlur={onBlur}
          onClickRule={onClickRule}
          onChangeRule={onChangeRule}
          doCustomGetQuery={doCustomGetQuery}
        />
      ) : (
        groupItem.filters.map((item, i) => {
          return (
            <RenderGroupRule
              availableFilters={availableFilters}
              key={i}
              rule={item}
              editModel={editModel}
              onBlur={onBlur}
              onClickRule={onClickRule}
              onChangeRule={onChangeRule}
              doCustomGetQuery={doCustomGetQuery}
            />
          );
        })
      )}
    </div>
  );
};

export default RenderGroupRule;
