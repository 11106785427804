import { useCallback, useEffect, useState } from "react";
import { Record } from "types";
import { AxiosError } from "axios";
import RecordService from "services/RecordService";

interface State {
  loading: boolean;
  records: Record[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  records: [],
  error: undefined,
};

const useRecords = (offset: number, count: number, query: string) => {
  const [state, setState] = useState<State>(initialState);

  const getRecords = useCallback(async () => {
    setState({ error: undefined, records: [], loading: true });
    RecordService.getAll(offset, count, query)
      .then(({ data }) => {
        setState({ records: data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ records: [], loading: false, error: err });
      });
  }, [query, offset, count]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return { ...state, getRecords };
};

export default useRecords;
