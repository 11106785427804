import React, { ChangeEvent, FC } from "react";
import { SourceDetailMag } from "types/source";

// components
import SleepSec from "../SleepSec";
import FolderMag from "../FolderMag";

// material ui
import Grid from "@material-ui/core/Grid";

interface Props {
  state: SourceDetailMag;
  setState: (item: SourceDetailMag) => void;
  disable: boolean;
}

const Mag: FC<Props> = ({ state, setState, disable }) => {
  const { folderDetail, sleepSec } = state;

  const setSleepSec = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState({ ...state, sleepSec: Number(value) });
  };

  const onChangeTextFolderDetail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = event.target;
    setState({ ...state, folderDetail: { ...state.folderDetail, [name]: value } });
  };

  const setPathValue = (value: string, name?: string) => {
    const pathName = name === undefined ? "path" : name;
    setState({ ...state, folderDetail: { ...state.folderDetail, [pathName]: value } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FolderMag state={folderDetail} onChange={onChangeTextFolderDetail} setPathValue={setPathValue} />
      </Grid>
      <Grid item xs={12}>
        <SleepSec sleepSec={sleepSec} setSleepSec={setSleepSec} />
      </Grid>
    </Grid>
  );
};

export default Mag;
