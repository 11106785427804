import { Group, Rule, Condition, Operation } from "types/queryBuilder";
import { generateUuid } from "functions/common";

export function isGroup(item: Group | Rule): item is Group {
  return (item as Group).type === "group";
}

export function getDefaultGroup(): Group {
  return {
    type: "group",
    uuid: generateUuid(),
    operation: "AND",
    filters: [],
  };
}

export function getConditionName(c: Condition) {
  if (c === "!=") return "Не равно";
  if (c === ">") return "Больше";
  if (c === "<") return "Меньше";
  return "Равно";
}

export function getOperationName(o: Operation) {
  if (o === "NOT") return "не";
  if (o === "OR") return "или";
  return "И";
}
