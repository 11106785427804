import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Obzor } from "types/obzor";
import { FolderSmb, FolderType } from "types/source";
import StorageService from "services/StorageService";
import { catchError } from "functions/common";
import ObzorSMB from "components/obzor/ObzorSMB";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  button: {
    width: 82,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
}));

interface Props {
  state: FolderSmb;
  setPathValue: (value: string, pathName?: string) => void;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const PathSmb: FC<Props> = ({ state, onChange, setPathValue }) => {
  const classes = useStyles();
  const { smbPath, login, password, smbVersion } = state;

  const [rootPath, setRootPath] = useState<string>(smbPath);
  const [openObzor, setOpenObzor] = useState(false);
  const [files, setFiles] = useState<Obzor[]>([]);
  const [breadcrumbPath, setBreadcrumbPath] = useState("");
  const [filterValue, setFilterValue] = useState<string>("");

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFilter = event.target.value;
    setFilterValue(newFilter);
  };

  const folderClick = (folderName: string) => {
    // Нажатие на имя папки
    setBreadcrumbPath(folderName);
    const obzor: FolderSmb = {
      type: FolderType.SMB,
      smbPath: folderName,
      login,
      password,
      smbVersion,
    };

    StorageService.obzor(obzor)
      .then(({ data }) => {
        setFiles(data);
        setFilterValue("");
      })
      .catch(catchError);
  };

  const handleSelect = (type?: string, name?: string) => {
    setOpenObzor(false);
    if (name) {
      setBreadcrumbPath(name);
      setPathValue(name, "smbPath");
    }
  };

  const handleCrumbClick = (newPath: string) => {
    setBreadcrumbPath(newPath);
    const obzor: FolderSmb = {
      type: FolderType.SMB,
      smbPath: newPath,
      login,
      password,
      smbVersion,
    };

    StorageService.obzor(obzor)
      .then(({ data }) => {
        setFiles(data);
        setFilterValue("");
      })
      .catch(catchError);
  };

  const handleMainCrumbClickSMB = () => {
    setBreadcrumbPath("");
    setPathValue("\\\\127.0.0.1\\shared\\", "smbPath");
    const obzor: FolderSmb = {
      type: FolderType.SMB,
      smbPath: "\\\\127.0.0.1\\shared\\",
      login,
      password,
      smbVersion,
    };

    StorageService.obzor(obzor)
      .then(({ data }) => {
        setFiles(data);
        setFilterValue("");
      })
      .catch(catchError);
  };

  const handleOpenObzor = () => {
    setBreadcrumbPath(smbPath);
    const obzor: FolderSmb = {
      type: FolderType.SMB,
      smbPath: smbPath,
      login,
      password,
      smbVersion,
    };

    StorageService.obzor(obzor)
      .then(({ data }) => {
        setOpenObzor(true);
        setFiles(data);
        setPathValue(smbPath, "smbPath");
        setFilterValue("");
      })
      .catch(catchError);
  };

  useEffect(() => {
    const root = smbPath.split("\\");
    const ip = root[2];
    const rootFolder = root[3];
    const path = `\\\\${ip}\\${rootFolder}\\`;
    setRootPath(path);
    setBreadcrumbPath(smbPath);
  }, [smbPath]);

  useEffect(() => {
    setFilterValue("");
  }, [openObzor]);

  useEffect(() => {
    setBreadcrumbPath(smbPath);
  }, [smbPath]);

  return (
    <div className={classes.wrapper}>
      <TextField label="Путь" fullWidth name="smbPath" onChange={onChange} value={smbPath} />
      <Button className={classes.button} color="primary" variant="contained" size="small" onClick={handleOpenObzor}>
        Обзор
      </Button>

      <ObzorSMB
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
        open={openObzor}
        onClose={() => setOpenObzor(false)}
        files={files}
        folderClick={folderClick}
        handleSelect={handleSelect}
        handleCrumbClick={handleCrumbClick}
        handleMainCrumbClickSMB={handleMainCrumbClickSMB}
        breadcrumbPath={breadcrumbPath}
        rootPath={rootPath}
      />
    </div>
  );
};

export default PathSmb;
