import axios, { AxiosResponse } from "axios";
import { AuthResponse } from "types/auth";
import { API_URL, auth } from "http/urls";
import api from "http/api";

function AuthService() {
  async function login(login: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return api.post<AuthResponse>(auth.login, { login, password });
  }

  async function logout(data: any): Promise<AxiosResponse> {
    return api.post(auth.logout, data);
  }

  async function refresh(data: any) {
    return axios.post<AuthResponse>(auth.refresh, data, { baseURL: API_URL });
  }

  async function logIn(login: string, password: string): Promise<Response> {
    return await fetch(`${API_URL}${auth.login}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ login, password }),
    });
  }

  async function getServices(): Promise<AxiosResponse> {
    return api.get(auth.services);
  }

  return Object.freeze({
    login,
    logout,
    refresh,
    logIn,
    getServices,
  });
}

export default AuthService();
