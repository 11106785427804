import React, { ChangeEvent, FC, useEffect, useState } from "react";
import RecordService from "services/RecordService";
import { RootState } from "redux/types";
import { setDiarizationHighLight } from "redux/actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import PeopleIcon from "@material-ui/icons/People";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  body: {
    width: 365,
    padding: theme.spacing(2),
  },
  mr10: {
    marginRight: 10,
  },
  fc: {
    height: "fit-content",
  },
}));

interface Props {
  recordId: number;
  diarizationSpeakers: string[];
  setDiarizationSpeakers: Function;
}

const DiarizationSettings: FC<Props> = ({ recordId, diarizationSpeakers, setDiarizationSpeakers }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { diarizationHighlight } = useSelector((state: RootState) => state.settings);

  const [speakers, setSpeakers] = useState<string[]>([]);

  const changeDiarizationHighlight = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    dispatch(setDiarizationHighLight(checked));
  };

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    const currentIndex = diarizationSpeakers.indexOf(name);
    const newChecked = [...diarizationSpeakers];

    if (currentIndex === -1) {
      newChecked.push(name);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setDiarizationSpeakers(newChecked);
  };

  useEffect(() => {
    RecordService.getDetailDiarization(recordId).then(({ data }) => {
      const speakers = data.reduce((acc: string[], curr) => {
        if (!acc.includes(curr.speakerId)) {
          acc.push(curr.speakerId);
        }
        return acc;
      }, []);
      setSpeakers(speakers);
    });
  }, [recordId]);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        title="Насторойки отображения монологов дикторов"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={speakers.length === 0}
        className={clsx(classes.mr10, classes.fc)}
      >
        <PeopleIcon />
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <div className={classes.body}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={diarizationHighlight}
                    onChange={changeDiarizationHighlight}
                    name="highlight"
                    color="primary"
                  />
                }
                label="Выделять монологи дикторов"
              />
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>Выбрать монолог диктора:</Typography>
              {speakers.map((s, i) => (
                <div key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={diarizationSpeakers.indexOf(s) !== -1}
                        onChange={handleChecked}
                        name={s}
                        color="primary"
                      />
                    }
                    label={s}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </Menu>
    </>
  );
};

export default DiarizationSettings;
