import React, { ChangeEvent, FC, useEffect, useState } from "react";
import clsx from "clsx";
import { Label } from "types/label";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { DialogContent, DialogTitle } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Alert, AlertTitle } from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 15,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  open: boolean;
  onClose: (label?: Label) => void;
  labels: Label[];
}

const RemoveLabelDialog: FC<Props> = ({ open, onClose, labels }) => {
  const classes = useStyles();

  const [state, setState] = useState(labels[0]?.id ?? 0);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setState(() => event.target.value as number);
  };

  const handleSave = () => {
    onClose(labels.find((l) => l.id === state));
  };

  useEffect(() => {
    setState(labels[0]?.id ?? 0);
  }, [labels]);

  if (state === 0) {
    return (
      <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
        <DialogTitle>Отметить маркером</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>Внимание</AlertTitle>
            {"Выбранные записи не содержат маркеров"}
          </Alert>
          <div className={classes.buttons}>
            <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
              Отмена
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => onClose()}
            >
              Ок
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Удалить маркер</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-label-label">Mаркер</InputLabel>
              <Select labelId="select-label-label" value={state} onChange={handleChange} fullWidth>
                {labels.map((l) => (
                  <MenuItem key={l.id} value={l.id}>
                    <span
                      style={{
                        backgroundColor: l.color,
                        width: 20,
                        height: 20,
                        display: "inline-block",
                        marginRight: 10,
                      }}
                    />
                    {l.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveLabelDialog;
