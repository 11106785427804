import React, { FC, useEffect, useState } from "react";
import MessageService from "services/MessageService";
import { subscriber } from "subscribers/MessageSubscriber";
import { catchError } from "functions/common";
import MessagesList from "./MessagesList";

// material ui
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import IconButton from "@material-ui/core/IconButton";

const MessagesCount: FC = () => {
  const [notViewedCount, setNotViewedCount] = useState<number>(0);
  const [openPanel, setOpenPanel] = useState(false);

  useEffect(() => {
    MessageService.getNotViewedCount()
      .then(({ data }) => {
        setNotViewedCount(data.count);
      })
      .catch(catchError);
  }, [openPanel]);

  useEffect(() => {
    subscriber.subscribe((wsData) => {
      const { count } = wsData.data;
      setNotViewedCount(count);
    });
  }, []);

  return (
    <div>
      <IconButton color="inherit" onClick={() => setOpenPanel(true)} title="Лента сообщений">
        <Badge badgeContent={notViewedCount === 0 ? null : notViewedCount} color="secondary" max={1000}>
          <MailIcon />
        </Badge>
      </IconButton>

      <MessagesList open={openPanel} setOpen={setOpenPanel} />
    </div>
  );
};

export default MessagesCount;
