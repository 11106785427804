import React, { ChangeEvent, useState, FC, useRef, useEffect } from "react";
import { GridApi } from "ag-grid-community";
import FilesTable from "components/FilesTable";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    flexGrow: 1,
  },
  fileUpload: {},
  tableButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px 0 20px",
  },
  dialogActions: {
    padding: "10px 20px",
    textAlign: "right",
  },
  settingsTitle: {
    margin: 0,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
}));

interface Props {
  open: boolean;
  onClose: (files?: File[]) => void;
  accept: string;
}

const UploadFilesDialog: FC<Props> = ({ open, onClose, accept }) => {
  const classes = useStyles();

  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<File[]>([]);

  const disabled = files.length === 0;

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const clearFileInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files !== null) {
      setFiles((prev) => prev.concat(Array.from(files)));
      clearFileInput();
    }
  };

  const handleRemoveFiles = () => {
    setFiles((prev) => prev.filter((f) => !selectedRows.includes(f)));
    setSelectedRows([]);
  };

  useEffect(() => {
    if (!open) {
      setFiles([]);
      clearFileInput();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="md" className={classes.root}>
      <DialogTitle>Импорт</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.tableButtons}>
              <Button
                className={classes.button}
                color="primary"
                size="small"
                onClick={handleRemoveFiles}
                disabled={selectedRows.length === 0}
              >
                Удалить
              </Button>
              <div className={classes.fileUpload}>
                <input
                  onChange={handleFileChange}
                  id="icon-button-file"
                  type="file"
                  // accept="audio/*"
                  accept={accept}
                  hidden
                  multiple
                  ref={inputRef}
                />
                <label htmlFor="icon-button-file">
                  <Button variant="contained" color="primary" component="span" size="small">
                    Выбрать файлы
                  </Button>
                </label>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FilesTable rowData={files} setGridApi={setGridApi} onSelection={onSelection} />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={() => onClose(files)}
                disabled={disabled}
              >
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UploadFilesDialog;
