import React, { FC } from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { rootReducer } from "redux/types";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { save } from "redux-localstorage-simple";

const middleware = [thunk, save({ namespace: "APP" })];
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

const ReduxProvider: FC = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;
