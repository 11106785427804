import React, { FC } from "react";
import { Word } from "types";
import { humanDuration } from "functions/common";

const KeyWordTimeRender: FC<{ value: Word[] }> = ({ value }) => {
  if (value.length === 0) return null;

  const first = value[0];
  const last = value[value.length - 1];

  return <span>{`${humanDuration(first.start)} - ${humanDuration(last.end)}`}</span>;
};

export default KeyWordTimeRender;
