import React, { FC, useCallback, useMemo } from "react";
import { Word as WordType } from "types/record";
import clsx from "clsx";
import { RootState } from "redux/types";
import { useSelector } from "react-redux";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey, red, yellow } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  word: {
    display: "inline-block",
  },
  p: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: yellow[200] + "!important",
    },
  },
  active: {
    backgroundColor: yellow[300] + "!important",
  },
  padding: {
    padding: "2px 4px",
  },
  input: {
    display: "inline-block",
    border: "none",
    background: "none",
    fontSize: "inherit",
    padding: "2px 4px",
    "&:focus": {
      outline: "1px solid " + grey[400],
      borderRadius: 5,
    },
  },
  confidence: {
    borderBottom: "1px dashed " + red[300],
    "&:focus": {
      borderBottom: "1px solid " + grey[400],
    },
  },
}));

interface Props {
  word: WordType;
  currentTime: number;
  color: string | undefined;
  bgColor: string | undefined;
  onWordEdit: (event: any, word: WordType) => void;
  onWordClick: (time: number) => void;
}

const TextWord: FC<Props> = ({ word, currentTime, color, bgColor, onWordEdit, onWordClick }) => {
  const classes = useStyles();
  const { editMode } = useSelector((state: RootState) => state.settings);
  const { word: w, start, end, lang, color: wColor, bgColor: wBgColor, confidence } = word;

  const isActive = currentTime >= start && currentTime <= end;
  const hasSpace = lang !== "chinese";

  const style = useMemo(() => {
    if (editMode) return;
    return {
      color: color ? color : wColor === "" ? "inherit" : wColor,
      borderBottomColor: bgColor ? bgColor : wBgColor === "" ? "inherit" : wBgColor,
      borderBottomWidth: "2px",
      "border-bottom-style": wBgColor ? "solid" : "",
    };
  }, [color, wColor, bgColor, wBgColor, editMode]);

  const handleClick = useCallback(() => {
    if (!editMode && onWordClick) {
      onWordClick(start);
    }
  }, [start, editMode, onWordClick]);

  if (editMode) {
    return (
      <div
        className={clsx(classes.input, { [classes.confidence]: confidence < 1 })}
        contentEditable
        onBlur={(e) => onWordEdit(e, word)}
        title={confidence < 1 ? "confidence: " + confidence : ""}
      >
        {w}
      </div>
    );
  }

  return (
    <div
      style={style}
      onClick={handleClick}
      className={clsx(classes.word, {
        [classes.p]: !editMode,
        [classes.active]: isActive,
        [classes.padding]: hasSpace,
      })}
    >
      {w}
    </div>
  );
};

export default TextWord;
