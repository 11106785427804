import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { isIPv4 } from "is-ip";

// components
import { IpAddress } from "types/ipAddress";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
}));

const initialState: IpAddress = {
  id: -1,
  from: 0,
  fromString: "",
  to: 0,
  toString: "",
  country: "",
  city: "",
  lat: 0.0,
  long: 0.0,
  comment: "",
  dateChanged: "",
  dateCreated: "",
};

interface Props {
  open: boolean;
  onClose: (data?: IpAddress) => void;
}

const CreateIpAddressDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [state, setState] = useState<IpAddress>(initialState);

  const [ipFromValidErr, setIpFromValidErr] = useState(false);
  const [ipToValidErr, setIpToValidErr] = useState(false);

  const [latValidErr, setLatValidErr] = useState(false);
  const [longValidErr, setLongValidErr] = useState(false);

  const { fromString, toString, country, city, lat, long, comment } = state;

  const disabled = ipFromValidErr || ipToValidErr || latValidErr || longValidErr;

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetErrors = () => {
    setIpFromValidErr(true);
    setIpToValidErr(true);
  };

  const handleIpChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === "fromString") {
      setIpFromValidErr(!isIPv4(value));
    }

    if (name === "toString") {
      setIpToValidErr(!isIPv4(value));
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === "lat") {
      if (isNaN(Number(value)) || value === "") {
        setLatValidErr(true);
      } else {
        setLatValidErr(false);
        setState((prev) => ({
          ...prev,
          [name]: Number(value),
        }));
        return;
      }
    }

    if (name === "long") {
      if (isNaN(Number(value)) || value === "") {
        setLongValidErr(true);
      } else {
        setLongValidErr(false);
        setState((prev) => ({
          ...prev,
          [name]: Number(value),
        }));
        return;
      }
    }

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = useCallback(() => {
    resetErrors();
    onClose(state);
  }, [onClose, state]);

  useEffect(() => {
    if (!open) {
      resetErrors();
      setState(initialState);
    }
  }, [open]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Создать IP адрес</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Диапазон: от"
              error={ipFromValidErr}
              helperText={ipFromValidErr ? "Неверный IP адрес" : ""}
              fullWidth
              name="fromString"
              onChange={handleIpChange}
              value={fromString}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Диапазон: до"
              error={ipToValidErr}
              helperText={ipToValidErr ? "Неверный IP адрес" : ""}
              fullWidth
              name="toString"
              onChange={handleIpChange}
              value={toString}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Страна" fullWidth name="country" onChange={handleChange} value={country} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Город" fullWidth name="city" onChange={handleChange} value={city} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Широта"
              type="number"
              error={latValidErr}
              helperText={latValidErr ? "Неверная широта" : ""}
              fullWidth
              name="lat"
              onChange={handleNumberChange}
              value={lat}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Долгота"
              type="number"
              error={longValidErr}
              helperText={longValidErr ? "Неверная долгота" : ""}
              fullWidth
              name="long"
              onChange={handleNumberChange}
              value={long}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSave}
                disabled={disabled}
              >
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateIpAddressDialog;
