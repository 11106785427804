import React, { FC, ChangeEvent } from "react";
import { Permission as P } from "types/permission";

// material ui
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

interface Props {
  permission: P;
  changePermission: (p: P) => void;
}

const PermissionRow: FC<Props> = ({ permission, changePermission }) => {
  const { login, isCanWrite, isCanRead } = permission;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const perm: P = {
      ...permission,
      [name]: checked,
    };
    changePermission(perm);
  };

  return (
    <TableRow hover>
      <TableCell component="th" scope="row">
        {login}
      </TableCell>
      <TableCell align="right">
        <Checkbox checked={isCanRead} name="isCanRead" onChange={handleChange} color="primary" />
      </TableCell>
      <TableCell align="right">
        <Checkbox checked={isCanWrite} name="isCanWrite" onChange={handleChange} color="primary" />
      </TableCell>
    </TableRow>
  );
};

export default PermissionRow;
