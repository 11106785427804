import React, { FC } from "react";
import { ProcessStatus } from "types/common";

// icons
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { green, red, blue, orange, grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  green: {
    color: green[300],
  },
  blue: {
    color: blue[300],
  },
  orange: {
    color: orange[300],
  },
  red: {
    color: red[300],
  },
  grey: {
    color: grey[400],
  },
  message: {
    marginLeft: 5,
  },
}));

const RecordStatusRenderer: FC<{ value: ProcessStatus }> = ({ value }) => {
  const classes = useStyles();

  if (value === "progress") {
    return (
      <div className={classes.wrapper}>
        <QueryBuilderIcon className={classes.blue} />
      </div>
    );
  }

  if (value === "notProcessed") {
    return (
      <div className={classes.wrapper}>
        <CheckCircleIcon className={classes.grey} />
      </div>
    );
  }

  if (value === "ok") {
    return (
      <div className={classes.wrapper}>
        <CheckCircleIcon className={classes.green} />
      </div>
    );
  }

  if (value === "error") {
    return (
      <div className={classes.wrapper}>
        <ErrorIcon className={classes.red} />
      </div>
    );
  }

  return null;
};

export default RecordStatusRenderer;
