import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/types";
import { hideAlert } from "../redux/actions/alertActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.modal + 10,
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: 0,
    left: 0,
  },
  notification: {
    backgroundColor: "#353535",
    color: "#fff",
    padding: 20,
    width: 450,
    minHeight: 150,
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.modal + 11,
  },
  content: {
    position: "relative",
  },
  close: {
    position: "absolute",
    top: 0,
    right: -15,
  },
  closeBtn: {
    color: grey[500],
  },
  icon: {
    fontSize: 18,
  },
  message: {
    fontSize: theme.typography.fontSize,
    wordBreak: "break-all",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  img: {
    width: 48,
    height: 48,
    marginRight: 10,
  },
  title: {
    fontSize: theme.typography.fontSize + 4,
  },
}));

const Notification: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, message } = useSelector((state: RootState) => state.alert);

  const onClick = () => {
    dispatch(hideAlert());
  };

  if (!open) return null;

  return (
    <div className={classes.notification}>
      <div className={classes.content}>
        <div className={classes.close}>
          <IconButton className={classes.closeBtn} onClick={onClick} color="inherit" title="Закрыть">
            <CloseIcon className={classes.icon} />
          </IconButton>
        </div>
        <div className={classes.logo}>
          <img src="/logo.ico" alt="Replica 7" className={classes.img} />
          <Typography variant="h6" noWrap className={classes.title}>
            Технологии обработки речи
          </Typography>
        </div>
        <div className={classes.message}>{message}</div>
      </div>
    </div>
  );
};

export default Notification;
