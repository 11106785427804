import { AxiosResponse } from "axios";
import { records } from "http/urls";
import api from "http/api";
import { Speaker } from "../types/speaker";

function SpeakerService() {
  function getAll(root: number, query = "", offset = 0, count = 5000): Promise<AxiosResponse<Speaker[]>> {
    let url =
      records.getAllSpeakers
        .replace(":root", String(root))
        .replace(":offset", String(offset))
        .replace(":count", String(count)) + query;
    return api.get<Speaker[]>(decodeURI(url));
  }

  function create(body: any) {
    return api.post(records.createSpeaker, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  function update(body: any) {
    return api.put(records.updateSpeaker, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  function remove(ids: string) {
    const url = records.removeSpeakers.replace(":ids", ids);
    return api.delete(url);
  }

  function importSpeakers(id: string | number, body: FormData) {
    const url = records.importSpeaker.replace(":id", String(id));
    return api.post(url, body);
  }

  function importSpeakersZip(id: string | number, body: FormData) {
    const url = records.importSpeakerZip.replace(":id", String(id));
    return api.post(url, body);
  }

  function getAudioFile(speakerId: number, modelId: number) {
    const url = records.getSpeakerAudioFile
      .replace(":speakerId", String(speakerId))
      .replace(":modelId", String(modelId));
    return api.get(url, { responseType: "blob" });
  }

  function getFile(speakerId: number, fileId: number) {
    const url = records.getSpeakerFile.replace(":speakerId", String(speakerId)).replace(":fileId", String(fileId));
    return api.get(url, { responseType: "blob" });
  }

  function moveSpeakers(body: { rootId: number; ids: number[] }) {
    return api.post(records.moveSpeakers, body);
  }

  function getSpeakersByIds(body: { ids: number[] }): Promise<AxiosResponse<Speaker[]>> {
    return api.post(records.getSpeakersByIds, body);
  }

  return Object.freeze({
    getAll,
    create,
    update,
    remove,
    importSpeakers,
    importSpeakersZip,
    getAudioFile,
    getFile,
    moveSpeakers,
    getSpeakersByIds,
  });
}

export default SpeakerService();
