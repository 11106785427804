import { AxiosResponse } from "axios";
import { RecordFile } from "types";
import { files } from "http/urls";
import api from "http/api";

function FileService() {
  function getByRecordId(recordId: number, offset = 0, count = 1000): Promise<AxiosResponse<RecordFile[]>> {
    const url = files.getAll
      .replace(":recordId", String(recordId))
      .replace(":offset", String(offset))
      .replace(":count", String(count));
    return api.get<RecordFile[]>(url);
  }

  function getCount(recordId: number): Promise<AxiosResponse> {
    const url = files.count.replace(":recordId", String(recordId));
    return api.get(url);
  }

  return Object.freeze({
    getByRecordId,
    getCount,
  });
}

export default FileService();
