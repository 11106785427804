import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { Filter } from "types/filter";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

const FilterNameRenderer: FC<{ value: string; data: Filter }> = ({ value, data }) => {
  const classes = useStyles();
  const history = useHistory();
  const { filter } = data;

  const handleClick = () => {
    const { path } = routes.results;
    const url = path + "?f=" + filter;
    history.push(encodeURI(url));
  };

  return (
    <span className={classes.root} onClick={handleClick}>
      {value}
    </span>
  );
};

export default FilterNameRenderer;
