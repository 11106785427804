import React, { ChangeEvent, FC, KeyboardEvent, useMemo, useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import { defaultFolderSftp, defaultFolderSmb, FolderSmb as FolderSmbType, FolderType, SmbVersion } from "types/source";
import { ExportTask, ExportTaskDetail, TaskDetailType } from "types/task";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { Group } from "types/queryBuilder";
import { generateUuid } from "functions/common";

// components
import HistoryFilters from "components/search/HistoryFilters";
import MaskInput from "components/form/MaskInput";
import Filter from "components/filter/Filter";
import SelectProtocol from "pages/sources/components/SelectProtocol";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  task: ExportTask;
}

interface State {
  id: number;
  name: string;
  comment: string;
  isActive: boolean;
}

const UpdateExportTaskDialog: FC<Props> = ({ onClose, open, task }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const initialState: State = useMemo(
    () => ({
      id: -1,
      name: "Экспорт от " + moment().format("DD-MM-YYYY HH:mm:ss"),
      comment: "",
      isActive: true,
    }),
    []
  );
  const initialTaskDetailState: ExportTaskDetail = useMemo(
    () => ({
      type: TaskDetailType.Export,
      filterDetail: "",
      storageDetail: defaultFolderSftp,
      isExportOrigFiles: true,
      formatAudioFile: "wav",
      formatTextFile: "txt",
      formatText: "text",
      mask: "[namespace]/[name]",
    }),
    []
  );
  const classes = useStyles();

  const { filters: availableFilters } = useAvailableFilters("records");
  const [state, setState] = useState<State>(initialState);
  const { name, comment, isActive } = state;
  const [taskDetail, setTaskDetail] = useState<ExportTaskDetail>(initialTaskDetailState);
  const { isExportOrigFiles, storageDetail, mask } = taskDetail;
  const disabled = name.length === 0;

  const [filter, setFilter] = useState(defaultFilter);
  const [formatAudioFile, setFormatAudioFile] = useState(1);
  const [formatTextFile, setFormatTextFile] = useState(1);
  const [formatText, setFormatText] = useState(0);

  const maskFields: { k: string; v: string }[] = useMemo(
    () => [
      { k: "Идентификатор", v: "id" },
      { k: "Дата", v: "date" },
      { k: "Время", v: "time" },
      { k: "Имя файла", v: "name" },
      { k: "Расширения файла", v: "ext" },
      { k: "IP-адрес терминала", v: "terminalIp" },
      { k: "Номер терминала", v: "terminalNumber" },
      { k: "Имя терминала", v: "terminalName" },
      { k: "Язык", v: "lang" },
      { k: "Диктор", v: "speaker" },
      { k: "Пол", v: "gender" },
      { k: "Тип данных", v: "codec" },
      { k: "Пространство", v: "namespace" },
      { k: "Город", v: "city" },
      { k: "Страна", v: "country" },
      { k: "Адрес", v: "address" },
    ],
    []
  );

  const handleChangeFormatAudioFile = (event: ChangeEvent<{ value: unknown }>) => {
    const v = event.target.value as number;
    if (v === 0) {
      setFormatAudioFile(0);
      setTaskDetail((prev) => ({ ...prev, formatAudioFile: "" }));
    }
    if (v === 1) {
      setFormatAudioFile(1);
      setTaskDetail((prev) => ({ ...prev, formatAudioFile: "wav" }));
    }
    if (v === 2) {
      setFormatAudioFile(2);
      setTaskDetail((prev) => ({ ...prev, formatAudioFile: "mp3" }));
    }
  };

  const handleChangeFormatTextFile = (event: ChangeEvent<{ value: unknown }>) => {
    const v = event.target.value as number;
    if (v === 0) {
      setFormatTextFile(0);
      setTaskDetail((prev) => ({ ...prev, formatTextFile: "" }));
    }
    if (v === 1) {
      setFormatTextFile(1);
      setTaskDetail((prev) => ({ ...prev, formatTextFile: "txt" }));
    }
    if (v === 2) {
      setFormatTextFile(2);
      setTaskDetail((prev) => ({ ...prev, formatTextFile: "docx" }));
    }
    if (v === 3) {
      setFormatTextFile(3);
      setTaskDetail((prev) => ({ ...prev, formatTextFile: "pdf" }));
    }
  };

  const handleChangeFormatText = (event: ChangeEvent<{ value: unknown }>) => {
    const v = event.target.value as number;
    if (v === 0) {
      setFormatText(0);
      setTaskDetail((prev) => ({ ...prev, formatText: "text" }));
    }
    if (v === 1) {
      setFormatText(1);
      setTaskDetail((prev) => ({ ...prev, formatText: "translate" }));
    }
    if (v === 2) {
      setFormatText(2);
      setTaskDetail((prev) => ({ ...prev, formatText: "textAndTranslate" }));
    }
  };

  const handleSave = () => {
    onClose({ ...state, taskDetail: { ...taskDetail, filterDetail: JSON.stringify(filter) } });
  };

  const handleCheckedTaskDetail = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setTaskDetail((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (key !== "Escape") {
      event.stopPropagation();
    }
  };

  const onChangeTextFolderDetail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setTaskDetail((prev) => ({
      ...prev,
      storageDetail: {
        ...prev.storageDetail,
        [name]: value,
      },
    }));
  };

  const handleChangeSmbVersion = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    const storageDetail: FolderSmbType = {
      ...(taskDetail.storageDetail as FolderSmbType),
      smbVersion: value as SmbVersion,
    };
    setTaskDetail((prev) => ({ ...prev, storageDetail }));
  };

  const setStorageDetailPathValue = (value: string, name?: string) => {
    const pathName = name === undefined ? "path" : name;
    setTaskDetail((prev) => ({ ...prev, storageDetail: { ...prev.storageDetail, [pathName]: value } }));
  };

  const setMaskInput = (mask: string) => {
    setTaskDetail((prev) => ({ ...prev, mask }));
  };

  const changeConnectionType = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    if (value === FolderType.SFTP) {
      setTaskDetail((prev) => ({ ...prev, storageDetail: defaultFolderSftp }));
    }

    if (value === FolderType.SMB) {
      setTaskDetail((prev) => ({ ...prev, storageDetail: defaultFolderSmb }));
    }
  };

  useEffect(() => {
    setState({
      name: task.name,
      comment: task.comment,
      isActive: task.isActive,
      id: task.id,
    });
    setTaskDetail(task.taskDetail);
    try {
      const filter: Group = JSON.parse(task.taskDetail.filterDetail);
      setFilter(filter);
    } catch (e) {
      setFilter(defaultFilter);
    }
  }, [task, defaultFilter]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактирование задания на экспорт файлов</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Имя"
              fullWidth
              variant="standard"
              name="name"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={name}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <MaskInput mask={mask} setMaskInput={setMaskInput} fields={maskFields} label="Маска выходного пути" />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Фильтр</Typography>
            <div className={classes.filter}>
              <div className={classes.input}>
                <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
              </div>
              <HistoryFilters setFilter={setFilter} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={comment}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isActive} onChange={handleChecked} name="isActive" color="primary" />}
              label="Активно"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="formatAudioFile-label">Выходной формат аудио файлов</InputLabel>
              <Select
                fullWidth
                labelId="formatAudioFile-label"
                id="formatAudioFile"
                value={formatAudioFile}
                onChange={handleChangeFormatAudioFile}
              >
                <MenuItem value={0}>Не задан</MenuItem>
                <MenuItem value={1}>WAV</MenuItem>
                <MenuItem value={2}>MP3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="formatTextFile-label">Выходной формат текстовых файлов</InputLabel>
              <Select
                fullWidth
                labelId="formatTextFile-label"
                id="formatTextFile"
                value={formatTextFile}
                onChange={handleChangeFormatTextFile}
              >
                <MenuItem value={0}>Не задан</MenuItem>
                <MenuItem value={1}>TXT</MenuItem>
                <MenuItem value={2}>DOCX</MenuItem>
                <MenuItem value={3}>PDF</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="formatTextFile-label">Формат текстового файла</InputLabel>
              <Select
                disabled={formatTextFile === 0}
                fullWidth
                labelId="formatText-label"
                id="formatText"
                value={formatText}
                onChange={handleChangeFormatText}
              >
                <MenuItem value={0}>Текст</MenuItem>
                <MenuItem value={1}>Перевод</MenuItem>
                <MenuItem value={2}>Текст и перевод</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isExportOrigFiles}
                  onChange={handleCheckedTaskDetail}
                  name="isExportOrigFiles"
                  color="primary"
                />
              }
              label="Экспортировать оригинальные файлы"
            />
          </Grid>

          <Grid item xs={12}>
            <SelectProtocol
              folderDetail={storageDetail}
              onChangeConnectionType={changeConnectionType}
              onChangeSmbVersion={handleChangeSmbVersion}
              onChangeInputElement={onChangeTextFolderDetail}
              setPathValue={setStorageDetailPathValue}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateExportTaskDialog;
