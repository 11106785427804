import React, { FC } from "react";

// components
import AdminQuestion from "./AdminQuestion";
import AllServices from "layouts/user/header/AllServices";
import User from "layouts/user/header/User";
import SystemError from "components/SystemError";

// material ui
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "white",
  },
  adminTitle: {
    padding: theme.spacing(0, 3),
    flex: 1,
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  img: {
    width: 48,
    height: 48,
  },
  link: {
    textDecoration: "none",
    color: "#ffffff",
    fontSize: theme.typography.fontSize * 1.2,
    marginRight: 20,
  },
  buttons: {
    paddingRight: 20,
    display: "none",
  },
  user: {},
  logoLink: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  allServices: {
    textAlign: "right",
    marginRight: 10,
  },
  freeSpace: {
    flex: 1,
    padding: "0 20px",
    display: "flex",
  },
}));

const AdminHeader: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const logoClick = () => {
    history.push("/");
  };

  return (
    <section className={classes.container}>
      <div className={classes.logoLink} onClick={logoClick}>
        <img src="/logo.ico" alt="Replica 7" className={classes.img} />
        <Typography variant="h6" noWrap className={classes.title}>
          Технологии обработки речи
        </Typography>
      </div>

      <div className={classes.freeSpace}>
        <SystemError />
      </div>

      <div className={classes.buttons}>
        <AdminQuestion />
      </div>

      <div className={classes.allServices}>
        <AllServices />
      </div>

      <div className={classes.user}>
        <User />
      </div>
    </section>
  );
};

export default AdminHeader;
