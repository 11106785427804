import React, { ChangeEvent, FC, useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { UrlParams } from "types/admin";
import FilterService from "services/admin/FilterService";

// ag-grid
import { AgGridReact } from "ag-grid-react";
import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import FilterNameRenderer from "components/agGrid/renderers/FilterNameRenderer";
import IsCanWriteRenderer from "components/agGrid/renderers/IsCanWriteRenderer";
import localization from "components/agGrid/localization";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(() => ({
  root: {},
  input: {
    marginBottom: 10,
  },
  table: {
    height: 290,
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Идентификатор",
    field: "id",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sort: "desc",
  },
  {
    headerName: "Имя",
    field: "name",
    flex: 1,
  },
];

interface Props {
  value: string;
  changeValue: (value: string) => void;
  params: UrlParams;
}

const ObjectInput: FC<Props> = ({ value, changeValue, params }) => {
  const classes = useStyles();

  const mounted = useRef(false);
  const [list, setList] = useState<any[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    if (mounted.current) {
      changeValue(rows.map((p) => p.id).join("\n"));
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!params || !params.url) return;
    FilterService.doCustomGetQuery(params.url).then((res) => {
      setList(res.data);
    });
  }, [params]);

  useEffect(() => {
    if (gridApi === undefined) return;
    if (list.length === 0) return;

    const ids = value.split("\n").map((v) => Number(v));
    gridApi.forEachNode((node) => {
      if (ids.includes(node.data.id)) {
        node.setSelected(true);
      }
    });

    return () => {
      gridApi.deselectAll();
      gridApi.clearRangeSelection();
    };
  }, [value, gridApi, list]);

  return (
    <div className={classes.root}>
      <TextField
        className={classes.input}
        placeholder="Поиск"
        onChange={onQuickFilterChanged}
        onKeyDown={(e) => e.stopPropagation()}
        variant="outlined"
        size="small"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <div className={clsx(classes.table, "ag-theme-balham")}>
        <AgGridReact
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          defaultColDef={defaultColDef}
          rowData={list}
          columnDefs={columnDefs}
          rowSelection="multiple"
          enableRangeSelection
          suppressDragLeaveHidesColumns
          localeText={localization}
          frameworkComponents={{
            DateRenderer,
            FilterNameRenderer,
            IsCanWriteRenderer,
          }}
        />
      </div>
    </div>
  );
};

export default ObjectInput;
