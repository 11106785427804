import React, { FC, ChangeEvent } from "react";
import { SourceDetailSprutInterbase, SourceType } from "types/source";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { Group } from "types/queryBuilder";
import { Sort as SortType } from "types/common";

// components
import Sort from "../Sort";
import PasswordInput from "components/form/PasswordInput";
import SleepSec from "../SleepSec";
import SourceFilter from "../SourceFilter";

// material ui
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

interface Props {
  state: SourceDetailSprutInterbase;
  setState: (item: SourceDetailSprutInterbase) => void;
  disable: boolean;
}

const SprutInterbase: FC<Props> = ({ state, setState, disable }) => {
  const {
    host,
    login,
    password,
    database,
    sort,
    sleepSec,
    isClearComment,
    isSaveToComment,
    filter,
    deleteFilter,
  } = state;

  const { filters: availableFiltersByType } = useAvailableFilters(SourceType.SPRUT_INTERBASE);
  const { filters: availableFilters } = useAvailableFilters("records");

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleChangeSort = (event: ChangeEvent<{ value: unknown }>) => {
    const sort = event.target.value as SortType;
    setState({ ...state, sort });
  };

  const setSleepSec = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState({ ...state, sleepSec: Number(value) });
  };

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setState({ ...state, [name]: checked });
  };

  const setFilter = (f: Group) => {
    setState({ ...state, filter: JSON.stringify(f) });
  };

  const setDeleteFilter = (f: Group) => {
    setState({ ...state, deleteFilter: JSON.stringify(f) });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField label="Хост" fullWidth name="host" onChange={handleChange} value={host} />
      </Grid>
      <Grid item xs={12}>
        <TextField label="Логин" fullWidth name="login" onChange={handleChange} value={login} />
      </Grid>
      <Grid item xs={12}>
        <PasswordInput handleChange={handleChange} value={password} margin="none" />
      </Grid>
      <Grid item xs={12}>
        <TextField label="База данных" fullWidth name="database" onChange={handleChange} value={database} />
      </Grid>
      <Grid item xs={12}>
        <Sort disabled={disable} sort={sort} handleChange={handleChangeSort} />
      </Grid>
      <Grid item xs={12}>
        <SourceFilter title="Фильтр" filter={filter} setFilter={setFilter} availableFilters={availableFiltersByType} />
      </Grid>
      <Grid item xs={12}>
        <SourceFilter
          title="Исключить данные по фильтру"
          filter={deleteFilter}
          setFilter={setDeleteFilter}
          availableFilters={availableFilters}
        />
      </Grid>
      <Grid item xs={12}>
        <SleepSec sleepSec={sleepSec} setSleepSec={setSleepSec} />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={<Checkbox checked={isClearComment} onChange={handleChecked} name="isClearComment" color="primary" />}
          label="Очищать комментарии перед записью"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox checked={isSaveToComment} onChange={handleChecked} name="isSaveToComment" color="primary" />
          }
          label="Сохранять результат в комментарии"
        />
      </Grid>
    </Grid>
  );
};

export default SprutInterbase;
