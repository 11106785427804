import React, { ChangeEvent, FC, useState, useCallback } from "react";
import Element, { ElementType } from "./components/Element";
import RecordService from "services/RecordService";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chart from "./components/Chart";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    height: "calc(100vh - 90px)",
    overflow: "auto",
  },
  buttons: {
    marginBottom: theme.spacing(1),
  },
  elements: {
    display: "flex",
    overflow: "auto",
    marginBottom: 20,
  },
  charts: {},
  chart: {},
}));

const initialElement: ElementType = {
  id: Date.now(),
  filterV2: "",
  fields: [],
};

const Analytica: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [elements, setElements] = useState<ElementType[]>([initialElement]);
  const [filters, setFilters] = useState<string[]>([]);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const removeElement = (id: number) => {
    const filtered = elements.filter((el) => el.id !== id);
    setElements(filtered);
    setFilters(filtered.map((el) => el.filterV2));
  };

  const handleChangeElementFilter = (id: number, filterV2: string) => {
    setElements((prev) => prev.map((el) => (el.id === id ? { ...el, filterV2 } : el)));
  };

  const handleChangeField = (event: ChangeEvent<HTMLTextAreaElement>, id: number) => {
    const { value, name } = event.target;
    setElements((prev) =>
      prev.map((el) => {
        if (el.id === id) {
          const fields = el.fields.map((el) => (el.name === name ? { name, value } : el));
          return {
            ...el,
            fields,
          };
        }
        return el;
      })
    );
  };

  const handleSearch = (id: number) => {
    setFilters(elements.map((el) => el.filterV2));
    const element = elements.find((el) => el.id === id);
    if (element) {
      const q = "?filterv2=" + element.filterV2;
      RecordService.getAnalytics(q)
        .then(({ data }) => {
          const upElements = elements.map((el) => (el.id === id ? { ...el, fields: data } : el));

          if (upElements.length > 1) {
            const map = new Map();
            for (let i = 0; i < upElements.length; i++) {
              const fields = upElements[i].fields;
              for (let j = 0; j < fields.length; j++) {
                const field = fields[j];
                const key = `${field.name}_${field.value}`;
                const v = map.get(key);
                if (v !== undefined) {
                  map.set(key, v + 1);
                }
                if (v === undefined) {
                  map.set(key, 1);
                }
              }
            }

            for (let i = 0; i < upElements.length; i++) {
              const fields = upElements[i].fields;
              for (let j = 0; j < fields.length; j++) {
                const field = fields[j];
                const key = `${field.name}_${field.value}`;
                const v = map.get(key);
                field.isActive = v === upElements.length;
              }
            }
          }

          setElements(upElements);
        })
        .catch((err) => catchError(err.response.data));
    }
  };
  return (
    <Paper className={classes.root}>
      <div className={classes.buttons}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setElements((prev) => [...prev, { id: Date.now(), filterV2: "", fields: [] }])}
        >
          Добавить
        </Button>
      </div>
      <div className={classes.elements}>
        {elements.map((el) => (
          <Element
            key={el.id}
            el={el}
            remove={removeElement}
            onChangeFilter={handleChangeElementFilter}
            handleSearch={handleSearch}
            onChangeField={handleChangeField}
          />
        ))}
      </div>
      <div className={classes.charts}>
        <div className={classes.chart}>
          <Chart filters={filters} />
        </div>
      </div>
    </Paper>
  );
};

export default Analytica;
