import React, { FC } from "react";
import { DownloadTextType as T } from "types/record";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  root: {
    width: 300,
  },
}));

interface Props {
  anchorEl: null | HTMLElement;
  setAnchorEl: Function;
  onClick: (type: T) => void;
}

const CopyToClipboard: FC<Props> = ({ anchorEl, setAnchorEl, onClick }) => {
  const classes = useStyles();

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      className={classes.root}
    >
      <MenuItem onClick={() => onClick(T.TEXT)}>Текст</MenuItem>
      <MenuItem onClick={() => onClick(T.TRANSLATE)}>Перевод</MenuItem>
      <MenuItem onClick={() => onClick(T.TEXT_AND_TRANSLATE)}>Текст и перевод</MenuItem>
      <MenuItem onClick={() => onClick(T.CTM)}>Формат ctm</MenuItem>
      <MenuItem onClick={() => onClick(T.SPEECH_ANNOTATION)}>Формат speech annotation</MenuItem>
      <MenuItem onClick={() => onClick(T.CLEAR_TEXT)}>Неформатированный текст</MenuItem>
    </Menu>
  );
};

export default CopyToClipboard;
