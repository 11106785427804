import { AxiosResponse } from "axios";
import { records as route } from "http/urls";
import api from "http/api";
import { CountResponse, Message } from "types/message";

function MessageService() {
  function getNotViewedCount(): Promise<AxiosResponse<CountResponse>> {
    const url = route.messagesNotViewedCount;
    return api.get<CountResponse>(url);
  }

  function getDefault(): Promise<AxiosResponse<Message[]>> {
    const url = route.messagesGetDefault;
    return api.get<Message[]>(url);
  }

  function getByOrder(startId: number, order: string): Promise<AxiosResponse<Message[]>> {
    const url = route.messagesGet.replace(":startId", String(startId)).replace(":order", order);
    return api.get<Message[]>(url);
  }

  function setViewed(id: number): Promise<AxiosResponse<CountResponse>> {
    const url = route.messagesSetViewed.replace(":id", String(id));
    return api.post<CountResponse>(url);
  }

  function setViewedAll(): Promise<AxiosResponse<CountResponse>> {
    const url = route.messagesSetViewedAll;
    return api.post<CountResponse>(url);
  }

  function createMessage(m: Message): Promise<AxiosResponse> {
    return api.post(route.messagesCreate, m);
  }

  return Object.freeze({
    getNotViewedCount,
    getDefault,
    getByOrder,
    setViewed,
    setViewedAll,
    createMessage,
  });
}

export default MessageService();
