import { AxiosResponse } from "axios";
import { Filter } from "types/filter";
import { Permission } from "types/permission";
import { filters as route } from "http/urls";
import api from "http/api";

function FilterService() {
  function getAll(): Promise<AxiosResponse<Filter[]>> {
    const url = route.getAll;
    return api.get<Filter[]>(url);
  }

  function create(body: any) {
    const url = route.create;
    return api.post(url, body);
  }

  function update(body: Filter): Promise<AxiosResponse> {
    return api.put(route.update, body);
  }

  function remove(ids: string) {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  function getPermissions(id: number): Promise<AxiosResponse<Permission[]>> {
    const url = route.getAccess.replace(":id", String(id));
    return api.get<Permission[]>(url);
  }

  function setPermissions(id: number, body: Permission[]): Promise<AxiosResponse> {
    const url = route.setAccess.replace(":id", String(id));
    return api.post(url, body);
  }

  return Object.freeze({
    getAll,
    create,
    update,
    remove,
    getPermissions,
    setPermissions,
  });
}

export default FilterService();
