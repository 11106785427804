import React, { FC, useState } from "react";
import SortSelect from "./SortSelect";
import { Record } from "types/record";
import { AdminTypeFilter } from "types/admin";

// icons
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GetAppIcon from "@material-ui/icons/GetApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GridOnIcon from "@material-ui/icons/GridOn";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import CachedIcon from "@material-ui/icons/Cached";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  list: {
    width: 320,
  },
  buttons: {
    padding: theme.spacing(1, 0),
  },
  button: {
    marginRight: 10,
  },
  visibilityButton: {
    width: 175,
  },
  iconButton: {},
  hidden: {
    display: "none",
  },
  tableSettings: {
    marginRight: 5,
  },
  sort: {
    flexGrow: 1,
  },
}));

interface Props {
  refresh: () => void;
  selectedRows: Record[];
  setOpenSetLabelDialog: Function;
  setOpenRemoveLabelDialog: Function;
  setShowRecordFiles: Function;
  setOpenDeleteDialog: Function;
  setOpenCopyDialog: Function;
  setOpenSetDictionaryDialog: Function;
  setOpenCreateSpeakerDialog: Function;
  disabledCreateSpeaker: boolean;
  handleSetIsViewed: () => void;
  isCanDelete: boolean;
  handleOpenCreateTaskDialog: () => void;
  handleOpenCreateExportDialog: () => void;
  availableFilters: AdminTypeFilter[];
  setOpenSettingsTableDialog: Function;
}

const ActionButtons: FC<Props> = ({
  refresh,
  selectedRows,
  setOpenSetLabelDialog,
  setOpenRemoveLabelDialog,
  setShowRecordFiles,
  setOpenDeleteDialog,
  setOpenCopyDialog,
  setOpenCreateSpeakerDialog,
  disabledCreateSpeaker,
  handleSetIsViewed,
  handleOpenCreateTaskDialog,
  handleOpenCreateExportDialog,
  isCanDelete,
  availableFilters,
  setOpenSettingsTableDialog,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const viewed =
    selectedRows.length === 0 ? "Просмотрено" : selectedRows[0].isViewed ? "Не просмотрено" : "Просмотрено";

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.tableSettings}
        onClick={() => setOpenSettingsTableDialog(true)}
        title="Настройка колонок таблицы"
        size="small"
      >
        <GridOnIcon fontSize="inherit" />
      </IconButton>
      <div className={classes.sort}>
        <SortSelect availableFilters={availableFilters} />
      </div>
      <div className={classes.iconButton}>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <List component="nav" className={classes.list}>
            <ListItem button onClick={refresh}>
              <ListItemIcon>
                <CachedIcon />
              </ListItemIcon>
              <ListItemText primary="Обновить" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => setOpenSetLabelDialog(true)} disabled={selectedRows.length === 0}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Добавить маркер" />
            </ListItem>
            <ListItem button onClick={() => setOpenRemoveLabelDialog(true)} disabled={selectedRows.length === 0}>
              <ListItemIcon>
                <RemoveIcon />
              </ListItemIcon>
              <ListItemText primary="Удалить маркер" />
            </ListItem>
            <Divider />
            <ListItem
              button
              disabled={selectedRows.length !== 1}
              onClick={() => setShowRecordFiles((prev: boolean) => !prev)}
            >
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText primary="Файлы" />
            </ListItem>
            <Divider />
            <ListItem button disabled={selectedRows.length === 0} onClick={handleSetIsViewed}>
              <ListItemIcon>{viewed === "Просмотрено" ? <VisibilityIcon /> : <VisibilityOffIcon />}</ListItemIcon>
              <ListItemText primary={viewed} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setOpenCopyDialog(true);
                setAnchorEl(null);
              }}
              disabled={selectedRows.length === 0}
            >
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary="Копировать" />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleOpenCreateTaskDialog} disabled={selectedRows.length === 0}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Создать задание" />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleOpenCreateExportDialog} disabled={selectedRows.length === 0}>
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText primary="Экспорт" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => setOpenCreateSpeakerDialog(true)} disabled={disabledCreateSpeaker}>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Создать дикторскую карточку" />
            </ListItem>
            {/*<ListItem button onClick={() => setOpenSetDictionaryDialog(true)} disabled={selectedRows.length === 0}>*/}
            {/*  <ListItemIcon>*/}
            {/*    <PageviewIcon />*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText primary="Проверить в справочнике" />*/}
            {/*</ListItem>*/}
            {isCanDelete && (
              <>
                <Divider />
                <ListItem
                  button
                  onClick={() => {
                    setAnchorEl(null);
                    setOpenDeleteDialog(true);
                  }}
                  disabled={selectedRows.length === 0}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Удалить" />
                </ListItem>
              </>
            )}
          </List>
        </Popover>
      </div>
    </div>
  );
};

export default ActionButtons;
