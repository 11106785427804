import { Obzor } from "types/obzor";

export const getBeforeLastSlash = (value: string) => {
  const parts = value.split("/");
  parts.pop();
  if (parts.length === 1 && parts[0] === "/") {
    return "/";
  }
  return parts.join("/") + "/";
};

export const getBeforeLastBackSlash = (value: string) => {
  const parts = value.split("\\");
  parts.pop();
  if (parts.length === 1 && parts[0] === "\\") {
    return "\\";
  }
  return parts.join("\\") + "\\";
};

export const getAfterLastSlash = (value: string): string => {
  const parts = value.split("/");
  return parts[parts.length - 1];
};

export const getAfterLastSlashSMB = (value: string): string => {
  const parts = value.split("/");
  return "/" + parts[parts.length - 1];
};

export const getAfterLastBackSlash = (value: string): string => {
  const parts = value.split("\\");
  return parts[parts.length - 1];
};

export const getParentDirectory = (currentPath: string) => {
  let parentDirectory = currentPath.split("/");
  parentDirectory.pop();
  let directory = parentDirectory.join("/");
  if (directory === "") {
    directory = "/";
  }
  return directory;
};

export const getStartPath = (path: string): string => {
  const lastIndexOfSlash = path.lastIndexOf("\\");
  if (lastIndexOfSlash !== -1) {
    return path.slice(0, lastIndexOfSlash + 1);
  }
  return "";
};

export const getRootPathSMB = (str: string): string => {
  const lastBackslashIndex = str.lastIndexOf("\\");
  return str.substring(0, lastBackslashIndex + 1);
};

export function transformRowDataSFTP(rowData: Obzor[]) {
  return rowData.map((item) => {
    const name = item.path.substring(item.path.lastIndexOf("\\") + 1);
    return {
      ...item,
      name,
    };
  });
}

export function transformRowDataSMB(rowData: Obzor[]) {
  return rowData.map((item) => {
    const name = item.path.substring(item.path.lastIndexOf("/") + 1);
    return {
      ...item,
      name,
    };
  });
}
