import {
  SET_REMOVE_RECORDS_ACCESS,
  AccessState,
  AccessAction,
  SET_SHOW_NAMESPACE_NAME,
  SET_SHOW_SPEAKERS_NAME,
  SET_SHOW_AUTOINFORMATORS_NAME,
} from "redux/types/accessTypes";

const initialState: AccessState = {
  isCanDelete: false,
  isShowNameSpaceName: false,
  isShowSpeakersName: false,
  isShowAutoInformatorsName: false,
};

export default (state = initialState, action: AccessAction) => {
  const { type } = action;

  if (type === SET_REMOVE_RECORDS_ACCESS) {
    return {
      ...state,
      isCanDelete: action.payload,
    };
  }

  if (type === SET_SHOW_NAMESPACE_NAME) {
    return {
      ...state,
      isShowNameSpaceName: action.payload,
    };
  }

  if (type === SET_SHOW_SPEAKERS_NAME) {
    return {
      ...state,
      isShowSpeakersName: action.payload,
    };
  }

  if (type === SET_SHOW_AUTOINFORMATORS_NAME) {
    return {
      ...state,
      isShowAutoInformatorsName: action.payload,
    };
  }

  return state;
};
