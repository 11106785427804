import React, { ChangeEvent, FC, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import clsx from "clsx";
import {
  setPlayerIsEcho,
  setWaveformType,
  setPlayerGain,
  setPlayerIsAdeClick,
  setPlayerIsKTCH,
  setPlayerIsNoise,
  setPlayerIsNormalize,
  setPlayerIsPan,
  setPlayOnlyVoiceSegments,
  setKeywordsLayout,
  setIsShowLayoutVad,
} from "redux/actions/settingsActions";
import { WaveformType, KeywordsLayout } from "../types";
import InfoDialog from "./InfoDialog";

// icons
import SettingsIcon from "@material-ui/icons/Settings";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

// material ui
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
import { grey, orange } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    settings: {
      width: 400,
      padding: theme.spacing(2),
      margin: 0,
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
    button: {},
    popover: {
      overflow: "hidden",
    },
    colorGrey: {
      color: grey[600],
    },
    diff: {
      width: 3,
      height: 15,
      backgroundColor: orange[600],
      marginRight: 10,
      marginLeft: -13,
    },
    item: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    container: {
      display: "flex",
      alignItems: "center",
    },
    hidden: {
      display: "none",
    },
  })
);

const Diff: FC<{ hidden: boolean }> = ({ hidden }) => {
  const classes = useStyles();
  return <div className={clsx(classes.diff, { [classes.hidden]: !hidden })} />;
};

const Settings: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    playerIsPan,
    playerIsAdeClick,
    playerIsNoise,
    playerIsKTCH,
    playerIsEcho,
    playerPlayOnlyVoiceSegments,
    playerGain,
    playerWaveformType,
    playerKeywordsLayout,
    isShowLayoutVad,
  } = useSelector((state: RootState) => state.settings);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.currentTarget.blur();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const payload = checked ? 1 : 0;
    if (name === "isPan") {
      dispatch(setPlayerIsPan(payload));
    }
    if (name === "isAdeClick") {
      dispatch(setPlayerIsAdeClick(payload));
    }
    if (name === "isNormalize") {
      dispatch(setPlayerIsNormalize(payload));
    }
    if (name === "isNoise") {
      dispatch(setPlayerIsNoise(payload));
    }
    if (name === "isKTCH") {
      dispatch(setPlayerIsKTCH(payload));
    }
    if (name === "isEcho") {
      dispatch(setPlayerIsEcho(payload));
    }
    if (name === "playOnlyVoiceSegments") {
      dispatch(setPlayOnlyVoiceSegments(checked));
    }
    if (name === "isShowLayoutVad") {
      dispatch(setIsShowLayoutVad(payload));
    }
  };

  const handleGainChange = (event: any, newValue: number | number[]) => {
    dispatch(setPlayerGain(newValue as number));
  };

  const handleKeywordsLayoutChange = (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(setKeywordsLayout(event.target.value as KeywordsLayout));
  };

  const countNotDefaultSettings = useMemo(() => {
    let count = 0;
    if (playerIsPan !== 0) {
      count += 1;
    }
    if (playerIsAdeClick !== 0) {
      count += 1;
    }
    // if (playerIsNormalize !== 0) {
    //   count += 1;
    // }
    if (playerIsNoise) {
      count += 1;
    }
    if (playerIsKTCH !== 0) {
      count += 1;
    }
    if (playerIsEcho !== 0) {
      count += 1;
    }
    if (playerGain !== 1) {
      count += 1;
    }
    if (playerPlayOnlyVoiceSegments) {
      count += 1;
    }
    if (playerWaveformType !== "waveform") {
      count += 1;
    }
    if (playerKeywordsLayout !== "all") {
      count += 1;
    }
    if (isShowLayoutVad !== 0) {
      count += 1;
    }
    return count;
  }, [
    playerIsPan,
    playerIsAdeClick,
    playerIsNoise,
    playerIsKTCH,
    playerIsEcho,
    playerGain,
    playerPlayOnlyVoiceSegments,
    playerWaveformType,
    playerKeywordsLayout,
    isShowLayoutVad,
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const handleOpenInfoDialog = (filter: string) => {
    setSelectedFilter(filter);
    setIsOpen(true);
  };

  const handleCloseInfoDialog = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <IconButton className={classes.button} onClick={handleClick} title="Настройки" disableRipple color="primary">
        <Badge badgeContent={countNotDefaultSettings} color="secondary" max={1000}>
          <SettingsIcon fontSize="inherit" />
        </Badge>
      </IconButton>

      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Grid container spacing={2} className={classes.settings}>
          <Grid item xs={12}>
            <div className={classes.container}>
              <Diff hidden={playerWaveformType !== "waveform"} />
              <FormControl fullWidth className={classes.item}>
                <InputLabel id="select-wft-label">Представление аудио данных</InputLabel>
                <Select
                  labelId="select-wft-label"
                  fullWidth
                  value={playerWaveformType}
                  onChange={(e: ChangeEvent<{ value: unknown }>) =>
                    dispatch(setWaveformType(e.target.value as WaveformType))
                  }
                >
                  <MenuItem value="waveform">Осциллограмма</MenuItem>
                  <MenuItem value="spectral">Сонограмма</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.container}>
              <Diff hidden={playerKeywordsLayout !== "all"} />
              <FormControl fullWidth className={classes.item}>
                <InputLabel id="select-kwl-label">Отображение ключевых слов</InputLabel>
                <Select
                  labelId="select-kwl-label"
                  fullWidth
                  value={playerKeywordsLayout}
                  onChange={handleKeywordsLayoutChange}
                >
                  <MenuItem value="all">Все</MenuItem>
                  <MenuItem value="origin">Из текста оригинала</MenuItem>
                  <MenuItem value="translate">Из текста перевода</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.container}>
              <Diff hidden={isShowLayoutVad !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isShowLayoutVad === 1}
                      onChange={handleCheckbox}
                      color="primary"
                      name="isShowLayoutVad"
                    />
                  }
                  label="Отображать сегменты речи"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography id="gain-slider" gutterBottom>
              {`Усиление: x${playerGain}`}
            </Typography>
            <div className={classes.container}>
              <Diff hidden={playerGain !== 1} />
              <div className={classes.item}>
                <Slider
                  defaultValue={playerGain}
                  onChangeCommitted={handleGainChange}
                  aria-labelledby="gain-slider"
                  valueLabelDisplay="auto"
                  marks
                  step={1}
                  min={1}
                  max={50}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: -10 }} className={classes.container}>
              <Diff hidden={playerIsPan !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox checked={playerIsPan === 1} onChange={handleCheckbox} color="primary" name="isPan" />
                  }
                  label="Панорамирование"
                />
                <IconButton className={classes.button} onClick={() => handleOpenInfoDialog("Панорамирование")}>
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.container}>
              <Diff hidden={playerPlayOnlyVoiceSegments} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={playerPlayOnlyVoiceSegments}
                      onChange={handleCheckbox}
                      color="primary"
                      name="playOnlyVoiceSegments"
                    />
                  }
                  label="Воспроизводить сегменты речи"
                />
              </div>
            </div>
            <Divider className={classes.divider} />

            {/*<div className={playerIsNormalize !== 0 ? "diff" : ""}>*/}
            {/*  <FormControlLabel*/}
            {/*    control={*/}
            {/*      <Checkbox*/}
            {/*        checked={playerIsNormalize === 1}*/}
            {/*        onChange={handleCheckbox}*/}
            {/*        color="primary"*/}
            {/*        name="isNormalize"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    label="Нормализация"*/}
            {/*  />*/}
            {/*</div>*/}

            <div className={classes.container}>
              <Diff hidden={playerIsEcho !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox checked={playerIsEcho === 1} onChange={handleCheckbox} color="primary" name="isEcho" />
                  }
                  label="Эхо компенсация"
                />
                <IconButton className={classes.button} onClick={() => handleOpenInfoDialog("Эхо компенсация")}>
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.container}>
              <Diff hidden={playerIsNoise !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox checked={playerIsNoise === 1} onChange={handleCheckbox} color="primary" name="isNoise" />
                  }
                  label="Фильтр широкополосного шума"
                />
                <IconButton
                  className={classes.button}
                  onClick={() => handleOpenInfoDialog("Фильтр широкополосного шума")}
                >
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.container}>
              <Diff hidden={playerIsAdeClick !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={playerIsAdeClick === 1}
                      onChange={handleCheckbox}
                      color="primary"
                      name="isAdeClick"
                    />
                  }
                  label="Фильтр импульсных помех"
                />
                <IconButton className={classes.button} onClick={() => handleOpenInfoDialog("Фильтр импульсных помех")}>
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.container}>
              <Diff hidden={playerIsKTCH !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox checked={playerIsKTCH === 1} onChange={handleCheckbox} color="primary" name="isKTCH" />
                  }
                  label="Фильтр канала тональной частоты"
                />
                <IconButton
                  className={classes.button}
                  onClick={() => handleOpenInfoDialog("Фильтр канала тональной частоты")}
                >
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </Popover>
      {isOpen && <InfoDialog onClose={handleCloseInfoDialog} selectedFilter={selectedFilter} />}
    </div>
  );
};

export default Settings;
