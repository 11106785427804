import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 15px",
  },
  button: {
    width: 100,
  },
}));

interface State {
  comment: string;
  ip: string;
  port: string;
}

const initialState: State = {
  comment: "",
  ip: "",
  port: "",
};

interface Props {
  s: {
    open: boolean;
    ph: string;
  };
  onClose: (data?: { ip: string; port: string; comment: string }) => void;
}

const CreateDialog: FC<Props> = ({ onClose, s }) => {
  const classes = useStyles();
  const { open, ph } = s;

  const [appPort, setAppPort] = useState("1400");
  const [state, setState] = useState<State>(initialState);
  const { comment, ip, port } = state;
  const disabled = useMemo(() => ip.length === 0 || port.length === 0, [ip, port]);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // обработчик нажатия клавиш
  const handleKeyDown = useCallback(
    (event) => {
      if (!open) return;
      const { key } = event;

      if (key === "Enter" ?? disabled) {
        event.preventDefault();
        event.stopPropagation();
        onClose(state);
        return;
      }
    },
    [open, onClose, state, disabled]
  );

  const handleChangeAppPort = (event: ChangeEvent<{ value: unknown }>) => {
    const port = event.target.value as string;
    setAppPort(port);
    if (port !== "custom") {
      setState((prev) => ({ ...prev, port }));
    }
  };

  useEffect(() => {
    if (!open) {
      setState(initialState);
    }

    if (open) {
      setState((prev) => ({ ...prev, ip: ph }));
    }
  }, [open, ph]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      className={classes.root}
      fullWidth
      maxWidth="md"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Добавить программу</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Ip адрес" fullWidth name="ip" onChange={handleChange} value={ip} autoFocus />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Порт</InputLabel>
              <Select labelId="select-label" value={appPort} onChange={handleChangeAppPort} fullWidth>
                <MenuItem value="1400">1400 (replica7-api-gateway)</MenuItem>
                <MenuItem value="1404">1404 (replica7-storage)</MenuItem>
                <MenuItem value="1412">1412 (replica7-worker)</MenuItem>
                <MenuItem value="custom">Задать вручную</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {appPort === "custom" && (
            <Grid item xs={12}>
              <TextField label="Порт" fullWidth name="port" onChange={handleChange} value={port} />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => onClose(state)}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDialog;
