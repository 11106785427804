import { useEffect, useState } from "react";
import { AdminTypeFilter } from "types/admin";
import FilterService from "services/admin/FilterService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  filters: AdminTypeFilter[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  filters: [],
  error: undefined,
};

const useAvailableFilters = (topic: string) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));

    if (topic === "") {
      setState({ filters: [], loading: false, error: undefined });
      return;
    }

    FilterService.getAll(topic)
      .then(({ data }) => {
        setState({ filters: data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ filters: [], loading: false, error: err });
      });
  }, [topic]);

  return state;
};

export default useAvailableFilters;
