import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { AdminTech, AdminTechStt } from "types/admin";
import { TaskTechStt, Lang, TechType } from "types/task";
import { LmModel } from "types/admin/lmModel";
import { Word } from "types/word";
import useWords from "hooks/useWords";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(() => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  adminTechs: AdminTech[];
  lmModels: LmModel[];
  tech: TaskTechStt;
}

const Stt: FC<Props> = ({ open, onClose, adminTechs, tech, lmModels }) => {
  const classes = useStyles();

  const { words } = useWords();

  const [langs, setLangs] = useState<Lang[]>([]);
  const [state, setState] = useState(tech);
  const { lang, isEnableWord2Num, parasiteWordId } = state;
  const [filteredLmModels, setFilteredLmModels] = useState<LmModel[]>([]);

  const keyWords = useMemo(() => {
    const kw: Word[] = [];

    const findWord = (group: string) => {
      for (let i = 0; i < words.length; i++) {
        const w = words[i];
        if (w.group.includes(group)) {
          return w;
        }
      }
      return undefined;
    };

    for (let i = words.length - 1; i >= 0; i--) {
      const w = words[i];
      // если есть слова - это не группа и не подгруппа
      if (w.words !== "") {
        // название словаря для вывода на экран
        let title = "";
        // группы объедины через /
        const groups = w.group.split("/");
        for (let j = 0; j < groups.length; j++) {
          const group = groups[j];
          // поиск словаря по id группы
          const word = findWord(group);
          if (word) title += word.name + " / ";
          // пример того что может получится: "Словари / Английский / МИД / "
        }
        // привести к формату "Словари / Английский / МИД"
        w.title = title.slice(0, -3);
        kw.push(w);
      }
    }

    return kw;
  }, [words]);

  const parasiteKeyWords = keyWords.find((w) => w.id === parasiteWordId);

  // используется только внутри компанента для отрисовки селекта, никуда не передается
  // 1 = числа, 2 = слова
  const [wordNumType, setWordNumType] = useState(isEnableWord2Num ? 1 : 2);

  const handleSave = () => {
    onClose(state);
  };

  const handleChangeLang = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setState((prev: any) => ({
      ...prev,
      lang: value,
    }));
  };

  const handleChangeLmModel = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setState((prev: any) => ({
      ...prev,
      languageModelId: Number(value),
    }));
  };

  const handleChangeWordNumType = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setWordNumType(value as number);
    setState((prev: any) => ({
      ...prev,
      isEnableWord2Num: value === 1,
    }));
  };

  const handleChangeParasiteWordId = (event: any, w: Word | null) => {
    setState((prev: any) => ({
      ...prev,
      parasiteWordId: w === null ? 0 : w.id,
    }));
  };

  useEffect(() => {
    const filtered = lmModels.filter((lm) => lm.lang === lang);
    setFilteredLmModels(filtered);
    const defaultLmModel = filtered.find((m) => m.isDefault);
    if (defaultLmModel !== undefined) {
      setState((prev: any) => ({
        ...prev,
        languageModelId: Number(defaultLmModel.id),
      }));
    }
  }, [lmModels, lang]);

  useEffect(() => {
    for (let i = 0; i < adminTechs.length; i++) {
      if (adminTechs[i].techDetail.type === TechType.STT) {
        const techStt: AdminTechStt = adminTechs[i].techDetail as AdminTechStt;
        setLangs(techStt.languages);
        break;
      }
    }
  }, [adminTechs]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Настройка</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-lang">Язык</InputLabel>
              <Select labelId="select-lang" name="lang" value={state.lang} onChange={handleChangeLang} fullWidth>
                {langs.map((lang) => (
                  <MenuItem key={lang.id} value={lang.id}>
                    {lang.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-lang-model">Языковая модель</InputLabel>
              <Select
                labelId="select-lang-model"
                value={state.languageModelId}
                onChange={handleChangeLmModel}
                fullWidth
                disabled={filteredLmModels.length === 0}
              >
                <MenuItem value={0}>Базовая</MenuItem>
                {filteredLmModels.map((lm) => (
                  <MenuItem key={lm.id} value={lm.id}>
                    {lm.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-wordNumType">Вариант отображения числительных</InputLabel>
              <Select
                labelId="select-wordNumType"
                name="wordNumType"
                value={wordNumType}
                onChange={handleChangeWordNumType}
                fullWidth
              >
                <MenuItem value={1}>Числовой</MenuItem>
                <MenuItem value={2}>Текстовый</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {keyWords.length > 0 && (
              <Autocomplete
                onKeyDown={(e) => e.stopPropagation()}
                onChange={handleChangeParasiteWordId}
                options={keyWords}
                getOptionLabel={(w) => w.title ?? ""}
                renderInput={(params) => <TextField {...params} label="Исключить список из результата обработки" />}
                value={parasiteKeyWords}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default Stt;
