import React, { FC, ChangeEvent, useRef, KeyboardEvent } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {},
  list: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5, 0, 0, 0),
    margin: 0,
  },
  chip: {
    cursor: "pointer",
    margin: theme.spacing(0.5),
  },
}));

interface Props {
  mask: string;
  setMaskInput: (mask: string) => void;
  fields: { k: string; v: string }[];
  label: string;
}

const MaskInput: FC<Props> = ({ mask, setMaskInput, fields, label }) => {
  const classes = useStyles();
  const ref = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMaskInput(event.target.value);
  };

  const onChipClick = (field: string) => {
    setMaskInput(mask + "[" + field + "]");
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <TextField
        label={label}
        value={mask}
        onChange={handleChange}
        className={classes.input}
        fullWidth
        ref={ref}
        onKeyDown={handleKeyDown}
      />
      <ul className={classes.list}>
        {fields.map(({ k, v }, idx) => (
          <li key={idx}>
            <Chip label={k} className={classes.chip} onClick={() => onChipClick(v)} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MaskInput;
