import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  checkbox: {
    // color: theme.palette.text.primary,
  },
}));

const BooleanRenderer: FC<{ value: boolean }> = ({ value }) => {
  const classes = useStyles();

  if (value) {
    return (
      <div className={classes.root}>
        <CheckBoxIcon className={classes.checkbox} />
      </div>
    );
  }

  return null;
};

export default BooleanRenderer;
