import React, { FC } from "react";
import { Dictionary, DictWord } from "types/dictionary";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 151px)",
  },
}));

const defaultColDef = {
  resizable: true,
  flex: 1,
};

const columnDefs = [
  {
    headerName: "Слово",
    field: "name",
    width: 320,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Комментарий",
    field: "comment",
  },
];

interface Props {
  rowData: DictWord[];
  setGridApi: Function;
  onSelection: Function;
}

const DictWordsTable: FC<Props> = ({ rowData, setGridApi, onSelection }) => {
  const classes = useStyles();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  function onSelectionChanged() {
    onSelection();
  }

  const getRowNodeId = (data: Dictionary) => String(data.id);

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        suppressCopyRowsToClipboard
        onSelectionChanged={onSelectionChanged}
        pagination
        suppressDragLeaveHidesColumns
      />
    </div>
  );
};

export default DictWordsTable;
