import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import ErrorBoundary from "./components/ErrorBoundary";

// providers
import ReduxProvider from "./providers/ReduxProvider";
import MaterialUiThemeProvider from "./providers/MaterialUiThemeProvider";
import NotiStackProvider from "./providers/NotiStackProvider";

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider>
      <MaterialUiThemeProvider>
        <NotiStackProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </NotiStackProvider>
      </MaterialUiThemeProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
