import React, { FC } from "react";
import { Format, Passport } from "types/admin";
import CreateFile from "./CreateFile";

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  passport: Passport;
}

const CreateDialog: FC<Props> = ({ open, onClose, passport }) => {
  const { detail } = passport;

  if (detail.type === Format.FILES) {
    return <CreateFile open={open} onClose={onClose} passport={passport} />;
  }

  return null;
};

export default CreateDialog;
