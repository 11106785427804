import React, { FC, useState, useEffect } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: "0",
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  hintIcon: {
    color: grey[600],
    marginRight: "1rem",
    zIndex: 100,
    cursor: "pointer",
  },
}));

interface ValueModel {
  value: string | null;
  time: number;
}

interface Props {
  id?: string;
  value: string;
  list: string[];
  onSelection?: Function;
  anchorEl: any;
}

const InputComboEditComponent: FC<Props> = ({ id, value, list, onSelection, anchorEl }) => {
  const classes = useStyles();

  const [autocompleteValue, setAutocompleteValue] = useState<ValueModel>({ value: value, time: 0 });
  const defaultValue = value === "" || value === null ? null : value;

  const onChange = (event: object, _value: any, reason: string) => {
    setAutocompleteValue({ value: _value, time: new Date().getTime() });
  };

  const onCloseCallback = () => {
    onSelection?.(autocompleteValue.value, "editAndClose");
  };

  const onCloseAutocomplete = (event: object, reason: string) => {
    if (reason === "select-option") {
      setAutocompleteValue((prev) => ({ ...prev, time: new Date().getTime() }));
      return;
    }

    if (reason === "escape") {
      onCloseCallback();
      return;
    }
  };

  const filterOptions = (options: string[]): string[] => {
    return options.filter((option) => option.trim() !== "");
  };

  useEffect(() => {
    if (autocompleteValue.value !== "" && autocompleteValue.value !== null && autocompleteValue.time !== 0) {
      onSelection?.(autocompleteValue.value, "edit");
    }
    // eslint-disable-next-line
  }, [autocompleteValue]);

  return (
    <div>
      <Popover
        open
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          onCloseCallback();
        }}
      >
        <Autocomplete
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          disablePortal
          open
          size="small"
          options={filterOptions(list)}
          value={defaultValue}
          onChange={onChange}
          onClose={onCloseAutocomplete}
          style={{ width: 450 }}
          renderInput={(params) => <TextField {...params} size="small" variant="outlined" autoFocus />}
          // renderOption={(option) => <HintRender option={option} />}
        />
      </Popover>
    </div>
  );
};

export default InputComboEditComponent;
