import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 5,
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  onClose: (data?: any) => void;
  handleSave: () => void;
  disabled: boolean;
}

const Buttons: FC<Props> = ({ onClose, handleSave, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
        Отмена
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        size="small"
        color="primary"
        onClick={handleSave}
        disabled={disabled}
      >
        Ок
      </Button>
    </div>
  );
};

export default Buttons;
