import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Event as EventType } from "types/event";
import { Group } from "types/queryBuilder";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { generateUuid } from "functions/common";

// components
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  fields: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 10,
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  event: EventType;
}

const UpdateEventDialog: FC<Props> = ({ open, onClose, event }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();
  const { filters: availableFilters } = useAvailableFilters("records");

  const [state, setState] = useState(event);
  const [filter, setFilter] = useState<Group>(defaultFilter);

  const { name, comment, isSendNotification } = state;
  const disabled = name.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSave = useCallback(() => {
    onClose({ ...state, filter: JSON.stringify(filter) });
  }, [onClose, state, filter]);

  useEffect(() => {
    setState(event);
    try {
      const filter: Group = JSON.parse(event.filter);
      setFilter(filter);
    } catch (err) {
      setFilter(defaultFilter);
    }
  }, [event, defaultFilter]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактировать контрольное событие</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth name="name" onChange={handleChange} value={name} />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Фильтр</Typography>
            <div className={classes.filter}>
              <div className={classes.input}>
                <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
              </div>
              <HistoryFilters setFilter={setFilter} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSendNotification}
                  onChange={handleChecked}
                  name="isSendNotification"
                  color="primary"
                />
              }
              label="Отправлять уведомления"
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSave}
                disabled={disabled}
              >
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateEventDialog;
