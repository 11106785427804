import React, { useRef, useEffect, useCallback, useState, MouseEvent, FC, useMemo } from "react";
import { normalizeTime } from "functions/common";
import { currTimeSubscriber } from "subscribers/PlayerSubscriber";
import { useDispatch, useSelector } from "react-redux";
import { Status } from "./types";
import { Keywords as IKeywords, Segment, Word } from "types";
import { RootState } from "redux/types";
import { setPlayerChannel, setZoom } from "redux/actions/settingsActions";
import clsx from "clsx";

// components
import LoopPlayback from "./components/LoopPlayback";
import Settings from "./components/Settings";
import Keywords from "./components/Keywords";

// icons
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import GetAppIcon from "@material-ui/icons/GetApp";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey, red } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import ChannelSelect from "./ChannelSelect";

const useStyles = makeStyles((theme) => ({
  root: {},
  controls: {
    marginRight: 10,
  },
  settings: {},
  waveform: {
    position: "relative",
    height: 166,
    backgroundColor: grey[100],
    overflowX: "scroll",
    overflowY: "hidden",
  },
  cursor: {
    position: "absolute",
    top: 0,
    width: 1,
    height: 150,
    zIndex: theme.zIndex.drawer + 10,
  },
  currentTime: {},
  duration: {},
  volume: {},
  download: {},
  link: {
    cursor: "default",
  },
  error: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.disabled,
    overflow: "hidden",
    fontSize: theme.typography.fontSize,
  },
  progress: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  liner: {
    width: 200,
  },
  audio: {
    display: "none",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {},
  zoomLevel: {
    margin: "0 3px",
  },
  mr10: {
    marginRight: 10,
  },
  time: {
    fontSize: 14,
    padding: "2px 8px",
    border: "1px solid " + grey[300],
    marginRight: 10,
    width: 102,
  },
  b1: {
    display: "flex",
    alignItems: "center",
  },
  b2: {
    display: "flex",
    alignItems: "center",
  },
  hide: {
    display: "none",
  },
  loadAudio: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(153, 153, 153, .3)",
  },
}));

const INTERVAL_TIMEOUT = 60;

interface ImageState {
  src: string | undefined;
  status: Status;
  error: Error | undefined;
}

interface Props {
  audioSrc: string;
  waveformSrc: string;
  waveformWidth: number;
  fileName: string;
  segments: Segment[];
  keywords: IKeywords[];
  recordChannel: number;
}

const AudioPlayer: FC<Props> = ({
  audioSrc,
  waveformSrc,
  fileName,
  segments,
  keywords,
  waveformWidth,
  recordChannel,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    playerPlayOnlyVoiceSegments,
    playerWaveformType,
    playerChannel,
    playerZoom: zoomLevel,
    editMode,
  } = useSelector((state: RootState) => state.settings);

  const audio = useRef<HTMLAudioElement>(null);
  const waveformRef = useRef<HTMLDivElement>(null);
  const intervalId = useRef<number>();

  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const [loopPlayback, setLoopPlayback] = useState({ loop: false, start: 0, end: 0 });

  const [imageState, setImageState] = useState<ImageState>({
    src: undefined,
    status: "Idle",
    error: undefined,
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const [audioHasError, setAudioHasError] = useState(false);
  const [audioCanPlay, setAudioCanPlay] = useState(false);

  const waveformContainerStyle = useMemo(
    () => ({
      width: zoomLevel === 1 ? waveformWidth : "100%",
    }),
    [zoomLevel, waveformWidth]
  );
  const currentPercentage = useMemo(() => (duration ? (progress / duration) * 100 : 0), [duration, progress]);
  const cursorStyle = useMemo(
    () => ({
      backgroundColor: playerWaveformType === "waveform" ? red[500] : "#FFF",
      left: (currentPercentage * waveformWidth) / 100,
    }),
    [playerWaveformType, currentPercentage, waveformWidth]
  );

  // массив слов
  const words = useMemo(() => {
    const words: Word[] = [];
    for (let i = 0; i < segments.length; i++) {
      segments[i].words
        .filter((w) => w.word !== "...")
        .forEach((w) => {
          // каждое слово начинается на 300мс раньше
          w.start -= 0.3;
          words.push(w);
        });
    }
    for (let i = 0; i < words.length; i++) {
      // текущее слово
      const word = words[i];
      // следующее слово
      const nextWord = words[i + 1];
      if (nextWord === undefined) break;
      // если пауза между словами меньше 2х секунд, то переход к следующему слову НЕ производить
      if (nextWord.start - word.start < 2) {
        // установить конец текущего слова, как начало следующего
        word.end = nextWord.start;
      }
    }
    return words;
  }, [segments]);

  // если текущее время в диапозоне начала и конца слова
  const isPlayWord = useCallback(
    (time) => {
      // если слов нет, считать весь файл одним словом
      // если стоит галка "Воспроизведение без пауз", дает возможность прослушивать файл
      if (words.length === 0) return true;
      for (let i = 0; i < words.length; i++) {
        const { start, end } = words[i];
        if (time >= start && time <= end) {
          return true;
        }
      }
      return false;
    },
    [words]
  );

  // получить время (в секундах) начало следующего сегмента
  const getNextWordStartTime = useCallback(
    (time) => {
      for (let i = 0; i < words.length; i++) {
        const { start, word } = words[i];
        if (word.startsWith("[")) continue;
        if (start > time) {
          return start;
        }
      }
      return undefined;
    },
    [words]
  );

  const seek = (time: number) => {
    if (audio && audio.current) {
      audio.current.currentTime = time;
      setProgress(time);
    }
  };

  // обработчик клика кнопки Play
  const play = useCallback(() => {
    if (audio && audio.current) {
      audio.current.play();
    }
  }, [audio]);

  // обработчик клика кнопки Pause
  const pause = useCallback(() => {
    if (audio && audio.current) {
      audio.current.pause();
    }
  }, [audio]);

  const followForTheCursor = () => {
    const waveform = document.querySelector(".app-waveform");
    const img = document.querySelector(".app-waveformImg");
    if (waveform && img && audio && audio.current) {
      const { currentTime, duration } = audio.current;
      const { width } = waveform.getBoundingClientRect();
      const { width: imgWidth } = img.getBoundingClientRect();
      const currentPercentage = (currentTime / duration) * 100;
      const left = (currentPercentage * imgWidth) / 100;
      waveform.scrollLeft = left - width / 2;
    }
  };

  // вызывается каждые INTERVAL_TIMEOUT
  const playInterval = useCallback(() => {
    if (!audio) return;
    if (!audio.current) return;

    const { currentTime } = audio.current;
    setProgress(currentTime);
    currTimeSubscriber.next(currentTime);

    // если активно "Воспроизводить без пауз"
    if (playerPlayOnlyVoiceSegments && !isPlayWord(currentTime)) {
      const nextWordStartTime = getNextWordStartTime(currentTime);
      nextWordStartTime !== undefined ? seek(nextWordStartTime) : seek(duration);
    }

    const { start, end, loop } = loopPlayback;
    // если активно "Зацикливание"
    if (loop) {
      // const currTime = wavesurferRef.current.getCurrentTime();
      if (currentTime > end) {
        seek(start);
      }
    }
    followForTheCursor();
  }, [audio, duration, getNextWordStartTime, loopPlayback, playerPlayOnlyVoiceSegments, isPlayWord]);

  // обработчик событие audio.play
  const onPlay = useCallback(() => {
    setIsPlaying(true);
    intervalId.current = window.setInterval(playInterval, INTERVAL_TIMEOUT);
  }, [playInterval]);

  // обработчик событие audio.pause
  const onPause = useCallback(() => {
    window.clearInterval(intervalId.current);
    setIsPlaying(false);
  }, []);

  const onEnded = useCallback(() => {
    window.clearInterval(intervalId.current);
    setIsPlaying(false);
    setProgress(0);
    if (waveformRef && waveformRef.current) {
      waveformRef.current.scrollLeft = 0;
    }
    if (audio && audio.current) {
      audio.current.currentTime = 0;
    }
  }, []);

  const onCanplaythrough = useCallback(() => {
    setAudioHasError(false);
    if (audio && audio.current) {
      setDuration(audio.current.duration);
      setAudioCanPlay(audio.current.readyState >= 2);
    }
  }, [audio]);

  const onError = useCallback(() => {
    setAudioHasError(true);
    setAudioCanPlay(false);
    setProgress(0);
    setDuration(0);

    if (audio && audio.current) {
      const { error } = audio.current;

      if (error) {
        let message = "";
        if (error.MEDIA_ERR_ABORTED === error.code) {
          message = "[Error]: ABORTED";
        }

        if (error.MEDIA_ERR_NETWORK === error.code) {
          message = "[Error]: NETWORK";
        }

        if (error.MEDIA_ERR_DECODE === error.code) {
          message = "[Error]: DECODE";
        }

        if (error.MEDIA_ERR_SRC_NOT_SUPPORTED === error.code) {
          message = "[Error]: SRC NOT SUPPORTED";
        }
        console.error(message);
      }
    }
  }, [audio]);

  const onLoadedmetadata = useCallback(() => {
    if (audio && audio.current) {
      audio.current.currentTime = progress;
      followForTheCursor();
    }
  }, [progress]);

  const onTimeUpdate = () => {};

  const handleWaveformClick = (event: MouseEvent<HTMLDivElement>) => {
    const { pageX } = event;
    const windowScrollX = window.scrollX;
    if (waveformRef && waveformRef.current && !audioHasError) {
      const { left } = waveformRef.current.getBoundingClientRect();
      const { scrollLeft } = waveformRef.current;
      const pos = pageX - windowScrollX - left + scrollLeft;
      const imgPercent = (100 / waveformWidth) * pos;
      const currentTime = duration ? (duration * imgPercent) / 100 : 0;
      seek(currentTime);
      currTimeSubscriber.next(currentTime);
    }
  };

  // play pause
  const playPause = useCallback(() => {
    isPlaying ? pause() : play();
  }, [isPlaying, play, pause]);

  // zoom in
  const zoomIn = useCallback(() => {
    dispatch(setZoom(zoomLevel * 2));
  }, [dispatch, zoomLevel]);

  // zoom out
  const zoomOut = useCallback(() => {
    dispatch(setZoom(zoomLevel === 1 ? 1 : zoomLevel / 2));
  }, [dispatch, zoomLevel]);

  // перейти к следующиму сегменту
  const nextSegment = useCallback(() => {
    if (segments.length === 0) return;
    // если текущее время находится в промежутке от начала до первого сегмента
    if (progress === 0 || progress < segments[0].start) {
      seek(segments[0].start);
      return;
    }
    for (let i = 0; i < segments.length; i++) {
      const { start } = segments[i];
      const end = segments[i + 1] ? segments[i + 1].start : duration;
      if (progress >= start && progress < end) {
        if (segments[i + 1]) {
          seek(segments[i + 1].start);
          followForTheCursor();
        }
        return;
      }
    }
  }, [duration, progress, segments]);

  // перейти к предыдущему сегменту
  const prevSegment = useCallback(() => {
    if (segments.length === 0) return;
    if (progress === 0) return;

    for (let i = segments.length - 1; i >= 0; i--) {
      const { end, start } = segments[i];
      if (end < progress) {
        seek(start);
        followForTheCursor();
        return;
      }
    }
  }, [progress, segments]);

  // перемотка вперед
  const seekForward = useCallback(
    (t = 3) => {
      const seekPosition = progress + t;
      seek(seekPosition >= duration ? duration : seekPosition);
      followForTheCursor();
    },
    [progress, duration]
  );

  // перемотка назад
  const seekBack = useCallback(
    (t = 3) => {
      const seekPosition = progress - t;
      seek(seekPosition <= 0 ? 0 : seekPosition);
      followForTheCursor();
    },
    [progress]
  );

  // скачать декодированный файл
  const download = useCallback(() => {
    fetch(audioSrc).then((res) => {
      res.blob().then((blob) => {
        let name = fileName;
        if (!name.endsWith(".wav")) {
          name += ".wav";
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
      });
    });
  }, [audioSrc, fileName]);

  // обработчик нажатия клавиш
  const handleKeyDown = useCallback(
    (event) => {
      if (document.activeElement?.tagName === "INPUT") return;
      if (editMode) return;
      const { key, repeat } = event;
      if (key === " " && !repeat) playPause();
      if (key === "ArrowRight") {
        nextSegment();
      }
      if (key === "ArrowLeft") {
        prevSegment();
      }
    },
    [playPause, nextSegment, prevSegment, editMode]
  );

  const setChannel = useCallback(
    (ch: -1 | 0 | 1) => {
      // если оба канала
      if (playerChannel === -1) {
        dispatch(setPlayerChannel(ch === 0 ? 1 : 0));
        return;
      }
      if (ch === 0 && playerChannel === 1) {
        dispatch(setPlayerChannel(-1));
        return;
      }
      if (ch === 1 && playerChannel === 0) {
        dispatch(setPlayerChannel(-1));
        return;
      }
      dispatch(setPlayerChannel(ch));
    },
    [playerChannel, dispatch]
  );

  const getErrorMessage = (error: Error | undefined) => {
    if (error === undefined) return "";
    let text = error.message;
    if (error.message === "Not Found") {
      text = "Аудиофайл не найден";
    }
    return "< " + text + " >";
  };

  // подписка и отписка на события аудио
  useEffect(() => {
    const node = audio.current;
    if (node) {
      node.addEventListener("canplaythrough", onCanplaythrough);
      node.addEventListener("timeupdate", onTimeUpdate);
      node.addEventListener("error", onError);
      node.addEventListener("play", onPlay);
      node.addEventListener("pause", onPause);
      node.addEventListener("ended", onEnded);
      node.addEventListener("loadedmetadata", onLoadedmetadata);
    }
    return () => {
      if (node) {
        node.removeEventListener("canplaythrough", onCanplaythrough);
        node.removeEventListener("timeupdate", onTimeUpdate);
        node.removeEventListener("error", onError);
        node.removeEventListener("play", onPlay);
        node.removeEventListener("pause", onPause);
        node.removeEventListener("ended", onEnded);
        node.removeEventListener("loadedmetadata", onLoadedmetadata);
      }
    };
  }, [onPlay, onPause, onError, onEnded, onLoadedmetadata, audioSrc, audio, onCanplaythrough, pause]);

  // подписка на событие смены текущего времени воспроизведения
  useEffect(() => {
    currTimeSubscriber.subscribe((time) => {
      if (!audio) return;
      if (!audio.current) return;
      if (audio.current.readyState !== 4) return;
      if (audio.current.currentTime === time) return;
      audio.current.currentTime = time;
      audio.current.play();
    });
  }, []);

  // загрузка осциллограммы
  useEffect(() => {
    setImageState(() => ({ src: undefined, status: "Loading", error: undefined }));
    const controller = new AbortController();
    fetch(waveformSrc, {
      signal: controller.signal,
    })
      .then((res: Response) => (res.ok ? res.blob() : res.json()))
      .then((data) => {
        if (data instanceof Blob) {
          const imgUrl = URL.createObjectURL(data);
          setImageState(() => ({ status: "Success", src: imgUrl, error: undefined }));
        } else {
          setImageState(() => ({ status: "Error", src: "", error: data }));
        }
      })
      .catch((error) => {
        setImageState(() => ({ status: "Error", src: "", error }));
        console.error("[AudioPlayer :: waveform] " + error);
      });
    return () => {
      controller.abort();
    };
  }, [waveformSrc]);

  useEffect(() => {
    setIsPlaying(false);
    window.clearInterval(intervalId.current);
  }, [audioSrc]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className={classes.root}>
      <audio ref={audio} className={classes.audio} src={audioSrc} />

      <div
        ref={waveformRef}
        onClick={handleWaveformClick}
        className={clsx(classes.waveform, "app-waveform")}
        style={waveformContainerStyle}
      >
        {imageState.src && (
          <img src={imageState.src} alt="waveform" className="app-waveformImg" onLoad={followForTheCursor} />
        )}
        {imageState.status === "Loading" && (
          <div className={classes.progress}>
            <LinearProgress className={classes.liner} />
          </div>
        )}
        {imageState.status === "Success" && <div className={classes.cursor} style={cursorStyle} />}
        {imageState.status === "Error" && <div className={classes.error}>{getErrorMessage(imageState.error)}</div>}
        {imageState.status !== "Loading" && imageState.status !== "Error" && (
          <Keywords keywords={keywords} waveformWidth={waveformWidth} duration={duration} />
        )}
      </div>

      <div className={classes.buttons}>
        <div className={classes.b1}>
          <div className={classes.time} title="Текущая позиция : Длительность сеанса">
            {`${normalizeTime(progress)} : ${normalizeTime(duration)}`}
          </div>
          <div>
            <ChannelSelect setChannel={setChannel} recordChannel={recordChannel} />
          </div>
          <IconButton
            onClick={() => seekBack()}
            className={classes.button}
            disabled={audioHasError}
            disableRipple
            color="primary"
            title="Текущая позиция - 3 сек."
          >
            <RotateLeftIcon />
          </IconButton>
          <IconButton
            onClick={playPause}
            className={classes.button}
            disabled={audioHasError || !audioCanPlay}
            disableRipple
            color="primary"
            title={isPlaying ? "Остановить воспроизведение" : "Воспроизвести"}
          >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <IconButton
            onClick={() => seekForward()}
            className={classes.button}
            disabled={audioHasError}
            disableRipple
            color="primary"
            title="Текущая позиция + 3 сек."
          >
            <RotateRightIcon />
          </IconButton>
          <IconButton
            onClick={zoomIn}
            className={classes.button}
            disabled={audioHasError}
            disableRipple
            color="primary"
            title="Увеличить масштаб"
          >
            <ZoomInIcon />
          </IconButton>
          <div className={clsx("app_zoom-level", classes.zoomLevel)} data-zoomlevel={zoomLevel}>
            {zoomLevel}
          </div>
          <IconButton
            onClick={zoomOut}
            className={classes.button}
            disabled={audioHasError}
            disableRipple
            color="primary"
            title="Уменьшить масштаб"
          >
            <ZoomOutIcon />
          </IconButton>
          <IconButton
            onClick={download}
            className={classes.button}
            disabled={audioHasError}
            disableRipple
            color="primary"
            title="Скачать декодированный файл"
          >
            <GetAppIcon />
          </IconButton>
        </div>
        <div className={classes.b2}>
          <LoopPlayback loopPlayback={loopPlayback} setLoopPlayback={setLoopPlayback} />
          <Settings />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
