import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Label } from "types/label";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { DialogContent, DialogTitle } from "@material-ui/core";
import {
  blue,
  cyan,
  deepPurple,
  green,
  grey,
  indigo,
  orange,
  pink,
  purple,
  red,
  yellow,
} from "@material-ui/core/colors";
import { ColorResult, TwitterPicker } from "react-color";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 15,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  button: {
    width: 100,
  },
  colorGrey: {
    color: grey[600],
  },
}));

const colors = [
  yellow[300],
  red[300],
  pink[300],
  purple[300],
  deepPurple[300],
  indigo[300],
  blue[300],
  cyan[300],
  orange[300],
  green[300],
];

interface Props {
  open: boolean;
  onClose: Function;
  label: Label;
}

const UpdateLabelDialog: FC<Props> = ({ open, onClose, label }) => {
  const classes = useStyles();

  const [state, setState] = useState(label);
  const { name, comment, color } = state;
  const disabled = name.length === 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeColor = (c: ColorResult) => {
    setState((prev) => ({
      ...prev,
      color: c.hex,
    }));
  };

  function handleSave() {
    onClose(state);
  }

  useEffect(() => {
    setState(label);
  }, [label]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Редактировать маркер</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth variant="standard" name="name" onChange={handleChange} value={name} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              value={comment}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.colorGrey} gutterBottom>
              Цвет фона
            </Typography>
            <TwitterPicker
              colors={colors}
              width="912px"
              color={color}
              onChangeComplete={handleChangeColor}
              triangle="hide"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLabelDialog;
