import {
  PageSettingsState,
  PageSettingsAction,
  PAGES_SET_SETTINGS,
  PAGE_RECORDS_SET_TABLE_PARAMS,
  PAGE_NAMESPACES_SET_TABLE_PARAMS,
  PAGE_TASKS_SET_TABLE_PARAMS,
  PAGE_DATASOURCES_SET_TABLE_PARAMS,
  PAGE_SOURCES_SET_TABLE_PARAMS,
  PAGE_FILTER_SET_TABLE_PARAMS,
  PAGE_EVENTS_SET_TABLE_PARAMS,
  PAGE_WORDS_SET_TABLE_PARAMS,
  PAGE_SPEAKERS_SET_TABLE_PARAMS,
  PAGE_OBJECTS_SET_TABLE_PARAMS,
  PAGE_LABELS_SET_TABLE_PARAMS,
  PAGE_LISTS_SET_TABLE_PARAMS,
  PAGE_BASE_STATIONS_SET_TABLE_PARAMS,
  PAGE_IP_ADDRESSES_SET_TABLE_PARAMS,
  PAGE_PHONE_NUMBERS_SET_TABLE_PARAMS,
} from "redux/types/pageSettings";
import SettingsService from "services/admin/SettingsService";
import { Key } from "types/settings";

export const defaultPageSettings: PageSettingsState = {
  records: {
    table: undefined,
  },
  namespaces: {
    table: undefined,
  },
  tasks: {
    table: undefined,
  },
  datasources: {
    table: undefined,
  },
  sources: {
    table: undefined,
  },
  filter: {
    table: undefined,
  },
  events: {
    table: undefined,
  },
  words: {
    table: undefined,
  },
  speakers: {
    table: undefined,
  },
  objects: {
    table: undefined,
  },
  labels: {
    table: undefined,
  },
  lists: {
    table: undefined,
  },
  baseStations: {
    table: undefined,
  },
  ipAddresses: {
    table: undefined,
  },
  phoneNumbers: {
    table: undefined,
  },
  nbsCategory: {
    table: undefined,
  },
};

function save(s: PageSettingsState) {
  SettingsService.setByKye({ key: Key.PAGE_SETTINGS, value: JSON.stringify(s) }).catch(console.error);
}

export default (state = defaultPageSettings, action: PageSettingsAction) => {
  const { type, payload } = action;

  if (type === PAGES_SET_SETTINGS) {
    return { ...defaultPageSettings, ...payload };
  }

  if (type === PAGE_RECORDS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      records: {
        ...state.records,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_NAMESPACES_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      namespaces: {
        ...state.namespaces,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_TASKS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      tasks: {
        ...state.tasks,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_DATASOURCES_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      datasources: {
        ...state.datasources,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_SOURCES_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      sources: {
        ...state.sources,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_FILTER_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      filter: {
        ...state.filter,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_EVENTS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      events: {
        ...state.events,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_WORDS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      words: {
        ...state.words,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_SPEAKERS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      speakers: {
        ...state.speakers,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_OBJECTS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      objects: {
        ...state.objects,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_LABELS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      labels: {
        ...state.labels,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_LISTS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      lists: {
        ...state.lists,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_BASE_STATIONS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      baseStations: {
        ...state.baseStations,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_IP_ADDRESSES_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      ipAddresses: {
        ...state.ipAddresses,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  if (type === PAGE_PHONE_NUMBERS_SET_TABLE_PARAMS) {
    const settings: PageSettingsState = {
      ...state,
      phoneNumbers: {
        ...state.phoneNumbers,
        table: payload,
      },
    };
    save(settings);
    return settings;
  }

  return state;
};
