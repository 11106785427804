import React, { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { AdminTech, AdminTechOCR } from "types/admin";
import { TaskTechOcr, TechType } from "types/task";
import SelectLanguage from "components/SelectLanguages";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(() => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  onClose: Function;
  tech: TaskTechOcr;
  open: boolean;
  adminTechs: AdminTech[];
}

const Ocr: FC<Props> = ({ onClose, tech, open, adminTechs }) => {
  const classes = useStyles();

  const [state, setState] = useState(tech);

  const availableLanguages = useMemo(() => {
    const tech = adminTechs.find((el) => el.techDetail.type === TechType.OCR);
    if (tech === undefined) return;
    const techDetail = tech.techDetail as AdminTechOCR;
    return techDetail.languages;
  }, [adminTechs]);

  const setLang = (lang: string) => {
    setState((prev) => ({ ...prev, lang }));
  };

  useEffect(() => {
    setState(tech);
  }, [tech]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Настройка</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectLanguage
              selectedLanguage={state.lang}
              setSelectedLanguage={setLang}
              availableLanguages={availableLanguages}
            />
          </Grid>
        </Grid>

        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => onClose(state)}
          >
            Ок
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Ocr;
