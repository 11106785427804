import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(4, 1),
    fontSize: theme.typography.fontSize + 2,
  },
  button: {
    width: 100,
  },
}));

interface Props {
  open: boolean;
  onClose: (confirm: boolean) => void;
  message?: string;
}

const ConfirmationDialog: FC<Props> = ({ open, onClose, message }) => {
  const classes = useStyles();
  const title = message ?? "Удалить выбранные элементы?";

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="sm" fullWidth open={open}>
      <DialogContent>
        <div className={classes.body}>{title}</div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          onClick={() => onClose(false)}
          color="primary"
          className={classes.button}
        >
          Отмена
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => onClose(true)}
          color="primary"
          className={classes.button}
        >
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
