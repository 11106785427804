import { useCallback, useEffect, useState } from "react";
import { Source } from "types";
import SourceService from "services/SourceService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  sources: Source[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  sources: [],
  error: undefined,
};

const useSources = () => {
  const [state, setState] = useState<State>(initialState);

  const getSources = useCallback(() => {
    setState((prev) => ({ ...prev, loading: true }));
    SourceService.getAll()
      .then(({ data }) => {
        setState(() => ({ sources: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ sources: [], loading: false, error: err }));
      });
  }, []);

  useEffect(() => {
    getSources();
  }, [getSources]);

  return { ...state, getSources };
};

export default useSources;
