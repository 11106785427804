import { ProcessStatus } from "./common";
import { Message } from "./message";
import { Record } from "./record";

export type WsData =
  | WsDataTypeSystem
  | WsDataTypePush
  | WsDataTypeAssistant
  | WsDataTypeSpeaker
  | WsDataTypeNewMessage
  | WsDataTypeRecord
  | WsDataTypeSystemErrorMessage;

export enum WsDataType {
  SYSTEM = "system",
  PUSH = "push",
  ASSISTANT = "assistant",
  RECORD_UPDATE_STATUS = "recordUpdateStatus",
  SPEAKER_UPDATE_STATUS = "speakerUpdateStatus",
  MESSAGE_NEW = "messageNew",
  SYSTEM_ERROR_MESSAGE = "systemErrorMessage",
}

export interface WsDataTypeSystemErrorMessage {
  type: WsDataType.SYSTEM_ERROR_MESSAGE;
  data: {
    message: string;
  };
}

export interface WsDataTypeSystem {
  type: WsDataType.SYSTEM;
  data: string;
}

export interface WsDataTypePush {
  type: WsDataType.PUSH;
  data: string;
}

export interface WsDataTypeAssistant {
  type: WsDataType.ASSISTANT;
  data: string;
}

export interface WsDataTypeRecord {
  type: WsDataType.RECORD_UPDATE_STATUS;
  data: Record | undefined;
}

export interface WsDataTypeSpeaker {
  type: WsDataType.SPEAKER_UPDATE_STATUS;
  data: {
    id: number;
    status: ProcessStatus;
    message: string;
    models: {
      id: number;
      status: ProcessStatus;
      message: string;
    }[];
    files: {
      id: number;
      status: ProcessStatus;
      message: string;
    }[];
  };
}

export interface WsDataTypeNewMessage {
  type: WsDataType.MESSAGE_NEW;
  data: { count: number; message: Message | undefined };
}
