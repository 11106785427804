import { AxiosResponse } from "axios";
import { LidModel } from "types/admin/lidModel";
import { admin } from "http/urls";
import api from "http/api";

const { lidModels } = admin;

function LidModelService() {
  function getAll(): Promise<AxiosResponse<LidModel[]>> {
    return api.get<LidModel[]>(lidModels.getAll);
  }

  function create(body: any): Promise<AxiosResponse<LidModel>> {
    return api.post(lidModels.create, body);
  }

  function update(body: LidModel): Promise<AxiosResponse<LidModel>> {
    return api.put(lidModels.update, body);
  }

  function remove(ids: string): Promise<AxiosResponse> {
    const url = lidModels.remove.replace(":ids", ids);
    return api.delete(url);
  }

  return Object.freeze({
    getAll,
    create,
    update,
    remove,
  });
}

export default LidModelService();
