import React, { FC, useMemo } from "react";
import { API_URL, records } from "../http/urls";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../redux/types";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
  },
  button: {
    color: "white",
  },
}));

const DownloadRO: FC = () => {
  const classes = useStyles();
  const { accessToken } = useSelector((state: RootState) => state.auth);

  const url = useMemo(() => API_URL + records.ro + `?authorization=${accessToken}`, [accessToken]);

  return (
    <a className={classes.link} href={url} target="_blank" rel="noopener noreferrer">
      <IconButton className={classes.button} title="Открыть руководство оператора">
        <HelpOutlineIcon fontSize="inherit" />
      </IconButton>
    </a>
  );
};

export default DownloadRO;
