import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import { Setting } from "types/admin";
import { RootState } from "redux/types";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch, useSelector } from "react-redux";
import SettingsService from "services/admin/SettingsService";
import { addRow, removeRows, updateRow } from "components/agGrid/functions";
import useSettings from "hooks/admin/useSettings";
import useSettingsCount from "hooks/admin/useSettingsCount";

// components
import SettingsTable from "./components/SettingsTable";
import CreateSettingDialog from "./components/CreateSettingDialog";
import UpdateSettingDialog from "./components/UpdateSettingDialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import PaginationPanel from "components/pagination/PaginationPanel";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
  mr10: {
    marginRight: 10,
  },
}));

const Settings: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { rowsPerPage: perPage } = useSelector((state: RootState) => state.settings);

  const [offset, setOffset] = useState(0);
  const [queryString] = useState("");

  const { count } = useSettingsCount(queryString);
  const { rowData, getSettings } = useSettings(offset, perPage, queryString);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Setting[]>([]);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const catchError = (err: any) => {
    dispatch(showErrorAlert(err.message));
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data) {
      const body: any = {
        userId: Number(data.userId),
        key: data.key,
        value: data.value,
      };
      SettingsService.createOrUpdate(body)
        .then((res) => {
          addRow(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseUpdateDialog = (data?: any) => {
    setOpenUpdateDialog(false);
    if (data) {
      const body: any = {
        userId: Number(data.userId),
        key: data.key,
        value: data.value,
      };
      SettingsService.createOrUpdate(body)
        .then((res) => {
          updateRow(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows.map((el) => el.id).join(",");
      SettingsService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [rowData]);

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.buttons}>
          <div>
            <Button className={classes.mr10} color="primary" size="small" variant="contained" onClick={getSettings}>
              Обновить
            </Button>
            <TextField
              className={classes.search}
              placeholder="ID пользователя"
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Удалить
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => setOpenCreateDialog(true)}
            >
              Создать
            </Button>
          </div>
        </div>
      </div>

      <SettingsTable rowData={rowData} setGridApi={setGridApi} onSelection={onSelection} />

      <div className={classes.pagination}>
        <PaginationPanel countSelected={selectedRows.length} countTotal={count} setOffset={setOffset} />
      </div>

      <CreateSettingDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
      {selectedRows.length === 1 && (
        <UpdateSettingDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} setting={selectedRows[0]} />
      )}
    </Paper>
  );
};

export default Settings;
