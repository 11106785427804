import React, { ChangeEvent, FC } from "react";
import {
  defaultFolderSftp,
  defaultFolderSmb,
  FolderType,
  SourceDetail,
  SourceDetailSharedFolder,
  FolderSmb as FolderSmbType,
  SmbVersion,
} from "types/source";
import { FileFormatDetail } from "types/admin";
import { Group } from "types/queryBuilder";
import useAvailableFilters from "hooks/admin/useAvailableFilters";

// components
import SelectStorage from "components/SelectStorage";
import SelectFileFormat from "../SelectFileFormat";
import SleepSec from "../SleepSec";
import SourceFilter from "../SourceFilter";
import SelectProtocol from "../SelectProtocol";

// material ui
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";

interface Props {
  state: SourceDetailSharedFolder;
  setState: (item: SourceDetail) => void;
  disable: boolean;
}

const SharedFolder: FC<Props> = ({ state, setState, disable }) => {
  const { filters: availableFilters } = useAvailableFilters("records");
  const { folderDetail, fileFormatDetail, sleepSec, deleteFilter } = state;

  const setStorageId = (id: number) => {
    setState({ ...state, storageId: id });
  };

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setState({ ...state, [name]: checked });
  };

  const setSleepSec = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState({ ...state, sleepSec: Number(value) });
  };

  const handleChangeFolderDetailType = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    if (value === FolderType.SFTP) {
      setState({ ...state, folderDetail: defaultFolderSftp });
    }

    if (value === FolderType.SMB) {
      setState({ ...state, folderDetail: defaultFolderSmb });
    }
  };

  const handleChangeSmbVersion = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    const folderDetail: FolderSmbType = {
      ...(state.folderDetail as FolderSmbType),
      smbVersion: value as SmbVersion,
    };
    setState({ ...state, folderDetail });
  };

  const onChangeTextFolderDetail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = event.target;
    setState({ ...state, folderDetail: { ...state.folderDetail, [name]: value } });
  };

  const setFileFormatDetail = (fileFormatDetail: FileFormatDetail) => {
    setState({ ...state, fileFormatDetail });
  };

  const setDeleteFilter = (f: Group) => {
    setState({ ...state, deleteFilter: JSON.stringify(f) });
  };

  const setPathValue = (value: string, name?: string) => {
    const pathName = name === undefined ? "path" : name;
    setState({ ...state, folderDetail: { ...state.folderDetail, [pathName]: value } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectProtocol
          disabled={disable}
          folderDetail={folderDetail}
          onChangeConnectionType={handleChangeFolderDetailType}
          onChangeSmbVersion={handleChangeSmbVersion}
          onChangeInputElement={onChangeTextFolderDetail}
          setPathValue={setPathValue}
        />
      </Grid>

      <Grid item xs={12}>
        <SourceFilter
          title="Исключить данные по фильтру"
          filter={deleteFilter}
          setFilter={setDeleteFilter}
          availableFilters={availableFilters}
        />
      </Grid>

      <Grid item xs={12}>
        <SleepSec sleepSec={sleepSec} setSleepSec={setSleepSec} />
      </Grid>

      <Grid item xs={12}>
        <SelectFileFormat fileFormatDetail={fileFormatDetail} setFileFormatDetail={setFileFormatDetail} />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disable}
              checked={state.isEchFolderInNewNamespace}
              onChange={handleChecked}
              name="isEchFolderInNewNamespace"
              color="primary"
            />
          }
          label="Загружать каждую папку в отдельное пространство"
        />
      </Grid>

      <Fade in={state.isEchFolderInNewNamespace} mountOnEnter unmountOnExit>
        <Grid item xs={12}>
          <SelectStorage disabled={disable} storageId={state.storageId} setStorageId={setStorageId} />
        </Grid>
      </Fade>
    </Grid>
  );
};

export default SharedFolder;
