import React, { useState, ChangeEvent, FC, useEffect } from "react";
import usePublicPassports from "hooks/usePublicPassports";
import { Format } from "types/admin";

// material ui
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

interface Props {
  setFileFormat: Function;
  selectedPassportType?: Format;
}

// выбрать формат загружемых файлов
const SelectPassport: FC<Props> = ({ setFileFormat, selectedPassportType }) => {
  const { passports } = usePublicPassports();
  const [passportId, setPassportID] = useState("");

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setPassportID(value as string);
    const passport = passports.find((p) => p.id === Number(value));
    if (passport !== undefined) {
      setFileFormat(passport.detail);
    }
  };

  useEffect(() => {
    // если формат был передан
    if (selectedPassportType !== undefined) {
      const p = passports.find((p) => p.detail.type === selectedPassportType);
      if (p) {
        setPassportID(String(p.id));
      }
    }

    // если формат не передан, устанавливаем формат "Файлы", если он есть
    if (selectedPassportType === undefined) {
      const p = passports.find((p) => p.detail.type === "files");
      if (p) {
        setPassportID(String(p.id));
        setFileFormat(p.detail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPassportType, passports]);

  return (
    <FormControl fullWidth>
      <InputLabel id="passport-select">Формат данных</InputLabel>
      <Select labelId="passport-select" value={passportId} onChange={handleChange} fullWidth>
        {passports.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectPassport;
