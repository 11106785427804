import { AxiosResponse } from "axios";
import { AdminTech } from "types/admin";
import { admin } from "http/urls";
import api from "http/api";

export interface BodySpace {
  id: string;
  isActive: boolean;
}

const { techs: route } = admin;

function TechService() {
  function getAllProtected(): Promise<AxiosResponse<AdminTech[]>> {
    return api.get<AdminTech[]>(route.Protected.getAll);
  }

  function getTechByName(name: string): Promise<AxiosResponse<AdminTech>> {
    return api.get<AdminTech>(route.getTech.replace(":tech", name));
  }

  function updateProtected(body: BodySpace): Promise<AxiosResponse> {
    return api.put(route.Protected.update, body);
  }

  function getAll(): Promise<AxiosResponse<AdminTech[]>> {
    return api.get<AdminTech[]>(route.getAll);
  }

  return Object.freeze({
    getAll,
    getTechByName,
    getAllProtected,
    updateProtected,
  });
}

export default TechService();
