import React, { FC } from "react";
import { ExportTask, Task, TaskDetailType } from "types";
import EditTaskDialog from "./EditTaskDialog";
import EditExportTaskDialog from "./EditExportTaskDialog";

interface Props {
  open: boolean;
  onCloseTaskEdit: Function;
  onCloseExportTaskEdit: Function;
  task: Task | ExportTask;
}

const UpdateTaskDialog: FC<Props> = ({ open, onCloseTaskEdit, onCloseExportTaskEdit, task }) => {
  const { type } = task.taskDetail;

  if (type === TaskDetailType.Process) {
    return <EditTaskDialog open={open} onClose={onCloseTaskEdit} task={task as Task} />;
  }

  if (type === TaskDetailType.Export) {
    return <EditExportTaskDialog open={open} onClose={onCloseExportTaskEdit} task={task as ExportTask} />;
  }

  return null;
};

export default UpdateTaskDialog;
