import { Lang } from "types/task";
import { AvailableSource } from "types/source";

export const SET_SYSTEM_SOURCES = "SET_SYSTEM_SOURCES";
export const SET_SYSTEM_LID = "SET_SYSTEM_LID";
export const SET_SYSTEM_SERVICES = "SET_SYSTEM_SERVICES";
export const SHOW_SYSTEM_ERROR = "SHOW_SYSTEM_ERROR";
export const HIDE_SYSTEM_ERROR = "HIDE_SYSTEM_ERROR";

export interface SystemService {
  id: string;
  name: string;
  host: string;
}

export interface SystemState {
  sources: AvailableSource[];
  lidLanguages: Lang[];
  services: SystemService[];
  error: {
    display: boolean;
    message: string;
  };
}

export type SystemAction =
  | { type: typeof SHOW_SYSTEM_ERROR; payload: string }
  | { type: typeof HIDE_SYSTEM_ERROR; payload: any }
  | { type: typeof SET_SYSTEM_SOURCES; payload: AvailableSource[] }
  | { type: typeof SET_SYSTEM_SERVICES; payload: SystemService[] }
  | { type: typeof SET_SYSTEM_LID; payload: Lang[] };
