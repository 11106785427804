import React, { FC, useState, MouseEvent, useEffect, useMemo } from "react";
import { logout as logoutAction } from "redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { SystemService } from "redux/types/systemTypes";
import { routes } from "routes";
import { useLocation, useHistory } from "react-router-dom";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  container: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  name: {
    paddingLeft: 10,
    fontSize: theme.typography.fontSize * 1.3,
    // color: "inherit",
  },
  a: {
    flex: 1,
    color: "inherit",
    textDecoration: "none",
  },
}));

const User: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { auth, system } = useSelector((state: RootState) => state);
  const { id, login, isAdmin } = auth;
  const { services } = system;

  const src = useMemo(() => {
    if (login === "kem") return "/img/kem.jpeg";
    if (login === "odd") return "/img/odd.jpeg";

    return undefined;
  }, [login]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [personalAriaUrl, setPersonalAriaUrl] = useState("");

  const isAdminSpace = location.pathname.includes("/admin");

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  const redirectToAdminSpace = () => {
    history.push(routes.admin.path);
  };

  const redirectToUserSpace = () => {
    history.push(routes.entry);
  };

  useEffect(() => {
    services.forEach((el: SystemService) => {
      if (el.id === "auth") {
        setPersonalAriaUrl(el.host);
      }
    });
  }, [services]);

  if (id === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container} onClick={handleClick}>
        <Avatar src={src}>{login.length > 0 ? login[0].toUpperCase() : ""}</Avatar>
        <div className={classes.name}>{login}</div>
      </div>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {personalAriaUrl !== "" && (
          <MenuItem>
            <a href={personalAriaUrl} target="_blank" rel="noopener noreferrer" className={classes.a}>
              Личный кабинет
            </a>
          </MenuItem>
        )}
        {isAdmin && !isAdminSpace && <MenuItem onClick={redirectToAdminSpace}>Администратор</MenuItem>}
        {isAdmin && isAdminSpace && <MenuItem onClick={redirectToUserSpace}>Оператор</MenuItem>}
        <Divider />
        <MenuItem onClick={logout}>Выход</MenuItem>
      </Menu>
    </div>
  );
};

export default User;
