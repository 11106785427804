import React, { FC, useState } from "react";

// components
import Editor from "./Editor";
import NoFile from "./NoFile";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PublishIcon from "@material-ui/icons/Publish";

const useStyles = makeStyles(() => ({
  root: {
    width: 1280,
    height: 340,
    padding: 20,
    // border: "1px dashed",
    position: "relative",
    margin: "0 auto",
  },
  fileUpload: {
    position: "absolute",
    left: 10,
    bottom: 10,
  },
  input: {
    display: "none",
  },
}));

interface Props {
  onClose: (data?: any) => void;
  f: File | string | undefined;
}

const AudioEditor: FC<Props> = ({ onClose, f }) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | string | undefined>(f);

  const handleFileUpload = (file: File) => {
    setFile(file);
  };

  return (
    <div className={classes.root}>
      {file ? <Editor file={file} onClose={onClose} /> : <NoFile />}

      <div className={classes.fileUpload}>
        <input
          className={classes.input}
          id="icon-button-file"
          type="file"
          accept="audio/*"
          multiple={false}
          onChange={(e) => handleFileUpload(e.target.files![0])}
        />

        <label htmlFor="icon-button-file">
          <IconButton color="primary" component="span">
            <PublishIcon />
          </IconButton>
        </label>
      </div>
    </div>
  );
};

export default AudioEditor;
