import React, { FC, useEffect, useState } from "react";
import { Transcription as Tr } from "types/admin/lmModel";
import Keyboard from "./Keyboard";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    paddingRight: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  transcription: {
    height: 40,
    padding: 5,
    backgroundColor: "#f6f6ee",
  },
}));

interface Props {
  open: boolean;
  onClose: (w?: Tr) => void;
  word: Tr;
  lang: string;
}

const EditTranscription: FC<Props> = ({ word, open, onClose, lang }) => {
  const classes = useStyles();

  const [transcription, setTranscription] = useState<string[]>([]);

  const handleSave = () => {
    onClose({ ...word, transcription: transcription.join(" ") });
  };

  const onKeyClick = (v: string) => {
    if (v === "delete") {
      setTranscription((prev) => prev.slice(0, -1));
      return;
    }
    setTranscription((prev) => [...prev, v]);
  };

  useEffect(() => {
    setTranscription(word.transcription.split(" "));
  }, [word]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактировать транскрипцию</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.transcription}>
              {transcription.join(" ")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Keyboard lang={lang} onClick={onKeyClick} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={transcription.length === 0}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EditTranscription;
