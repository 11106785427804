import React, { ChangeEvent, FC, useState } from "react";
import { Module } from "types/admin";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(() => ({
  root: {},
  body: {
    padding: "0 15px 15px 15px",
    position: "relative",
  },
  inputWrapper: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
  },
  input: {},
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  select: {
    width: 200,
  },
  title: {
    padding: "15px 15px 5px 15px",
  },
}));

interface Props {
  open: boolean;
  onClose: (module?: Module) => void;
  module: Module;
}

const EditModuleDialog: FC<Props> = ({ open, onClose, module }) => {
  const classes = useStyles();

  const [state, setState] = useState(module);
  const { name, isActive } = state;
  const disabled = name.length === 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setState((prev) => ({ ...prev, isActive: checked }));
  };

  function handleSave() {
    onClose(state);
  }

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <div className={classes.title}>
        <Typography variant="h6">Редактировать</Typography>
      </div>

      <div className={classes.body}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Имя"
              fullWidth
              variant="standard"
              name="name"
              onChange={handleChange}
              value={name}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isActive} onChange={handleChecked} name="checkedB" color="primary" />}
              label="Активен"
            />
          </Grid>
        </Grid>

        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditModuleDialog;
