import React, { ChangeEvent, FC, useCallback, useEffect, useState, useRef } from "react";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import useWords from "hooks/useWords";
import WordService from "services/WordService";
import { Word } from "types/word";
import { useDispatch } from "react-redux";
import {
  addRow,
  addRows,
  removeRows,
  updateRow,
  getRowsToRemoveFromIds,
  getUniqueIdsDownToHierarchy,
  updateColumnOrder,
} from "components/agGrid/functions";
import { showErrorAlert } from "redux/actions/alertActions";
import { setPageWordsTableSettings } from "redux/actions/pageSettingsActions";

// components
import WordsTable from "./components/WordsTable";
import CreateWordDialog from "./components/CreateWordDialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import UpdateWordDialog from "./components/UpdateWordDialog";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";
import ShowWordsDialog from "./components/ShowWordsDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  fileUpload: {},
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: 10,
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchWrapper: {
    flexGrow: 1,
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
  upload: {
    display: "none",
  },
  mr10: {
    marginRight: 10,
  },
}));

const Words: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { words: rowData, error } = useWords();

  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedRows, setSelectedRows] = useState<Word[]>([]);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);
  const [openShowWordsDialog, setOpenShowWordsDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageWordsTableSettings(colState));
    }
  };

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      if (!gridApi) {
        return;
      }

      const ids: number[] = getUniqueIdsDownToHierarchy(selectedRows, gridApi);
      const idsStrings = ids.join(",");

      const rowsToRemove: Word[] = getRowsToRemoveFromIds(ids, gridApi);

      WordService.remove(idsStrings)
        .then(() => {
          removeRows(rowsToRemove, gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseImportDialog = (confirm: boolean) => {
    setOpenImportDialog(false);
    if (confirm) {
      if (!gridApi) {
        return;
      }

      const ids: number[] = getUniqueIdsDownToHierarchy(selectedRows, gridApi);
      const idsStrings = ids.join(",");

      WordService.download(idsStrings)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "words.zip");
          document.body.appendChild(link);
          link.click();
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseCreateDialog = (data?: Word) => {
    setOpenCreateDialog(false);
    if (data) {
      WordService.create(data)
        .then((res) => {
          addRow(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseUpdateDialog = (data?: Word) => {
    setOpenUpdateDialog(false);
    if (data) {
      WordService.update(data)
        .then((res) => {
          updateRow(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const clearFileInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const sendWordsFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files !== null) {
      let group = "";
      if (selectedRows.length === 1) {
        group = selectedRows[0].group;
      }

      const formData = new FormData();
      formData.append("group", group);
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      clearFileInput();
      WordService.upload(formData)
        .then((res) => {
          addRows(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [catchError, error]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.buttons}>
          <IconButton
            className={classes.mr10}
            onClick={() => setOpenSettingsTableDialog(true)}
            title="Настройка колонок таблицы"
            size="small"
          >
            <GridOnIcon fontSize="inherit" />
          </IconButton>
          <div className={classes.searchWrapper}>
            <TextField
              className={classes.search}
              placeholder="Поиск..."
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenImportDialog(true)}
              disabled={selectedRows.length === 0}
            >
              Экспорт
            </Button>
            <input
              onChange={sendWordsFiles}
              className={classes.upload}
              id="icon-button-wordsFiles"
              type="file"
              multiple
              ref={inputRef}
              accept=".zip,.txt"
              disabled={selectedRows.length > 1}
            />
            <label htmlFor="icon-button-wordsFiles">
              <Button color="primary" component="span" size="small" disabled={selectedRows.length > 1}>
                Импорт
              </Button>
            </label>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={selectedRows.length === 0}
            >
              Удалить
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenShowWordsDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Просмотреть
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              disabled={selectedRows.length > 1}
              onClick={() => setOpenCreateDialog(true)}
            >
              Создать
            </Button>
          </div>
        </div>
      </div>

      <WordsTable
        rowData={rowData}
        setGridApi={setGridApi}
        setColumnApi={setColumnApi}
        onSelection={onSelection}
        height="calc(100vh - 151px)"
      />

      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
      <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />
      <ConfirmationDialog
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        message={"Экспортировать выбранные элементы?"}
      />
      <CreateWordDialog
        open={openCreateDialog}
        onClose={handleCloseCreateDialog}
        path={selectedRows.length !== 0 ? selectedRows[0].group : ""}
      />

      {selectedRows.length === 1 && (
        <UpdateWordDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} item={selectedRows[0]} />
      )}

      {selectedRows.length === 1 && (
        <ShowWordsDialog
          open={openShowWordsDialog}
          onClose={() => setOpenShowWordsDialog(false)}
          words={selectedRows[0].words}
        />
      )}
    </Paper>
  );
};

export default Words;
