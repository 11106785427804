import React, { FC } from "react";
import { LmModel } from "types/admin/lmModel";
import { TechType } from "types/task";
import { AdminTech } from "types/admin/tech";

// components
import SidTable from "./techs/SidTable";
import Lid from "./techs/Lid";
import Stt from "./techs/Stt";
import Ocr from "./techs/Ocr";
import Vad from "./techs/Vad";
import Diarization from "./techs/Diarization";
import Translate from "./techs/Translate";

interface Props {
  open: boolean;
  onClose: Function;
  tech: any;
  adminTechs: AdminTech[];
  lmModels: LmModel[];
}

const EditTaskDialog: FC<Props> = ({ open, onClose, tech, adminTechs, lmModels }) => {
  const techType: TechType = tech.type;

  if (techType === TechType.OCR) {
    return <Ocr onClose={onClose} tech={tech} open={open} adminTechs={adminTechs} />;
  }

  if (techType === TechType.STT) {
    return <Stt open={open} onClose={onClose} adminTechs={adminTechs} tech={tech} lmModels={lmModels} />;
  }

  if (techType === TechType.SID) {
    // return <SidList open={open} onClose={onClose} tech={tech} />;
    return <SidTable open={open} onClose={onClose} tech={tech} />;
  }

  if (techType === TechType.LID) {
    return <Lid open={open} onClose={onClose} adminTechs={adminTechs} tech={tech} />;
  }

  if (techType === TechType.VAD) {
    return <Vad open={open} onClose={onClose} tech={tech} />;
  }

  if (techType === TechType.DIARIZATION) {
    return <Diarization open={open} onClose={onClose} tech={tech} />;
  }

  if (techType === TechType.TRANSLATE) {
    return <Translate open={open} onClose={onClose} tech={tech} />;
  }

  return null;
};

export default EditTaskDialog;
