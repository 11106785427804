import React, { FC } from "react";
import { Source } from "types/source";
import { Group } from "types/queryBuilder";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

const SourceNameRenderer: FC<{ value: string; data: Source }> = ({ value, data }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    const { path } = routes.results;

    const group: Group = {
      type: "group",
      uuid: "1",
      operation: "AND",
      filters: [{ type: "filter", uuid: "2", filter: "Пространства", condition: "=", value: String(data.nameSpaceId) }],
    };

    const url = path + "?f=" + JSON.stringify(group);
    history.push(encodeURI(url));
  };

  return (
    <span className={classes.root} onClick={handleClick}>
      {value}
    </span>
  );
};

export default SourceNameRenderer;
