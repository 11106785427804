import { AxiosResponse } from "axios";
import { Setting } from "types/admin";
import { admin } from "http/urls";
import api from "http/api";

const { settings: route } = admin;

function SettingsService() {
  function getAll(offset: number, count: number, queryString: string): Promise<AxiosResponse<Setting[]>> {
    const url = route.getAll.replace(":offset", String(offset)).replace(":count", String(count));
    return api.get<Setting[]>(url + queryString);
  }

  function getCount(queryString: string) {
    return api.get(route.count + queryString);
  }

  function remove(ids: string) {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  function createOrUpdate(body: Setting) {
    return api.post(route.createOrUpdate, body);
  }

  function getByKye(body: { key: string }) {
    return api.post(route.getByKey, body);
  }

  function setByKye(body: { key: string; value: string }) {
    return api.post(route.setByKey, body);
  }

  return Object.freeze({
    getAll,
    getCount,
    remove,
    createOrUpdate,
    getByKye,
    setByKye,
  });
}

export default SettingsService();
