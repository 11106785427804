import {
  AlertActions,
  AlertState,
  HIDE_ALERT,
  SHOW_ERROR_ALERT,
  SHOW_SUCCESS_ALERT,
  SHOW_WARNING_ALERT,
} from "redux/types/alertTypes";

const initialState: AlertState = {
  open: false,
  severity: "error",
  title: "Ошибка",
  message: "",
};

function alertReducer(state = initialState, actions: AlertActions) {
  const { type } = actions;

  if (type === SHOW_SUCCESS_ALERT) {
    return {
      title: "Успешно",
      severity: "success",
      open: true,
      message: actions.payload,
    };
  }

  if (type === SHOW_ERROR_ALERT) {
    return {
      title: "Ошибка",
      severity: "error",
      open: true,
      message: actions.payload,
    };
  }

  if (type === SHOW_WARNING_ALERT) {
    return {
      title: "Внимание",
      severity: "warning",
      open: true,
      message: actions.payload,
    };
  }

  if (type === HIDE_ALERT) {
    return initialState;
  }

  return state;
}

export default alertReducer;
