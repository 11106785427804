import React, { FC, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import localization from "components/agGrid/localization";
import clsx from "clsx";
import { Transcription as Tr } from "types/admin/lmModel";
import CreateTranscriptionDialog from "./CreateTranscriptionDialog";
import EditTranscription from "./EditTranscription";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  root: {},
  table: {
    height: 240,
  },
  buttons: {
    textAlign: "right",
    marginBottom: 10,
  },
  button: {
    margin: "0 5px",
  },
  generate: {
    width: 145,
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
  flex: 1,
};

interface Props {
  transcriptions: Tr[];
  addTranscriptions: (t: Tr) => void;
  updateTranscriptions: (t: Tr) => void;
  removeTranscriptions: (t: Tr[]) => void;
  disabled: boolean;
  lang: string;
  generateTranscription: () => void;
  loading: boolean;
}

const Transcriptions: FC<Props> = ({
  disabled,
  transcriptions,
  addTranscriptions,
  updateTranscriptions,
  lang,
  generateTranscription,
  loading,
}) => {
  const classes = useStyles();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [openEditTranscription, setOpenEditTranscription] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Tr[]>([]);

  const handleCloseEditTranscription = (tr?: Tr) => {
    setOpenEditTranscription(false);
    if (tr !== undefined) {
      updateTranscriptions(tr);
      setSelectedRows([]);
    }
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Слово",
        field: "word",
      },
      {
        headerName: "Транскрипция",
        field: "transcription",
      },
    ],
    []
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseCreateDialog = (data?: Tr) => {
    setOpenCreateDialog(false);
    if (data !== undefined) {
      addTranscriptions(data);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <Button
          color="primary"
          size="small"
          variant="contained"
          className={classes.button}
          onClick={() => setOpenEditTranscription(true)}
          disabled={selectedRows.length !== 1 || disabled}
        >
          Редактировать
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={generateTranscription}
          disabled={disabled}
          className={classes.generate}
        >
          {loading && <CircularProgress size={22} color="inherit" />}
          {!loading && <span>Сгенерировать</span>}
        </Button>
      </div>

      <div className={clsx(classes.table, "ag-theme-balham")}>
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          rowData={transcriptions}
          columnDefs={columnDefs}
          localeText={localization}
          rowSelection="single"
          enableRangeSelection
          onSelectionChanged={onSelectionChanged}
          suppressDragLeaveHidesColumns
        />
      </div>

      <CreateTranscriptionDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
      {selectedRows.length === 1 && (
        <EditTranscription
          open={openEditTranscription}
          onClose={handleCloseEditTranscription}
          word={selectedRows[0]}
          lang={lang}
        />
      )}
    </div>
  );
};

export default Transcriptions;
