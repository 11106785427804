import { useEffect, useState } from "react";
import { Lang } from "types/task";
import { AdminTechLid } from "types/admin/tech";
import TechService from "services/admin/TechService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  languages: Lang[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  languages: [],
  error: undefined,
};

const useLidLanguages = () => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState(() => ({ languages: [], error: undefined, loading: true }));
    TechService.getTechByName("lid")
      .then(({ data }) => {
        const techLid = data.techDetail as AdminTechLid;
        const languages = techLid.languages.filter((l) => l.id !== "auto" && l.id !== "unknown_language");
        setState({ languages, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ languages: [], loading: false, error: err });
      });
  }, []);

  return state;
};

export default useLidLanguages;
