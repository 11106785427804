import React, { FC, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { drawerClose, drawerOpen } from "redux/actions/drawerActions";

// components
import AdminNavigation from "layouts/admin/navigation/AdminNavigation";
import AdminHeader from "layouts/admin/header/AdminHeader";
import Notification from "components/Notification";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";

// icons
import TwitchCloseIcon from "components/icons/TwitchCloseIcon";
import TwitchOpenIcon from "components/icons/TwitchOpenIcon";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  aside: {
    height: "100vh",
    overflow: "auto",
    borderRight: "1px solid #ccc",
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  navigation: {},
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  toggleButton: {
    height: 45,
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    cursor: "pointer",
  },
  menuTitle: {
    margin: 0,
    padding: 0,
  },
  alert: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "30vw",
    zIndex: theme.zIndex.drawer + 10,
  },
}));

interface Props {
  children: ReactNode;
}

const AdminLayout: FC<Props> = ({ children }) => {
  const widthOpen = 370;
  const widthClose = 56;
  const classes = useStyles();
  const open = useSelector((state: RootState) => state.drawer.open);
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    dispatch(drawerOpen());
  };

  const handleDrawerClose = () => {
    dispatch(drawerClose());
  };

  const handleOpen = () => {
    open ? handleDrawerClose() : handleDrawerOpen();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <AdminHeader />
        </Toolbar>
      </AppBar>

      <aside style={{ width: open ? widthOpen : widthClose }} className={clsx(classes.aside, "app_aside")}>
        <Toolbar />
        <div
          onClick={handleOpen}
          className={classes.toggleButton}
          style={{ justifyContent: open ? "flex-end" : "center" }}
        >
          {open ? <TwitchCloseIcon /> : <TwitchOpenIcon />}
        </div>
        <div className={classes.navigation}>
          <AdminNavigation />
        </div>
      </aside>

      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>

      <Notification />
    </div>
  );
};

export default AdminLayout;
