import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { routes as r } from "routes";

// pages
import Modules from "pages/admin/modules/Modules";
import Techs from "pages/admin/techs/Techs";
import Settings from "pages/admin/settings/Settings";
import Passports from "pages/admin/passports/Passports";
import Servers from "pages/admin/servers/Servers";
import Access from "pages/admin/access/Access";
import LmModels from "pages/admin/lmModels/LmModels";
import LidModels from "pages/admin/lidModels/LidModels";
import NbsCategory from "pages/admin/nbsCategory/NbsCategory";
import Diagnostic from "pages/admin/diagnostic/Diagnostic";
import Sources from "pages/admin/sources/Sources";
import Theme from "pages/admin/theme/Theme";
import ExternalApi from "pages/admin/externalApi/ExternalApi";
import AutoCleanUp from "pages/admin/autoCleanUp/AutoCleanUp";

const Admin: FC = () => {
  return (
    <Switch>
      {/* Модули */}
      <Route exact path={r.admin.path}>
        <Modules />
      </Route>

      {/* Технологии */}
      <Route exact path={r.admin.techs.path}>
        <Techs />
      </Route>

      {/* Настройки */}
      <Route exact path={r.admin.settings.path}>
        <Settings />
      </Route>

      {/* Паспорта */}
      <Route exact path={r.admin.passports.path}>
        <Passports />
      </Route>

      {/* Сервера */}
      <Route exact path={r.admin.servers.path}>
        <Servers />
      </Route>

      {/* Диагностика */}
      <Route exact path={r.admin.diagnostic.path}>
        <Diagnostic />
      </Route>

      {/* Права */}
      <Route exact path={r.admin.access.path}>
        <Access />
      </Route>

      {/* Автоматическая очистка */}
      <Route exact path={r.admin.autoCleanUp.path}>
        <AutoCleanUp />
      </Route>

      {/* Модели перевода речи в текст */}
      <Route exact path={r.admin.lmModels.path}>
        <LmModels />
      </Route>

      {/* Модели диалектно-языковой группы */}
      <Route exact path={r.admin.lidModels.path}>
        <LidModels />
      </Route>

      {/* Категория нарушения */}
      <Route exact path={r.admin.nbsCategory.path}>
        <NbsCategory />
      </Route>

      {/* Источники */}
      <Route exact path={r.admin.sources.path}>
        <Sources />
      </Route>

      {/* Тема */}
      <Route exact path={r.admin.theme.path}>
        <Theme />
      </Route>

      {/* Настройка внешнего апи */}
      <Route exact path={r.admin.externalApi.path}>
        <ExternalApi />
      </Route>
    </Switch>
  );
};

export default Admin;
