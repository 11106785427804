import { useCallback, useEffect, useState } from "react";
import { Task } from "types";
import TaskService from "services/TaskService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  tasks: Task[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  tasks: [],
  error: undefined,
};

const useTasks = (query = "") => {
  const [state, setState] = useState<State>(initialState);

  const getTasks = useCallback(() => {
    setState((prev) => ({ ...prev, loading: true }));

    TaskService.getAll(query)
      .then(({ data }) => {
        setState(() => ({ tasks: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ tasks: [], loading: false, error: err }));
      });
  }, [query]);

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  return { ...state, getTasks };
};

export default useTasks;
