import { admin } from "http/urls";
import api from "http/api";
import { AxiosResponse } from "axios";

const { externalApi: route } = admin;

function ExternalApiService() {
  function getState(): Promise<AxiosResponse<{ setActive: boolean; namespaceId: number }>> {
    return api.get(route.setStatus);
  }

  function setState(body: { setActive: boolean; namespaceId: number }) {
    return api.post(route.setStatus, body);
  }

  return Object.freeze({
    getState,
    setState,
  });
}

export default ExternalApiService();
