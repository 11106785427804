import React, { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { setThemePrimaryColor } from "redux/actions/themeActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ColorResult, TwitterPicker } from "react-color";
import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 96px)",
    padding: theme.spacing(1),
  },
}));

const Theme: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.theme);

  const colors = useMemo(() => {
    return [
      teal[300],
      red[300],
      pink[300],
      purple[300],
      deepPurple[300],
      indigo[300],
      blue[300],
      lightBlue[300],
      cyan[300],
      green[300],
      lightGreen[300],
      lime[300],
      yellow[300],
      amber[300],
      orange[300],
      deepOrange[300],
      brown[300],
      grey[300],
      blueGrey[300],
    ];
  }, []);

  const handleChangeColor = (c: ColorResult) => {
    dispatch(setThemePrimaryColor(c.hex));
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            Основной цвет
          </Typography>
          <TwitterPicker
            colors={colors}
            width="860px"
            color={theme.palette.primary.main}
            onChangeComplete={handleChangeColor}
            triangle="hide"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Theme;
