import React, { ChangeEvent, FC } from "react";
import { Sort as T } from "types/common";

// material ui
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

interface Props {
  disabled?: boolean;
  sort: T;
  handleChange: (e: ChangeEvent<{ value: unknown }>) => void;
}

const Sort: FC<Props> = ({ disabled, sort, handleChange }) => {
  return (
    <FormControl fullWidth disabled={!!disabled}>
      <InputLabel id="select-sort-label">Сортировка</InputLabel>
      <Select labelId="select-sort-label" value={sort} onChange={handleChange} fullWidth>
        <MenuItem value="asc">По возрастанию</MenuItem>
        <MenuItem value="desc">По убыванию</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Sort;
