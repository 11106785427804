import React, { FC, useMemo, useState } from "react";
import clsx from "clsx";
import { ExportTask } from "types";
import { Group } from "types/queryBuilder";
import { generateUuid } from "functions/common";
import CreateExportTaskDialog from "./CreateExportTaskDialog";
import UpdateExportTaskDialog from "./UpdateExportTaskDialog";

// ag-grid
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import BooleanRenderer from "components/agGrid/renderers/BooleanRenderer";
import { removeRows } from "components/agGrid/functions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {},
  table: {
    height: 200,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  mr10: {
    marginRight: 10,
  },
}));

const defaultColDef = {
  resizable: true,
};

interface Props {
  exports: ExportTask[];
  setExports: Function;
}

const TaskExportTable: FC<Props> = ({ exports, setExports }) => {
  const classes = useStyles();
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState<ExportTask[]>([]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Имя",
        field: "name",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      {
        headerName: "Активно",
        field: "isActive",
        cellRenderer: "BooleanRenderer",
      },
      {
        headerName: "Тип",
        field: "taskDetail",
        valueGetter: (params: any) => params.data?.taskDetail?.storageDetail?.type ?? "",
      },
      {
        headerName: "Комментарий",
        field: "comment",
        flex: 1,
      },
    ],
    []
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    if (gridApi === undefined) return;
    const rows = gridApi.getSelectedRows();
    setSelectedRows(rows);
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data !== undefined) {
      setExports((prev: ExportTask[]) => [...prev, data]);
    }
  };

  const handleCloseUpdateDialog = (data?: any) => {
    setOpenUpdateDialog(false);
    if (data !== undefined) {
      setExports((prev: ExportTask[]) => prev.map((task) => (task.id === data.id ? data : task)));
    }
  };

  const handleDelete = () => {
    if (gridApi === undefined) return;
    const ids = selectedRows.map((t) => t.id);
    setExports((prev: ExportTask[]) => prev.filter((task) => !ids.includes(task.id)));
    removeRows(selectedRows, gridApi);
    setSelectedRows([]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <Typography>Экспорт</Typography>
        <div>
          <Button
            color="primary"
            size="small"
            className={classes.mr10}
            disabled={selectedRows.length === 0}
            onClick={handleDelete}
          >
            Удалить
          </Button>
          <Button
            color="primary"
            size="small"
            className={classes.mr10}
            disabled={selectedRows.length !== 1}
            onClick={() => setOpenUpdateDialog(true)}
          >
            Редактировать
          </Button>
          <Button color="primary" variant="contained" size="small" onClick={() => setOpenCreateDialog(true)}>
            Создать
          </Button>
        </div>
      </div>
      <div className={clsx(classes.table, "ag-theme-balham")}>
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          rowData={exports}
          columnDefs={columnDefs}
          localeText={localization}
          rowSelection="multiple"
          enableRangeSelection
          onSelectionChanged={onSelectionChanged}
          suppressDragLeaveHidesColumns
          suppressMovableColumns
          suppressColumnMoveAnimation
          suppressCopyRowsToClipboard
          frameworkComponents={{
            BooleanRenderer,
          }}
        />
      </div>

      <CreateExportTaskDialog filterDetail={defaultFilter} open={openCreateDialog} onClose={handleCloseCreateDialog} />
      {selectedRows.length === 1 && (
        <UpdateExportTaskDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} task={selectedRows[0]} />
      )}
    </div>
  );
};

export default TaskExportTable;
