import React, { Component } from "react";
import FallBack from "./FallBack";

export default class ErrorBoundary extends Component {
  state = { hasError: false };

  // рендеринг запасного UI в случае отлова ошибки
  static getDerivedStateFromError(error: Error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    console.error(error);
    return { hasError: true };
  }

  // журналирование информации об отловленной ошибке
  componentDidCatch(error: Error, errorInfo: {}) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <FallBack />;
    }

    return this.props.children;
  }
}
