import { AxiosResponse } from "axios";
import {
  DetailDiarization,
  DetailLID,
  DetailSID,
  DetailVAD,
  Record,
  RecordFile,
  Segment,
  DetailFax,
  DetailSTT,
  Keywords,
  AnalyticElement,
  AnalyticTimelineElement,
  LIDSegment,
  DownloadTextType,
  Word,
  WordsCloudWord,
} from "types/record";
import { records } from "http/urls";
import api from "http/api";
import { Label } from "types/label";
import { LmModel } from "types/admin/lmModel";

function RecordService() {
  function getAll(offset = 0, count = 1000, query = ""): Promise<AxiosResponse<Record[]>> {
    let url = records.getAll.replace(":offset", String(offset)).replace(":count", String(count)) + query;
    return api.get<Record[]>(decodeURI(url));
  }

  function getCount(query = "") {
    let url = records.count + query;
    return api.get(decodeURI(url));
  }

  function upload(body: { name: string; diskId: number }): Promise<AxiosResponse> {
    return api.post(records.upload, body);
  }

  function getDefaultData(recordId: string | number): Promise<AxiosResponse<RecordFile>> {
    const url = records.getDefaultData.replace(":recordId", String(recordId));
    return api.get<RecordFile>(url);
  }

  function getFileData(viewType: string, q: string, config: any) {
    const url = records.getFileData.replace(":viewType", viewType) + q;
    return api.get(decodeURI(url), config);
  }

  function getSegments(recordId: string | number, queryString?: string): Promise<AxiosResponse<Segment[]>> {
    let url = records.getSegments.replace(":recordId", String(recordId));
    if (queryString !== undefined) {
      url = url + queryString;
    }
    return api.get<Segment[]>(url);
  }

  function getTranslatedSegments(recordId: string | number, queryString?: string): Promise<AxiosResponse<Segment[]>> {
    let url = records.getTranslatedSegments.replace(":recordId", String(recordId));
    if (queryString !== undefined) {
      url = url + queryString;
    }
    return api.get<Segment[]>(url);
  }

  function getSTTResultAsText(recordId: string | number, type?: DownloadTextType) {
    const q = type ? `?textFormatType=${type}` : "";
    const url = records.getSTTResultAsText.replace(":recordId", String(recordId));
    return api.get(url + q);
  }

  function getSTTResultAsFile(recordId: string | number, type?: string) {
    const q = type ? `?textFormatType=${type}` : "";
    const url = records.getSTTResultAsFile.replace(":recordId", String(recordId));
    return api.get(url + q, { responseType: "blob" });
  }

  function setViewed(ids: number[], value: boolean) {
    return api.post(records.viewed, { ids: ids, isViewed: value });
  }

  function getDetailLID(recordId: string | number): Promise<AxiosResponse<DetailLID[]>> {
    const url = records.getDetailLID.replace(":recordId", String(recordId));
    return api.get<DetailLID[]>(url);
  }

  function getLIDSegments(recordId: string | number): Promise<AxiosResponse<LIDSegment[]>> {
    const url = records.getLIDSegment.replace(":recordId", String(recordId));
    return api.get<LIDSegment[]>(url);
  }

  function getDetailVAD(recordId: string | number): Promise<AxiosResponse<DetailVAD[]>> {
    const url = records.getDetailVAD.replace(":recordId", String(recordId));
    return api.get<DetailVAD[]>(url);
  }

  function getDetailDiarization(recordId: string | number): Promise<AxiosResponse<DetailDiarization[]>> {
    const url = records.getDetailDiarization.replace(":recordId", String(recordId));
    return api.get<DetailDiarization[]>(url);
  }

  function getDetailSID(recordId: string | number): Promise<AxiosResponse<DetailSID[]>> {
    const url = records.getDetailSID.replace(":recordId", String(recordId));
    return api.get<DetailSID[]>(url);
  }

  function getDetailFax(recordId: string | number): Promise<AxiosResponse<DetailFax[]>> {
    const url = records.getDetailFax.replace(":recordId", String(recordId));
    return api.get<DetailFax[]>(url);
  }

  function getDetailSTT(recordId: string | number): Promise<AxiosResponse<DetailSTT[]>> {
    const url = records.getDetailSTT.replace(":recordId", String(recordId));
    return api.get<DetailSTT[]>(url);
  }

  function getLabels(ids: number[]): Promise<AxiosResponse<{ recordId: number; labels: Label[] }[]>> {
    return api.post<{ recordId: number; labels: Label[] }[]>(records.getLabels, { ids });
  }

  function setLabel(labelId: number, ids: number[]) {
    const body = { labelId, ids };
    return api.post(records.setLabels, body);
  }

  function removeLabel(labelId: number, ids: number[]) {
    const body = { labelId, ids };
    return api.post(records.removeLabels, body);
  }

  function removeRecords(ids: number[]) {
    const url = records.remove;
    return api.post(url, { ids: ids });
  }

  function getKeywords(recordId: string | number, queryString: string): Promise<AxiosResponse<Keywords[]>> {
    const url = records.getKeywords.replace(":recordId", String(recordId));
    return api.get<Keywords[]>(url + queryString);
  }

  function getAnalytics(q: string): Promise<AxiosResponse<AnalyticElement[]>> {
    return api.get<AnalyticElement[]>(records.getAnalytics + q);
  }

  function getAnalyticsTimeline(q: string): Promise<AxiosResponse<AnalyticTimelineElement[]>> {
    return api.get<AnalyticTimelineElement[]>(records.getAnalyticsTimeline + q);
  }

  function getComment(recordId: string | number) {
    const url = records.getComment.replace(":recordId", String(recordId));
    return api.get(url);
  }

  function setComment(recordId: string | number, body: { comment: string }) {
    const url = records.setComment.replace(":recordId", String(recordId));
    return api.post(url, body);
  }

  // получить параметры автоочистки
  function getAutoCleanUp() {
    return api.get(records.getAutoCleanUp);
  }

  // установить параметры автоочистки
  function setAutoCleanUp(body: { isActive: boolean; filterV2: string[] }) {
    return api.post(records.setAutoCleanUp, body);
  }

  // выполнить очистку незамедлительно
  function executeAutoCleanUp(body: { filter: string; isActive: boolean }) {
    return api.post(records.executeAutoCleanUp, body);
  }

  // выполнить очистку незамедлительно для админки, с фильтром V2
  function executeAutoCleanUpV2(body: { filterV2: string; isActive: boolean }) {
    return api.post(records.executeAutoCleanUp, body);
  }

  function copy(body: { ids: number[]; namespaceId: number }) {
    return api.post(records.copy, body);
  }

  function getStatLayer(body: {
    filter: string;
    layer: "country" | "city" | "address";
    northEast: { lat: number; lng: number };
    southWest: { lat: number; lng: number };
  }) {
    return api.post(records.getStatLayer, body);
  }

  // для отладки пушей
  function pushMessage(toUserId = -1, message = "test") {
    return api.post("/record/messages", { toUserId, message });
  }

  function setDictionary(body: { ids: number[] }): Promise<AxiosResponse<Record[]>> {
    return api.post<Record[]>(records.setDictionary, body);
  }

  // получить все доступные лм модели
  function getAllLmModels(): Promise<AxiosResponse<LmModel[]>> {
    return api.get<LmModel[]>(records.getAllLmModels);
  }

  function updateWord(recordId: number, id: number, word: string): Promise<AxiosResponse<Word[]>> {
    const url = records.editWord.replace(":recordId", String(recordId));
    return api.post(url, { id, word });
  }

  function getWordCloud(queryString: string): Promise<AxiosResponse<WordsCloudWord[]>> {
    const url = records.wordCloud + queryString;
    return api.get(url);
  }

  return Object.freeze({
    getAll,
    getCount,
    upload,
    getDefaultData,
    getFileData,
    getSegments,
    getTranslatedSegments,
    setViewed,
    getDetailLID,
    getLIDSegments,
    getDetailVAD,
    getDetailSID,
    getDetailDiarization,
    getDetailFax,
    getDetailSTT,
    getLabels,
    setLabel,
    removeLabel,
    removeRecords,
    getKeywords,
    getAnalytics,
    getAnalyticsTimeline,
    getComment,
    setComment,
    getAutoCleanUp,
    setAutoCleanUp,
    executeAutoCleanUp,
    executeAutoCleanUpV2,
    copy,
    getStatLayer,
    pushMessage,
    setDictionary,
    getAllLmModels,
    getSTTResultAsText,
    getSTTResultAsFile,
    updateWord,
    getWordCloud,
  });
}

export default RecordService();
