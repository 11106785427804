import { URL_METHOD_OF_FORMING, WS_HOST } from "./urls";

export function getSocketUrl(path: string): string {
  const urlMethodOfForming = URL_METHOD_OF_FORMING ?? "STATIC";
  const { host, protocol } = window.location;
  let url = "";

  if (urlMethodOfForming.trim() === "DYNAMIC") {
    const wsProtocol = protocol === "https:" ? "wss://" : "ws://";
    url = `${wsProtocol}${host}${path}`;
  }

  if (urlMethodOfForming.trim() === "STATIC") {
    const wsHost = WS_HOST ?? `ws://127.0.0.1`;
    url = `${wsHost}${path}`;
  }

  return url;
}
