import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { LidClass } from "types/admin/lidModel";

// components
import LidClassesTable from "./LidClassesTable";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  textArea: {
    backgroundColor: "#f6f6ee",
    padding: theme.spacing(1),
    border: "none",
    outline: "none",
    width: "100%",
  },
  textAlignRight: {
    textAlign: "right",
  },
  q: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    color: grey[600],
  },
  mr10: {
    marginRight: 10,
  },
}));

interface State {
  name: string;
  comment: string;
  similarity: string;
  isActive: boolean;
  classes: LidClass[];
  countOutChanelInLayers: string;
}

const initialState: State = {
  name: "",
  comment: "",
  similarity: "0.5",
  isActive: true,
  classes: [],
  countOutChanelInLayers: "192, 192, 192, 192, 576",
};

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
}

const CreateLidModelDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [state, setState] = useState<State>(initialState);

  const { name, comment, similarity, isActive, countOutChanelInLayers } = state;

  const disabled = name.length === 0 || state.classes.length < 2;

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeIsDefault = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, isActive: event.target.checked });
  };

  const handleSave = () => {
    onClose(state);
  };

  const addLidClass = (lidClass: LidClass) => {
    setState((prev) => ({ ...prev, classes: [...prev.classes, lidClass] }));
  };

  const removeLidClasses = (lidClasses: LidClass[]) => {
    const exists = (lc: LidClass): boolean => {
      for (let i = 0; i < lidClasses.length; i++) {
        if (lc.userName === lidClasses[i].userName) {
          return true;
        }
      }
      return false;
    };

    setState((prev) => {
      const filteredClasses: LidClass[] = [];

      for (let i = 0; i < prev.classes.length; i++) {
        // lc - класс который есть в стэйте
        const lc = prev.classes[i];
        if (!exists(lc)) filteredClasses.push(lc);
      }

      return {
        ...prev,
        classes: filteredClasses,
      };
    });
  };

  useEffect(() => {
    if (!open) {
      setState(initialState);
    }
  }, [open]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Создать модель диалектно-языковой группы</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth name="name" onChange={handleChange} value={name} autoFocus />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Порог достоверности"
              fullWidth
              name="similarity"
              onChange={handleChange}
              value={similarity}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.q}>
              <TextField
                className={classes.mr10}
                label="Количество нейронов в слоях"
                fullWidth
                name="countOutChanelInLayers"
                onChange={handleChange}
                value={countOutChanelInLayers}
              />
              <span
                title="Количество выходных каналов для слоев TDNN/SERes2Net.
                       От выбранного количества каналов зависит занимаемый объем видеопамяти при обучении и впоследствии при работе.
                       Для загрузки видеокарты RTX3060(12GB) следует указать: 192, 192, 192, 192, 576"
                className={classes.icon}
              >
                <HelpOutlineIcon />
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isActive} onChange={handleChangeIsDefault} color="primary" />}
              label="Активна"
            />
          </Grid>
          <Grid item xs={12}>
            <LidClassesTable lidClasses={state.classes} addLidClass={addLidClass} removeLidClasses={removeLidClasses} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateLidModelDialog;
