import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// reducers
import accessReducer from "./accessReducer";
import authReducer from "./authReducer";
import drawerReducer from "./drawerReducer";
import alertReducer from "./alertReducer";
import settingsReducer from "./settingsReducer";
import navigationReducer from "./navigationReducer";
import themeReducer from "./themeReducer";
import wsReducer from "./wsReducer";
import pageSettingsReducer from "./pageSettingsReducer";
import selectedTabReducer from "./selectedTabReducer";
import systemReducer from "./systemReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  drawer: drawerReducer,
  alert: alertReducer,
  settings: settingsReducer,
  navigation: navigationReducer,
  theme: themeReducer,
  access: accessReducer,
  ws: wsReducer,
  pageSettings: pageSettingsReducer,
  selectedTab: selectedTabReducer,
  system: systemReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
