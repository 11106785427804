import React, { ChangeEvent, FC, useState, MouseEvent } from "react";

// icons
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";

// material ui
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 300,
      padding: "10px 20px 20px 20px",
    },
    button: {},
  })
);

interface Props {
  loopPlayback: {
    loop: boolean;
    start: number;
    end: number;
  };
  setLoopPlayback: Function;
}

const LoopPlayback: FC<Props> = ({ loopPlayback, setLoopPlayback }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { loop, start, end } = loopPlayback;

  const [state, setState] = useState({ s: start, e: end, l: loop });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    const { s, e, l } = state;
    setLoopPlayback({
      start: Number(s),
      end: Number(e),
      loop: l,
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setState((prev) => ({ ...prev, l: checked }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <IconButton
        className={classes.button}
        onClick={handleClick}
        title="Воспроизведение зациклено"
        disableRipple
        color="primary"
      >
        <AllInclusiveIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={state.l} onChange={handleCheckbox} color="primary" />}
                label="Активно"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Начало сегмента (сек.)" value={state.s} name="s" onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Конец сегмента (сек.)" value={state.e} name="e" onChange={handleChange} fullWidth />
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
};

export default LoopPlayback;
