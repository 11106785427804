import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  root: {
    color: grey[600],
  },
}));

const IsCanWriteRenderer: FC<{ value: boolean }> = ({ value }) => {
  const classes = useStyles();

  return <div className={classes.root}>{!value && <LockIcon />}</div>;
};

export default IsCanWriteRenderer;
