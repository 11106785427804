import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { Record, RecordDetail, RecordFileViewType } from "types/record";
import { API_URL, records } from "http/urls";

// material ui
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
}));

interface Props {
  record: Record;
  recordDetail: RecordDetail;
  queryFilter: string;
  viewType: RecordFileViewType.VIDEO;
  videoRef: React.RefObject<HTMLVideoElement>;
}

const Video: FC<Props> = ({ viewType, queryFilter, videoRef }) => {
  const classes = useStyles();
  const { accessToken } = useSelector((state: RootState) => state.auth);

  const videoSrc = useMemo(() => {
    const url = API_URL + records.getFileData;
    const orig = "&orig=y";
    return decodeURI(url.replace(":viewType", viewType) + `?q=${queryFilter}` + orig + `&authorization=${accessToken}`);
  }, [queryFilter, viewType, accessToken]);

  return (
    <div className={classes.root}>
      <video controls ref={videoRef} width="500" height="273">
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
};

export default Video;
