import { SET_THEME, SET_THEME_PRIMARY_COLOR, ThemeAction } from "redux/types/themeTypes";
import { teal, red } from "@material-ui/core/colors";
import { ThemeOptions } from "@material-ui/core/styles";
import { RootState } from "../types";
import { load } from "redux-localstorage-simple";

// получить сохраненный store из localstorage
const storageState: RootState = load({ namespace: "APP" }) as RootState;

const defaultState: ThemeOptions = {
  palette: {
    primary: {
      main: teal[300],
      contrastText: "#FFF",
    },
    secondary: {
      main: red[400],
    },
  },
};

const initialState = storageState.theme ?? defaultState;

export default (state = initialState, action: ThemeAction) => {
  if (action.type === SET_THEME) {
    return action.payload;
  }

  if (action.type === SET_THEME_PRIMARY_COLOR) {
    return {
      ...state,
      palette: {
        ...state.palette,
        primary: {
          ...state.palette?.primary,
          main: action.payload,
        },
      },
    };
  }

  return state;
};
