import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { AccessDeleteRecord } from "types/admin";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  access: AccessDeleteRecord;
}

const UpdateAccessDialog: FC<Props> = ({ open, onClose, access }) => {
  const classes = useStyles();

  const [state, setState] = useState(access);
  const { isCanDelete, isShowNameSpaceName, isShowSpeakersName, isShowAutoInformatorsName } = state;

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSave = useCallback(() => {
    onClose(state);
  }, [onClose, state]);

  useEffect(() => {
    setState(access);
  }, [access]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактировать права</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isCanDelete} onChange={handleChecked} name="isCanDelete" color="primary" />}
              label="Разрешено удалять записи"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isShowNameSpaceName}
                  onChange={handleChecked}
                  name="isShowNameSpaceName"
                  color="primary"
                />
              }
              label="Разрешено видеть имя пространства"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isShowSpeakersName}
                  onChange={handleChecked}
                  name="isShowSpeakersName"
                  color="primary"
                />
              }
              label="Разрешено видеть имя диктора"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isShowAutoInformatorsName}
                  onChange={handleChecked}
                  name="isShowAutoInformatorsName"
                  color="primary"
                />
              }
              label="Разрешено видеть имя автоинформатора"
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAccessDialog;
