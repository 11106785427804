import { AdminTech } from "types/admin";
import { TaskTech, TechType } from "types";

export function getDefaultTaskTech(adminTech: AdminTech): TaskTech {
  const tech: any = {
    id: adminTech.id,
    type: adminTech.techDetail.type,
  };

  if (adminTech.techDetail.type === TechType.STT) {
    tech.lang = adminTech.techDetail.languages[0].id;
    tech.languageModelId = 0;
    tech.isEnableWord2Num = true;
  }

  if (adminTech.techDetail.type === TechType.LID) {
    tech.langs = adminTech.techDetail.languages.map((el) => el.id);
  }

  if (adminTech.techDetail.type === TechType.FAX) {
    tech.isActiveOpticalRecognition = adminTech.techDetail.isActiveOpticalRecognition;
  }
  if (adminTech.techDetail.type === TechType.SID) {
    tech.ids = [];
  }

  if (adminTech.techDetail.type === TechType.OCR) {
    tech.lang = adminTech.techDetail.languages[0].id;
  }

  if (adminTech.techDetail.type === TechType.VAD) {
    tech.vadType = "vad";
  }

  if (adminTech.techDetail.type === TechType.DIARIZATION) {
    tech.speakerCount = 2;
  }

  return tech;
}
