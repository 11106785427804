import React, { FC, useEffect, useState } from "react";
import ExternalApiService from "services/admin/ExternalApiService";
import { catchError } from "functions/common";
import SelectNamespace from "components/SelectNamespace";
import clsx from "clsx";
// import api from "http/api";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Skeleton from "@material-ui/lab/Skeleton";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
// import Grid from "@material-ui/core/Grid";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 96px)",
    overflow: "auto",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  switch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 50,
  },
  title: {
    marginLeft: 10,
  },
  block: {
    width: 400,
    height: 230,
    borderRadius: 5,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 5px 15px",
    marginBottom: theme.spacing(2),
  },
  up: {
    backgroundColor: theme.palette.primary.main,
  },
  down: {
    backgroundColor: theme.palette.text.disabled,
  },
  color: {
    height: 8,
  },
  namespace: {},
  c: {
    padding: theme.spacing(2),
  },
  img: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  input: {
    display: "none",
  },
  w: {
    height: "calc(100vh - 545px)",
    overflow: "auto",
    border: "1px solid " + grey[400],
    padding: 5,
    backgroundColor: "#303030",
    color: "#f7f7f7",
  },
  pre: {
    whiteSpace: "break-spaces",
  },
}));

interface State {
  loading: boolean;
  setActive: boolean;
  namespaceId: number;
}

const ExternalApi: FC = () => {
  const classes = useStyles();

  const [state, setState] = useState<State>({ loading: false, setActive: false, namespaceId: 0 });

  // const [res, setRes] = useState<any>({});
  // const [id, setId] = useState("");

  const { loading, setActive, namespaceId } = state;

  const onChange = (event: any) => {
    const { checked } = event.target;
    const body = { setActive: checked, namespaceId };
    ExternalApiService.setState(body)
      .then(() => {
        setState((prev) => ({ ...prev, ...body }));
      })
      .catch(catchError);
  };

  const setNamespaceId = (id: number) => {
    const body = { setActive: setActive, namespaceId: id };
    ExternalApiService.setState(body)
      .then(() => {
        setState((prev) => ({ ...prev, ...body }));
      })
      .catch(catchError);
  };

  // const upload = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { files } = event.target;
  //   if (files === null || files.length === 0) return;
  //
  //   const fd = new FormData();
  //   const config = {
  //     codec: "wav",
  //     techs: [
  //       {
  //         type: "stt",
  //         parameters: {
  //           isActive: true,
  //           language: "russian",
  //           numeral: "text",
  //         },
  //       },
  //       {
  //         type: "lid",
  //         parameters: {
  //           isActive: true,
  //         },
  //       },
  //       {
  //         type: "vad",
  //         parameters: {
  //           isActive: true,
  //           detector: "default",
  //         },
  //       },
  //     ],
  //   };
  //   fd.append("config", JSON.stringify(config));
  //   fd.append("audio", files[0]);
  //
  //   api
  //     .post("/extapi/process", fd, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //       responseType: "json",
  //     })
  //     .then(({ data }) => {
  //       console.log("DATA: ", data);
  //       setId(data.message);
  //     });
  // };

  // const getResult = () => {
  //   api.get("/extapi/result/" + id).then(({ data }) => {
  //     setRes(data);
  //   });
  // };

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    ExternalApiService.getState()
      .then(({ data }) => {
        const { namespaceId, setActive } = data;
        setState({ loading: false, namespaceId, setActive });
      })
      .catch(catchError)
      .finally(() => {
        setState((prev) => ({ ...prev, loading: false }));
      });
  }, []);

  if (loading) return null;

  return (
    <Paper className={classes.root}>
      {/*<img*/}
      {/*  className={classes.img}*/}
      {/*  src={setActive ? "/img/api_up.png" : "/img/api_down.png"}*/}
      {/*  alt="api"*/}
      {/*  width="50"*/}
      {/*  height="50"*/}
      {/*/>*/}

      <div className={classes.block}>
        {loading ? (
          <Skeleton variant="rect" width={400} height={230} />
        ) : (
          <div className={classes.block}>
            <div
              className={clsx(classes.color, {
                [classes.up]: setActive,
                [classes.down]: !setActive,
              })}
            />
            <div className={classes.c}>
              <div className={classes.switch}>
                <Switch edge="end" onChange={onChange} checked={setActive} color="primary" />
                <Typography className={classes.title}>Активен</Typography>
              </div>

              <div className={classes.namespace}>
                <SelectNamespace namespaceId={namespaceId} setNamespaceId={setNamespaceId} />
              </div>
            </div>
          </div>
        )}
      </div>

      {/*<Grid container spacing={2}>*/}
      {/*  <Grid item xs={12}>*/}

      {/*  </Grid>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <Typography variant="h6">Проверка:</Typography>*/}
      {/*  </Grid>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <input*/}
      {/*      accept="*"*/}
      {/*      onChange={upload}*/}
      {/*      className={classes.input}*/}
      {/*      id="contained-button-file"*/}
      {/*      multiple={false}*/}
      {/*      type="file"*/}
      {/*    />*/}
      {/*    <label htmlFor="contained-button-file">*/}
      {/*      <Button variant="contained" color="primary" component="span">*/}
      {/*        Вабрать файл*/}
      {/*      </Button>*/}
      {/*    </label>*/}
      {/*  </Grid>*/}

      {/*  <Grid item xs={12}>*/}
      {/*    <Grid container spacing={2}>*/}
      {/*      <Grid item xs={6}>*/}
      {/*        <TextField value={id} onChange={(e) => setId(e.target.value)} label="ID" fullWidth />*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={6}>*/}
      {/*        <Button variant="contained" color="primary" onClick={getResult}>*/}
      {/*          Получить результаты*/}
      {/*        </Button>*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={12}>*/}
      {/*        <div className={classes.w}>*/}
      {/*          <pre className={classes.pre}>{JSON.stringify(res, undefined, 2)}</pre>*/}
      {/*        </div>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </Paper>
  );
};

export default ExternalApi;
