import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

//icons
import FolderIcon from "@material-ui/icons/Folder";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    color: grey[600],
  },
}));

const FileRenderer: FC<{ value: boolean }> = ({ value }) => {
  const classes = useStyles();

  // true | false
  if (value) {
    return (
      <div className={classes.wrapper}>
        <FolderIcon />
      </div>
    );
  } else {
    return (
      <div className={classes.wrapper}>
        <InsertDriveFileIcon />
      </div>
    );
  }
};

export default FileRenderer;
