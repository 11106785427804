import React, { FC, useCallback } from "react";
import { Task } from "types";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { setPageTasksTableSettings } from "redux/actions/pageSettingsActions";
import { RootState } from "redux/types";

// ag-grid
import { ColumnResizedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import NameRenderer from "components/agGrid/renderers/TaskNameRenderer";
import StatusRenderer from "components/agGrid/renderers/StatusRenderer";
import CheckboxRenderer from "components/agGrid/renderers/CheckboxRenderer";
import IsCanWriteRenderer from "components/agGrid/renderers/IsCanWriteRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 151px)",
  },
}));

const defaultColDef = {
  resizable: true,
};

const columnDefs = [
  {
    headerName: "Идентификатор",
    field: "id",
    width: 100,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Статус",
    field: "status",
    cellRenderer: "StatusRenderer",
    width: 100,
  },
  {
    headerName: "Права",
    field: "isCanWrite",
    width: 100,
    cellRenderer: "IsCanWriteRenderer",
  },
  {
    headerName: "Сообщение",
    field: "message",
  },
  {
    headerName: "Имя задания",
    field: "name",
    width: 320,
    cellRenderer: "NameRenderer",
  },
  {
    headerName: "Создатель",
    field: "ownerId",
  },
  {
    headerName: "Активно",
    field: "isActive",
    width: 100,
    cellRenderer: "CheckboxRenderer",
  },
  {
    headerName: "Задач в обработке",
    field: "taskInProcessed",
  },
  {
    headerName: "Комментарий",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

interface Props {
  rowData: Task[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
}

const TasksTable: FC<Props> = ({ rowData, setGridApi, setColumnApi, onSelection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tasks: pageSettings } = useSelector((state: RootState) => state.pageSettings);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (pageSettings.table !== undefined) {
      params.columnApi.setColumnState(pageSettings.table);
    }
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: Task) => String(data.id);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageTasksTableSettings(colState));
      }
    },
    [dispatch]
  );

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        onColumnResized={onColumnResized}
        suppressMovableColumns
        suppressColumnMoveAnimation
        suppressCopyRowsToClipboard
        frameworkComponents={{
          DateRenderer,
          StatusRenderer,
          CheckboxRenderer,
          IsCanWriteRenderer,
          NameRenderer,
        }}
      />
    </div>
  );
};

export default TasksTable;
