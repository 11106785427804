import React, { ChangeEvent, FC } from "react";
import { Lang } from "types/task";
import useLidLanguages from "hooks/admin/useLidLanguages";

// material ui
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

interface LoadLanguagesProps {
  selectedLanguage: string;
  setSelectedLanguage: (l: string) => void;
}
const LoadLanguages: FC<LoadLanguagesProps> = ({ setSelectedLanguage, selectedLanguage }) => {
  const { languages } = useLidLanguages();

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setSelectedLanguage(value as string);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="language-select">Язык</InputLabel>
      <Select labelId="language-select" value={selectedLanguage} onChange={handleChange} fullWidth>
        {languages.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface Props {
  selectedLanguage: string;
  setSelectedLanguage: (l: string) => void;
  availableLanguages?: Lang[];
}
const SelectLanguage: FC<Props> = ({ selectedLanguage, setSelectedLanguage, availableLanguages }) => {
  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setSelectedLanguage(value as string);
  };

  if (availableLanguages !== undefined) {
    return (
      <FormControl fullWidth>
        <InputLabel id="language-select">Язык</InputLabel>
        <Select labelId="language-select" value={selectedLanguage} onChange={handleChange} fullWidth>
          {availableLanguages.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return <LoadLanguages selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />;
};

export default SelectLanguage;
