import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { catchError, copyToClipboard } from "functions/common";
import { useDispatch } from "react-redux";
import { showErrorAlert, showSuccessAlert } from "redux/actions/alertActions";
import DiagnosticService from "services/admin/DiagnosticService";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  w: {
    height: "calc(100vh - 155px)",
    overflow: "auto",
    border: "1px solid " + grey[400],
    padding: 5,
    backgroundColor: "#303030",
    color: "#f7f7f7",
  },
  pre: {
    whiteSpace: "break-spaces",
    margin: 0,
    paddingLeft: 10,
  },
  h: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  t: {
    flexGrow: 1,
  },
  b: {},
  btn: {
    margin: "0 5px",
  },
}));

const Diagnostic: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const [text, setText] = useState("");

  const copy = () => {
    copyToClipboard(text)
      ? dispatch(showSuccessAlert("Скопировано"))
      : dispatch(showErrorAlert("Копирование в буфер обмена запрещено"));
  };

  const download = () => {
    const blob = new Blob([text], { type: "text/plain" });
    const href = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = href;
    a.download = moment().format("YYYY-MM-DD_HH-mm-ss") + "_диагностика.txt";
    a.click();
    a.remove();
    URL.revokeObjectURL(href);
  };

  const upload = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files === null || files.length === 0) return;

    const fd = new FormData();
    fd.append("files", files[0]);
    DiagnosticService.uploadSettings(fd)
      .then(() => {
        dispatch(showSuccessAlert("Файл загружен"));
      })
      .catch(catchError);
  };

  useEffect(() => {
    DiagnosticService.systemInfo()
      .then(({ data }) => {
        setText(data);
      })
      .catch(catchError);
  }, []);

  return (
    <Paper className={classes.root}>
      <div className={classes.h}>
        <Typography className={classes.t}>Диагностика системы</Typography>
        <div className={classes.b}>
          <Button color="primary" size="small" className={classes.btn} onClick={copy}>
            Скопировать
          </Button>
          <Button color="primary" size="small" className={classes.btn} onClick={download}>
            Скачать
          </Button>

          <input onChange={upload} id="icon-button-file" type="file" hidden multiple={false} ref={inputRef} />
          <label htmlFor="icon-button-file">
            <Button color="primary" component="span" size="small" className={classes.btn}>
              Загрузить
            </Button>
          </label>
        </div>
      </div>
      <div className={classes.w}>
        <pre className={classes.pre}>{text}</pre>
      </div>
    </Paper>
  );
};

export default Diagnostic;
