import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Group as TGroup, SelectedGroup, SelectedRule } from "types/queryBuilder";
import { getOperationName, isGroup } from "./helperFunctions";
import { subscriber } from "components/extendedFilter/ExtendedFilter";
import RenderRule from "./RenderRule";
import { AdminTypeFilter } from "types/admin";

// icons
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 10,
    borderRadius: 3,
  },
  selected: {
    outline: "2px solid " + grey[400],
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  block: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    marginRight: 10,
  },
  group: {
    border: "1px solid " + grey[600],
    padding: "5px 15px",
  },
  hide: {
    display: "none",
  },
  chip: {
    marginLeft: 10,
  },
}));

interface Props {
  group: TGroup;
  parentGroup: TGroup | undefined;
  setSelectedGroup: Function;
  selectedGroup: SelectedGroup;
  selectedRule: SelectedRule;
  setSelectedRule: Function;
  availableFilters: AdminTypeFilter[];
}

const RenderGroup: FC<Props> = ({
  group,
  parentGroup,
  setSelectedGroup,
  setSelectedRule,
  selectedGroup,
  selectedRule,
  availableFilters,
}) => {
  const classes = useStyles();
  const checked = useMemo(() => selectedGroup.group?.uuid === group.uuid, [group, selectedGroup]);

  const [minimized, setMinimized] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (selectedGroup.group?.uuid === group.uuid && !checked) {
      setSelectedGroup({ group: undefined, parent: undefined });
    } else {
      setSelectedGroup({ group, parent: parentGroup });
    }
    setSelectedRule({ rule: undefined, parent: undefined });
  };

  useEffect(() => {
    if (parentGroup === undefined) return;
    subscriber.subscribe((value) => {
      if (value === undefined) return;
      setMinimized(value);
    });
  }, [parentGroup]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.selected]: checked,
        [classes.group]: !!parentGroup,
        // [classes.group]: true,
      })}
    >
      {parentGroup && (
        <div className={classes.title}>
          <div className={classes.block}>
            <div className={classes.checkbox}>
              <Checkbox checked={checked} onChange={handleChange} color="primary" />
            </div>
            <Chip label={getOperationName(group.operation)} color="primary" />
            {minimized &&
              group.filters.map((item) => (
                <Chip key={item.uuid} label={isGroup(item) ? "Группа" : item.filter} className={classes.chip} />
              ))}
          </div>

          <IconButton size="small" onClick={() => setMinimized((prev) => !prev)}>
            {minimized ? <KeyboardArrowRightIcon fontSize="inherit" /> : <KeyboardArrowDownIcon fontSize="inherit" />}
          </IconButton>
        </div>
      )}

      {!minimized &&
        group.filters.map((item) => {
          if (isGroup(item)) {
            return (
              <RenderGroup
                key={item.uuid}
                group={item}
                parentGroup={group}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                setSelectedRule={setSelectedRule}
                selectedRule={selectedRule}
                availableFilters={availableFilters}
              />
            );
          }
          return (
            <RenderRule
              key={item.uuid}
              rule={item}
              parentGroup={group}
              setSelectedRule={setSelectedRule}
              selectedRule={selectedRule}
              setSelectedGroup={setSelectedGroup}
              availableFilters={availableFilters}
            />
          );
        })}
    </div>
  );
};

export default RenderGroup;
