import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { LmModel, Transcription as Tr } from "types/admin/lmModel";

// components
import Transcriptions from "./Transcriptions";
import SelectLang from "./SelectLang";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme) => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  textArea: {
    backgroundColor: "#f6f6ee",
    padding: theme.spacing(1),
    border: "none",
    outline: "none",
    width: "100%",
  },
  textAlignRight: {
    textAlign: "right",
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  lmModel: LmModel;
}

const UpdateLmModelDialog: FC<Props> = ({ open, onClose, lmModel }) => {
  const classes = useStyles();

  const [state, setState] = useState(lmModel);
  const [transcriptions, setTranscriptions] = useState<Tr[]>([]);
  const { name, comment, text, lang, isDefault } = state;

  const disabledSave = name.length === 0 || lang === "";

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = useCallback(() => {
    let tr = "";
    transcriptions.forEach((t) => {
      tr += `${t.word}\t${t.transcription}\n`;
    });
    onClose({ ...state, transcriptions: tr });
  }, [onClose, state, transcriptions]);

  const setLanguage = (lang: string) => {
    setState((prev) => ({ ...prev, lang }));
  };

  const handleChangeIsDefault = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, isDefault: event.target.checked });
  };

  useEffect(() => {
    setState(lmModel);
    const rows = lmModel.transcriptions.split("\n");
    const arr: Tr[] = [];
    rows.forEach((row) => {
      const tr = row.split("\t");
      if (tr.length === 2) {
        arr.push({ word: tr[0], transcription: tr[1] });
      }
    });
    setTranscriptions(arr);
  }, [open, lmModel]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактировать языковую модель</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField label="Имя" fullWidth name="name" onChange={handleChange} value={name} />
          </Grid>
          <Grid item xs={6}>
            <SelectLang language={lang} setLanguage={setLanguage} disabled excludeAuto />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isDefault} onChange={handleChangeIsDefault} color="primary" />}
              label="Использовать по имолчанию"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Данные</Typography>
            <textarea
              className={classes.textArea}
              rows={15}
              name="text"
              onChange={handleChange}
              value={text}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Транскрипции новых слов</Typography>
            <Transcriptions
              lang={lang}
              transcriptions={transcriptions}
              addTranscriptions={() => {}}
              updateTranscriptions={() => {}}
              removeTranscriptions={() => {}}
              generateTranscription={() => {}}
              disabled
              loading={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabledSave}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLmModelDialog;
