import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import * as cloud from "d3-cloud";
import * as rasterizer from "rasterizehtml";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {},
  svgWrapper: {
    flexGrow: 1,
  },
}));

function WordsCloud({ words, setToolbarWCActions, mainWindowState }) {
  const classes = useStyles();

  const svgRef = useRef(null);
  const [updateWordsVisible, setUpdateWordsVisible] = useState(null);

  const createCloud = () => {
    const svgEl = svgRef.current;

    const colors = ["#F18F01", "#141414", "#EA526F"];
    // let width, height;
    const fontFamily = "sans-serif";
    const fontScale = 10;
    const rotate = () => 0;
    const padding = 4;

    const parent = svgEl.parentElement;
    const { width, height } = parent.getBoundingClientRect();

    let bWidth, bHeight, bMidX, bMidY;

    function draw(words, bounds) {
      if (!bounds) return;
      bWidth = bounds[1].x - bounds[0].x;
      bHeight = bounds[1].y - bounds[0].y;
      bMidX = bounds[0].x + bWidth / 2;
      bMidY = bounds[0].y + bHeight / 2;

      const zoom = d3
        .zoom()
        .extent([
          [0, 0],
          [width, height],
        ])
        .scaleExtent([1, 8])
        .translateExtent([
          [0, 0],
          [width, height],
        ])
        .on("zoom", zoomed);

      const svg = d3.select(svgEl).attr("viewBox", [0, 0, width, height]).style("background-color", "white").call(zoom);
      const g = svg.append("g");

      g.selectAll("text")
        .data(words)
        .enter()
        .append("text")
        .style("font-size", (d) => d.size + "px")
        .style("font-family", fontFamily)
        .attr("text-anchor", "middle")
        .attr("transform", (d) => "translate(" + [bMidX + d.x, bMidY + d.y] + ")rotate(" + d.rotate + ")")
        .text((d) => d.word)
        .style("fill", () => colors[Math.floor(Math.random() * colors.length)]);

      function zoomed({ transform }) {
        g.attr("transform", transform);
      }

      function reset() {
        svg
          .transition()
          .duration(750)
          .call(zoom.transform, d3.zoomIdentity, d3.zoomTransform(svg.node()).invert([width / 2, height / 2]));
      }

      const zoomIn = () => {
        svg.transition().call(zoom.scaleBy, 2);
      };
      const zoomOut = () => {
        svg.transition().call(zoom.scaleBy, 0.5);
      };

      function setInlineStyles(svg, cloneSvg) {
        function explicitlySetStyle(element, cloneElement) {
          const cSSStyleDeclarationComputed = getComputedStyle(element);
          let i, len, key, value;
          const svgExcludedValues = ["height", "width", "min-height", "min-width"];
          let computedStyleStr = "";
          for (i = 0, len = cSSStyleDeclarationComputed.length; i < len; i++) {
            key = cSSStyleDeclarationComputed[i];
            if (!(element instanceof SVGElement && svgExcludedValues.indexOf(key) >= 0)) {
              value = cSSStyleDeclarationComputed.getPropertyValue(key);
              computedStyleStr += key + ":" + value + ";";
            }
          }
          cloneElement.setAttribute("style", computedStyleStr);
        }
        function traverse(obj) {
          const tree = [];
          tree.push(obj);
          visit(obj);
          function visit(node) {
            if (node && node.hasChildNodes()) {
              let child = node.firstChild;
              while (child) {
                if (child.nodeType === 1 && child.nodeName !== "SCRIPT") {
                  tree.push(child);
                  visit(child);
                }
                child = child.nextSibling;
              }
            }
          }
          return tree;
        }
        const allElements = traverse(svg);
        const allElementsClone = traverse(cloneSvg);
        let i = allElements.length;
        while (i--) {
          explicitlySetStyle(allElements[i], allElementsClone[i]);
        }
      }

      const saveSvgToPng = () => {
        const elementToExport = document.querySelector("#svg-wrapper");
        const clone = elementToExport.cloneNode(true);
        setInlineStyles(elementToExport, clone);
        const htmlContent = clone.innerHTML;
        const canvas = document.querySelector("#canvas");

        canvas.width = elementToExport.getBoundingClientRect().width + 16;
        canvas.height = elementToExport.getBoundingClientRect().height + 16;

        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        rasterizer.drawHTML(htmlContent, canvas).then(function success() {
          const canvasdata = canvas.toDataURL("image/jpeg", 1.0).replace("image/jpeg", "image/octet-stream");

          const a = document.createElement("a");
          a.download = "wordsCloud.jpeg";
          a.href = canvasdata;

          a.click();
        });
      };

      setUpdateWordsVisible({
        updateWordsVisible: (t) => {
          g.selectAll("text").style("display", function (w) {
            return w.confidence >= t ? "block" : "none";
          });
        },
      });

      const zoomReset = reset;

      setToolbarWCActions({ zoomIn, zoomOut, zoomReset, saveSvgToPng });
    }

    cloud()
      .size([width, height])
      .words(
        words.map((d) => {
          return { word: d.word, confidence: d.confidence, size: d.size };
        })
      )
      .padding(padding)
      .rotate(rotate)
      .text((d) => d.word)
      .fontSize((d) => Math.sqrt(d.size) * fontScale)
      .on("end", draw)
      .start();
  };

  useEffect(() => {
    createCloud();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!updateWordsVisible) return;

    if (mainWindowState.threshold.isEnabled) {
      updateWordsVisible.updateWordsVisible(mainWindowState.threshold.value);
    } else {
      updateWordsVisible.updateWordsVisible(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainWindowState]);

  return (
    <div
      id="svg-wrapper"
      className={classes.svgWrapper}
      style={{ display: mainWindowState.displayOptions.displayWordsCloud }}
    >
      <svg id="svg" ref={svgRef} />
      <canvas id="canvas" style={{ display: "none", margin: 0, padding: 0 }} />
    </div>
  );
}

export default WordsCloud;
