import React, { useState, FC, ChangeEvent } from "react";

// material-ui
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface Props {
  handleChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  value: string;
  variant?: "filled" | "outlined" | "standard";
  margin?: "dense" | "none" | "normal";
  error?: boolean;
  label?: string;
  name?: string;
}

const PasswordInput: FC<Props> = ({ handleChange, value, variant, margin, error, label, name }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      label={label ? label : "Пароль"}
      variant={variant ? variant : "standard"}
      margin={margin ? margin : "normal"}
      name={name ? name : "password"}
      error={error !== undefined ? error : false}
      type={showPassword ? "text" : "password"}
      onChange={handleChange}
      value={value}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
