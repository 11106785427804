import React, { FC, useCallback, useEffect, useState, ChangeEvent } from "react";
import { Object as ObjectType } from "types/record";
import { Group } from "types/queryBuilder";
import ObjectService from "services/ObjectService";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch, useSelector } from "react-redux";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import { RootState } from "redux/types";
import ObjectsTable from "./comonents/ObjectsTable";
import { routes } from "routes";
import { addRow, removeRows, updateColumnOrder, updateRow } from "components/agGrid/functions";
import { useHistory } from "react-router-dom";
import { setPageObjectsTableSettings } from "redux/actions/pageSettingsActions";

// components
import ConfirmationDialog from "components/ConfirmationDialog";
import CreateObjectDialog from "./comonents/CreateObjectDialog";
import UpdateObjectDialog from "./comonents/UpdateObjectDialog";
import PaginationPanel from "components/pagination/PaginationPanel";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  bc: {
    marginBottom: 10,
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  mr10: {
    marginRight: 10,
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchWrapper: {
    flexGrow: 1,
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
}));

interface State {
  objects: ObjectType[];
  loading: boolean;
  error: Error | undefined;
}

const initialState: State = {
  objects: [],
  loading: false,
  error: undefined,
};

const Objects: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { rowsPerPage: perPage } = useSelector((state: RootState) => state.settings);

  const { path } = routes.objects;

  const [pag, setPag] = useState<{ root: number; name: string }[]>([]);
  const [state, setState] = useState(initialState);
  const [selectedRows, setSelectedRows] = useState<ObjectType[]>([]);

  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [root, setRoot] = useState(0);

  const { objects, error } = state;

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageObjectsTableSettings(colState));
    }
  };

  const getParentId = useCallback(() => (selectedRows.length > 0 ? selectedRows[0].id : 0), [selectedRows]);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows.map((e) => e.id).join(",");
      ObjectService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data) {
      ObjectService.create({ ...data, parentId: root })
        .then((res) => {
          addRow(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseUpdateDialog = (data?: any) => {
    setOpenUpdateDialog(false);
    if (data) {
      ObjectService.update(data)
        .then((res) => {
          updateRow(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleOpenResult = () => {
    if (selectedRows.length > 0) {
      const { id } = selectedRows[0];
      const { path } = routes.results;

      const group: Group = {
        type: "group",
        uuid: "1",
        operation: "AND",
        filters: [{ type: "filter", uuid: "2", filter: "Объект", condition: "=", value: String(id) }],
      };

      const url = path + "?f=" + JSON.stringify(group);
      history.push(encodeURI(url));
    }
  };

  const handleOpenGraph = () => {
    if (selectedRows.length > 0) {
      const objects = selectedRows.map((row) => row.id).join(",");
      const { path } = routes.graph;
      const url = path + "?ids=" + objects;
      history.push(encodeURI(url));
    }
  };

  // клик по именя в таблице
  const handleSpeakerNameClick = (speaker: ObjectType) => {
    const { id: root, name } = speaker;
    setSelectedRows([]);
    setPag((prev) => [...prev, { root, name }]);
    setOffset(0);
    setRoot(root);
  };

  // клик по хлебной крошке
  const handleBreadCrumbClick = (event: any, root: number) => {
    event.preventDefault();
    if (root === 0) {
      setPag([]);
      setOffset(0);
      setRoot(0);
      return;
    }
    const q = [];
    for (let i = 0; i < pag.length; i++) {
      if (pag[i].root === root) {
        q.push(pag[i]);
        break;
      }
      q.push(pag[i]);
    }
    const last = q.slice(-1)[0];
    setPag(q);
    setOffset(0);
    setRoot(last.root);
  };

  useEffect(() => {
    ObjectService.getCount(root)
      .then(({ data }) => {
        const { count } = data;
        setCount(count);
        setState(() => ({ loading: true, objects: [], error: undefined }));
        ObjectService.getAll(root, offset, perPage)
          .then(({ data }) => {
            setState(() => ({ objects: data, error: undefined, loading: false }));
          })
          .catch((error) => {
            setState(() => ({ objects: [], loading: false, error }));
          });
      })
      .catch((error) => {
        catchError(error.response.data);
        setCount(0);
      });
  }, [catchError, root, offset, perPage]);

  useEffect(() => {
    error !== undefined && catchError(error);
  }, [error, catchError]);

  useEffect(() => {
    setSelectedRows([]);
  }, [objects]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  return (
    <Paper className={classes.root}>
      <div className={classes.bc}>
        {pag.length === 0 && (
          <Breadcrumbs aria-label="breadcrumb">
            <Typography>Главная</Typography>
          </Breadcrumbs>
        )}

        {pag.length !== 0 && (
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href={path} onClick={(e: any) => handleBreadCrumbClick(e, 0)}>
              Главная
            </Link>
            {pag.map((el) => (
              <Link key={el.root} color="inherit" href={path} onClick={(e: any) => handleBreadCrumbClick(e, el.root)}>
                {el.name}
              </Link>
            ))}
          </Breadcrumbs>
        )}
      </div>

      <div className={classes.actions}>
        <div className={classes.buttons}>
          <IconButton
            className={classes.mr10}
            onClick={() => setOpenSettingsTableDialog(true)}
            title="Настройка колонок таблицы"
            size="small"
          >
            <GridOnIcon fontSize="inherit" />
          </IconButton>
          <div className={classes.searchWrapper}>
            <TextField
              className={classes.search}
              placeholder="Имя"
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.mr10}
              onClick={handleOpenResult}
              disabled={selectedRows.length !== 1}
            >
              Открыть в результатах
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.mr10}
              onClick={handleOpenGraph}
              disabled={selectedRows.length === 0}
            >
              Открыть на графе
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.mr10}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.mr10}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={selectedRows.length === 0}
            >
              Удалить
            </Button>
            <Button color="primary" size="small" variant="contained" onClick={() => setOpenCreateDialog(true)}>
              Создать
            </Button>
          </div>
        </div>
      </div>

      <ObjectsTable
        rowData={objects}
        setGridApi={setGridApi}
        setColumnApi={setColumnApi}
        onSelection={onSelection}
        handleSpeakerNameClick={handleSpeakerNameClick}
      />

      <div className={classes.pagination}>
        <PaginationPanel countSelected={selectedRows.length} countTotal={count} setOffset={setOffset} />
      </div>

      <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />
      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
      <CreateObjectDialog open={openCreateDialog} onClose={handleCloseCreateDialog} parentId={getParentId()} />

      {selectedRows.length === 1 && (
        <UpdateObjectDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} obj={selectedRows[0]} />
      )}
    </Paper>
  );
};

export default Objects;
