import { AxiosResponse } from "axios";
import { Label } from "types/label";
import { labels as route } from "http/urls";
import api from "http/api";

function LabelService() {
  function getAll(): Promise<AxiosResponse<Label[]>> {
    const url = route.getAll;
    return api.get<Label[]>(url);
  }

  function create(body: any) {
    const url = route.create;
    return api.post(url, body);
  }

  function update(body: Label): Promise<AxiosResponse> {
    return api.put(route.update, body);
  }

  function remove(ids: string) {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  return Object.freeze({
    getAll,
    create,
    update,
    remove,
  });
}

export default LabelService();
