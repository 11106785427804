import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Namespace } from "types/namespace";
import useStorages from "hooks/useStorages";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
  body: {
    padding: "0 15px 15px 15px",
    position: "relative",
  },
  inputWrapper: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
  },
  input: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  select: {
    width: 200,
  },
  title: {
    padding: "15px 15px 5px 15px",
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  space: Namespace;
}

const UpdateNamespaceDialog: FC<Props> = ({ open, onClose, space }) => {
  const classes = useStyles();

  const { rowData: storages } = useStorages();

  const [state, setState] = useState(space);
  const { name, storageId, comment } = state;
  const disabled = name.length === 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    setState((prev) => ({
      ...prev,
      storageId: event.target.value as number,
    }));
  };

  function handleSave() {
    onClose({ ...state, storageId: Number(state.storageId) });
  }

  useEffect(() => {
    setState(space);
  }, [space]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактировать пространства</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth variant="standard" name="name" onChange={handleChange} value={name} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              value={comment}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="select-diskId-label">Диск</InputLabel>
            <Select labelId="select-diskId-label" value={storageId} onChange={handleSelectChange} fullWidth>
              {storages.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateNamespaceDialog;
