import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import { addRow, removeRows, updateColumnOrder, updateRow } from "components/agGrid/functions";
import EventService from "services/EventService";
import { Event as EventType } from "types/event";
import useEvents from "hooks/useEvents";
import { showSuccessAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";
import { Permission } from "types/permission";
import { setPageEventsTableSettings } from "redux/actions/pageSettingsActions";
import { catchError, checkError } from "functions/common";

// components
import EventsTable from "./components/EventsTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import CreateEventDialog from "./components/CreateEventDialog";
import UpdateEventDialog from "./components/UpdateEventDialog";
import SetEventPermissions from "./components/SetEventPermissions";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  mr10: {
    marginRight: 10,
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchWrapper: {
    flexGrow: 1,
  },
  searchInput: {
    fontSize: 14,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 151px)",
  },
}));

const Events: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { events: rowData, error, loading } = useEvents();

  const [selectedRows, setSelectedRows] = useState<EventType[]>([]);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSetPermissionsDialog, setOpenSetPermissionsDialog] = useState(false);
  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageEventsTableSettings(colState));
    }
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows.map((el) => el.id).join(",");
      EventService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch(catchError);
    }
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data) {
      EventService.create(data)
        .then(({ data }) => {
          addRow(data, gridApi);
        })
        .catch(catchError);
    }
  };

  const handleCloseUpdateDialog = (data?: any) => {
    setOpenUpdateDialog(false);
    if (data) {
      EventService.update(data)
        .then(({ data }) => {
          updateRow(data, gridApi);
        })
        .catch(catchError);
    }
  };

  const isDisabledRemove = useCallback(() => {
    if (selectedRows.length === 0) return true;
    return !selectedRows.every((n) => n.isCanWrite);
  }, [selectedRows]);

  const isDisabledUpdate = useCallback(() => {
    if (selectedRows.length !== 1) return true;
    return !selectedRows[0].isCanWrite;
  }, [selectedRows]);

  // изменение прав
  const handleCloseChangePermissionsDialog = (data?: Permission[]) => {
    setOpenSetPermissionsDialog(false);
    if (data !== undefined && selectedRows.length === 1) {
      const { id } = selectedRows[0];
      EventService.setPermissions(id, data)
        .then(() => {
          dispatch(showSuccessAlert("Права изменены"));
        })
        .catch(catchError);
    }
  };

  useEffect(() => {
    checkError(error);
  }, [error]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.buttons}>
          <IconButton
            className={classes.mr10}
            onClick={() => setOpenSettingsTableDialog(true)}
            title="Настройка колонок таблицы"
            size="small"
          >
            <GridOnIcon fontSize="inherit" />
          </IconButton>
          <div className={classes.searchWrapper}>
            <TextField
              className={classes.search}
              placeholder="Имя"
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.mr10}
              disabled={isDisabledUpdate()}
              onClick={() => setOpenSetPermissionsDialog(true)}
            >
              Права
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.mr10}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={isDisabledRemove()}
            >
              Удалить
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.mr10}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
            <Button color="primary" size="small" variant="contained" onClick={() => setOpenCreateDialog(true)}>
              Создать
            </Button>
          </div>
        </div>
      </div>

      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}

      {!loading && (
        <EventsTable rowData={rowData} setGridApi={setGridApi} setColumnApi={setColumnApi} onSelection={onSelection} />
      )}

      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
      <CreateEventDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
      <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />

      {selectedRows.length === 1 && (
        <>
          <SetEventPermissions
            open={openSetPermissionsDialog}
            onClose={handleCloseChangePermissionsDialog}
            id={selectedRows[0].id}
          />
          <UpdateEventDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} event={selectedRows[0]} />
        </>
      )}
    </Paper>
  );
};

export default Events;
