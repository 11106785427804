import { AxiosResponse } from "axios";
import { BaseStation } from "types/baseStation";
import { baseStations as route } from "http/urls";
import api from "http/api";

function BaseStationService() {
  function getAll(offset = 0, count = 1000, query = ""): Promise<AxiosResponse<BaseStation[]>> {
    const tQuery = query.length === 0 ? "" : "?search=" + query;
    const url = route.getAll.replace(":offset", String(offset)).replace(":count", String(count)) + tQuery;
    return api.get<BaseStation[]>(url);
  }

  function getCount(query = "") {
    const tQuery = query.length === 0 ? "" : "?search=" + query;
    const url = route.count + tQuery;
    return api.get(url);
  }

  function create(body: any) {
    const url = route.create;
    return api.post(url, body);
  }

  function update(body: BaseStation): Promise<AxiosResponse> {
    return api.put(route.update, body);
  }

  function remove(ids: string) {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  return Object.freeze({
    getAll,
    getCount,
    create,
    update,
    remove,
  });
}

export default BaseStationService();
