import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef } from "react";
import { AnalyticElement } from "types";
import { Group } from "types/queryBuilder";
import clsx from "clsx";
import { generateUuid } from "functions/common";
import { subscriber } from "subscribers/ScrollSubscriber";
import useAvailableFilters from "hooks/admin/useAvailableFilters";

// components
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: 600,
    border: "1px solid " + grey[300],
    marginRight: 10,
  },
  textarea: {
    border: "1px solid " + grey[300],
    width: "100%",
  },
  active: {
    backgroundColor: green[200],
  },
  filter: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  button: {},
  fields: {
    maxHeight: 500,
    overflow: "auto",
    paddingRight: theme.spacing(1),
  },
  field: {
    marginTop: 10,
  },
  filter2: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  input: {
    flexGrow: 1,
    marginRight: 10,
  },
}));

export interface ElementType {
  id: number;
  filterV2: string;
  fields: AnalyticElement[];
}

interface Props {
  el: ElementType;
  remove: (id: number) => void;
  onChangeFilter: (id: number, filter: string) => void;
  handleSearch: (id: number) => void;
  onChangeField: (event: ChangeEvent<HTMLTextAreaElement>, id: number) => void;
}

const Element: FC<Props> = ({ el, remove, onChangeField, handleSearch, onChangeFilter }) => {
  const classes = useStyles();
  const { filters: availableFilters } = useAvailableFilters("records");
  const { id, fields } = el;

  const setFilter = useCallback(
    (group: Group) => {
      onChangeFilter(el.id, JSON.stringify(group));
    },
    [el, onChangeFilter]
  );

  const filter = useMemo(() => {
    try {
      return JSON.parse(el.filterV2);
    } catch (e) {
      return {
        type: "group",
        uuid: generateUuid(),
        operation: "AND",
        filters: [],
      };
    }
  }, [el]);

  const ref = useRef<HTMLDivElement | null>(null);

  const handleScroll = (event: any) => {
    const { scrollTop } = event.target;
    if (event.target.classList.contains("app_analytica-field")) {
      subscriber.next({ scrollTop, id });
    }
  };

  useEffect(() => {
    subscriber.subscribe(({ scrollTop, id: scrolledId }) => {
      if (scrolledId !== id && ref && ref.current) {
        ref.current.scrollTop = scrollTop;
      }
    });
  }, [id]);

  return (
    <div className={classes.root}>
      <div className={classes.filter}>
        <div className={classes.filter2}>
          <HistoryFilters setFilter={setFilter} />
          <div className={classes.input}>
            <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
          </div>
          <Button size="small" onClick={() => handleSearch(id)} variant="contained" color="primary">
            Поиск
          </Button>
        </div>
        <IconButton className={classes.button} onClick={() => remove(id)}>
          <HighlightOffIcon fontSize="small" />
        </IconButton>
      </div>
      <div className={clsx(classes.fields, "app_analytica-field")} onScroll={handleScroll} ref={ref}>
        {fields.map((f) => (
          <div key={f.name} className={classes.field}>
            <Typography>{f.name}</Typography>
            <textarea
              name={f.name}
              className={clsx(classes.textarea, { [classes.active]: f.isActive ?? false })}
              rows={10}
              value={f.value}
              onChange={(e) => onChangeField(e, id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Element;
