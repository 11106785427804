import React, { FC, useMemo } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  list: {
    backgroundColor: "#b7e4ff",
    width: 200,
    border: "1px solid #80ceff",
    color: grey[900],
  },
  key: {
    width: 80,
  },
}));

interface Props {
  values: number[];
}

const RangeInfo: FC<Props> = ({ values }) => {
  const classes = useStyles();
  const sum = useMemo(() => values.reduce((acc, curr) => acc + curr), [values]);
  const min = useMemo(
    () =>
      values.reduce((acc, curr) => {
        if (curr < acc) {
          acc = curr;
        }
        return acc;
      }),
    [values]
  );
  const max = useMemo(
    () =>
      values.reduce((acc, curr) => {
        if (curr > acc) {
          acc = curr;
        }
        return acc;
      }),
    [values]
  );
  const avg = values.length === 0 ? 0 : sum / values.length;

  return (
    <List className={classes.list}>
      <ListItem>
        <span className={classes.key}>{"Сумма: "}</span>
        <span>{Math.round(sum * 100) / 100}</span>
      </ListItem>
      <ListItem>
        <span className={classes.key}>{"Минимум: "}</span>
        <span>{Math.round(min * 100) / 100}</span>
      </ListItem>
      <ListItem>
        <span className={classes.key}>{"Максимум: "}</span>
        <span>{Math.round(max * 100) / 100}</span>
      </ListItem>
      <ListItem>
        <span className={classes.key}>{"Среднее: "}</span>
        <span>{Math.round(avg * 100) / 100}</span>
      </ListItem>
    </List>
  );
};

export default RangeInfo;
