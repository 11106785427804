import React, { FC, useState, ChangeEvent } from "react";
import useLists from "hooks/useLists";

// material ui
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

interface Props {
  setListValue: (value: string) => void;
}

const ListSelect: FC<Props> = ({ setListValue }) => {
  const { lists } = useLists();
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setValue(value as number);
    if (Number(value) === 0) {
      setListValue("");
    }

    if (Number(value) !== 0) {
      const list = lists.find((l) => l.id === Number(value));
      if (list) {
        setListValue(list.list);
      }
    }
  };

  return (
    <Select value={value} onChange={handleChange} fullWidth>
      <MenuItem value={0}>Текущий</MenuItem>
      {lists.map((l) => (
        <MenuItem key={l.id} value={l.id}>
          {l.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ListSelect;
