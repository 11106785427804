import { BehaviorSubject } from "rxjs";
import { WsDataType, WsDataTypeSpeaker } from "types/ws";

const initial: WsDataTypeSpeaker = {
  type: WsDataType.SPEAKER_UPDATE_STATUS,
  data: {
    id: -1,
    status: "notProcessed",
    message: "",
    models: [{ id: -1, status: "notProcessed", message: "" }],
    files: [{ id: -1, status: "notProcessed", message: "" }],
  },
};

const subscriber = new BehaviorSubject(initial);

const SpeakerStatusSubscriber = {
  send: (curr: WsDataTypeSpeaker) => subscriber.next(curr),
};

export { subscriber, SpeakerStatusSubscriber };
