import React, { useState, FC, ChangeEvent } from "react";
import clsx from "clsx";
import { TaskTechVad, VadType } from "types/task";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(() => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  tech: TaskTechVad;
}

const Vad: FC<Props> = ({ open, onClose, tech }) => {
  const classes = useStyles();

  const [state, setState] = useState(tech);
  const { vadType } = state;

  const handleSave = () => {
    onClose(state);
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const vadType = event.target.value as VadType;
    setState((prev) => ({
      ...prev,
      vadType,
    }));
  };

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Настройка</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-vadType">Детектор активности речи</InputLabel>
              <Select labelId="select-vadType" value={vadType} onChange={handleChange} fullWidth>
                <MenuItem value="vad">По умолчанию</MenuItem>
                <MenuItem value="allFile">Использовать все данные как один речевой сегмент</MenuItem>
                <MenuItem value="vadUkv">Для данных с низким отношением сигнал/шум</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default Vad;
