import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { SpeakerModel } from "types/speaker";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { strOnlyFloat } from "../../../functions/common";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  model: SpeakerModel;
}

const UpdateModelDataDialog: FC<Props> = ({ open, onClose, model }) => {
  const classes = useStyles();

  const [name, setName] = useState(model.name);
  const [similarity, setSimilarity] = useState(String(model.similarity));

  const disabled = name.length === 0;

  const handleChangeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setName(value);
  };

  const handleChangeSimilarity = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setSimilarity(strOnlyFloat(value));
  };

  const handleSave = () => {
    onClose({ ...model, name, similarity: parseFloat(similarity) });
  };

  useEffect(() => {
    setName(model.name);
    setSimilarity(String(model.similarity));
  }, [model]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактировать данные модели</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth name="name" onChange={handleChangeName} value={name} />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Вероятность"
              fullWidth
              name="similarity"
              onChange={handleChangeSimilarity}
              value={similarity}
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSave}
                disabled={disabled}
              >
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateModelDataDialog;
