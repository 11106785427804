import { MenuItem } from "types";

export function comparePriority(a: MenuItem, b: MenuItem) {
  if (a.priority < b.priority) {
    return -1;
  }
  if (a.priority > b.priority) {
    return 1;
  }
  return 0;
}
