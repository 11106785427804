import { AxiosResponse } from "axios";
import { NbsCategory as T } from "types/nbsCategory";
import { nbsCategory as route } from "http/urls";
import api from "http/api";

function NbsCategoryService() {
  function getAll(): Promise<AxiosResponse<T[]>> {
    const url = route.getAll;
    return api.get<T[]>(url);
  }

  function create(body: T): Promise<AxiosResponse<T>> {
    const url = route.create;
    return api.post<T>(url, body);
  }

  function update(body: T): Promise<AxiosResponse<T>> {
    return api.put<T>(route.update, body);
  }

  function remove(ids: string): Promise<AxiosResponse> {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  return Object.freeze({
    getAll,
    create,
    update,
    remove,
  });
}

export default NbsCategoryService();
