import React, { FC, useEffect, useMemo, useState } from "react";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { Group } from "types/queryBuilder";
import { generateUuid } from "functions/common";

// components
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  fields: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 10,
  },
  filter: {
    display: "flex",
    alignItems: "center",
    marginBottom: 28,
  },
  input: {
    flexGrow: 1,
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
}

const CreateCleanUpDialog: FC<Props> = ({ open, onClose }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();
  const { filters: availableFilters } = useAvailableFilters("records");

  const [filter, setFilter] = useState<Group>(defaultFilter);

  function handleSave() {
    onClose(JSON.stringify(filter));
    setFilter(defaultFilter);
  }

  useEffect(() => {
    setFilter(defaultFilter);
  }, [open, defaultFilter]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Создать новый фильтр очистки</DialogTitle>

      <DialogContent>
        <Grid item xs={12}>
          <div className={classes.filter}>
            <div className={classes.input}>
              <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
            </div>
            <HistoryFilters setFilter={setFilter} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.buttons}>
            <Button className={classes.button} size="small" onClick={() => onClose()}>
              Отмена
            </Button>
            <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
              Ок
            </Button>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCleanUpDialog;
