import { WS_SET_IS_CONNECT, WsState, WsAction } from "redux/types/wsTypes";

const initialState: WsState = {
  isConnect: false,
};

export default (state = initialState, action: WsAction) => {
  const { type } = action;

  if (type === WS_SET_IS_CONNECT) {
    return {
      ...state,
      isConnect: action.payload,
    };
  }

  return state;
};
