import React, { FC, useCallback, useState } from "react";
import {
  Group as TGroup,
  Rule as TRule,
  Operation as TOperation,
  SelectedGroup,
  SelectedRule,
} from "types/queryBuilder";
import { Filter as TFilter } from "types";
import { getDefaultGroup, isGroup } from "./helperFunctions";
import { generateUuid } from "functions/common";
import RenderGroup from "./RenderGroup";
import DetailRule from "./DetailRule";
import DetailGroup from "./DetailGroup";
import DetailDefault from "./DetailDefault";
import { AdminTypeFilter, HistoryFilter } from "types/admin";
import FilterService from "services/FilterService";
import { showErrorAlert, showSuccessAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";
import { BehaviorSubject } from "rxjs";

// components
import NoData from "../NoData";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CreateFilterDialog from "./CreateFilterDialog";
import UploadFilterDialog from "./UploadFilterDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  query: {
    paddingTop: theme.spacing(2),
  },
  actions: {},
  buttons: {},
  button: {
    marginRight: 10,
    // width: 145,
    "&:last-child": {
      marginRight: 0,
    },
  },
  mr20: {
    marginRight: 20,
  },
  noData: {
    marginTop: 190,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  renderGroup: {
    // maxHeight: "calc(100vh - 239px)",
    height: 500,
    overflow: "auto",
    padding: 2,
  },
}));

interface Props {
  availableFilters: AdminTypeFilter[];
  query: TGroup;
  setQuery: Function;
}

let minimizeAllGroup: boolean | undefined = undefined;
export const subscriber = new BehaviorSubject<undefined | boolean>(minimizeAllGroup);

const ExtendedFilter: FC<Props> = ({ availableFilters, query, setQuery }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState<SelectedGroup>({ group: undefined, parent: undefined });
  const [selectedRule, setSelectedRule] = useState<SelectedRule>({ rule: undefined, parent: undefined });

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data) {
      const reqBody = {
        ...data,
        filter: JSON.stringify(query),
      };
      FilterService.create(reqBody)
        .then(() => {
          dispatch(showSuccessAlert("Фильтр сохранён."));
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseUploadDialog = (filter?: TFilter | HistoryFilter) => {
    setOpenUploadDialog(false);
    if (filter) {
      try {
        const f = JSON.parse(filter.filter);
        setQuery(f);
      } catch (err) {
        dispatch(showErrorAlert(err.message));
      }
    }
  };

  // добавить правило или группу в группу
  const addRuleOrGroupToGroup = (item: TRule | TGroup, group: TGroup) => {
    setQuery((prevState: TGroup) => {
      (function update(parentGroup: TGroup) {
        if (parentGroup.uuid === group.uuid) {
          parentGroup.filters.push(item);
        } else {
          const childGroups = parentGroup.filters.filter((item) => isGroup(item));
          childGroups.forEach((item) => update(item as TGroup));
        }
      })(prevState);
      return {
        ...prevState,
      };
    });
  };

  // добавить правило в группу
  const addRuleToGroup = (rule: TRule, group: TGroup) => {
    addRuleOrGroupToGroup(rule, group);
  };

  // добавить группу в группу
  const addGroupToGroup = (newGroup: TGroup, group: TGroup) => {
    addRuleOrGroupToGroup(newGroup, group);
  };

  // удалиь правило или группу из группы
  const removeRuleOrGroupFromGroup = (item: TRule | TGroup, group: TGroup) => {
    setQuery((prevState: TGroup) => {
      (function update(parentGroup: TGroup) {
        if (parentGroup.uuid === group.uuid) {
          parentGroup.filters = parentGroup.filters.filter((el) => el.uuid !== item.uuid);
        } else {
          const childGroups = parentGroup.filters.filter((item) => isGroup(item));
          childGroups.forEach((item) => update(item as TGroup));
        }
      })(prevState);

      return {
        ...prevState,
      };
    });
  };

  // удаилть правило из группы
  const removeRuleFromGroup = (rule: TRule, group: TGroup) => {
    removeRuleOrGroupFromGroup(rule, group);
  };

  // удалить группу из группы
  const removeGroupFromGroup = (removedGroup: TGroup, group: TGroup) => {
    removeRuleOrGroupFromGroup(removedGroup, group);
  };

  // редактировать условие в группе
  const changeGroupOperation = (group: TGroup, operation: TOperation) => {
    setQuery((prevState: TGroup) => {
      (function update(parentGroup: TGroup) {
        if (parentGroup.uuid === group.uuid) {
          parentGroup.operation = operation;
        } else {
          const childGroups = parentGroup.filters.filter((item) => isGroup(item));
          childGroups.forEach((item) => update(item as TGroup));
        }
      })(prevState);

      return {
        ...prevState,
      };
    });
  };

  // редактировать правило, rule - правило которое менять, group - группа в которой находится правило
  const changeRule = (rule: TRule, group: TGroup) => {
    setQuery((prevState: TGroup) => {
      (function updateRule(parentGroup: TGroup) {
        if (parentGroup.uuid === group.uuid) {
          parentGroup.filters = parentGroup.filters.map((el) => (el.uuid === rule.uuid ? rule : el));
        } else {
          const childGroups = parentGroup.filters.filter((item) => isGroup(item));
          childGroups.forEach((item) => updateRule(item as TGroup));
        }
      })(prevState);

      return {
        ...prevState,
      };
    });
  };

  // добавление нового правила в группу
  const handleAddRuleToGroup = () => {
    if (availableFilters.length === 0) return;

    const rule: TRule = {
      type: "filter",
      uuid: generateUuid(),
      condition: "=",
      filter: availableFilters[0].name,
      value: "",
    };

    // если группа не выбрана
    if (selectedGroup.group === undefined) {
      // если есть выбранное правило, то добавить новое в группу выбранного правила
      if (selectedRule.rule !== undefined && selectedRule.parent !== undefined) {
        addRuleToGroup(rule, selectedRule.parent);
        setSelectedRule({ rule, parent: selectedRule.parent });
      } else {
        // если правило не выбрано, добавить в корневую группу
        addRuleToGroup(rule, query);
        setSelectedRule({ rule, parent: query });
      }
    } else {
      // если группа выбрана, добавить в неё
      addRuleToGroup(rule, selectedGroup.group);
      setSelectedRule({ rule, parent: selectedGroup.group });
    }
    setSelectedGroup({ group: undefined, parent: undefined });
  };

  const handleAddGroupToGroup = () => {
    subscriber.next(undefined);
    const newGroup = getDefaultGroup();

    if (selectedGroup.group === undefined) {
      addGroupToGroup(newGroup, query);
      setSelectedGroup({ group: newGroup, parent: query });
    } else {
      addGroupToGroup(newGroup, selectedGroup.group);
      setSelectedGroup({ group: newGroup, parent: selectedGroup.group });
    }
    setSelectedRule({ rule: undefined, parent: undefined });
  };

  const deleteGroup = () => {
    const { parent, group } = selectedGroup;
    if (parent === undefined || group === undefined) return;
    removeGroupFromGroup(group, parent);
    setSelectedGroup({ group: undefined, parent: undefined });
  };

  const deleteRule = () => {
    const { parent, rule } = selectedRule;
    if (rule === undefined) return;
    if (parent === undefined) {
      removeRuleFromGroup(rule, query);
      setSelectedRule({ rule: undefined, parent: undefined });
      return;
    }
    removeRuleFromGroup(rule, parent);
    setSelectedRule({ rule: undefined, parent: undefined });
  };

  const handleDelete = () => {
    if (selectedRule.rule !== undefined && selectedGroup.group === undefined) {
      deleteRule();
    }

    if (selectedGroup.group !== undefined && selectedRule.rule === undefined) {
      deleteGroup();
    }
  };

  const handleMinimizeAllGroup = () => {
    minimizeAllGroup = minimizeAllGroup === undefined ? true : !minimizeAllGroup;
    subscriber.next(minimizeAllGroup);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className={classes.head}>
            <div className={classes.actions}>
              <Button
                // variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => setOpenCreateDialog(true)}
                disabled={query.filters.length === 0}
              >
                сохранить
              </Button>
              <Button
                // variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => setOpenUploadDialog(true)}
              >
                загрузить
              </Button>
            </div>
            <div className={classes.buttons}>
              <Button
                // variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleAddRuleToGroup}
              >
                новое правило
              </Button>
              <Button
                // variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleAddGroupToGroup}
              >
                новая группа
              </Button>
              <Button
                // variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                disabled={query.filters.length === 0}
                onClick={handleMinimizeAllGroup}
              >
                свернуть/развернуть
              </Button>
              <Button
                // variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleDelete}
                disabled={selectedRule.rule === undefined && selectedGroup.group === undefined}
              >
                удалить
              </Button>
            </div>
          </div>
          <div className={classes.renderGroup}>
            {query.filters.length === 0 && (
              <div className={classes.noData}>
                <NoData />
              </div>
            )}
            <RenderGroup
              group={query}
              parentGroup={undefined}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              setSelectedRule={setSelectedRule}
              selectedRule={selectedRule}
              availableFilters={availableFilters}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          {selectedRule.rule && (
            <DetailRule
              rule={selectedRule.rule}
              parentGroup={selectedRule.parent ?? query}
              changeRule={changeRule}
              availableFilters={availableFilters}
            />
          )}
          {selectedGroup.group && (
            <DetailGroup group={selectedGroup.group} changeGroupOperation={changeGroupOperation} />
          )}
          {!selectedGroup.group && !selectedRule.rule && <DetailDefault />}
        </Grid>
      </Grid>

      <CreateFilterDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
      <UploadFilterDialog open={openUploadDialog} onClose={handleCloseUploadDialog} />
    </div>
  );
};

export default ExtendedFilter;
