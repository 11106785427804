import React, { ChangeEvent, FC, useState } from "react";
import { Permission as P } from "types/permission";
import PermissionRow from "./PermissionRow";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(() => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  table: {},
}));

interface Props {
  open: boolean;
  onClose: Function;
  permissions: P[];
}

const SetPermissionsDialog: FC<Props> = ({ open, onClose, permissions: p }) => {
  const classes = useStyles();
  const [permissions, setPermissions] = useState(p);

  function handleSave() {
    onClose(permissions);
  }

  const handleChangePermission = (p: P) => {
    setPermissions((prev) => prev.map((el) => (el.id === p.id ? p : el)));
  };

  const setIsCanForAllUsers = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setPermissions((prev) => prev.map((p) => ({ ...p, [name]: checked })));
  };

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Установить права</DialogTitle>
      <DialogContent>
        <div className={classes.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Логин</TableCell>
                <TableCell align="right">
                  Просмотр
                  <Checkbox
                    checked={permissions.every((p) => p.isCanRead)}
                    name="isCanRead"
                    onChange={setIsCanForAllUsers}
                    color="primary"
                  />
                </TableCell>
                <TableCell align="right">
                  Редактирование
                  <Checkbox
                    checked={permissions.every((p) => p.isCanWrite)}
                    name="isCanWrite"
                    onChange={setIsCanForAllUsers}
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions.map((p) => (
                <PermissionRow key={p.id} permission={p} changePermission={handleChangePermission} />
              ))}
            </TableBody>
          </Table>
        </div>

        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SetPermissionsDialog;
