import { useEffect, useState } from "react";
import { Label } from "types/label";
import LabelService from "services/LabelService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  labels: Label[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  labels: [],
  error: undefined,
};

const useLabels = () => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    LabelService.getAll()
      .then(({ data }) => {
        setState(() => ({ labels: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ labels: [], loading: false, error: err }));
      });
  }, []);

  return state;
};

export default useLabels;
