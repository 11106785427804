import React from "react";

// material ui
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const AdminQuestion: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton style={{ color: "#fff" }} aria-label="помощь" onClick={handleClick}>
        <HelpOutlineIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>Как пользоваться ...</MenuItem>
        <MenuItem onClick={handleClose}>Как настроить ...</MenuItem>
        <MenuItem onClick={handleClose}>Как включить ...</MenuItem>
      </Menu>
    </>
  );
};

export default AdminQuestion;
