import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { SpeakerFile, SpeakerModel, SpeakerType, UserFile } from "types/speaker";
import { generateUuid } from "functions/common";

// components
import Models from "./Models";
import Files from "./Files";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
    padding: "0 20px 10px 0",
  },
  button: {
    width: 100,
  },
  grid: {
    height: 884,
    overflowY: "auto",
    overflowX: "hidden",
  },
  models: {
    paddingTop: 20,
  },
}));

interface State {
  speakerType: SpeakerType;
  name: string;
  comment: string;
  country: string;
  city: string;
  address: string;
  phoneModel: string;
  telecomOperator: string;
  isDeleteInText: boolean;
  isHighlightInText: boolean;
  models: SpeakerModel[];
  files: SpeakerFile[];
}

const initialState: State = {
  name: "",
  comment: "",
  country: "",
  city: "",
  address: "",
  phoneModel: "",
  telecomOperator: "",
  speakerType: "speaker",
  isDeleteInText: false,
  isHighlightInText: false,
  models: [],
  files: [],
};

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
}

const CreateSpeakerDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [state, setState] = useState<State>(initialState);
  const [userFiles, setUserFiles] = useState<UserFile[]>([]);

  const {
    name,
    comment,
    country,
    city,
    address,
    phoneModel,
    telecomOperator,
    models,
    files,
    isDeleteInText,
    isHighlightInText,
    speakerType,
  } = state;

  const disabled = name.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addModel = (model: SpeakerModel) => {
    setState((prev) => ({
      ...prev,
      models: [...prev.models, model],
    }));
  };

  const removeModels = (models: SpeakerModel[]) => {
    setState((prev) => ({
      ...prev,
      models: prev.models.filter((m) => !models.find((el) => el.id === m.id)),
    }));
  };

  const addFiles = (fileList: FileList) => {
    const files = Array.from(fileList);
    const speakerFiles: SpeakerFile[] = [];
    const userFiles: UserFile[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const uuid = generateUuid();
      speakerFiles.push({
        id: -(Date.now() + i * 6),
        speakerId: 0,
        name: file.name,
        fileIdUpload: uuid,
        status: "notProcessed",
        message: "ожидает загрузки на сервер",
        ownerId: 0,
        dateChanged: new Date().toISOString(),
        dateCreated: new Date().toISOString(),
      });
      userFiles.push({
        uuid,
        file,
      });
    }

    setUserFiles((prev) => [...prev, ...userFiles]);
    setState((prev) => ({
      ...prev,
      files: [...prev.files, ...speakerFiles],
    }));
  };

  const removeFiles = (speakerFiles: SpeakerFile[]) => {
    setUserFiles((prev) => prev.filter((f) => !speakerFiles.find((el) => el.fileIdUpload === f.uuid)));
    setState((prev) => ({
      ...prev,
      files: prev.files.filter((f) => !speakerFiles.find((el) => el.id === f.id)),
    }));
  };

  const handleSave = useCallback(() => {
    onClose({ speaker: state, userFiles });
  }, [onClose, state, userFiles]);

  const handleChangeSpeakerType = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setState((prev) => ({ ...prev, speakerType: value as SpeakerType }));
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (!open) {
      setState(initialState);
      setUserFiles([]);
    }
  }, [open]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Создать дикторскую карточку</DialogTitle>

      <DialogContent>
        <div className={classes.grid}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField label="Имя" fullWidth name="name" onChange={handleChange} value={name} autoFocus />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="type-label">Объект</InputLabel>
                <Select labelId="type-label" value={speakerType} onChange={handleChangeSpeakerType} fullWidth>
                  <MenuItem value="speaker">Диктор</MenuItem>
                  <MenuItem value="autoinformator">Автоинформатор</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Модель телефона"
                fullWidth
                name="phoneModel"
                onChange={handleChange}
                value={phoneModel}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Оператор"
                fullWidth
                name="telecomOperator"
                onChange={handleChange}
                value={telecomOperator}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Страна" fullWidth name="country" onChange={handleChange} value={country} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Город" fullWidth name="city" onChange={handleChange} value={city} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Адрес" fullWidth name="address" onChange={handleChange} value={address} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
            </Grid>

            {speakerType === "autoinformator" && (
              <>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isDeleteInText}
                        onChange={handleCheckbox}
                        name="isDeleteInText"
                        color="primary"
                      />
                    }
                    label="Удалять в тексте"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isHighlightInText}
                        onChange={handleCheckbox}
                        name="isHighlightInText"
                        color="primary"
                      />
                    }
                    label="Выделять в тексте"
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <div className={classes.models}>
                <Models
                  models={models}
                  addModel={addModel}
                  removeModels={removeModels}
                  speakerId={undefined}
                  updateModel={() => {}}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Files files={files} addFiles={addFiles} removeFiles={removeFiles} speakerId={undefined} />
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <div className={classes.buttons}>
        <Button className={classes.button} size="small" onClick={() => onClose()}>
          Отмена
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          color="primary"
          onClick={handleSave}
          disabled={disabled}
        >
          Ок
        </Button>
      </div>
    </Dialog>
  );
};

export default CreateSpeakerDialog;
