import { DRAWER_CLOSE, DRAWER_OPEN, DrawerAction } from "redux/types/drawerTypes";

export function drawerOpen(): DrawerAction {
  return {
    type: DRAWER_OPEN,
  };
}

export function drawerClose(): DrawerAction {
  return {
    type: DRAWER_CLOSE,
  };
}
