import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}));

const ColorRender: FC<{ value: string }> = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ width: 17, height: 17, backgroundColor: value, marginRight: 10 }} />
      <div>{value}</div>
    </div>
  );
};

export default ColorRender;
