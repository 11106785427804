import React, { FC, useCallback, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import { SpeakerModel } from "types/speaker";
import ModelsTable from "./ModelsTable";
import UploadFileDialog from "./UploadFileDialog";
import { records as routes, API_VERSION } from "http/urls";
import { updateRow } from "components/agGrid/functions";
import { subscriber } from "subscribers/SpeakerStatusSubscriber";
import SpeakerService from "services/SpeakerService";
import UpdateFileDialog from "./UpdateFileDialog";
import UpdateModelDataDialog from "./UpdateModelDataDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  root: {},
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  buttons: {},
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  models: SpeakerModel[];
  addModel: (m: SpeakerModel) => void;
  updateModel: (m: SpeakerModel) => void;
  removeModels: (m: SpeakerModel[]) => void;
  speakerId: number | undefined;
}

const Models: FC<Props> = ({ models, addModel, speakerId, removeModels, updateModel }) => {
  const classes = useStyles();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<SpeakerModel[]>([]);
  const [openUploadFileDialog, setOpenUploadFileDialog] = useState(false);
  const [openUpdateFileDialog, setOpenUpdateFileDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  // создание
  const handleCloseOpenUploadFileDialog = (data?: { audioSrc: string; sessionID: number | string }) => {
    setOpenUploadFileDialog(false);
    if (data) {
      const model: SpeakerModel = {
        id: -Date.now(),
        speakerId: speakerId ?? 0,
        name: "модель голоса - " + Date.now(),
        audioEditorId: String(data.sessionID),
        status: "notProcessed",
        similarity: 0.4,
        message: "ожидает загрузки на сервер",
        ownerId: 0,
        dateChanged: new Date().toISOString(),
        dateCreated: new Date().toISOString(),
      };
      addModel(model);
    }
  };

  // редактирование
  const handleCloseOpenUpdateFileDialog = (data?: { audioSrc: string; sessionID: number }) => {
    setOpenUpdateFileDialog(false);
    if (data) {
      try {
        const model = selectedRows[0];
        updateModel({ ...model, audioEditorId: String(data.sessionID) });
        setSelectedRows([]);
      } catch (e) {}
    }
  };

  const handleCloseUpdateDialog = (data?: SpeakerModel) => {
    setOpenUpdateDialog(false);
    if (data) {
      try {
        updateRow(data, gridApi);
        const model = selectedRows[0];
        updateModel({ ...model, ...data });
        setSelectedRows([]);
      } catch (e) {}
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleRemove = () => {
    removeModels(selectedRows);
    setSelectedRows([]);
  };

  const getFileUrl = () => {
    if (speakerId === undefined || selectedRows.length === 0) return "";

    const path = routes.getSpeakerAudioFile
      .replace(":speakerId", String(speakerId))
      .replace(":modelId", String(selectedRows[0].id));
    return `/api/${API_VERSION}${path}`;
  };

  const isDisabledDownload = useCallback(() => {
    if (speakerId === undefined) return true;
    if (selectedRows.length !== 1) return true;
    if (selectedRows.length === 1) {
      if (selectedRows[0].id > 0) return false;
    }
    return true;
  }, [selectedRows, speakerId]);

  const handleDownload = () => {
    if (speakerId === undefined) return;
    const model = selectedRows[0];

    SpeakerService.getAudioFile(speakerId, model.id).then(({ data }) => {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = url;
      a.download = model.name;
      a.click();
      a.remove();
    });
  };

  useEffect(() => {
    subscriber.subscribe((wsData) => {
      const { id, models } = wsData.data;
      if (id !== speakerId) return;
      if (gridApi) {
        gridApi.forEachNode((node) => {
          models.forEach(({ id, status, message }) => {
            if (node.id === String(id)) {
              node.setData({ ...node.data, status, message });
            }
          });
        });
      }
    });
  }, [gridApi, speakerId]);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Typography gutterBottom>Модели</Typography>
        <div className={classes.buttons}>
          <Button
            className={classes.mr10}
            color="primary"
            size="small"
            onClick={handleRemove}
            title="Удалить"
            disabled={selectedRows.length === 0}
          >
            удалить
          </Button>
          <Button
            className={classes.mr10}
            color="primary"
            size="small"
            onClick={handleDownload}
            title="Скачать файл"
            disabled={isDisabledDownload()}
          >
            экспорт
          </Button>
          <Button
            className={classes.mr10}
            color="primary"
            size="small"
            onClick={() => setOpenUpdateDialog(true)}
            title="Редактировать данные модели"
            disabled={selectedRows.length !== 1}
          >
            параметры
          </Button>
          <Button
            className={classes.mr10}
            color="primary"
            size="small"
            onClick={() => setOpenUpdateFileDialog(true)}
            disabled={selectedRows.length !== 1 || speakerId === undefined || speakerId <= 0}
            title="Редактировать аудио"
          >
            редактировать
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => setOpenUploadFileDialog(true)}
            title="Загрузить аудио"
          >
            загрузить
          </Button>
        </div>
      </div>

      <ModelsTable rowData={models} setGridApi={setGridApi} onSelection={onSelection} />

      <UploadFileDialog open={openUploadFileDialog} onClose={handleCloseOpenUploadFileDialog} />

      {selectedRows.length === 1 && (
        <>
          <UpdateFileDialog open={openUpdateFileDialog} onClose={handleCloseOpenUpdateFileDialog} file={getFileUrl()} />
          <UpdateModelDataDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} model={selectedRows[0]} />
        </>
      )}
    </div>
  );
};

export default Models;
