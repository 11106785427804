import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Word } from "types/word";
import { ColorResult, TwitterPicker } from "react-color";
import SelectLanguage from "components/SelectLanguages";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { blue, cyan, deepPurple, green, indigo, orange, pink, purple, red, yellow } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
}));

const colors = [
  yellow[300],
  red[300],
  pink[300],
  purple[300],
  deepPurple[300],
  indigo[300],
  blue[300],
  cyan[300],
  orange[300],
  green[300],
];

interface Props {
  open: boolean;
  onClose: (data?: Word) => void;
  item: Word;
}

const UpdateWordDialog: FC<Props> = ({ open, onClose, item }) => {
  const classes = useStyles();

  const [state, setState] = useState(item);
  const { name, comment, color, words, lang } = state;

  const disabled = name.length === 0;

  const setLanguage = (lang: string) => {
    setState((prev) => ({
      ...prev,
      lang,
    }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = useCallback(() => {
    onClose(state);
  }, [onClose, state]);

  useEffect(() => {
    setState(item);
  }, [item]);

  const handleChangeColor = (c: ColorResult) => {
    setState((prev) => ({
      ...prev,
      color: c.hex,
    }));
  };

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактировать ключевые слова</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth name="name" onChange={handleChange} value={name} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>
          <Grid item xs={12}>
            <SelectLanguage selectedLanguage={lang} setSelectedLanguage={setLanguage} />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Цвет</Typography>
            <TwitterPicker
              colors={colors}
              width="912px"
              color={color}
              onChangeComplete={handleChangeColor}
              triangle="hide"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Ключевые слова"
              fullWidth
              multiline
              rows={16}
              name="words"
              onChange={handleChange}
              value={words}
              InputProps={{
                onKeyDown: (e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                    e.currentTarget.value += " ";
                  }
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateWordDialog;
