import React, { useState, FC, ChangeEvent, useEffect } from "react";
import { GridApi } from "ag-grid-community";
import clsx from "clsx";
import { routes } from "routes";
import { useHistory } from "react-router-dom";
import { Dictionary } from "types/dictionary";
import { RootState } from "redux/types";
import { useSelector } from "react-redux";
import useDictionaries from "hooks/dictionary/useDictionaries";
import useDictionariesCount from "hooks/dictionary/useDictionariesCount";
import { checkError } from "functions/common";

// components
import PaginationPanel from "components/pagination/PaginationPanel";
import DictionariesTable from "./components/DictionariesTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import CreateDictionaryDialog from "./components/CreateDictionaryDialog";
import EditDictionaryDialog from "./components/EditDictionaryDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {},
  empty: {
    color: theme.palette.text.disabled,
    textAlign: "center",
  },
  progress: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  ml4: {
    marginLeft: theme.spacing(4),
  },
  pagination: {
    paddingTop: 5,
  },
}));

const Dictionaries: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { rowsPerPage: perPage } = useSelector((state: RootState) => state.settings);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Dictionary[]>([]);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);

  const [offset, setOffset] = useState(0);
  const [queryString] = useState("");

  const { count } = useDictionariesCount(queryString);
  const { rowData, error } = useDictionaries(offset, perPage, queryString);

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    console.log(value);
  };

  const handleCloseCreateDictionaryDialog = (data?: any) => {
    setOpenCreateDialog(false);

    if (data !== undefined) {
      console.log(data);
    }
  };

  const handleDeleteDict = () => {
    setOpenDeleteConfirmationDialog(true);
  };

  const onCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteConfirmationDialog(false);
    if (confirm) {
      console.log("DELETE");
    }
  };

  const handleCloseUpdateDictionaryDialog = (dict?: any) => {
    setOpenEditDialog(false);
    if (dict !== undefined) {
      console.log(dict);
    }
  };

  const handleOpen = () => {
    const id = selectedRows[0].id;
    history.push(routes.dictionaries.words.path.replace(":dictId", String(id)));
  };

  useEffect(() => {
    checkError(error);
  }, [error]);

  useEffect(() => {
    setSelectedRows([]);
  }, [rowData]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.actions}>
              <div className={classes.buttons}>
                <div>
                  <TextField
                    className={classes.search}
                    placeholder="Имя словаря"
                    onChange={onQuickFilterChanged}
                    InputProps={{
                      className: classes.searchInput,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={selectedRows.length === 0}
                    onClick={handleDeleteDict}
                  >
                    Удалить
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={selectedRows.length !== 1}
                    onClick={() => setOpenEditDialog(true)}
                  >
                    Редактировать
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={selectedRows.length !== 1}
                    onClick={handleOpen}
                  >
                    Открыть
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={clsx(classes.button, classes.ml4)}
                    onClick={() => setOpenCreateDialog(true)}
                  >
                    Создать
                  </Button>
                </div>
              </div>
            </div>

            <DictionariesTable rowData={rowData} setGridApi={setGridApi} onSelection={onSelection} />
            <div className={classes.pagination}>
              <PaginationPanel countSelected={selectedRows.length} countTotal={count} setOffset={setOffset} />
            </div>
          </Paper>
        </Grid>
      </Grid>

      <ConfirmationDialog open={openDeleteConfirmationDialog} onClose={onCloseDeleteDialog} />
      <CreateDictionaryDialog open={openCreateDialog} onClose={handleCloseCreateDictionaryDialog} />
      {selectedRows.length === 1 && (
        <EditDictionaryDialog
          open={openEditDialog}
          onClose={handleCloseUpdateDictionaryDialog}
          dictionary={selectedRows[0]}
        />
      )}
    </div>
  );
};

export default Dictionaries;
