import React, { FC } from "react";
import { ColorResult, GithubPicker } from "react-color";

// material ui
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
} from "@material-ui/core/colors";

interface Props {
  onChangeColor: (color: string) => void;
}

const ColorPicker: FC<Props> = ({ onChangeColor }) => {
  const colors: string[] = [
    red[500],
    purple[500],
    deepPurple[500],
    indigo[500],
    blue[500],
    lightBlue[500],
    pink[500],
    cyan[500],
    teal[500],
    green[500],
    lightGreen[500],
    lime[500],
    yellow[500],
    amber[500],
    orange[500],
    deepOrange[500],
    brown[500],
    grey[500],
  ];

  const handleChange = (color: ColorResult) => {
    const { hex } = color;
    onChangeColor(hex);
  };

  return <GithubPicker triangle="hide" colors={colors} width="238px" onChange={handleChange} />;
};

export default ColorPicker;
