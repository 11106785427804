import React, { FC } from "react";
import { phones } from "./phones";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import BackspaceIcon from "@material-ui/icons/Backspace";

const useStyles = makeStyles(() => ({
  root: {},
  keys: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    gridGap: 10,
  },
  btn: {
    textTransform: "none",
  },
  specialBtns: {
    textAlign: "right",
    marginBottom: 10,
  },
}));

interface Props {
  lang: string;
  onClick: (v: string) => void;
}

const Keyboard: FC<Props> = ({ lang, onClick }) => {
  const classes = useStyles();
  const keys = phones[lang] ?? phones.english;

  return (
    <div className={classes.root}>
      <div className={classes.specialBtns}>
        <Button onClick={() => onClick("delete")} variant="contained" color="primary" startIcon={<BackspaceIcon />}>
          Удалить
        </Button>
      </div>
      <div className={classes.keys}>
        {keys.map((k: string) => (
          <Button key={k} onClick={() => onClick(k)} variant="contained" className={classes.btn}>
            {k}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Keyboard;
