import { useCallback, useEffect, useState } from "react";
import RecordService from "services/RecordService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  count: number;
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  count: 0,
  error: undefined,
};

const useRecordsCount = (query: string) => {
  const [state, setState] = useState<State>(initialState);

  const getCount = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));
    RecordService.getCount(query)
      .then(({ data }) => {
        setState(() => ({ count: data.count, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ count: 0, loading: false, error: err }));
      });
  }, [query]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return { ...state, getCount };
};

export default useRecordsCount;
