import { AxiosResponse } from "axios";
import { viceAssistant as route } from "http/urls";
import api from "http/api";

function VoiceAssistantService() {
  function push(body: any): Promise<AxiosResponse> {
    const url = route.push;
    return api.post(url, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  return Object.freeze({
    push,
  });
}

export default VoiceAssistantService();
