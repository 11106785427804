import React, { FC, useMemo, useState } from "react";
import { Record, RecordDetail } from "types/record";
import { Speaker, SpeakerModel } from "types/speaker";
import { API_URL, records } from "http/urls";

// components
import UpdateSpeakerDialog from "pages/speakers/components/UpdateSpeakerDialog";
import UpdateFileDialog from "pages/speakers/components/UpdateFileDialog";

interface State {
  openAudioEditor: boolean;
  speaker: Speaker;
}

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  record: Record;
  recordDetail: RecordDetail;
}

const CreateSpeakerFromResultDialog: FC<Props> = ({ onClose, open, record, recordDetail }) => {
  const defaultSpeaker: Speaker = useMemo(
    () => ({
      id: -1,
      parentId: 0,
      speakerType: "speaker",
      name: record.name,
      comment: "",
      country: "",
      city: "",
      address: "",
      phoneModel: "",
      telecomOperator: "",
      isDeleteInText: false,
      isHighlightInText: false,
      models: [],
      files: [],
      status: "notProcessed",
      message: "",
      ownerId: -1,
      dateChanged: "",
      dateCreated: "",
    }),
    [record]
  );

  const audioURL = useMemo(() => {
    const url = API_URL + records.getFileData;
    return decodeURI(url.replace(":viewType", "audio") + `?q=${recordDetail.filter}`);
  }, [recordDetail]);

  const [state, setState] = useState<State>({ openAudioEditor: true, speaker: defaultSpeaker });
  const { openAudioEditor, speaker } = state;

  const handleCloseOpenUpdateFileDialog = (data?: { audioSrc: string; sessionID: number | string }) => {
    if (data === undefined) {
      onClose();
    }

    if (data) {
      const model: SpeakerModel = {
        id: -Date.now(),
        speakerId: 0,
        name: "модель голоса - " + Date.now(),
        audioEditorId: String(data.sessionID),
        status: "notProcessed",
        similarity: 0.4,
        message: "ожидает загрузки на сервер",
        ownerId: 0,
        dateChanged: new Date().toISOString(),
        dateCreated: new Date().toISOString(),
      };
      setState((prev) => ({
        openAudioEditor: false,
        speaker: { ...prev.speaker, models: [...prev.speaker.models, model] },
      }));
    }
  };

  if (open && openAudioEditor) {
    return <UpdateFileDialog open={openAudioEditor} onClose={handleCloseOpenUpdateFileDialog} file={audioURL} />;
  }

  return <UpdateSpeakerDialog speaker={speaker} open={open} onClose={onClose} />;
};

export default CreateSpeakerFromResultDialog;
