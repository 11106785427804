import { AuthResponse } from "types/auth";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REFRESH = "REFRESH";

export interface AuthState {
  isLoggedIn: boolean;
  accessToken: string;
  error: string;
  id: number;
  isAdmin: boolean;
  login: string;
}

export type AuthAction =
  | { type: typeof LOGIN_SUCCESS; payload: AuthResponse }
  | { type: typeof LOGIN_FAIL; payload: { message: string } }
  | { type: typeof REFRESH; payload: { accessToken: string } }
  | { type: typeof LOGOUT };
