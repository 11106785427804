import { AxiosResponse } from "axios";
import { Passport } from "types/admin";
import { admin } from "http/urls";
import api from "http/api";

const { passports } = admin;
const { Protected: protectedRoute } = passports;

function PassportsService() {
  function getAllPublic(): Promise<AxiosResponse<Passport[]>> {
    return api.get<Passport[]>(passports.getAll);
  }

  function getAll(): Promise<AxiosResponse<Passport[]>> {
    return api.get<Passport[]>(protectedRoute.getAll);
  }

  function getById(id: string) {
    const url = protectedRoute.getById.replace(":id", id);
    return api.get(url);
  }

  function create(body: any) {
    return api.post(protectedRoute.create, body);
  }

  function update(body: any) {
    return api.put(protectedRoute.update, body);
  }

  function remove(ids: string) {
    const url = protectedRoute.remove.replace(":ids", ids);
    return api.delete(url);
  }

  return Object.freeze({
    getAllPublic,
    getAll,
    getById,
    create,
    update,
    remove,
  });
}

export default PassportsService();
