import React, { FC, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import useMacAddresses from "hooks/macAddresses/useMacAddresses";
import useMacAddressesCount from "hooks/macAddresses/useMacAddressesCount";
import MacAddressService from "services/MacAddressService";
import { MacAddress } from "types/macAddress";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { addRow, removeRows, updateRow } from "components/agGrid/functions";
import { catchError, checkError } from "functions/common";

// components
import MacAddressesTable from "./components/MacAddressesTable";
import PaginationPanel from "components/pagination/PaginationPanel";
import CreateMacAddressDialog from "./components/CreateMacAddressDialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import UpdateMacAddressDialog from "./components/UpdateMacAddressDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
}));

const MacAddresses: FC = () => {
  const classes = useStyles();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { rowsPerPage: perPage } = useSelector((state: RootState) => state.settings);
  const [query, setQuery] = useState("");
  const { count } = useMacAddressesCount(query);
  const [offset, setOffset] = useState(0);

  const { macAddresses: rowData, error } = useMacAddresses(offset, perPage, query);

  const onQuickFilterSend = (event: any) => {
    const { value } = event.target;

    if (event.key !== "Enter") {
      return;
    }

    if (value === query) {
      return;
    }

    setOffset(0);
    if (value === "") {
      setQuery("");
    } else {
      setQuery(value.trim());
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows.map((e) => e.id).join(",");
      MacAddressService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch(catchError);
    }
  };

  const handleCloseCreateDialog = (data?: MacAddress) => {
    setOpenCreateDialog(false);
    if (data) {
      MacAddressService.create(data)
        .then((res) => {
          addRow(res.data, gridApi);
        })
        .catch(catchError);
    }
  };

  const handleCloseUpdateDialog = (data?: MacAddress) => {
    setOpenUpdateDialog(false);
    if (data) {
      MacAddressService.update(data)
        .then((res) => {
          updateRow(res.data, gridApi);
        })
        .catch(catchError);
    }
  };

  useEffect(() => {
    checkError(error);
  }, [error]);

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.buttons}>
          <div>
            <TextField
              className={classes.search}
              placeholder="Поиск..."
              onKeyPress={onQuickFilterSend}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={selectedRows.length === 0}
            >
              Удалить
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => setOpenCreateDialog(true)}
            >
              Добавить
            </Button>
          </div>
        </div>
      </div>

      <MacAddressesTable
        rowData={rowData}
        setGridApi={setGridApi}
        onSelection={onSelection}
        height="calc(100vh - 190px)"
      />
      <div className={classes.pagination}>
        <PaginationPanel countSelected={selectedRows.length} countTotal={count} setOffset={setOffset} />
      </div>

      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
      <CreateMacAddressDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />

      {selectedRows.length === 1 && (
        <UpdateMacAddressDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} item={selectedRows[0]} />
      )}
    </Paper>
  );
};

export default MacAddresses;
