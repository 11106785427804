import React, { ChangeEvent, FC, useState } from "react";
import clsx from "clsx";
import useNamespaces from "hooks/namespace/useNamespaces";
import { Namespace } from "types/namespace";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const useStyles = makeStyles(() => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface SelectNamespacesProps {
  open: boolean;
  onClose: (namespaceId?: number) => void;
  namespaces: Namespace[];
}

const SelectNamespace: FC<SelectNamespacesProps> = ({ open, onClose, namespaces }) => {
  const classes = useStyles();

  const [state, setState] = useState(namespaces[0].id);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setState(() => event.target.value as number);
  };

  const handleSave = () => {
    const n = namespaces.find((n) => n.id === state);
    onClose(n ? n.id : undefined);
  };

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Выберите пространство для копирования</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="namespace-label">Пространство</InputLabel>
              <Select labelId="namespace-label" value={state} onChange={handleChange} fullWidth>
                {namespaces.map((n) => (
                  <MenuItem key={n.id} value={n.id}>
                    {n.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface Props {
  open: boolean;
  onClose: (namespaceId?: number) => void;
}

const CopyDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const { namespaces, loading, error } = useNamespaces();
  if (loading) {
    return null;
  }
  if (error) {
    return (
      <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
        <DialogTitle>Выберите пространство для копирования</DialogTitle>
        <DialogContent>
          <Alert severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {error?.response?.data?.message}
          </Alert>
          <div className={classes.buttons}>
            <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
              Отмена
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => onClose()}
            >
              Ок
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  if (namespaces.length === 0) {
    return (
      <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
        <DialogTitle>Выберите пространство для копирования</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>Внимание</AlertTitle>
            {"В системе нет созданных пространств"}
          </Alert>
          <div className={classes.buttons}>
            <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
              Отмена
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => onClose()}
            >
              Ок
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  return <SelectNamespace open={open} onClose={onClose} namespaces={namespaces} />;
};

export default CopyDialog;
