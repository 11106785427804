import React, { ChangeEvent, FC, useState } from "react";
import clsx from "clsx";
import { TaskTechDiarization } from "types/task";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(() => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  tech: TaskTechDiarization;
}

const Diarization: FC<Props> = ({ open, onClose, tech }) => {
  const classes = useStyles();

  const [state, setState] = useState(tech);
  const { speakerCount } = state;

  const handleSave = () => {
    onClose(state);
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setState((prev) => ({ ...prev, speakerCount: Number(value) }));
  };

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Настройка</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="speaker-select">Количество дикторов</InputLabel>
              <Select labelId="speaker-select" value={speakerCount} onChange={handleChange} fullWidth>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default Diarization;
