import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { FilterType, Group as TGroup, Rule as TRule, SelectedRule } from "types/queryBuilder";
import clsx from "clsx";
import { getConditionName } from "./helperFunctions";
import { getFormatDate } from "functions/date";
import { AdminTypeFilter, UrlParams } from "types/admin";
import { Record } from "types";
import FilterService from "services/admin/FilterService";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { grey } from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(() => ({
  root: {
    padding: 5,
    border: "1px solid " + grey[400],
    borderRadius: 3,
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
  },
  selected: {
    border: "1px solid " + grey[400],
  },
  field: {
    width: 200,
  },
  checkbox: {
    marginRight: 10,
  },
  rule: {},
  chip: {
    marginRight: 10,
  },
}));

interface PropsValue {
  rule: TRule;
  baseFilter: AdminTypeFilter;
}

const ObjectValue: FC<PropsValue> = ({ rule, baseFilter }) => {
  const classes = useStyles();
  const [label, setLabel] = useState("");

  useEffect(() => {
    const params = baseFilter.params as UrlParams;
    const { value } = rule;

    if (!params || !params.url) return;

    const url = params.url + "/getByIds";
    let ids: number[] = [];
    if (value !== "") {
      ids = value.split("\n").map((str) => parseInt(str, 10));
    }
    FilterService.doCustomPostQuery(url, { ids }).then((res) => {
      if (res.data) {
        const records = res.data as Record[];
        const label = records.map((r) => r.name).join(", ");
        setLabel(label);
      }
    });
  }, [rule, baseFilter]);

  return <Chip label={label.length > 70 ? label.slice(0, 70) + "..." : label} className={classes.chip} />;
};

const Value: FC<PropsValue> = ({ rule, baseFilter }) => {
  const classes = useStyles();
  const { value, filter } = rule;

  if (baseFilter.type === FilterType.Object || baseFilter.type === FilterType.Tree) {
    return <ObjectValue rule={rule} baseFilter={baseFilter} />;
  }

  if (baseFilter.type === FilterType.Boolean) {
    return <Chip label={value === "true" ? "да" : "нет"} className={classes.chip} />;
  }

  if (filter === "Дата сеанса") {
    const date = value.replaceAll(" ", "+").toString().split("\n");
    if (value === "" || date.length !== 2) {
      return <Chip label={""} className={classes.chip} />;
    }
    // console.log(moment(date[2]).format("DD-MM-YYYY HH:mm:ss"));
    const label = "" + getFormatDate(date[0]) + " - " + getFormatDate(date[1]);
    return <Chip label={label} className={classes.chip} />;
  }

  const label = value.length > 70 ? value.slice(0, 70) + "..." : value;
  return <Chip label={label} className={classes.chip} />;
};

interface Props {
  rule: TRule;
  selectedRule: SelectedRule;
  setSelectedRule: Function;
  parentGroup: TGroup | undefined;
  setSelectedGroup: Function;
  availableFilters: AdminTypeFilter[];
}

const RenderRule: FC<Props> = ({
  rule,
  selectedRule,
  setSelectedRule,
  parentGroup,
  setSelectedGroup,
  availableFilters,
}) => {
  const classes = useStyles();
  const { filter, condition } = rule;
  const checked = useMemo(() => selectedRule.rule?.uuid === rule.uuid, [rule, selectedRule]);

  const baseFilter = availableFilters.find((p) => p.name === rule.filter);
  if (!baseFilter) {
    return null;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (selectedRule.rule?.uuid === rule.uuid && !checked) {
      setSelectedRule({ rule: undefined, parent: undefined });
    } else {
      setSelectedRule({ rule, parent: parentGroup });
    }
    setSelectedGroup({ group: undefined, parent: undefined });
  };

  return (
    <div className={clsx(classes.root, { [classes.selected]: checked })}>
      <div className={classes.checkbox}>
        <Checkbox checked={checked} onChange={handleChange} color="primary" />
      </div>
      <div className={classes.rule}>
        <Chip label={filter} className={classes.chip} />
        <Chip label={getConditionName(condition)} className={classes.chip} />
        <Value rule={rule} baseFilter={baseFilter} />
      </div>
    </div>
  );
};

export default RenderRule;
