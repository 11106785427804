import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { LidClass } from "types/admin/lidModel";
import { FolderType, defaultFolderSftp, defaultFolderSmb, FolderSmb as FolderSmbType, SmbVersion } from "types/source";

// components
import SelectProtocol from "pages/sources/components/SelectProtocol";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  textArea: {
    backgroundColor: "#f6f6ee",
    padding: theme.spacing(1),
    border: "none",
    outline: "none",
    width: "100%",
  },
  textAlignRight: {
    textAlign: "right",
  },
}));

const initialState: LidClass = {
  userName: "",
  storageDetail: defaultFolderSftp,
};

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
}

const AddLIdClassDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [state, setState] = useState<LidClass>(initialState);
  const { userName, storageDetail } = state;

  const disabled = userName.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onClose(state);
  };

  const handleChangeSmbVersion = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    const newStorageDetail: FolderSmbType = {
      ...(storageDetail as FolderSmbType),
      smbVersion: value as SmbVersion,
    };
    setState((prev) => ({ ...prev, storageDetail: newStorageDetail }));
  };

  const setPathValue = (value: string, name?: string) => {
    const pathName = name === undefined ? "path" : name;
    setState((prev) => ({ ...prev, storageDetail: { ...prev.storageDetail, [pathName]: value } }));
  };

  const onChangeInputElement = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      storageDetail: {
        ...prev.storageDetail,
        [name]: value,
      },
    }));
  };

  const onChangeConnectionType = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    if (value === FolderType.SFTP) {
      setState((prev) => ({
        ...prev,
        storageDetail: defaultFolderSftp,
      }));
    }

    if (value === FolderType.SMB) {
      setState((prev) => ({
        ...prev,
        storageDetail: defaultFolderSmb,
      }));
    }
  };

  useEffect(() => {
    if (!open) {
      setState(initialState);
    }
  }, [open]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Добавить класс</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth name="userName" onChange={handleChange} value={userName} autoFocus />
          </Grid>
          <Grid item xs={12}>
            <SelectProtocol
              folderDetail={storageDetail}
              onChangeConnectionType={onChangeConnectionType}
              onChangeSmbVersion={handleChangeSmbVersion}
              onChangeInputElement={onChangeInputElement}
              setPathValue={setPathValue}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddLIdClassDialog;
