import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Transcription as Tr } from "types/admin/lmModel";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
}));

const initialState: Tr = {
  word: "",
  transcription: "",
};

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
}

const CreateTranscriptionDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [state, setState] = useState<Tr>(initialState);
  const { word, transcription } = state;

  const disabled = word.length === 0 || transcription.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!open) {
      setState(initialState);
    }
  }, [open]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Добавить транскрипцию</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Слово" fullWidth name="word" onChange={handleChange} value={word} autoFocus />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Транскрипция"
              fullWidth
              name="transcription"
              onChange={handleChange}
              value={transcription}
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={() => onClose(state)}
                disabled={disabled}
              >
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTranscriptionDialog;
