import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

// material ui
import { makeStyles } from "@material-ui/core/styles";

import WifiOffIcon from "@material-ui/icons/WifiOff";
import WifiIcon from "@material-ui/icons/Wifi";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 5,
  },
}));

const WsConnect: FC = () => {
  const classes = useStyles();
  const { isConnect } = useSelector((state: RootState) => state.ws);
  const titleStatus = isConnect ? "соединение установлено" : "установление соединения";
  return (
    <div className={classes.root} title={"WebSocket Статус: " + titleStatus}>
      {isConnect ? <WifiIcon /> : <WifiOffIcon />}
    </div>
  );
};

export default WsConnect;
