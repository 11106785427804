import React, { FC } from "react";
import { RecordFile } from "types";
import { API_URL, records } from "http/urls";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
  icon: {
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.primary.main,
    marginTop: 10,
  },
}));

const DownloadFileRenderer: FC<{ value: string; data: RecordFile }> = ({ data }) => {
  const classes = useStyles();
  const { accessToken } = useSelector((state: RootState) => state.auth);

  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const baseUrl = API_URL + records.getFileData;
    const queryFilter = JSON.stringify([{ storageFileId: data.storageFileId, codec: data.codec }]);
    const url = baseUrl.replace(":viewType", "bits") + `?q=${queryFilter}&authorization=${accessToken}`;

    let name = data.name;
    if (!data.name.endsWith(data.codec)) {
      name += "." + data.codec;
    }

    fetch(url).then((res) => {
      res.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
      });
    });
  };

  return (
    <div className={classes.icon} onClick={handleClick} title="Скачать оригинальный файл">
      <GetAppIcon />
    </div>
  );
};

export default DownloadFileRenderer;
