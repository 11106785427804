import React, { FC, useCallback, useMemo } from "react";
import clsx from "clsx";
import { setPageObjectsTableSettings } from "redux/actions/pageSettingsActions";
import { RootState } from "redux/types";
import { Object as TObject } from "types/record";
import { useDispatch, useSelector } from "react-redux";

// ag-grid
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import { ColumnResizedEvent } from "ag-grid-community";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import ColorRenderer from "components/agGrid/renderers/ColorRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 220px)",
  },
  span: {},
  folder: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

interface Props {
  rowData: TObject[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
  handleSpeakerNameClick: any;
}

const ObjectsTable: FC<Props> = ({ rowData, setGridApi, setColumnApi, onSelection, handleSpeakerNameClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { objects: pageSettings } = useSelector((state: RootState) => state.pageSettings);

  const NameRenderer: FC<{ value: string; data: TObject }> = ({ value, data }) => {
    const classes = useStyles();
    return (
      <span className={classes.folder} onClick={() => handleSpeakerNameClick(data)}>
        {value}
      </span>
    );
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Идентификатор",
        field: "id",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 100,
      },
      {
        headerName: "Имя",
        field: "name",
        cellRenderer: "NameRenderer",
      },
      {
        headerName: "Комментарий",
        field: "comment",
        flex: 1,
      },
      {
        headerName: "Цвет",
        field: "color",
        cellRenderer: "ColorRenderer",
      },
      {
        headerName: "Кластеризован по",
        field: "clusterBy",
      },
      {
        headerName: "Дата изменения",
        field: "dateChanged",
        cellRenderer: "DateRenderer",
      },
    ],
    []
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (pageSettings.table !== undefined) {
      params.columnApi.setColumnState(pageSettings.table);
    }
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: TObject) => String(data.id);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageObjectsTableSettings(colState));
      }
    },
    [dispatch]
  );

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        onColumnResized={onColumnResized}
        suppressMovableColumns
        suppressColumnMoveAnimation
        suppressCopyRowsToClipboard
        frameworkComponents={{
          DateRenderer,
          ColorRenderer,
          NameRenderer,
        }}
      />
    </div>
  );
};

export default ObjectsTable;
