import { SET_SELECTED_TAB } from "../types/selectedTabTypes";

const initialState = {
  selectedTab: 0,
};

const selectedTabReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    default:
      return state;
  }
};

export default selectedTabReducer;
