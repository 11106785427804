import React, { ChangeEvent, FC, KeyboardEvent, useCallback } from "react";
import clsx from "clsx";
import { NumberParams } from "types/admin";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  input: {
    paddingRight: 30,
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    cursor: "pointer",
    width: 20,
    height: 15,
    position: "absolute",
    border: "1px solid",
  },
  up: {
    top: 0,
    right: 0,
  },
  down: {
    top: 18,
    right: 0,
  },
  btns: {
    marginTop: 20,
  },
  chip: {
    marginRight: 10,
  },
}));

interface Props {
  value: number;
  changeValue: (value: string) => void;
  params: NumberParams;
}

const NumberInput: FC<Props> = ({ value, changeValue, params }) => {
  const classes = useStyles();

  const max = Number(params.max);
  const min = Number(params.min);
  const step = Number(params.step);

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    const whiteList = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ""];
    let v = "";
    for (let i = 0; i < value.length; i++) {
      if (whiteList.includes(value[i])) {
        v += value[i];
      }
    }
    changeValue(v);
  };

  const increase = useCallback(() => {
    const newValue = Number(value) + step;
    if (newValue > max) return;
    changeValue(String(newValue));
  }, [value, changeValue, step, max]);

  const decrease = useCallback(() => {
    const newValue = Number(value) - step;
    if (newValue < min) return;
    changeValue(String(newValue));
  }, [value, changeValue, step, min]);

  return (
    <div className={classes.root}>
      <TextField className={classes.input} value={value} onChange={handleChange} fullWidth onKeyDown={onKeyDown} />
      <div className={clsx(classes.up, classes.btn)} onClick={increase}>
        <ExpandLessIcon />
      </div>
      <div className={clsx(classes.down, classes.btn)} onClick={decrease}>
        <ExpandMoreIcon />
      </div>
    </div>
  );
};

export default NumberInput;
