import { AxiosResponse } from "axios";
import { AccessDeleteRecord } from "types/admin";
import { admin, access } from "http/urls";
import api from "http/api";

const { access: route } = admin;

function SettingsService() {
  function getAccessRemoveRecords(): Promise<AxiosResponse<AccessDeleteRecord[]>> {
    return api.get<AccessDeleteRecord[]>(route.removeRecords);
  }

  function setAccessRemoveRecords(body: AccessDeleteRecord): Promise<AxiosResponse<AccessDeleteRecord>> {
    return api.post<AccessDeleteRecord>(route.setRemoveRecords, body);
  }

  function getUserAccessRemoveRecords(): Promise<AxiosResponse<AccessDeleteRecord>> {
    return api.get<AccessDeleteRecord>(access.getRemoveRecordAccess);
  }

  return Object.freeze({
    getAccessRemoveRecords,
    setAccessRemoveRecords,
    getUserAccessRemoveRecords,
  });
}

export default SettingsService();
