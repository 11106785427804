import React from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  noFile: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: theme.typography.fontSize,
  },
}));

const NoFile: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.noFile}>
      <p>Фаил не выбран</p>
    </div>
  );
};

export default NoFile;
