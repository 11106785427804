import { getCyrillicLangName } from "functions/common";

export function langGetter(params: any) {
  // ODD: 2023-12-06 17:14
  // если с бэка прийдут некорректные данные(не сможет объединить по group), то ag-grid передает в функцию undefined и фронт крашиться
  if (!params.data) {
    return "";
  }
  const { lang } = params.data;
  return getCyrillicLangName(lang);
}
