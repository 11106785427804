import React from "react";
import { default as GraphVis } from "react-graph-vis";

import "./vis-network.css";

const GraphView = ({ graphData, setNetwork, graphEvents }) => {
  const graph = {
    nodes: graphData.graphNodes,
    edges: graphData.graphEdges,
  };

  let options = {
    autoResize: true,
    layout: {
      hierarchical: false,
      improvedLayout: false,
    },
    locale: "ru",
    interaction: {
      keyboard: false,
      multiselect: true,
    },

    physics: {
      barnesHut: {
        springConstant: 0,
        avoidOverlap: 0.2,
      },
      stabilization: {
        enabled: true,
      },
    },

    edges: {
      arrows: {
        to: false,
        from: false,
      },
    },
    height: "820px",
  };

  return <GraphVis graph={graph} options={options} events={graphEvents} getNetwork={setNetwork} />;
};

export default GraphView;
