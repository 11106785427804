import { fileEditor } from "http/urls";
import api from "http/api";

function FileEditorService() {
  function upload(fd: FormData) {
    const url = fileEditor.upload;
    return api.post(url, fd, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  function uploadUrl(body: any) {
    const url = fileEditor.uploadUrl;
    return api.post(url, body);
  }

  function operationDelete(sessionId: number, start: number, end: number) {
    const url = fileEditor.operationDelete
      .replace(":sessionId", String(sessionId))
      .replace(":start", String(start))
      .replace(":end", String(end));
    return api.delete(url, {
      headers: {
        "Content-Type": "text/plain",
      },
    });
  }

  return Object.freeze({
    upload,
    uploadUrl,
    operationDelete,
  });
}

export default FileEditorService();
