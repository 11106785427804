import React, { FC, useCallback, useEffect, useState } from "react";
import SpeakerService from "services/SpeakerService";
import { Speaker, SpeakerType } from "types/speaker";

// icons
import PersonIcon from "@material-ui/icons/Person";
import FolderIcon from "@material-ui/icons/Folder";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import CreateFolderDialog from "./CreateFolderDialog";
import { showErrorAlert } from "../redux/actions/alertActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  list: {
    height: 600,
    overflow: "auto",
  },
  listItem: {
    borderBottom: "1px solid " + grey[200],
    borderTop: "1px solid " + grey[200],
  },
}));

interface Breadcrumb {
  name: string;
  root: number;
}

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  ids: number[];
}

const IconRender: FC<{ type: SpeakerType }> = ({ type }) => {
  if (type === "speaker") return <PersonIcon />;
  if (type === "autoinformator") return <GraphicEqIcon />;
  return <FolderIcon />;
};

const SelectSpeakerFolderDialog: FC<Props> = ({ open, onClose, ids }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [root, setRoot] = useState(0);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([{ root: 0, name: "Главная" }]);
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);

  const list = speakers.filter((s) => !ids.includes(s.id));

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const handleBreadCrumbClick = (event: any, root: number) => {
    event.preventDefault();
    setRoot(root);

    setBreadcrumbs((prev) => {
      const bc: Breadcrumb[] = [];
      for (let i = 0; i < prev.length; i++) {
        const b = prev[i];
        if (b.root === root) {
          bc.push(b);
          break;
        }
        bc.push(b);
      }
      return bc;
    });
  };

  const handleListItemClick = (folder: Speaker) => {
    setRoot(folder.id);
    setBreadcrumbs((prev) => [...prev, { root: folder.id, name: folder.name }]);
  };

  // создание папки
  const handleCloseCreateFolderDialog = (data?: { name: string; comment: string }) => {
    setOpenCreateFolderDialog(false);
    if (data) {
      const fd = new FormData();
      fd.append(
        "speaker",
        JSON.stringify({
          parentId: root,
          speakerType: "folder",
          name: data.name,
          comment: data.comment,
        })
      );
      SpeakerService.create(fd).then(({ data }) => {
        setSpeakers((prev) => [data, ...prev]);
      });
    }
  };

  useEffect(() => {
    SpeakerService.getAll(root)
      .then(({ data }) => {
        setSpeakers(data);
      })
      .catch((err) => catchError(err.response.data));
  }, [root, catchError]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>
        <div className={classes.title}>
          <Typography>Выбрать папку</Typography>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => setOpenCreateFolderDialog(true)}
            endIcon={<FolderIcon />}
          >
            Создать папку
          </Button>
        </div>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              {breadcrumbs.map((bc) => (
                <Link key={bc.root} color="inherit" href="/" onClick={(e: any) => handleBreadCrumbClick(e, bc.root)}>
                  {bc.name}
                </Link>
              ))}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <List component="nav" aria-label="main mailbox folders" className={classes.list}>
              {list.length === 0 && (
                <ListItem disabled>
                  <ListItemText primary="Папка пуста" />
                </ListItem>
              )}
              {list.map((s) => (
                <ListItem
                  key={s.id}
                  button
                  onClick={() => handleListItemClick(s)}
                  className={classes.listItem}
                  disabled={s.speakerType !== "folder"}
                >
                  <ListItemIcon>
                    <IconRender type={s.speakerType} />
                  </ListItemIcon>
                  <ListItemText primary={s.name} />
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={() => onClose(root)}
              >
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <CreateFolderDialog open={openCreateFolderDialog} onClose={handleCloseCreateFolderDialog} />
    </Dialog>
  );
};

export default SelectSpeakerFolderDialog;
