import { esb as route } from "http/urls";
import api from "http/api";
import { AxiosResponse } from "axios";
import { AvailableDocument, Module } from "types/esb";

function EsbService() {
  function sendDocumentNbs(body: {
    recordId: number;
    machineId: string;
    moduleName: string;
    category: string;
    comment: string;
  }) {
    const url = route.sendNbs;
    return api.post(url, body);
  }

  function sendDocuments(body: { ids: number[] }) {
    const url = route.sendDocument;
    return api.post(url, body);
  }

  function availableDocuments(): Promise<AxiosResponse<AvailableDocument[]>> {
    const url = route.availableDocuments;
    return api.get(url);
  }

  function onlineModules(): Promise<AxiosResponse<Module[]>> {
    const url = route.onlineModules;
    return api.get(url);
  }

  return Object.freeze({
    sendDocuments,
    availableDocuments,
    onlineModules,
    sendDocumentNbs,
  });
}

export default EsbService();
