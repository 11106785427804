import {
  AlertActions,
  HIDE_ALERT,
  SHOW_ERROR_ALERT,
  SHOW_SUCCESS_ALERT,
  SHOW_WARNING_ALERT,
} from "redux/types/alertTypes";

export function showErrorAlert(message: string): AlertActions {
  return {
    type: SHOW_ERROR_ALERT,
    payload: message,
  };
}

export function showSuccessAlert(message: string): AlertActions {
  return {
    type: SHOW_SUCCESS_ALERT,
    payload: message,
  };
}

export function showWarningAlert(message: string): AlertActions {
  return {
    type: SHOW_WARNING_ALERT,
    payload: message,
  };
}

export function hideAlert(): AlertActions {
  return {
    type: HIDE_ALERT,
    payload: "",
  };
}
