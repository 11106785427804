import {
  PAGE_BASE_STATIONS_SET_TABLE_PARAMS,
  PAGE_DATASOURCES_SET_TABLE_PARAMS,
  PAGE_EVENTS_SET_TABLE_PARAMS,
  PAGE_FILTER_SET_TABLE_PARAMS,
  PAGE_IP_ADDRESSES_SET_TABLE_PARAMS,
  PAGE_LABELS_SET_TABLE_PARAMS,
  PAGE_LISTS_SET_TABLE_PARAMS,
  PAGE_NAMESPACES_SET_TABLE_PARAMS,
  PAGE_NBS_CATEGORY_SET_TABLE_PARAMS,
  PAGE_OBJECTS_SET_TABLE_PARAMS,
  PAGE_PHONE_NUMBERS_SET_TABLE_PARAMS,
  PAGE_RECORDS_SET_TABLE_PARAMS,
  PAGE_SOURCES_SET_TABLE_PARAMS,
  PAGE_SPEAKERS_SET_TABLE_PARAMS,
  PAGE_TASKS_SET_TABLE_PARAMS,
  PAGE_WORDS_SET_TABLE_PARAMS,
  PAGES_SET_SETTINGS,
  PageSettingsAction,
  PageSettingsState,
} from "redux/types/pageSettings";

export function pagesSetSettings(payload: PageSettingsState): PageSettingsAction {
  return {
    type: PAGES_SET_SETTINGS,
    payload,
  };
}

export function setPageRecordsTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_RECORDS_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageNamespacesTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_NAMESPACES_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageTasksTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_TASKS_SET_TABLE_PARAMS,
    payload,
  };
}
export function setPageDataSourcesTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_DATASOURCES_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageSourcesTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_SOURCES_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageFilterTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_FILTER_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageEventsTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_EVENTS_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageWordsTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_WORDS_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageSpeakersTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_SPEAKERS_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageObjectsTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_OBJECTS_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageLabelsTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_LABELS_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageListsTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_LISTS_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageBaseStationsTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_BASE_STATIONS_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageIpAddressesTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_IP_ADDRESSES_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPagePhoneNumbersTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_PHONE_NUMBERS_SET_TABLE_PARAMS,
    payload,
  };
}

export function setPageNbsCategoryTableSettings(payload: any): PageSettingsAction {
  return {
    type: PAGE_NBS_CATEGORY_SET_TABLE_PARAMS,
    payload,
  };
}
