import { useCallback, useEffect, useState } from "react";
import { Namespace } from "types";
import SpaceService from "services/NamespaceService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  namespaces: Namespace[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  namespaces: [],
  error: undefined,
};

const useNamespaces = () => {
  const [state, setState] = useState<State>(initialState);

  const getNamespaces = useCallback(() => {
    setState((prev) => ({ ...prev, loading: true }));
    SpaceService.getAll()
      .then(({ data }) => {
        setState({ namespaces: data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ namespaces: [], loading: false, error: err });
      });
  }, []);

  useEffect(() => {
    getNamespaces();
  }, [getNamespaces]);

  return { ...state, getNamespaces };
};

export default useNamespaces;
