import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import { SpeakerFile } from "types/speaker";
import FilesTable from "./FilesTable";
import { subscriber } from "subscribers/SpeakerStatusSubscriber";
import SpeakerService from "services/SpeakerService";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  root: {},
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  buttons: {},
  mr10: {
    marginRight: 10,
  },
  input: {
    display: "none",
  },
}));

interface Props {
  files: SpeakerFile[];
  addFiles: (f: FileList) => void;
  removeFiles: (f: SpeakerFile[]) => void;
  speakerId: number | undefined;
}

const Files: FC<Props> = ({ files, addFiles, removeFiles, speakerId }) => {
  const classes = useStyles();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<SpeakerFile[]>([]);

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleRemove = () => {
    removeFiles(selectedRows);
    setSelectedRows([]);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files !== null) {
      addFiles(files);
    }
  };

  const isDisabledDownload = useCallback(() => {
    if (speakerId === undefined) return true;
    if (selectedRows.length !== 1) return true;
    if (selectedRows.length === 1) {
      if (selectedRows[0].id > 0) return false;
    }
    return true;
  }, [selectedRows, speakerId]);

  const handleDownload = () => {
    if (speakerId === undefined) return;
    const file = selectedRows[0];

    SpeakerService.getFile(speakerId, file.id).then(({ data }) => {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      a.click();
      a.remove();
    });
  };

  useEffect(() => {
    subscriber.subscribe((wsData) => {
      const { id, files } = wsData.data;
      if (id !== speakerId) return;
      if (gridApi) {
        gridApi.forEachNode((node) => {
          files.forEach(({ id, status, message }) => {
            if (node.id === String(id)) {
              node.setData({ ...node.data, status, message });
            }
          });
        });
      }
    });
  }, [gridApi, speakerId]);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Typography gutterBottom>Файлы</Typography>
        <div className={classes.buttons}>
          <Button
            className={classes.mr10}
            color="primary"
            size="small"
            onClick={handleRemove}
            title="Удалить"
            disabled={selectedRows.length === 0}
          >
            удалить
          </Button>

          <Button
            className={classes.mr10}
            color="primary"
            size="small"
            onClick={handleDownload}
            title="Скачать файл"
            disabled={isDisabledDownload()}
          >
            экспорт
          </Button>

          <input
            accept="*/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button size="small" variant="contained" color="primary" component="span">
              загрузить
            </Button>
          </label>
        </div>
      </div>
      <FilesTable rowData={files} setGridApi={setGridApi} onSelection={onSelection} />
    </div>
  );
};

export default Files;
