import React, { useEffect, FC, useRef } from "react";
import * as echarts from "echarts";
import { Statistic } from "types/statistic";
import { LineSeries } from "types/chart";
//material ui
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(1100)]: {
      minWidth: "500px",
    },
  },
}));

type Chart = {
  name: string;
  description: string;
  series: LineSeries;
  statistic?: Statistic;
};

const AreaChart: FC<Chart> = ({ series, name, description }) => {
  const classes = useStyles();
  const chartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);

      const options = {
        tooltip: {
          trigger: "axis",
          position: function (pt: any) {
            return [pt[0], "10%"];
          },
        },
        title: {
          text: name,
          left: "center",
          top: 15,
        },
        animation: true,
        grid: {
          top: "18%",
          left: "3%",
          right: "4%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
              title: {
                zoom: "Приближение мышкой",
                back: "Назад",
              },
            },
            restore: {
              title: "Сбросить",
            },
            saveAsImage: {
              title: "Сохранить как картинку",
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: series.xaxis,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
        },
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 100,
          },
          {
            fillerColor: "rgba(220, 240, 220, 0.34)",
            borderColor: "rgba(18, 79, 45, 0.38)",
          },
        ],
        series: [
          {
            name: name,
            type: "line",
            sampling: "lttb",
            stack: "Total",
            color: "rgba(26, 107, 93, 0.9)",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(77, 182, 172, 0.7)",
                  },
                  {
                    offset: 1,
                    color: "rgba(145, 205, 217, 0.1)",
                  },
                ],
              },
            },
            data: series.yaxis,
          },
        ],
      };

      chart.setOption(options);

      //по клику на график будет какой нибудь другой переход. Какой?

      // chart.on('click', function(params) {
      //   const fromHour = parseInt(decodeURIComponent(params.name)) + 1;
      //   const toHour = encodeURIComponent(fromHour)
      //   const prefix = '/results?f='
      //   const newLine = '\\n' + toDateString
      //   const updatedFilter = filter?.replace('$1', spaceId ? spaceId : '1234');
      //   const updatedDate = updatedFilter?.replace('$2', filterDate + 'T' + encodeURIComponent(params.name) + ':00.000' + newLine + 'T' + toHour + ':00.000');
      //   const updatedUrl = replica + prefix + updatedDate
      //   const url = encodeURI(updatedUrl);
      //   console.log(url, updatedDate)
      //   window.open(
      //     url
      //   );
      // });
    }
  }, [series, name, description]);

  return <Paper className={classes.root} style={{ width: "100%", height: "500px" }} ref={chartRef} />;
};

export default AreaChart;
