import { DRAWER_OPEN, DRAWER_CLOSE, DrawerAction, DrawerState } from "redux/types/drawerTypes";
import { RootState } from "redux/types";
import { load } from "redux-localstorage-simple";

// получить сохраненный store из localstorage
const storageState: RootState = load({ namespace: "APP" }) as RootState;

const defaultState: DrawerState = {
  open: true,
};

const initialState: DrawerState = storageState.drawer ?? defaultState;

export default (state = initialState, action: DrawerAction) => {
  const { type } = action;

  if (type === DRAWER_OPEN) {
    return {
      open: true,
    };
  }

  if (type === DRAWER_CLOSE) {
    return {
      open: false,
    };
  }

  return state;
};
