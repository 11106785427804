import React, { FC, MouseEvent, useEffect, useState } from "react";
import ServerService from "services/admin/ServerService";
import { catchError } from "functions/common";
import { App, Server, defaultApp } from "types/admin/server";

// components
import CreateDialog from "./components/CreateDialog";
import UpdateDialog from "./components/UpdateDialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import StatusRenderer from "components/agGrid/renderers/StatusRenderer";

// icons
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";

// material ui
import { Theme, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ChangeIpDialog from "./components/ChangeIpDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Menu, MenuItem } from "@material-ui/core";
import ConfirmRestartDialog from "./components/ConfirmRestartDialog";
import ConfirmTurnOffDialog from "./components/ConfirmTurnOffDialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    height: "calc(100vh - 96px)",
    position: "relative",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  h: {
    textAlign: "right",
  },
  btn: {
    margin: "0 5px",
  },
  c: {
    marginTop: -25,
  },
  l: {},
  lItem: {
    padding: "5px 0",
    minHeight: 60,
    width: 370,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "white",
    },
    marginBottom: 5,
  },
  lStatus: {},
  lName: {
    padding: "0 10px",
    flexGrow: 1,
    fontSize: theme.typography.fontSize + 2,
  },
  cAct: {
    textAlign: "right",
  },
  card: {
    backgroundColor: grey[100],
    margin: 10,
  },
  img: {
    margin: "0 auto",
    display: "block",
    width: "100%",
  },
  g: {
    fontSize: theme.typography.fontSize - 2,
    color: grey[700],
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, .7)",
    zIndex: 2,
  },
  loaderBox: {
    display: "flex",
    alignItems: "center",
  },
  loaderText: {
    color: "white",
    marginLeft: 10,
  },
}));

interface Props {
  app: App;
  setUpdate: Function;
  setRemove: Function;
  freeze: boolean;
}
const ListItem: FC<Props> = ({ app, setUpdate, setRemove, freeze }) => {
  const classes = useStyles();

  const [showBtns, setShowBtns] = useState(false);

  return (
    <div className={classes.lItem} onMouseOver={() => setShowBtns(true)} onMouseLeave={() => setShowBtns(false)}>
      <div className={classes.lStatus}>
        <StatusRenderer value={app.status} message={app.message} />
      </div>
      <div className={classes.lName}>
        <div>{app.appName}</div>
        <div className={classes.g}>{app.host}</div>
        <div className={classes.g}>{app.comment}</div>
      </div>
      {showBtns && (
        <>
          <IconButton
            title="Редактировать"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setUpdate({ open: true, app });
            }}
            disabled={freeze}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            title="Удалить"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setRemove({ open: true, id: app.id });
            }}
            disabled={freeze}
          >
            <HighlightOffIcon fontSize="inherit" />
          </IconButton>
        </>
      )}
    </div>
  );
};

const Servers: FC = () => {
  const classes = useStyles();

  const [servers, setServers] = useState<Server[]>([]);
  const [remove, setRemove] = useState<{ open: boolean; id: number }>({ open: false, id: 0 });
  const [create, setCreate] = useState<{ open: boolean; ph: string }>({ open: false, ph: "" });
  const [update, setUpdate] = useState<{ open: boolean; app: App }>({ open: false, app: defaultApp });
  const [apply, setApply] = useState<{ open: boolean; ids: number[] }>({ open: false, ids: [] });
  const [changeHost, setChangeHost] = useState<{ open: boolean; ids: number[] }>({ open: false, ids: [] });
  const [freeze, setFreeze] = useState(false);
  const [restart, setRestart] = useState(false);
  const [turnOff, setTurnOff] = useState(false);

  const timeOut = () => {
    setFreeze(true);
    setTimeout(() => {
      setFreeze(false);
      getServers();
    }, 15000);
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    if (!confirm) {
      setRemove({ open: false, id: 0 });
    }

    if (confirm) {
      timeOut();
      ServerService.remove(String(remove.id))
        .then(({ data }) => setServers(data))
        .catch(catchError)
        .finally(() => setRemove({ open: false, id: 0 }));
    }
  };

  const handleCloseApplyDialog = (confirm: boolean) => {
    if (!confirm) {
      setApply({ open: false, ids: [] });
    }

    if (confirm) {
      timeOut();
      ServerService.applyConfig({ ids: apply.ids })
        .then(({ data }) => setServers(data))
        .catch(catchError)
        .finally(() => setApply({ open: false, ids: [] }));
    }
  };

  const handleOpenApplyDialog = () => {
    const ids = [];

    for (let i = 0; i < servers.length; i++) {
      const server = servers[i];
      for (let j = 0; j < server.apps.length; j++) {
        const app = server.apps[j];
        ids.push(app.id);
      }
    }

    setApply({ open: true, ids });
  };

  const handleCloseCreateDialog = (data?: { ip: string; port: string; comment: string }) => {
    setCreate({ open: false, ph: "" });
    if (data === undefined) return;
    const { ip, port, comment } = data;

    timeOut();
    const app: App = {
      ...defaultApp,
      host: `${ip}:${port}`,
      comment,
    };
    ServerService.addApp(app)
      .then(({ data }) => setServers(data))
      .catch(catchError);
  };

  const handleCloseUpdateDialog = (data?: App) => {
    setUpdate({ open: false, app: defaultApp });
    if (data === undefined) return;

    timeOut();
    ServerService.editApp(data)
      .then(({ data }) => setServers(data))
      .catch(catchError);
  };

  const handleCloseChangeHostDialog = (newIp?: string) => {
    const { ids } = changeHost;
    setChangeHost({ open: false, ids: [] });
    if (newIp === undefined) return;

    timeOut();
    ServerService.updateIp({ ids, newIp })
      .then(({ data }) => setServers(data))
      .catch(catchError);
  };

  const getServers = () => {
    ServerService.getAll()
      .then(({ data }) => {
        setServers(data);
      })
      .catch(catchError);
  };

  useEffect(() => {
    getServers();
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseRestartDialog = (confirm?: boolean) => {
    setRestart(false);
    if (confirm) {
      ServerService.serverCommand("reboot").then().catch();
    }
  };

  const handleCloseTurnOffDialog = (confirm?: boolean) => {
    setTurnOff(false);
    if (confirm) {
      ServerService.serverCommand("shutdown").then().catch(catchError);
    }
  };

  return (
    <Paper className={classes.root}>
      {freeze && (
        <div
          className={classes.loader}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className={classes.loaderBox}>
            <CircularProgress />
            <Typography className={classes.loaderText}>Обновления применяются...</Typography>
          </div>
        </div>
      )}
      <div className={classes.h}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.btn}
          onClick={handleOpenApplyDialog}
          disabled={freeze}
          title="Применить настройки"
        >
          Применить
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.btn}
          onClick={() => setCreate({ open: true, ph: "" })}
          disabled={freeze}
          title="Добавить программу"
        >
          Добавить
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.btn}
          onClick={handleClick}
          title="Выключить"
        >
          Выключить
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => setTurnOff(true)}>Выключить</MenuItem>
          <MenuItem onClick={() => setRestart(true)}> Перезагрузить </MenuItem>
        </Menu>
      </div>
      <div className={classes.content}>
        {servers.map((server) => (
          <Card key={server.id} className={classes.card}>
            <img src="/img/server.png" alt="server" className={classes.img} />
            <CardContent className={classes.c}>
              <Typography gutterBottom variant="h5" component="h2" align="center">
                {server.name}
              </Typography>
              <div className={classes.l}>
                {server.apps.map((app) => (
                  <ListItem key={app.id} app={app} setUpdate={setUpdate} setRemove={setRemove} freeze={freeze} />
                ))}
              </div>
            </CardContent>
            <Divider />
            <div className={classes.cAct}>
              <IconButton
                title="Изменить ip у всех программ"
                color="primary"
                onClick={() => {
                  const ids = server.apps.map((app) => app.id);
                  setChangeHost({ open: true, ids });
                }}
                disabled={freeze}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                title="Добавить программу в этот сервер"
                color="primary"
                onClick={() => {
                  setCreate({ open: true, ph: server.name });
                }}
                disabled={freeze}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </div>
          </Card>
        ))}
      </div>

      <ConfirmationDialog open={remove.open} onClose={handleCloseDeleteDialog} />
      <ConfirmationDialog open={apply.open} onClose={handleCloseApplyDialog} message="Применить настройки?" />
      <CreateDialog s={create} onClose={handleCloseCreateDialog} />
      <UpdateDialog s={update} onClose={handleCloseUpdateDialog} />
      <ChangeIpDialog open={changeHost.open} onClose={handleCloseChangeHostDialog} />
      <ConfirmRestartDialog open={restart} onClose={handleCloseRestartDialog} />
      <ConfirmTurnOffDialog open={turnOff} onClose={handleCloseTurnOffDialog} />
    </Paper>
  );
};

export default Servers;
