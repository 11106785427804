import React, { FC, useEffect, useMemo, useState } from "react";
import FilterService from "services/admin/FilterService";
import { UrlParams } from "types/admin";
import { convertToItems } from "components/searchFilter/components/InputTreeEditComponent";
import clsx from "clsx";

// ag-gird
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import { ChangeDetectionStrategyType } from "ag-grid-react/lib/changeDetectionService";

// icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() => ({
  root: {},
  disableTransition: {
    transition: "none",
  },
  userTable: {
    height: 300,
    marginTop: 29,
  },
  availableTable: {
    height: 300,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    width: 500,
  },
  btns: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  iconBtn: {
    marginBottom: 5,
  },
  folder: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  title: {
    marginBottom: 5,
  },
}));

export interface TableModel {
  id: number;
  parent: number;
  name: string;
  isCanSelected: boolean;
}

interface Props {
  value: string;
  changeValue: (value: string) => void;
  params: UrlParams;
}

const TreeInput: FC<Props> = ({ value, changeValue, params }) => {
  const classes = useStyles();

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  const columnUser = useMemo(
    () => [
      {
        field: "id",
        headerName: "Идентификатор",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        width: 100,
      },
      {
        headerName: "Имя",
        field: "name",
        flex: 1,
      },
    ],
    []
  );

  const columnAvailable = useMemo(
    () => [
      {
        field: "id",
        headerName: "Идентификатор",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        width: 100,
      },
      {
        headerName: "Имя",
        field: "name",
        flex: 1,
        cellRenderer: "NameRenderer",
      },
    ],
    []
  );

  const [availableRows, setAvailableRows] = useState<TableModel[]>([]);
  const [availableGridApi, setAvailableGridApi] = useState<GridApi | undefined>(undefined);
  const [availableSelectedRows, setAvailableSelectedRows] = useState<TableModel[]>([]);

  const [userRows, setUserRows] = useState<TableModel[]>([]);
  const [userGridApi, setUserGridApi] = useState<GridApi | undefined>(undefined);
  const [userSelectedRows, setUserSelectedRows] = useState<TableModel[]>([]);

  const [breadcrumbs, setBreadcrumbs] = useState<TableModel[]>([
    { id: 0, name: "Главная", parent: 1, isCanSelected: false },
  ]);

  const getRowNodeId = (data: TableModel) => data.id.toString();

  const addToBreadCrumb = (model: TableModel) => {
    const _model = model;
    setBreadcrumbs((prev) => {
      let list = [...prev];
      list.push(_model);
      return list;
    });
    setAvailableSelectedRows([]);
  };

  const goToBreadCrumb = (event: any, model: TableModel) => {
    event.preventDefault();

    setBreadcrumbs((prev) => {
      const bc: TableModel[] = [];
      for (let i = 0; i < prev.length; i++) {
        const b = prev[i];
        if (b.id === model.id) {
          bc.push(b);
          break;
        }
        bc.push(b);
      }
      return bc;
    });
  };

  const NameRenderer: FC<{ value: string; data: any }> = ({ value, data }) => {
    const classes = useStyles();

    if (data.speakerType === "folder") {
      return (
        <span className={classes.folder} onClick={() => addToBreadCrumb(data)}>
          {value}
        </span>
      );
    }

    return <span>{value}</span>;
  };

  const onUserSelection = () => {
    if (userGridApi) {
      const rows = userGridApi.getSelectedRows();
      setUserSelectedRows(rows);
    }
  };

  const onAvailableSelection = () => {
    if (availableGridApi) {
      const rows = availableGridApi.getSelectedRows();
      setAvailableSelectedRows(rows);
    }
  };

  const removeFromUser = () => {
    const ids = userSelectedRows.map((p) => p.id);
    const newUserRows = userRows.filter((p: any) => !ids.includes(p.id));
    setUserRows(newUserRows);
    changeValue(newUserRows.map((p) => p.id).join("\n"));

    setAvailableRows((prev: any) => {
      return prev.map((p: any) => {
        p.isCanSelected = !ids.includes(p.id);
        return p;
      });
    });

    setUserSelectedRows([]);
  };

  const addToUser = () => {
    const ids = availableSelectedRows.map((p) => p.id);
    const newUserRows = userRows.concat(availableSelectedRows);
    setUserRows(newUserRows);
    changeValue(newUserRows.map((p) => p.id).join("\n"));

    setAvailableRows((prev: any) => {
      return prev.map((p: any) => {
        p.isCanSelected = !ids.includes(p.id);
        return p;
      });
    });

    setAvailableSelectedRows([]);
    availableGridApi?.deselectAll();
  };

  const isRowSelectable = (e: any) => e.data && e.data.isCanSelected;

  useEffect(() => {
    if (!params || !params.url) return;

    const urlByIds = params.url + "/getByIds";
    let ids: number[] = [];
    if (value !== "") {
      ids = value.split("\n").map((str) => parseInt(str, 10));
    }

    FilterService.doCustomPostQuery(urlByIds, { ids: ids }).then((res) => {
      const items = convertToItems(ids, res.data);
      setUserRows(items);

      const userIds = items.map((p) => p.id);
      setAvailableRows((prev: any) => {
        return prev.map((p: any) => {
          p.isCanSelected = !userIds.includes(p.id);
          return p;
        });
      });
    });
  }, [value, params]);

  useEffect(() => {
    if (!params || !params.url) return;

    const ids = userRows.map((p) => p.id);

    const last = breadcrumbs[breadcrumbs.length - 1].id;
    const url = params.url + `/${last}/0/1000`;
    FilterService.doCustomGetQuery(url).then(({ data }) => {
      data.forEach((p) => (p.isCanSelected = !ids.includes(p.id)));
      setAvailableRows(data);
    });
  }, [params, breadcrumbs, userRows]);

  useEffect(() => {
    setBreadcrumbs([{ id: 0, name: "Главная", parent: 1, isCanSelected: false }]);
  }, [params]);

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <Typography className={classes.title}>Доступные</Typography>

        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbs.map((bc) => (
            <Link key={bc.id} color="inherit" href="/" onClick={(e: any) => goToBreadCrumb(e, bc)}>
              {bc.name}
            </Link>
          ))}
        </Breadcrumbs>

        <div className={clsx(classes.availableTable, "ag-theme-balham")}>
          <AgGridReact
            onSelectionChanged={onAvailableSelection}
            getRowNodeId={getRowNodeId}
            onGridReady={(params: any) => setAvailableGridApi(params.api)}
            isRowSelectable={isRowSelectable}
            defaultColDef={defaultColDef}
            rowData={availableRows}
            rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
            columnDefs={columnAvailable}
            localeText={localization}
            rowSelection={"multiple"}
            suppressCopyRowsToClipboard
            frameworkComponents={{
              NameRenderer,
            }}
            deltaRowDataMode
          />
        </div>
      </div>
      <div className={classes.btns}>
        <IconButton disabled={userSelectedRows.length === 0} onClick={removeFromUser} title="Удалить">
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          className={classes.iconBtn}
          disabled={availableSelectedRows.length === 0}
          onClick={addToUser}
          title="Добавить"
        >
          <ArrowForwardIcon fontSize="inherit" />
        </IconButton>
      </div>
      <div className={classes.item}>
        <Typography className={classes.title}>Выбранные</Typography>
        <div className={clsx(classes.userTable, "ag-theme-balham")}>
          <AgGridReact
            onSelectionChanged={onUserSelection}
            getRowNodeId={getRowNodeId}
            onGridReady={(params: any) => setUserGridApi(params.api)}
            defaultColDef={defaultColDef}
            rowData={userRows}
            rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
            columnDefs={columnUser}
            localeText={localization}
            rowSelection="multiple"
            suppressCopyRowsToClipboard
            suppressDragLeaveHidesColumns
            deltaRowDataMode
          />
        </div>
      </div>
    </div>
  );
};

export default TreeInput;
