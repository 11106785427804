import { AxiosResponse } from "axios";
import { LmModel } from "types/admin/lmModel";
import { admin } from "http/urls";
import api from "http/api";

const { lmModels } = admin;

function LmModelService() {
  function getAll(): Promise<AxiosResponse<LmModel[]>> {
    return api.get<LmModel[]>(lmModels.getAll);
  }

  function create(body: any) {
    return api.post(lmModels.create, body);
  }

  function update(body: LmModel): Promise<AxiosResponse> {
    return api.put(lmModels.update, body);
  }

  function remove(ids: string): Promise<AxiosResponse> {
    const url = lmModels.remove.replace(":ids", ids);
    return api.delete(url);
  }

  function generateTranscriptions(body: any): Promise<AxiosResponse> {
    return api.post(lmModels.generateTranscription, body);
  }

  return Object.freeze({
    getAll,
    create,
    update,
    remove,
    generateTranscriptions,
  });
}

export default LmModelService();
