import { AxiosResponse } from "axios";
import { SourceAdmin, AvailableSource } from "types/source";
import { admin } from "http/urls";
import api from "http/api";

const { sources: route } = admin;

// Сервис взаимодействует с системными источниками
// доступные источники задаются в файле /etc/replica7/replica7-admin-access.json
function AdminSourceService() {
  function updateProtected(body: { id: string; isActive: boolean }): Promise<AxiosResponse> {
    return api.put(route.update, body);
  }

  function getAll(): Promise<AxiosResponse<SourceAdmin[]>> {
    return api.get<SourceAdmin[]>(route.getAll);
  }

  function setActive(body: { ids: number[]; isActive: boolean }) {
    return api.post(route.setActive, body);
  }

  function getAvailableSources(): Promise<AxiosResponse<AvailableSource[]>> {
    return api.get<AvailableSource[]>(route.available);
  }

  return Object.freeze({
    getAll,
    updateProtected,
    setActive,
    getAvailableSources,
  });
}

export default AdminSourceService();
