import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { Filter as TFilter } from "types/filter";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { Group } from "types/queryBuilder";
import { generateUuid } from "functions/common";

// components
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(() => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  filter: TFilter;
}

const UpdateLabelDialog: FC<Props> = ({ open, onClose, filter: updatedFilter }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();
  const { filters: availableFilters } = useAvailableFilters("records");

  const [state, setState] = useState(updatedFilter);
  const [filter, setFilter] = useState<Group>(defaultFilter);
  const { name, comment } = state;
  const disabled = name.length === 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  function handleSave() {
    onClose({ ...state, filter: JSON.stringify(filter) });
  }

  useEffect(() => {
    setState(updatedFilter);
    try {
      const filter = JSON.parse(updatedFilter.filter);
      setFilter(filter);
    } catch (err) {
      console.error(err);
    }
  }, [updatedFilter]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Редактировать фильтр</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth variant="standard" name="name" onChange={handleChange} value={name} />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Фильтр</Typography>
            <div className={classes.filter}>
              <div className={classes.input}>
                <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
              </div>
              <HistoryFilters setFilter={setFilter} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              value={comment}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLabelDialog;
