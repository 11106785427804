import { useCallback, useEffect, useState } from "react";
import { Setting } from "types/admin";
import SettingsService from "services/admin/SettingsService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  rowData: Setting[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  rowData: [],
  error: undefined,
};

const useSettings = (offset: number, perPage: number, queryString: string) => {
  const [state, setState] = useState<State>(initialState);

  const getSettings = useCallback(() => {
    setState((prev) => ({ ...prev, loading: true }));
    SettingsService.getAll(offset, perPage, queryString)
      .then(({ data }) => {
        setState({ rowData: data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ rowData: [], loading: false, error: err });
      });
  }, [offset, perPage, queryString]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return { ...state, getSettings };
};

export default useSettings;
