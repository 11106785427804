import React, { ChangeEvent, useState, FC, useRef, useEffect, useMemo } from "react";
import { GridApi } from "ag-grid-community";
import { FileFormatDetail, Format } from "types/admin";
import { Group } from "types/queryBuilder";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { generateUuid, humanFileSize } from "functions/common";

// components
import FilesTable from "components/FilesTable";
import SelectPassport from "components/SelectPassport";
import TypeFormatFile from "components/TypeFormatFile";
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    flexGrow: 1,
  },
  fileUpload: {
    marginLeft: 13,
  },
  tableButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  upload: {},
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px 0 20px",
  },
  dialogActions: {
    padding: "10px 20px",
    textAlign: "right",
  },
  settingsTitle: {
    margin: 0,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
  info: {
    paddingTop: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  count: {
    fontSize: theme.typography.fontSize,
    marginRight: 20,
  },
  size: {
    fontSize: theme.typography.fontSize,
  },
  errorDiv: {
    display: "flex",
    justifyContent: "flex-end",
    color: "red",
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: { fileFormat: FileFormatDetail; files: File[]; deleteByFilter: Group }) => void;
}

const UploadFilesDialog: FC<Props> = ({ open, onClose }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();
  const { filters: availableFilters } = useAvailableFilters("records");

  const [deleteByFilter, setDeleteByFilter] = useState(defaultFilter);
  const [fileFormat, setFileFormat] = useState<FileFormatDetail | undefined>(undefined);
  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<File[]>([]);

  const disabled = files.length === 0 || fileFormat === undefined;
  const isFilesMax = files.reduce((acc, curr) => acc + curr.size, 0) > 4 * 1024 * 1024 * 1024;

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const clearFileInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files !== null) {
      setFiles((prev) => prev.concat(Array.from(files)));
      clearFileInput();
    }
  };

  const handleRemoveFiles = () => {
    setFiles((prev) => prev.filter((f) => !selectedRows.includes(f)));
    setSelectedRows([]);
  };

  const setFileFormatDetail = (fileFormat: FileFormatDetail) => {
    setFileFormat(fileFormat);
  };

  const handleSave = () => {
    if (fileFormat === undefined) return;
    const data = {
      files,
      fileFormat,
      deleteByFilter,
    };
    onClose(data);
  };

  useEffect(() => {
    if (!open) {
      setFiles([]);
      clearFileInput();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="md" className={classes.root}>
      <DialogTitle>Загрузка данных</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.tableButtons}>
              <Button
                className={classes.button}
                color="primary"
                size="small"
                onClick={handleRemoveFiles}
                disabled={selectedRows.length === 0}
              >
                Исключить
              </Button>
              <div className={classes.fileUpload}>
                <input
                  onChange={handleFileChange}
                  className={classes.upload}
                  id="icon-button-file"
                  type="file"
                  hidden
                  multiple
                  ref={inputRef}
                />
                <label htmlFor="icon-button-file">
                  <Button variant="contained" color="primary" component="span" size="small">
                    Выбрать
                  </Button>
                </label>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FilesTable rowData={files} setGridApi={setGridApi} onSelection={onSelection} />
            <div className={classes.info}>
              <div className={classes.count}>{`Выбрано: ${selectedRows.length}`}</div>
              <div className={classes.size}>{`Общий размер: ${humanFileSize(
                files.reduce((acc, curr) => acc + curr.size, 0)
              )}`}</div>
            </div>
          </Grid>

          {/*<Grid item xs={12}>*/}
          {/*  <TextField*/}
          {/*    label="Исключить данные по фильтру"*/}
          {/*    fullWidth*/}
          {/*    variant="standard"*/}
          {/*    name="deleteByFilter"*/}
          {/*    onChange={(e) => setDeleteByFilter(e.target.value)}*/}
          {/*    value={deleteByFilter}*/}
          {/*  />*/}
          {/*</Grid>*/}

          <Grid item xs={12}>
            <Typography gutterBottom>Исключить данные по фильтру</Typography>
            <div className={classes.filter}>
              <div className={classes.input}>
                <Filter filter={deleteByFilter} setFilter={setDeleteByFilter} availableFilters={availableFilters} />
              </div>
              <HistoryFilters setFilter={setDeleteByFilter} />
            </div>
          </Grid>

          <Grid item xs={12}>
            <SelectPassport setFileFormat={setFileFormat} />
          </Grid>
          <Grid item xs={12}>
            {fileFormat && fileFormat.type === Format.FILES && (
              <TypeFormatFile fileFormatDetail={fileFormat} setFileFormatDetail={setFileFormatDetail} />
            )}
          </Grid>
          {isFilesMax && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span>Максимальный размер загружаемых файлов не должен превышать 4ГБ.</span>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled || isFilesMax}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFilesDialog;
