export const PAGES_SET_SETTINGS = "PAGES_SET_SETTINGS";
export const PAGE_RECORDS_SET_TABLE_PARAMS = "AG_GRID_SET_PARAMS";
export const PAGE_NAMESPACES_SET_TABLE_PARAMS = "PAGE_NAMESPACES_SET_TABLE_PARAMS";
export const PAGE_TASKS_SET_TABLE_PARAMS = "PAGE_TASKS_SET_TABLE_PARAMS";
export const PAGE_DATASOURCES_SET_TABLE_PARAMS = "PAGE_DATASOURCES_SET_TABLE_PARAMS";
export const PAGE_SOURCES_SET_TABLE_PARAMS = "PAGE_SOURCES_SET_TABLE_PARAMS";
export const PAGE_FILTER_SET_TABLE_PARAMS = "PAGE_FILTER_SET_TABLE_PARAMS";
export const PAGE_EVENTS_SET_TABLE_PARAMS = "PAGE_EVENTS_SET_TABLE_PARAMS";
export const PAGE_WORDS_SET_TABLE_PARAMS = "PAGE_WORDS_SET_TABLE_PARAMS";
export const PAGE_SPEAKERS_SET_TABLE_PARAMS = "PAGE_SPEAKERS_SET_TABLE_PARAMS";
export const PAGE_OBJECTS_SET_TABLE_PARAMS = "PAGE_OBJECTS_SET_TABLE_PARAMS";
export const PAGE_LABELS_SET_TABLE_PARAMS = "PAGE_LABELS_SET_TABLE_PARAMS";
export const PAGE_LISTS_SET_TABLE_PARAMS = "PAGE_LISTS_SET_TABLE_PARAMS";
export const PAGE_BASE_STATIONS_SET_TABLE_PARAMS = "PAGE_BASE_STATIONS_SET_TABLE_PARAMS";
export const PAGE_IP_ADDRESSES_SET_TABLE_PARAMS = "PAGE_IP_ADDRESSES_SET_TABLE_PARAMS";
export const PAGE_PHONE_NUMBERS_SET_TABLE_PARAMS = "PAGE_PHONE_NUMBERS_SET_TABLE_PARAMS";
export const PAGE_NBS_CATEGORY_SET_TABLE_PARAMS = "PAGE_NBS_CATEGORY_SET_TABLE_PARAMS";

interface PageSettings {
  table: any;
}

export interface PageSettingsState {
  records: PageSettings;
  namespaces: PageSettings;
  tasks: PageSettings;
  datasources: PageSettings;
  sources: PageSettings;
  filter: PageSettings;
  events: PageSettings;
  words: PageSettings;
  speakers: PageSettings;
  objects: PageSettings;
  labels: PageSettings;
  lists: PageSettings;
  baseStations: PageSettings;
  ipAddresses: PageSettings;
  phoneNumbers: PageSettings;
  nbsCategory: PageSettings;
}

export type PageSettingsAction =
  | { type: typeof PAGES_SET_SETTINGS; payload: PageSettingsState }
  | { type: typeof PAGE_RECORDS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_NAMESPACES_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_TASKS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_DATASOURCES_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_SOURCES_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_FILTER_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_EVENTS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_WORDS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_SPEAKERS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_OBJECTS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_LABELS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_LISTS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_BASE_STATIONS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_IP_ADDRESSES_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_PHONE_NUMBERS_SET_TABLE_PARAMS; payload: any }
  | { type: typeof PAGE_NBS_CATEGORY_SET_TABLE_PARAMS; payload: any };
