import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import conditions from "./conditions";
import Typography from "@material-ui/core/Typography";
import TextAriaInput from "./values/TextAriaInput";

const useStyles = makeStyles(() => ({
  root: {
    height: 465,
  },
  field: {
    marginBottom: 20,
  },
  condition: {
    marginBottom: 20,
  },
  value: {},
}));

const DetailDefault: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.field}>
        <Typography gutterBottom>Категория:</Typography>
        <Select disabled fullWidth value="words">
          <MenuItem value="id">Идентификатор</MenuItem>
          <MenuItem value="name">Имя сеанса</MenuItem>
          <MenuItem value="realDurationRecord">Длительность файла</MenuItem>
          <MenuItem value="words">Слова</MenuItem>
          <MenuItem value="isViewed">Просмотрено</MenuItem>
        </Select>
      </div>

      <div className={classes.condition}>
        <Typography gutterBottom>Операция:</Typography>
        <Select disabled fullWidth value="=">
          {conditions.map((condition, idx) => (
            <MenuItem key={idx} value={condition.value}>
              {condition.printValue}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className={classes.value}>
        <Typography gutterBottom>Значение:</Typography>
        <TextAriaInput value="" changeValue={() => {}} disabled />
      </div>
    </div>
  );
};

export default DetailDefault;
