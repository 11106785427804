import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Object as ObjectType } from "types/record";
import { Group } from "types/queryBuilder";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { generateUuid } from "functions/common";
import { ColorResult, TwitterPicker } from "react-color";

// components
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";
import MaskInput from "components/form/MaskInput";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { blue, cyan, deepPurple, green, indigo, orange, pink, purple, red, yellow } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
}));

const colors = [
  yellow[300],
  red[300],
  pink[300],
  purple[300],
  deepPurple[300],
  indigo[300],
  blue[300],
  cyan[300],
  orange[300],
  green[300],
];

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  obj: ObjectType;
}

const CreateObjectDialog: FC<Props> = ({ open, onClose, obj }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();
  const { filters: availableFilters } = useAvailableFilters("records");

  const [state, setState] = useState(obj);
  const [filter, setFilter] = useState<Group>(defaultFilter);
  const { name, comment, color, clusterBy } = state;
  const disabled = name.length === 0;

  const maskFields: { k: string; v: string }[] = useMemo(
    () => [
      { k: "Идентификатор", v: "id" },
      { k: "Дата", v: "date" },
      { k: "Время", v: "time" },
      { k: "Имя файла", v: "name" },
      { k: "Расширения файла", v: "ext" },
      { k: "IP-адрес терминала", v: "terminalIp" },
      { k: "Номер терминала", v: "terminalNumber" },
      { k: "Имя терминала", v: "terminalName" },
      { k: "Язык", v: "lang" },
      { k: "Диктор", v: "speaker" },
      { k: "Пол", v: "gender" },
      { k: "Тип данных", v: "codec" },
      { k: "Пространство", v: "namespace" },
      { k: "Город", v: "city" },
      { k: "Страна", v: "country" },
      { k: "Адрес", v: "address" },
    ],
    []
  );

  const handleChangeColor = (c: ColorResult) => {
    setState((prev) => ({
      ...prev,
      color: c.hex,
    }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = useCallback(() => {
    onClose({ ...state, filter: JSON.stringify(filter) });
  }, [onClose, state, filter]);

  const setMaskInput = (mask: string) => {
    setState((prev) => ({ ...prev, clusterBy: mask }));
  };

  useEffect(() => {
    setState(obj);
    try {
      const filter: Group = JSON.parse(obj.filter);
      setFilter(filter);
    } catch (err) {}
  }, [obj]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактировать объект</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth name="name" onChange={handleChange} value={name} />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Фильтр</Typography>
            <div className={classes.filter}>
              <div className={classes.input}>
                <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
              </div>
              <HistoryFilters setFilter={setFilter} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <MaskInput mask={clusterBy} setMaskInput={setMaskInput} fields={maskFields} label="Кластеризовать по" />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Цвет</Typography>
            <TwitterPicker
              colors={colors}
              width="912px"
              color={color}
              onChangeComplete={handleChangeColor}
              triangle="hide"
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSave}
                disabled={disabled}
              >
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateObjectDialog;
