import { AxiosResponse } from "axios";
import { storages as route } from "http/urls";
import { Storage } from "types/storage";
import { FolderSftp, FolderSmb } from "types/source";
import api from "http/api";

function StorageService() {
  function getAll(): Promise<AxiosResponse<Storage[]>> {
    const url = route.getAll;
    return api.get<Storage[]>(url);
  }
  function obzor(body: FolderSftp | FolderSmb): Promise<AxiosResponse> {
    const url = route.obzor;
    return api.post<FolderSftp[] | FolderSmb[]>(url, body);
  }

  return Object.freeze({
    getAll,
    obzor,
  });
}

export default StorageService();
