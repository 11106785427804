import { AxiosResponse } from "axios";
import { Diagnostic } from "types/admin/diagnostic";
import { admin } from "http/urls";
import api from "http/api";

const { diagnostic: route } = admin;

function DiagnosticService() {
  function systemInfo(): Promise<AxiosResponse> {
    return api.get(route.sysInfo);
  }

  function getAll(): Promise<AxiosResponse<Diagnostic[]>> {
    return api.get<Diagnostic[]>(route.getAll);
  }

  function create(d: Diagnostic) {
    const url = route.create;
    return api.post(url, d);
  }

  function update(d: Diagnostic) {
    const url = route.update;
    return api.put(url, d);
  }

  function remove(ids: string) {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  function updateCluster(id: number, clusterId: string) {
    const url = route.updateCluster;
    return api.put(url, { id, clusterId });
  }

  function removeCluster(ids: string) {
    const url = route.removeCluster.replace(":ids", ids);
    return api.delete(url);
  }

  function checkApp() {
    const url = route.checkApp;
    return api.post(url);
  }

  function uploadSettings(fd: FormData) {
    return api.post(route.upload, fd, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  return Object.freeze({
    getAll,
    create,
    update,
    remove,
    updateCluster,
    removeCluster,
    checkApp,
    systemInfo,
    uploadSettings,
  });
}

export default DiagnosticService();
