import React, { FC } from "react";
import moment from "moment";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {},
}));

const DateRenderer: FC<{ value: string }> = ({ value }) => {
  const classes = useStyles();
  const d = moment(value);

  return <div className={classes.root}>{d.isValid() ? d.format("DD-MM-YYYY HH:mm:ss") : ""}</div>;
};

export default DateRenderer;
