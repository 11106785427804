import { AxiosResponse } from "axios";
import { Statistic } from "types/statistic";
import { statistics as route } from "http/urls";
import api from "http/api";

function StatisticsService() {
  function getAll(query = ""): Promise<AxiosResponse<Statistic>> {
    let url = route.getAll + query;
    return api.get<Statistic>(decodeURI(url));
  }

  return Object.freeze({
    getAll,
  });
}

export default StatisticsService();
