import { AxiosResponse } from "axios";
import { Source } from "types";
import { Permission } from "types/permission";
import { sources as path } from "http/urls";
import api from "http/api";

// Сервис взаимодействует с источниками созданными пользователем
function SourceService() {
  function getAll(): Promise<AxiosResponse<Source[]>> {
    const url = path.getAll;
    return api.get<Source[]>(url);
  }

  function getById(id: string) {
    const url = path.getById.replace(":id", id);
    return api.get<Source>(url);
  }

  function create(body: Source) {
    const url = path.create;
    return api.post(url, body);
  }

  function update(body: Source): Promise<AxiosResponse> {
    return api.put(path.update, body);
  }

  function remove(ids: string) {
    const url = path.remove.replace(":ids", ids);
    return api.delete(url);
  }

  function getPermissions(id: number): Promise<AxiosResponse<Permission[]>> {
    const url = path.getAccess.replace(":id", String(id));
    return api.get<Permission[]>(url);
  }

  function setPermissions(id: number, body: Permission[]): Promise<AxiosResponse> {
    const url = path.setAccess.replace(":id", String(id));
    return api.post(url, body);
  }

  return Object.freeze({
    getAll,
    getById,
    create,
    update,
    remove,
    getPermissions,
    setPermissions,
  });
}

export default SourceService();
