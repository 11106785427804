import React, { useMemo, useState, ChangeEvent, FC, Dispatch, SetStateAction } from "react";
import { AdminTech } from "types/admin/tech";
import { TaskTech, TechType } from "types/task";
import { LmModel } from "types/admin/lmModel";
import useTechs from "hooks/admin/useTechs";
import usePublicLmModels from "hooks/usePublicLmModels";
import { getDefaultTaskTech } from "functions/task";

// components
import TechBadge from "./TechBadge";
import InfoDialog from "components/audioPlayer/components/InfoDialog";
import EditTechDialog from "./EditTechDialog";

// material ui
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    marginRight: "2rem",
  },
  list: {
    overflow: "auto",
    maxHeight: "calc(100vh - 578px)",
  },
  item: {
    height: 48,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid " + grey[300],
    "&:hover": {
      cursor: "pointer",
    },
    "&:last-child": {
      borderBottom: "none",
    },
  },
  checkbox: {
    flex: 1,
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  preliminary: {
    margin: "20px 0 10px 0",
  },
}));

interface Props {
  adminTechs: AdminTech[];
  preliminary: AdminTech[];
  technologies: TaskTech[];
  setTechnologies: Dispatch<SetStateAction<TaskTech[]>>;
  lmModels: LmModel[];
}

const Technologies: FC<Props> = ({ adminTechs, technologies, setTechnologies, lmModels, preliminary }) => {
  const classes = useStyles();

  const [openEditTechDialog, setOpenEditTechDialog] = useState(false);
  const [editTech, setEditTech] = useState<any>(undefined);

  const checkedAll = useMemo(() => {
    if (adminTechs.length === technologies.length) return true;
    if (adminTechs.length - 1 === technologies.length) {
      return !technologies.find((t) => t.type === TechType.DIARIZATION);
    }
    return false;
  }, [adminTechs, technologies]);

  const handleChecked = (event: ChangeEvent<HTMLInputElement>, t: AdminTech) => {
    const { checked } = event.target;
    const tech = getDefaultTaskTech(t);
    if (checked) {
      setTechnologies((prev: any[]) => [...prev, tech]);
    } else {
      setTechnologies((prev: any[]) => prev.filter((el: AdminTech) => el.id !== tech.id));
    }
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const techs = adminTechs
      .filter((t) => t.techDetail.type !== TechType.DIARIZATION)
      .map((t) => getDefaultTaskTech(t));
    if (checked) {
      setTechnologies(techs);
    } else {
      setTechnologies([]);
    }
  };

  const isActive = (id: string) => !!technologies.find((el) => el.id === id);

  const isTechHasSettings = (tech: AdminTech) => {
    return (
      tech.techDetail.type === TechType.STT ||
      // tech.techDetail.type === TechType.LID ||
      tech.techDetail.type === TechType.SID ||
      tech.techDetail.type === TechType.OCR ||
      tech.techDetail.type === TechType.VAD ||
      tech.techDetail.type === TechType.DIARIZATION ||
      tech.techDetail.type === TechType.TRANSLATE
    );
  };

  const handleOpenEditTechDialog = (t: AdminTech) => {
    const tech = technologies.find((el) => el.id === t.id);
    if (tech) {
      setEditTech(tech);
      setOpenEditTechDialog(true);
    }
  };

  const handleCloseEditTechDialog = (tech?: any) => {
    setOpenEditTechDialog(false);
    setEditTech(undefined);
    if (tech) {
      setTechnologies((prev: any[]) => prev.map((el) => (el.id === tech.id ? tech : el)));
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const handleOpenInfoDialog = (filter: any) => {
    setSelectedFilter(filter);
    setOpenEditTechDialog(false);
    setIsOpen(true);
  };

  const handleCloseInfoDialog = () => {
    setIsOpen(false);
    setOpenEditTechDialog(true);
  };

  return (
    <div className={classes.root}>
      <Typography gutterBottom>Технологии:</Typography>

      <FormControlLabel
        control={<Checkbox checked={checkedAll} onChange={handleSelectAll} name="isActive" color="primary" />}
        label="Выбрать все"
      />
      <Divider />
      <div className={classes.list}>
        {adminTechs.map((tech) => (
          <div className={classes.item} key={tech.id}>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={isActive(tech.id)}
                  onChange={(e) => handleChecked(e, tech)}
                  name="isActive"
                  color="primary"
                />
              }
              label={tech.name}
            />
            {isActive(tech.id) && isTechHasSettings(tech) && (
              <IconButton onClick={() => handleOpenEditTechDialog(tech)}>
                <TechBadge adminTech={tech} technologies={technologies} />
              </IconButton>
            )}
          </div>
        ))}

        <Typography className={classes.preliminary}>Предобработка:</Typography>
        {preliminary.map((tech) => (
          <div className={classes.item} key={tech.id}>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={isActive(tech.id)}
                  onChange={(e) => handleChecked(e, tech)}
                  name="isActive"
                  color="primary"
                />
              }
              label={tech.name}
            />
            {tech.name && (
              <IconButton className={classes.button} onClick={() => handleOpenInfoDialog(tech.name)}>
                <HelpOutlineIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>

      {editTech !== undefined && (
        <EditTechDialog
          open={openEditTechDialog}
          onClose={handleCloseEditTechDialog}
          tech={editTech}
          adminTechs={adminTechs}
          lmModels={lmModels}
        />
      )}
      {isOpen && <InfoDialog onClose={handleCloseInfoDialog} selectedFilter={selectedFilter} />}
    </div>
  );
};

interface GetTechnologiesProps {
  technologies: TaskTech[];
  setTechnologies: Dispatch<SetStateAction<TaskTech[]>>;
}

const GetTechnologies: FC<GetTechnologiesProps> = ({ technologies, setTechnologies }) => {
  const { rowData, loading, error } = useTechs();
  const { loading: loadingLmModels, error: errorLmModels, lmModels } = usePublicLmModels(true);

  const adminTechs: AdminTech[] = [];
  const preliminary: AdminTech[] = [];

  for (let i = 0; i < rowData.length; i++) {
    const { type } = rowData[i].techDetail;
    const preliminaryCondition =
      type === TechType.NORMILIZE ||
      type === TechType.FILTER_DECLICK ||
      type === TechType.FILTER_KTCH ||
      type === TechType.FILTER_DENOISE ||
      type === TechType.FILTER_ECHO;

    if (preliminaryCondition) {
      preliminary.push(rowData[i]);
    } else {
      adminTechs.push(rowData[i]);
    }
  }

  if (error || loading) {
    return null;
  }
  if (loadingLmModels || errorLmModels) {
    return null;
  }

  return (
    <Technologies
      adminTechs={adminTechs}
      preliminary={preliminary}
      technologies={technologies}
      setTechnologies={setTechnologies}
      lmModels={lmModels}
    />
  );
};

export default GetTechnologies;
