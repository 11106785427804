import { AuthState, AuthAction, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, REFRESH } from "redux/types/authTypes";
import { load } from "redux-localstorage-simple";
import { RootState } from "redux/types";
import AuthService from "services/AuthService";
import { DecodedToken } from "types";
import jwt_decode from "jwt-decode";

// получить сохраненный store из localstorage
const storageState: RootState = load({ namespace: "APP" }) as RootState;

const defaultState: AuthState = {
  isLoggedIn: false,
  accessToken: "",
  error: "",
  id: 0,
  isAdmin: false,
  login: "",
};

const initialState: AuthState = storageState.auth ?? defaultState;

function authReducer(state = initialState, action: AuthAction): AuthState {
  if (action.type === LOGIN_SUCCESS) {
    const { accessToken } = action.payload;
    const { id, login, isAdmin }: DecodedToken = jwt_decode(accessToken);
    return {
      error: "",
      isLoggedIn: true,
      accessToken,
      id,
      login,
      isAdmin,
    };
  }

  if (action.type === LOGIN_FAIL) {
    return {
      isLoggedIn: false,
      error: action.payload.message,
      accessToken: "",
      id: 0,
      isAdmin: false,
      login: "",
    };
  }

  if (action.type === LOGOUT) {
    AuthService.logout(state);
    return defaultState;
  }

  if (action.type === REFRESH) {
    const { accessToken } = action.payload;
    return {
      ...state,
      isLoggedIn: true,
      accessToken,
    };
  }

  return state;
}

export default authReducer;
