import React, { FormEvent, ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login as loginAction } from "redux/actions/authActions";
import { RootState } from "redux/types";
import { Redirect } from "react-router-dom";

// material ui
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  pass: {
    marginTop: 0,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 50,
    color: "#FFF",
  },
  container: {
    paddingTop: theme.spacing(8),
    height: "100vh",
    backgroundImage: "url(img/bg.jpg)",
    backgroundSize: "cover",
  },
}));

export default function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoggedIn, error } = useSelector((state: RootState) => state.auth);
  const [login, setLogin] = useState("");
  const [validLogin, setValidLogin] = useState(true);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const isPassValidation = (): boolean => {
    let result = true;

    if (login === "") {
      setValidLogin(false);
      result = false;
    }

    if (password === "") {
      setValidPassword(false);
      result = false;
    }

    return result;
  };

  const handleLoginChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLogin(value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (isPassValidation()) {
      dispatch(loginAction(login, password));
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.container}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src="img/owl.png" alt="owl" width={540} height={358} />

          <form className={classes.form} method="POST" onSubmit={handleSubmit}>
            {error !== "" && <Alert severity="error">{error}</Alert>}

            <TextField
              error={!validLogin}
              variant="outlined"
              margin="normal"
              fullWidth
              id="login"
              label="Логин"
              name="login"
              autoComplete="login"
              value={login}
              onChange={handleLoginChange}
              autoFocus
            />

            <TextField
              error={!validPassword}
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Пароль"
              id="password"
              autoComplete="current-password"
              value={password}
              type={showPassword ? "text" : "password"}
              onChange={handlePasswordChange}
              className={classes.pass}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Войти
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}
