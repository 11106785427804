import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Group as TGroup } from "types/queryBuilder";
import RecordService from "services/RecordService";
import { catchError, defaultFilter } from "functions/common";
import { Group } from "types/queryBuilder";
import { useDispatch } from "react-redux";
import { showSuccessAlert } from "redux/actions/alertActions";

//components
import CreateCleanUpDialog from "./components/CreateCleanUpDialog";
import UpdateCleanUpDialog from "./components/UpdateCleanUpDialog";
import ConfirmationDialog from "components/ConfirmationDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    height: "calc(100vh - 94px)",
    overflow: "scroll",
  },
  buttonWrapper: {
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  filter: {
    padding: theme.spacing(2),
    marginRight: 10,
  },
  mr10: {
    marginRight: 10,
  },
  mb10: {
    marginBottom: 10,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  paddingTop: {
    paddingTop: "0.3rem",
  },
  filter2: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
  filterWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginTop: "20px",
    marginLeft: "10px",
    width: "100%",
    marginBottom: "15px",
  },
}));

export const convertFilterName2 = (str: string) => {
  let res = "";
  try {
    const arr: string[] = [];
    const f: TGroup = JSON.parse(str);
    f.filters.forEach((el) => {
      if (el.type === "group") {
        arr.push("группа");
      }
      if (el.type === "filter") {
        arr.push(`${el.filter} ${el.condition} ${el.value}`);
      }
    });
    res = arr.join(", ");
  } catch (err) {}
  return res;
};

const AutoCleanUp: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialItems: string[] = [];

  const [items, setItems] = useState<string[]>(initialItems);
  const [filter, setFilter] = useState<Group>(defaultFilter());
  const [isActive, setIsActive] = useState(false);

  const [selectedItem, setSelectedItem] = useState<number | null>(null);

  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openCreateFilterDialog, setOpenCreateFilterDialog] = useState<boolean>(false);
  const [openConfirmImmediatelyDialog, setOpenConfirmImmediatelyDialog] = useState(false);

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const handleSelect = (index: number) => {
    setSelectedItem((prevSelected) => (prevSelected === index ? null : index));
  };

  const handleDelete = () => {
    if (selectedItem !== null) {
      const updatedItems = items.filter((_, i) => i !== selectedItem);
      setItems(updatedItems);
      setSelectedItem(null);
      const body = { filterV2: updatedItems, isActive };
      RecordService.setAutoCleanUp(body)
        .then(() => {})
        .catch((err: any) => catchError(err.response.data));
    }
  };

  const handleOpenEditDialog = () => {
    if (selectedItem !== null) {
      setFilter(JSON.parse(items[selectedItem]));
      setOpenEditDialog(true);
    }
  };

  const handleSaveEdit = (newFilter?: any) => {
    setOpenEditDialog(false);
    if (selectedItem !== null && newFilter) {
      const updatedItems = [...items];
      const parsed = JSON.parse(newFilter);
      updatedItems[selectedItem] = JSON.stringify(parsed);
      setItems(updatedItems);
      const body = { filterV2: updatedItems, isActive };
      RecordService.setAutoCleanUp(body)
        .then(() => {
          setFilter(defaultFilter());
        })
        .catch((err: any) => catchError(err.response.data));
    }
  };

  const handleCreate = (newFilter?: string) => {
    setOpenCreateFilterDialog(false);
    if (newFilter) {
      const updatedItems = [...items, newFilter];
      setItems(updatedItems);
      const body = { filterV2: updatedItems, isActive };
      RecordService.setAutoCleanUp(body)
        .then(() => {})
        .catch((err: any) => catchError(err.response.data));
    }
  };

  const handleCloseImmediatelyConfirmDialog = (confirm: boolean) => {
    setOpenConfirmImmediatelyDialog(false);
    if (confirm && selectedItem !== null) {
      const body = { filterV2: JSON.stringify(items[selectedItem]), isActive };
      RecordService.executeAutoCleanUpV2(body)
        .then(() => {
          dispatch(showSuccessAlert("Очистка выполнена"));
        })
        .catch(catchError);
    }
  };

  useEffect(() => {
    RecordService.getAutoCleanUp()
      .then(({ data }) => {
        try {
          if (data.filterV2) {
            setItems(data.filterV2);
          }
          setIsActive(data.isActive);
        } catch (err) {}
      })
      .catch((err) => catchError(err.response.data));
  }, []);

  return (
    <Paper className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isActive}
            onChange={handleChecked}
            name="isActive"
            color="primary"
            className={classes.paddingTop}
          />
        }
        label="Включить автоматическую очистку результатов БД"
      />
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.mr10}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setOpenCreateFilterDialog(true)}
          disabled={!isActive}
        >
          Создать
        </Button>
        <Button
          className={classes.mr10}
          variant="text"
          size="small"
          color="primary"
          onClick={handleOpenEditDialog}
          disabled={!isActive || selectedItem === null}
        >
          Редактировать
        </Button>
        <Button
          className={classes.mr10}
          variant="text"
          size="small"
          color="primary"
          onClick={handleDelete}
          disabled={!isActive || selectedItem === null}
        >
          Удалить
        </Button>
        <Button
          className={classes.mr10}
          variant="text"
          size="small"
          color="primary"
          onClick={() => setOpenConfirmImmediatelyDialog(true)}
          disabled={!isActive || selectedItem === null}
        >
          Выполнить сейчас
        </Button>
      </div>
      <Grid container spacing={2}>
        <div className={classes.filterWrapper}>
          {items.map((item, index) => (
            <Paper className={classes.filter}>
              <Grid item container key={index} alignItems="center" justify="space-between">
                <Grid item xs="auto">
                  <span style={{ fontSize: "14px" }}>{convertFilterName2(item)}</span>
                </Grid>
                <Grid item xs="auto">
                  <Checkbox
                    checked={selectedItem === index}
                    onChange={() => handleSelect(index)}
                    color="primary"
                    disabled={!isActive}
                  />
                  <IconButton onClick={handleDelete} disabled={selectedItem !== index}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={handleOpenEditDialog} disabled={selectedItem !== index}>
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
        <UpdateCleanUpDialog
          open={openEditDialog}
          onClose={handleCreate}
          startFilter={filter}
          handleSaveEdit={handleSaveEdit}
        />
        <CreateCleanUpDialog open={openCreateFilterDialog} onClose={handleCreate} />
        <ConfirmationDialog
          open={openConfirmImmediatelyDialog}
          onClose={handleCloseImmediatelyConfirmDialog}
          message="Подтвердите намерение выполнить очистку"
        />
      </Grid>
    </Paper>
  );
};

export default AutoCleanUp;
