import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { TextField, InputAdornment, makeStyles, Theme, Button } from "@material-ui/core";
import { Word } from "types/word";
import SearchIcon from "@material-ui/icons/Search";

// ag-grid
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import ColorRenderer from "components/agGrid/renderers/ColorRenderer";
import { langGetter } from "components/agGrid/valueGetters";
import {
  addRow,
  getRowsToRemoveFromIds,
  getUniqueIdsDownToHierarchy,
  removeRows,
  updateRow,
} from "components/agGrid/functions";
import WordService from "services/WordService";
import { catchError } from "functions/common";
import ConfirmationDialog from "components/ConfirmationDialog";
import CreateWordDialog from "pages/words/components/CreateWordDialog";
import UpdateWordDialog from "pages/words/components/UpdateWordDialog";
import ShowWordsDialog from "pages/words/components/ShowWordsDialog";

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Язык",
    field: "lang",
    valueGetter: langGetter,
  },
  {
    headerName: "Цвет",
    field: "color",
    cellRenderer: "ColorRenderer",
  },
  {
    headerName: "Комментарий",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

const autoGroupColumnDef = {
  headerName: "Группа",
  field: "name",
  minWidth: 300,
  cellRendererParams: {
    suppressCount: true,
    checkbox: true,
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    marginBottom: theme.spacing(1),
  },
  TableButtons: {
    display: "flex",
    alignItems: "center",
  },
  TableButton: {
    marginLeft: 10,
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchWrapper: {
    flexGrow: 1,
  },
  searchInput: {
    fontSize: 14,
  },
  flex1: {
    flex: 1,
  },
}));

interface ListSearchSectionProps {
  words: Word[];
  handleSearchSettingsChange: (newSettings: string[]) => void;
  idToSelect: string[];
}

const ListSearchSection: FC<ListSearchSectionProps> = ({ handleSearchSettingsChange, words, idToSelect }) => {
  const classes = useStyles();

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };
  const [selectedRows, setSelectedRows] = useState<Word[]>([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openShowWordsDialog, setOpenShowWordsDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: any) => String(data.id);

  const getDataPath = useCallback((data: any) => data.group.split("/"), []);

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
      if (rows) {
        const newSearchSettings = {
          dictionary: rows.map((word) => word.id),
        };
        handleSearchSettingsChange(newSearchSettings.dictionary);
      }
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      if (!gridApi) {
        return;
      }

      const ids: number[] = getUniqueIdsDownToHierarchy(selectedRows, gridApi);
      const idsStrings = ids.join(",");

      const rowsToRemove: Word[] = getRowsToRemoveFromIds(ids, gridApi);

      WordService.remove(idsStrings)
        .then(() => {
          removeRows(rowsToRemove, gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseCreateDialog = (data?: Word) => {
    setOpenCreateDialog(false);
    if (data) {
      WordService.create(data)
        .then((res) => {
          addRow(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseUpdateDialog = (data?: Word) => {
    setOpenUpdateDialog(false);
    if (data) {
      WordService.update(data)
        .then((res) => {
          updateRow(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.forEachNodeAfterFilterAndSort((node) => {
        // Проверяем, входит ли айди строки в массив nameToSelect
        if (idToSelect.includes(node.data.id)) {
          node.setSelected(true);
          // gridApi.ensureIndexVisible(node.rowIndex, "middle");
        }
      });
    }
  }, [gridApi, idToSelect, words]);

  return (
    <div className={classes.flex1}>
      <div className={classes.actions}>
        <div className={classes.TableButtons}>
          <div className={classes.searchWrapper}>
            <TextField
              className={classes.search}
              placeholder="Поиск..."
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <Button
              color="primary"
              size="small"
              className={classes.TableButton}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={selectedRows.length === 0}
            >
              Удалить
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.TableButton}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.TableButton}
              onClick={() => setOpenShowWordsDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Просмотреть
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.TableButton}
              onClick={() => setOpenCreateDialog(true)}
            >
              Создать
            </Button>
          </div>
        </div>
      </div>
      <div className={"ag-theme-balham"} style={{ height: "calc(100vh - 500px)" }}>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          treeData
          rowSelection="multiple"
          suppressDragLeaveHidesColumns
          suppressMovableColumns
          suppressColumnMoveAnimation
          suppressCopyRowsToClipboard
          groupSelectsChildren
          frameworkComponents={{
            DateRenderer,
            ColorRenderer,
          }}
          getRowNodeId={getRowNodeId}
          onGridReady={onGridReady}
          rowData={words}
          onSelectionChanged={onSelectionChanged}
          localeText={localization}
          getDataPath={getDataPath}
        />
      </div>
      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
      <CreateWordDialog
        open={openCreateDialog}
        onClose={handleCloseCreateDialog}
        path={selectedRows.length !== 0 ? selectedRows[0].group : ""}
      />

      {selectedRows.length === 1 && (
        <UpdateWordDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} item={selectedRows[0]} />
      )}

      {selectedRows.length === 1 && (
        <ShowWordsDialog
          open={openShowWordsDialog}
          onClose={() => setOpenShowWordsDialog(false)}
          words={selectedRows[0].words}
        />
      )}
    </div>
  );
};

export default ListSearchSection;
