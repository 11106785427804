import React, { ChangeEvent, FC } from "react";
import { ColorResult, TwitterPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { setSttBackGroundColor, setSttFont, setSttFontSize, setSttTextColor } from "redux/actions/settingsActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { grey, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  body: {
    width: 315,
    padding: theme.spacing(1),
  },
  title: {
    fontSize: 12,
    color: grey[600],
  },
}));

const colors = [
  "#FFF",
  grey[200],
  grey[400],
  grey[600],
  grey[800],
  "#000",
  orange[200],
  orange[400],
  orange[600],
  orange[800],
];

interface Props {
  anchorEl: null | HTMLElement;
  setAnchorEl: Function;
}

const STTSettings: FC<Props> = ({ anchorEl, setAnchorEl }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { sttFont, sttFontSize, sttTextColor, sttBackgroundColor } = useSelector((state: RootState) => state.settings);

  const handleSetSttFontSize = (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(setSttFontSize(event.target.value as number));
  };

  const handleChangeSttTextColor = (color: ColorResult) => {
    dispatch(setSttTextColor(color.hex));
  };

  const handleChangeSttBackgroundColor = (color: ColorResult) => {
    dispatch(setSttBackGroundColor(color.hex));
  };

  const handleSetSttFont = (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(setSttFont(event.target.value as string));
  };

  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <div className={classes.body}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-stt-font">Шрифт</InputLabel>
              <Select labelId="select-stt-font" value={sttFont} onChange={handleSetSttFont} fullWidth>
                {/* <MenuItem value="Roboto, sans-serif">Roboto (sans-serif)</MenuItem> */}
                <MenuItem value="Arial, sans-serif">Arial (sans-serif)</MenuItem>
                <MenuItem value="Arial Black, sans-serif">Arial Black (sans-serif)</MenuItem>
                {/* <MenuItem value="Verdana, sans-serif">Verdana (sans-serif)</MenuItem> */}
                <MenuItem value="Tahoma, sans-serif">Tahoma (sans-serif)</MenuItem>
                <MenuItem value="Trebuchet MS, sans-serif">Trebuchet MS (sans-serif)</MenuItem>
                {/* <MenuItem value="Impact, sans-serif">Impact (sans-serif)</MenuItem> */}
                <MenuItem value="Times New Roman, serif">Times New Roman (serif)</MenuItem>
                {/* <MenuItem value="Didot, serif">Didot (serif)</MenuItem>
                <MenuItem value="Georgia, serif">Georgia (serif)</MenuItem>
                <MenuItem value="American Typewriter, serif">American Typewriter (serif)</MenuItem> */}
                <MenuItem value="Andalé Mono, monospace">Andalé Mono (monospace)</MenuItem>
                {/* <MenuItem value="Courier, monospace">Courier (monospace)</MenuItem>
                <MenuItem value="Lucida Console, monospace">Lucida Console (monospace)</MenuItem>
                <MenuItem value="Monaco, monospace">Monaco (monospace)</MenuItem> */}
                <MenuItem value="Bradley Hand, cursive">Bradley Hand (cursive)</MenuItem>
                {/* <MenuItem value="Brush Script MT, cursive">Brush Script MT (cursive)</MenuItem>
                <MenuItem value="Luminari, fantasy">Luminari (fantasy)</MenuItem>
                <MenuItem value="Comic Sans MS, cursive">Comic Sans MS (cursive)</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-stt-font-size">Размер шрифта</InputLabel>
              <Select labelId="select-stt-font-size" value={sttFontSize} onChange={handleSetSttFontSize} fullWidth>
                <MenuItem value={10}>10px</MenuItem>
                <MenuItem value={12}>12px</MenuItem>
                <MenuItem value={14}>14px</MenuItem>
                <MenuItem value={16}>16px</MenuItem>
                <MenuItem value={18}>18px</MenuItem>
                <MenuItem value={20}>20px</MenuItem>
                <MenuItem value={22}>22px</MenuItem>
                <MenuItem value={24}>24px</MenuItem>
                <MenuItem value={26}>26px</MenuItem>
                <MenuItem value={28}>28px</MenuItem>
                <MenuItem value={30}>30px</MenuItem>
                <MenuItem value={32}>32px</MenuItem>
                <MenuItem value={34}>34px</MenuItem>
                <MenuItem value={36}>36px</MenuItem>
                <MenuItem value={38}>38px</MenuItem>
                <MenuItem value={40}>40px</MenuItem>
                <MenuItem value={42}>42px</MenuItem>
                <MenuItem value={44}>44px</MenuItem>
                <MenuItem value={46}>46px</MenuItem>
                <MenuItem value={48}>48px</MenuItem>
                <MenuItem value={50}>50px</MenuItem>
                <MenuItem value={52}>52px</MenuItem>
                <MenuItem value={54}>54px</MenuItem>
                <MenuItem value={56}>56px</MenuItem>
                <MenuItem value={58}>58px</MenuItem>
                <MenuItem value={60}>60px</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom className={classes.title}>
              Цвет текста
            </Typography>
            <TwitterPicker
              colors={colors}
              width="300px"
              color={sttTextColor}
              onChangeComplete={handleChangeSttTextColor}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom className={classes.title}>
              Цвет фона
            </Typography>
            <TwitterPicker
              colors={colors}
              width="300px"
              color={sttBackgroundColor}
              onChangeComplete={handleChangeSttBackgroundColor}
            />
          </Grid>
        </Grid>
      </div>
    </Menu>
  );
};

export default STTSettings;
