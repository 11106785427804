import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import useLmModels from "hooks/admin/useLmModels";
import LmModelService from "services/admin/LmModelService";
import { catchError, checkError } from "functions/common";

// components
import ConfirmationDialog from "components/ConfirmationDialog";
import LmModelsTable from "./components/LmModelsTable";
import CreateLmModelDialog from "./components/CreateLmModelDialog";
import UpdateLmModelDialog from "./components/UpdateLmModelDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
}));

const LmModels: FC = () => {
  const classes = useStyles();

  const { lmModels: rowData, error, getLmModels } = useLmModels();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows.map((el) => el.id).join(",");
      LmModelService.remove(ids)
        .then(() => {
          setSelectedRows([]);
          getLmModels();
        })
        .catch(catchError);
    }
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data) {
      LmModelService.create(data)
        .then(() => {
          setSelectedRows([]);
          getLmModels();
        })
        .catch(catchError);
    }
  };

  const handleCloseUpdateDialog = (data?: any) => {
    setOpenUpdateDialog(false);
    if (data) {
      LmModelService.update(data)
        .then(() => {
          setSelectedRows([]);
          getLmModels();
        })
        .catch(catchError);
    }
  };

  useEffect(() => {
    checkError(error);
  }, [error]);

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.buttons}>
          <div>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => getLmModels()}
            >
              Обновить
            </Button>
            <TextField
              className={classes.search}
              placeholder="Имя"
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={selectedRows.length === 0}
            >
              Удалить
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => setOpenCreateDialog(true)}
            >
              Создать
            </Button>
          </div>
        </div>
      </div>

      <LmModelsTable rowData={rowData} setGridApi={setGridApi} onSelection={onSelection} />

      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
      <CreateLmModelDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />

      {selectedRows.length === 1 && (
        <UpdateLmModelDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} lmModel={selectedRows[0]} />
      )}
    </Paper>
  );
};

export default LmModels;
