import { useEffect, useState } from "react";
import { AvailableSource } from "types/source";
import { AxiosError } from "axios";
import AdminSourceService from "services/admin/AdminSourceService";

interface State {
  loading: boolean;
  availableSources: AvailableSource[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  availableSources: [],
  error: undefined,
};

const useAvailableSources = () => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    AdminSourceService.getAvailableSources()
      .then(({ data }) => {
        setState(() => ({ availableSources: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ availableSources: [], loading: false, error: err }));
      });
  }, []);

  return state;
};

export default useAvailableSources;
