import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 15px",
  },
  button: {
    width: 100,
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: string) => void;
}

const ChangeIpDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [state, setState] = useState("");
  const disabled = useMemo(() => state.length === 0, [state]);

  // обработчик нажатия клавиш
  const handleKeyDown = useCallback(
    (event) => {
      if (!open) return;
      const { key } = event;

      if (key === "Enter" ?? disabled) {
        event.preventDefault();
        event.stopPropagation();
        onClose(state);
        return;
      }
    },
    [open, onClose, state, disabled]
  );

  useEffect(() => {
    setState("");
  }, [open]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      className={classes.root}
      fullWidth
      maxWidth="md"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Изменить ip у всех программ</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info">
              У всех программ на данном сервере ip поменяются на новый, при этом порты остануться прежними
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Новый ip"
              fullWidth
              name="ip"
              onChange={(e) => setState(e.target.value)}
              value={state}
              autoFocus
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => onClose(state)}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeIpDialog;
