import { AxiosResponse } from "axios";
import { Word } from "types/word";
import { words as route } from "http/urls";
import api from "http/api";

function WordService() {
  function getAll(): Promise<AxiosResponse<Word[]>> {
    const url = route.getAll;
    return api.get<Word[]>(url);
  }

  function create(body: any) {
    const url = route.create;
    return api.post(url, body);
  }

  function update(body: Word): Promise<AxiosResponse> {
    return api.put(route.update, body);
  }

  function remove(ids: string) {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  function download(ids: string) {
    const url = route.download.replace(":ids", ids);
    return api.get(url, { responseType: "blob" });
  }

  function upload(body: FormData): Promise<AxiosResponse> {
    const url = route.upload;
    return api.post(url, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  return Object.freeze({
    getAll,
    create,
    update,
    remove,
    download,
    upload,
  });
}

export default WordService();
