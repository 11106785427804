import React, { FC } from "react";
// import { GridApi } from "ag-grid-community";
// import { addRow, removeRows, updateRow } from "components/agGrid/functions";

// components

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import TextField from "@material-ui/core/TextField";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
}));

const CodeCity: FC = () => {
  const classes = useStyles();

  // const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  // const [selectedRows, setSelectedRows] = useState<any[]>([]);
  //
  // const [openCreateDialog, setOpenCreateDialog] = useState(false);
  // const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  // const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  //
  // const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   if (gridApi) {
  //     gridApi.setQuickFilter(value);
  //   }
  // };
  //
  // const onSelection = () => {
  //   if (gridApi) {
  //     const rows = gridApi.getSelectedRows();
  //     setSelectedRows(rows);
  //   }
  // };
  //
  // const handleCloseDeleteDialog = (confirm: boolean) => {
  //   setOpenDeleteDialog(false);
  //   if (confirm) {
  //     removeRows(selectedRows, gridApi);
  //   }
  // };
  //
  // const handleCloseCreateDialog = (data?: any) => {
  //   setOpenCreateDialog(false);
  //   if (data) {
  //     const row = {
  //       ...data,
  //       id: faker.datatype.uuid(),
  //       changeDate: faker.date.past(),
  //     };
  //     addRow(row, gridApi);
  //   }
  // };
  //
  // const handleCloseUpdateDialog = (data?: any) => {
  //   setOpenUpdateDialog(false);
  //   if (data) {
  //     updateRow(data, gridApi);
  //   }
  // };

  return (
    <Paper className={classes.root}>
      <Typography>code city</Typography>
    </Paper>
  );
};

export default CodeCity;
