import { useCallback, useEffect, useState } from "react";
import { Filter } from "types/filter";
import FilterService from "services/FilterService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  filters: Filter[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  filters: [],
  error: undefined,
};

const useFilters = () => {
  const [state, setState] = useState<State>(initialState);

  const getFilters = useCallback(() => {
    setState((prev) => ({ ...prev, loading: true }));
    FilterService.getAll()
      .then(({ data }) => {
        setState(() => ({ filters: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ filters: [], loading: false, error: err }));
      });
  }, []);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  return { ...state, getFilters };
};

export default useFilters;
