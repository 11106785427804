import React, { ChangeEvent, FC, ReactNode, useEffect } from "react";
import { Record } from "types/record";
import { setSelectedTab } from "redux/actions/selectedTabActions";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/types";

// components
import RecordTable from "./RecordTable";
import DetailLID from "./DetailLID";
import DetailVAD from "./DetailVAD";
import DetailDiarization from "./DetailDiarization";
import DetailSID from "./DetailSID";
import DetailFax from "./DetailFax";
import DetailSTT from "./DetailSTT";
import DetailLIDSegments from "./DetailLIDSegments";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";

const useStyles = makeStyles((theme) => ({
  root: {},
  tabPanel: {
    padding: theme.spacing(1, 0),
  },
  history: {
    width: "100%",
    border: "none",
    height: "calc(100vh - 268px)",
    padding: theme.spacing(2),
  },
}));

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tech-tabpanel-${index}`}
      aria-labelledby={`tech-tab-${index}`}
      {...other}
      className={classes.tabPanel}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `tech-tab-${index}`,
    "aria-controls": `tech-tabpanel-${index}`,
  };
}

interface Props {
  record: Record;
}

const TabTechnologies: FC<Props> = ({ record }) => {
  const classes = useStyles();
  const selectedTab = useSelector((state: RootState) => state.selectedTab.selectedTab);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedTab(selectedTab));
  }, [selectedTab, dispatch]);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Общее" {...a11yProps(0)} />
          <Tab label="LID" {...a11yProps(1)} />
          <Tab label="VAD" {...a11yProps(2)} />
          <Tab label="Diarization" {...a11yProps(3)} />
          <Tab label="SID" {...a11yProps(4)} />
          <Tab label="Факс" {...a11yProps(5)} />
          <Tab label="Смена языка" {...a11yProps(6)} />
          <Tab label="STT" {...a11yProps(7)} />
          <Tab label="Логирование" {...a11yProps(8)} />
        </Tabs>
      </AppBar>

      <TabPanel value={selectedTab} index={0}>
        <RecordTable record={record} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <DetailLID recordId={record.id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <DetailVAD recordId={record.id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <DetailDiarization recordId={record.id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <DetailSID recordId={record.id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={5}>
        <DetailFax recordId={record.id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={6}>
        <DetailLIDSegments recordId={record.id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={7}>
        <DetailSTT recordId={record.id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={8}>
        <textarea className={classes.history} defaultValue={record.message} disabled />
      </TabPanel>
    </div>
  );
};

export default TabTechnologies;
