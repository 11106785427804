import React, { ChangeEvent, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { setSortName, setSortOrder } from "redux/actions/settingsActions";
import { AdminTypeFilter } from "types/admin";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  sort: {
    display: "flex",
    alignItems: "center",
  },
  sortName: {
    margin: "0 20px",
    width: 160,
  },
  sortOrder: {
    width: 160,
  },
  grey: {
    color: grey[600],
  },
}));

interface Props {
  availableFilters: AdminTypeFilter[];
}

const SortSelect: FC<Props> = ({ availableFilters }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { sortName, sortOrder } = useSelector((state: RootState) => state.settings);

  const handleChangeSortName = (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(setSortName(event.target.value as string));
  };

  const handleChangeSortOrder = (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(setSortOrder(event.target.value as string));
  };

  if (availableFilters.length === 0) {
    return null;
  }

  return (
    <div className={classes.sort}>
      <Typography>Сортировать по</Typography>
      <div className={classes.sortName}>
        <Select labelId="select-sort-name-label" value={sortName} onChange={handleChangeSortName} fullWidth>
          {availableFilters
            .filter((f) => f.isCanSorting)
            .map((f, i) => (
              <MenuItem value={f.name} key={i}>
                {f.name}
              </MenuItem>
            ))}
        </Select>
      </div>
      <div className={classes.sortOrder}>
        <Select labelId="select-sort-order-label" value={sortOrder} onChange={handleChangeSortOrder} fullWidth>
          <MenuItem value="asc">По возрастанию</MenuItem>
          <MenuItem value="desc">По убыванию</MenuItem>
        </Select>
      </div>
    </div>
  );
};

export default SortSelect;
