import React, { FC, useEffect, useState, useMemo } from "react";
import { Keywords, RecordDetail, RecordFileViewType, Segment as SegmentType } from "types/record";
import { currTimeSubscriber } from "subscribers/PlayerSubscriber";
import clsx from "clsx";

// components
import NoData from "components/NoData";
import TextSegment from "./TextSegment";
import TextTSegment from "./TextTSegment";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  segments: {
    // flexGrow: 1,
    overflow: "auto",
  },
  noData: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loading: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  translate: {
    wordWrap: "break-word",
    // lineHeight: "normal",
    overflow: "auto",
    padding: 3,
  },
  fb55: {
    flexBasis: "55%",
  },
  fb45: {
    flexBasis: "45%",
  },
  fg1: {
    flexGrow: 1,
  },
  hidden: {
    display: "none",
  },
  wordBreak: {
    wordBreak: "break-all",
  },
  noPadding: {
    padding: 0,
  },
}));

interface Props {
  loading: boolean;
  segments: SegmentType[];
  tSegments: SegmentType[];
  error: undefined | Error;
  keywords: Keywords[];
  showTranslate: boolean;
  alwaysOnDisplay?: boolean;
  recordDetail: RecordDetail;
  updateSegment: (segment: SegmentType) => void;
}

const TextSegments: FC<Props> = ({
  loading,
  segments,
  tSegments,
  keywords,
  alwaysOnDisplay,
  showTranslate,
  recordDetail,
  updateSegment,
}) => {
  const classes = useStyles();

  const [currTime, setCurrTime] = useState(0);

  // является ли сеанс текстовым документом
  const isText = useMemo(() => recordDetail.viewType === RecordFileViewType.TEXT, [recordDetail]);

  const seg = useMemo(
    () =>
      segments.map((s, i) => ({
        ...s,
        tWords: tSegments[i] ? tSegments[i].words : [],
      })),
    [segments, tSegments]
  );

  useEffect(() => {
    currTimeSubscriber.subscribe((time) => {
      setCurrTime(time);
    });
    return () => {
      currTimeSubscriber.next(-1);
    };
  }, []);

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  if (!loading && segments.length === 0) {
    return (
      <div className={classes.noData}>
        <NoData />
      </div>
    );
  }

  const isDiarizationNameExist = segments.findIndex((t) => t.diarizationName !== "") !== -1;

  return (
    <>
      <div
        className={clsx(classes.segments, {
          [classes.fg1]: !showTranslate || tSegments.length === 0,
          [classes.fb55]: showTranslate && tSegments.length > 0,
          [classes.wordBreak]: isText,
        })}
      >
        {seg.map((s, i) => (
          <TextSegment
            segment={s}
            key={i}
            currentTime={currTime}
            keywords={keywords}
            isDiarizationNameExist={isDiarizationNameExist}
            showTranslate={showTranslate}
            alwaysOnDisplay={alwaysOnDisplay}
            recordDetail={recordDetail}
            updateSegment={updateSegment}
          />
        ))}
      </div>
      <div
        className={clsx(classes.translate, {
          [classes.hidden]: !showTranslate,
          [classes.fb45]: showTranslate && tSegments.length > 0,
          [classes.noPadding]: tSegments.length === 0,
        })}
      >
        {tSegments.map((s) => (
          <TextTSegment key={s.id} segment={s} keywords={keywords} />
        ))}
      </div>
    </>
  );
};

export default TextSegments;
