import React, { FC, ChangeEvent } from "react";
import { FolderSmb as T } from "types/source";
import PasswordInput from "components/form/PasswordInput";

// material ui
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PathSmb from "./PathSmb";

interface Props {
  state: T;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  changeSmbVersion: (e: ChangeEvent<{ value: unknown }>) => void;
  setPathValue: (value: string, pathName?: string) => void;
}

const FolderSmb: FC<Props> = ({ state, onChange, changeSmbVersion, setPathValue }) => {
  const { smbVersion, login, password } = state;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="select-smb-version-label">Версия</InputLabel>
          <Select labelId="select-smb-version-label" value={smbVersion} onChange={changeSmbVersion} fullWidth>
            <MenuItem value={"1.0"}>1.0 (для Win-XP)</MenuItem>
            <MenuItem value={"2.1"}>2.1 (для Win7 и старше)</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <PathSmb state={state} setPathValue={setPathValue} onChange={onChange} />
      </Grid>

      <Grid item xs={12}>
        <TextField label="Логин" fullWidth variant="standard" name="login" onChange={onChange} value={login} />
      </Grid>

      <Grid item xs={12}>
        <PasswordInput handleChange={onChange} value={password} margin="none" />
      </Grid>
    </Grid>
  );
};

export default FolderSmb;
