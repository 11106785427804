import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { SourceDetail, SourceDetailParagraf } from "types/source";
import { Group } from "types/queryBuilder";
import { Sort as SortType } from "types/common";
import { Module } from "types/esb";
import EsbService from "services/EsbService";
import { catchError } from "functions/common";
import useAvailableFilters from "hooks/admin/useAvailableFilters";

// components
import SleepSec from "../SleepSec";
import Sort from "../Sort";
import NoData from "components/NoData";
import SourceFilter from "../SourceFilter";

// material ui
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

interface Props {
  state: SourceDetailParagraf;
  setState: (item: SourceDetail) => void;
  disable: boolean;
}

const Paragraf: FC<Props> = ({ state, setState, disable }) => {
  const { filters: availableFilters } = useAvailableFilters("records");
  const { sleepSec, id, machineId, sort, isSaveResultBackToParagraf, isSaveFile, deleteFilter } = state;

  const [onlineModules, setOnlineModules] = useState<Module[]>([]);

  const setSleepSec = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState({ ...state, sleepSec: Number(value) });
  };

  const handleChangeSort = (event: ChangeEvent<{ value: unknown }>) => {
    const sort = event.target.value as SortType;
    setState({ ...state, sort });
  };

  const handleChangeParagrafName = (event: ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;

    const module = onlineModules.find((m) => m.machineId + m.id === val);
    if (module !== undefined) {
      setState({ ...state, machineId: module.machineId, id: module.id });
    }
  };

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setState({ ...state, [name]: checked });
  };

  const setDeleteFilter = (f: Group) => {
    setState({ ...state, deleteFilter: JSON.stringify(f) });
  };

  useEffect(() => {
    EsbService.onlineModules()
      .then(({ data }) => setOnlineModules(data ?? []))
      .catch((err) => catchError(err, "Ошибка получения онлайн модулей ИШД: "));
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="online-modules-label">Имя Параграфа в ИШД</InputLabel>
          <Select labelId="online-modules-label" value={machineId + id} onChange={handleChangeParagrafName} fullWidth>
            {onlineModules.length > 0 ? (
              onlineModules.map((m) => (
                <MenuItem key={m.machineId + m.id} value={m.machineId + m.id}>
                  {`${m.id} (${m.machineName.trim()})`}
                </MenuItem>
              ))
            ) : (
              <NoData />
            )}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Sort disabled={disable} sort={sort} handleChange={handleChangeSort} />
      </Grid>

      <Grid item xs={12}>
        <SourceFilter
          title="Исключить данные по фильтру"
          filter={deleteFilter}
          setFilter={setDeleteFilter}
          availableFilters={availableFilters}
        />
      </Grid>

      <Grid item xs={12}>
        <SleepSec sleepSec={sleepSec} setSleepSec={setSleepSec} />
      </Grid>

      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSaveResultBackToParagraf}
              onChange={handleChecked}
              name="isSaveResultBackToParagraf"
              color="primary"
            />
          }
          label="Сохранять результат обратно"
        />
      </Grid>

      <Grid item xs={6}>
        <FormControlLabel
          control={<Checkbox checked={isSaveFile} onChange={handleChecked} name="isSaveFile" color="primary" />}
          label="Сохранять файл обратно"
        />
      </Grid>
    </Grid>
  );
};

export default Paragraf;
