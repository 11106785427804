import { useEffect, useState } from "react";
import { MacAddress } from "types/macAddress";
import MacAddressService from "services/MacAddressService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  macAddresses: MacAddress[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  macAddresses: [],
  error: undefined,
};

const useMacAddresses = (offset: number, perPage: number, query: string) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    MacAddressService.getAll(offset, perPage, query)
      .then(({ data }) => {
        setState(() => ({ macAddresses: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ macAddresses: [], loading: false, error: err }));
      });
  }, [offset, perPage, query]);

  return state;
};

export default useMacAddresses;
