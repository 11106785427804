import React, { FC } from "react";
import { Speaker } from "types/speaker";
import UpdateFolderDialog from "./UpdateFolderDialog";
import UpdateSpeakerDialog from "./UpdateSpeakerDialog";

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  speaker: Speaker;
}

const UpdateDialog: FC<Props> = ({ open, onClose, speaker }) => {
  if (speaker.speakerType === "folder") {
    return <UpdateFolderDialog open={open} onClose={onClose} speaker={speaker} />;
  }

  return <UpdateSpeakerDialog speaker={speaker} open={open} onClose={onClose} />;
};

export default UpdateDialog;
