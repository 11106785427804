import React, { FC } from "react";
import { AdminTech } from "types/admin";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import CheckboxRenderer from "components/agGrid/renderers/CheckboxRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 151px)",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Имя технологии",
    field: "name",
    width: 320,
    checkboxSelection: true,
  },
  {
    headerName: "Сообщение",
    field: "message",
    flex: 1,
  },
  {
    headerName: "Активен",
    field: "isActive",
    cellRenderer: "CheckboxRenderer",
  },
];

interface Props {
  rowData: AdminTech[];
  setGridApi: Function;
  onSelection: Function;
}

const TechsTable: FC<Props> = ({ rowData, setGridApi, onSelection }) => {
  const classes = useStyles();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  function onSelectionChanged() {
    onSelection();
  }

  const getRowNodeId = (data: AdminTech) => String(data.id);

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="single"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        frameworkComponents={{
          CheckboxRenderer,
        }}
      />
    </div>
  );
};

export default TechsTable;
