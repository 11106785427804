import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";
import { SpeakerFile, SpeakerModel, UserFile, Speaker, SpeakerType } from "types/speaker";
import { generateUuid } from "functions/common";

// components
import Models from "./Models";
import Files from "./Files";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  models: {
    paddingTop: 20,
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  speaker: Speaker;
}

const UpdateSpeakerDialog: FC<Props> = ({ open, onClose, speaker }) => {
  const classes = useStyles();

  const [state, setState] = useState<Speaker>(speaker);
  const [userFiles, setUserFiles] = useState<UserFile[]>([]);

  const {
    name,
    comment,
    country,
    city,
    address,
    phoneModel,
    telecomOperator,
    models,
    files,
    isDeleteInText,
    isHighlightInText,
    speakerType,
  } = state;

  const disabled = name.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  const addModel = (model: SpeakerModel) => {
    setState((prev) => ({
      ...prev,
      models: [...prev.models, model],
    }));
  };

  const updateModel = (model: SpeakerModel) => {
    setState((prev) => ({
      ...prev,
      models: prev.models.map((m) => (m.id === model.id ? model : m)),
    }));
  };

  const removeModels = (models: SpeakerModel[]) => {
    setState((prev) => ({
      ...prev,
      models: prev.models.filter((m) => !models.find((el) => el.id === m.id)),
    }));
  };

  const addFiles = (fileList: FileList) => {
    const files = Array.from(fileList);
    const speakerFiles: SpeakerFile[] = [];
    const userFiles: UserFile[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const uuid = generateUuid();
      speakerFiles.push({
        id: -(Date.now() + i * 6),
        speakerId: 0,
        name: file.name,
        fileIdUpload: uuid,
        status: "notProcessed",
        message: "ожидает загрузки на сервер",
        ownerId: 0,
        dateChanged: new Date().toISOString(),
        dateCreated: new Date().toISOString(),
      });
      userFiles.push({
        uuid,
        file,
      });
    }

    setUserFiles((prev) => [...prev, ...userFiles]);
    setState((prev) => ({
      ...prev,
      files: [...prev.files, ...speakerFiles],
    }));
  };

  const removeFiles = (speakerFiles: SpeakerFile[]) => {
    setUserFiles((prev) => prev.filter((f) => !speakerFiles.find((el) => el.fileIdUpload === f.uuid)));
    setState((prev) => ({
      ...prev,
      files: prev.files.filter((f) => !speakerFiles.find((el) => el.id === f.id)),
    }));
  };

  const handleSave = () => {
    onClose({ speaker: state, userFiles });
  };

  const handleChangeSpeakerType = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setState((prev) => ({ ...prev, speakerType: value as SpeakerType }));
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setState(speaker);
    setUserFiles([]);
  }, [speaker]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Редактировать дикторскую карточку</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Имя"
              fullWidth
              name="name"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={name}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Объект</InputLabel>
              <Select labelId="type-label" value={speakerType} onChange={handleChangeSpeakerType} fullWidth>
                <MenuItem value="speaker">Диктор</MenuItem>
                <MenuItem value="autoinformator">Автоинформатор</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Модель телефона"
              fullWidth
              name="phoneModel"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={phoneModel}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Оператор"
              fullWidth
              name="telecomOperator"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={telecomOperator}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Страна"
              fullWidth
              name="country"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={country}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Город"
              fullWidth
              name="city"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={city}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Адрес"
              fullWidth
              name="address"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={address}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              name="comment"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={comment}
            />
          </Grid>

          {speakerType === "autoinformator" && (
            <>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDeleteInText}
                      onChange={handleCheckbox}
                      name="isDeleteInText"
                      color="primary"
                    />
                  }
                  label="Удалять в тексте"
                />
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isHighlightInText}
                      onChange={handleCheckbox}
                      name="isHighlightInText"
                      color="primary"
                    />
                  }
                  label="Выделять в тексте"
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <div className={classes.models}>
              <Models
                models={models}
                addModel={addModel}
                removeModels={removeModels}
                speakerId={speaker.id}
                updateModel={updateModel}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Files files={files} addFiles={addFiles} removeFiles={removeFiles} speakerId={speaker.id} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateSpeakerDialog;
