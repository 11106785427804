import { AxiosResponse } from "axios";
import { GraphSettings } from "types/graph";
import { graph as route } from "http/urls";

import api from "http/api";

function GraphsService() {
  function getAll(ids: string, depth: string): Promise<AxiosResponse<any[]>> {
    const url = route.getAll.replace(":ids", ids) + "?depth=" + depth;
    return api.get<any[]>(url);
  }

  function settings(): Promise<AxiosResponse<GraphSettings>> {
    const url = route.settings;
    return api.get<GraphSettings>(url);
  }

  function createLink(id1: string, id2: string) {
    const url = route.createLink.replace(":id1", id1).replace(":id2", id2);
    return api.post(url);
  }

  function removeLink(id1: string, id2: string) {
    const url = route.removeLink.replace(":id1", id1).replace(":id2", id2);
    return api.delete(url);
  }
  /*
  function create(body: any) {
    const url = route.create;
    return api.post(url, body);
  }

  function update(body: MacAddress): Promise<AxiosResponse> {
    return api.put(route.update, body);
  }

  function remove(ids: string) {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }
*/
  return Object.freeze({
    getAll,
    settings,
    createLink,
    removeLink,
    //update,
    //remove,
  });
}

export default GraphsService();
