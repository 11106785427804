import React, { FC, useRef, useState, useEffect, useCallback } from "react";

// components
import InputTableEditComponent from "./InputTableEditComponent";
import FilterService from "services/admin/FilterService";

// icons
import CloseIcon from "@material-ui/icons/Close";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  divText: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "21px",
    lineHeight: "21px",
    cursor: "text",
    marginRight: "2px",
  },
  spanText: {
    minWidth: "20px",
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  StyleBtn: {
    color: grey[500],
    width: "16px",
    height: "16px",
    // position: "absolute",
    // right: "0px",
  },
  StyleBtnSvg: {
    // color: grey[500],
    width: "16px",
    height: "16px",
  },
}));

export interface State {
  text: string;
}

interface Props {
  id?: string;
  value: string;
  color: string;
  // list: string[];
  isFocus: boolean;
  isVisibleDeleteButton?: boolean;
  params: any;

  onSelection?: Function;
  onOpen?: Function;
  onClickDelete?: Function;
  doCustomGetQuery: Function;
}

const InputTableComponent: FC<Props> = ({
  id,
  value,
  color,
  // list,
  isFocus,
  isVisibleDeleteButton,
  params,

  onSelection,
  onOpen,
  onClickDelete,
  doCustomGetQuery,
}) => {
  const classes = useStyles();

  const refDiv = useRef<any>(null);

  const [text, setText] = useState<string>("Загрузка...");
  const [list, setList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getFilters = useCallback(() => {
    if (!params || !params.url) {
      setText(`Ошибка: параметры params не содержат url`);
      console.log(`Ошибка: параметры params не содержат url`);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    FilterService.doCustomGetQuery(params.url)
      .then((res) => {
        setList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setText(`Ошибка: ${err.response.data}`);
        setIsLoading(false);
      });
  }, [params]);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  useEffect(() => {
    if (isFocus && refDiv) {
      setAnchorEl(refDiv.current);
    } else {
      setAnchorEl(null);
    }
  }, [isFocus, refDiv]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    // console.log(`InputTableComponent useEffect value, list`);

    if (!isLoading && list.length > 0) {
      let str = "";
      const lines = value.split("\n");
      lines.forEach((p) => {
        if (p === "") {
          return;
        }

        const exist = list.find((item) => p === item.id.toString());
        if (exist) {
          str += `${exist.name} `;
        } else {
          str += `не найден (${p}) `;
        }
      });

      setText(str);
    }
  }, [value, list, isLoading]);

  // console.log(`InputTableComponent id: ${id} isFocus: ${isFocus}`);
  return (
    <div>
      <div
        ref={refDiv}
        className={classes.divText}
        style={{
          background: color,
        }}
        onClick={(e) => onOpen?.()}
      >
        <span className={classes.spanText} title={text}>
          {text}
        </span>

        {isVisibleDeleteButton && (
          <IconButton
            className={classes.StyleBtn}
            disableRipple
            color="primary"
            title="Удалить фильтр"
            onClick={(e) => onClickDelete?.()}
          >
            <CloseIcon className={classes.StyleBtnSvg} />
          </IconButton>
        )}
      </div>

      {anchorEl && (
        <InputTableEditComponent anchorEl={anchorEl} id={id} list={list} onSelection={onSelection} value={value} />
      )}
    </div>
  );
};

export default InputTableComponent;
