import { useCallback, useEffect, useState } from "react";
import { Segment } from "types/record";
import RecordService from "services/RecordService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  segments: Segment[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  segments: [],
  error: undefined,
};

const useTranslatedSegments = (recordId: number | string, queryString?: string) => {
  const [state, setState] = useState<State>(initialState);

  const request = useCallback(() => {
    setState(() => ({ error: undefined, segments: [], loading: true }));
    RecordService.getTranslatedSegments(recordId, queryString)
      .then(({ data }) => {
        setState({ segments: data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ segments: [], loading: false, error: err });
      });
  }, [recordId, queryString]);

  useEffect(() => {
    request();
  }, [request]);

  return { ...state, request };
};

export default useTranslatedSegments;
