import { useEffect, useState } from "react";
import { AdminTechStt } from "types/admin";
import { Lang } from "types/task";
import TechService from "services/admin/TechService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  languages: Lang[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  languages: [],
  error: undefined,
};

const useSttLanguages = (shouldFetch?: boolean) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    if (shouldFetch) {
      setState(() => ({ languages: [], error: undefined, loading: true }));
      TechService.getTechByName("stt")
        .then(({ data }) => {
          const techStt = data.techDetail as AdminTechStt;
          const languages = techStt.languages.filter((l) => l.id !== "auto" && l.id !== "unknown_language");
          setState({ languages, loading: false, error: undefined });
        })
        .catch((err) => {
          setState({ languages: [], loading: false, error: err });
        });
    }
  }, [shouldFetch]);

  return state;
};

export default useSttLanguages;
