import React, { FC } from "react";

// material ui
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {},
  chip: {
    marginRight: 10,
    cursor: "pointer",
  },
}));

interface Props {
  value: string;
  changeValue: (v: string) => void;
}

const BooleanInput: FC<Props> = ({ value, changeValue }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Chip
        label="Да"
        className={classes.chip}
        onClick={() => changeValue("true")}
        color={value === "true" ? "primary" : "default"}
      />
      <Chip
        label="Нет"
        className={classes.chip}
        onClick={() => changeValue("false")}
        color={value === "false" ? "primary" : "default"}
      />
    </div>
  );
};

export default BooleanInput;
