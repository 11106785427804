import { useEffect, useState } from "react";
import { Passport } from "types/admin";
import PassportsService from "services/admin/PassportsService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  rowData: Passport[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  rowData: [],
  error: undefined,
};

const usePassports = () => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    PassportsService.getAll()
      .then(({ data }) => {
        setState({ rowData: data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ rowData: [], loading: false, error: err });
      });
  }, []);

  return state;
};

export default usePassports;
