import { useEffect, useState } from "react";
import { List } from "types/list";
import ListService from "services/ListService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  lists: List[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: true,
  lists: [],
  error: undefined,
};

const useLists = () => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    ListService.getAll()
      .then(({ data }) => {
        setState(() => ({ lists: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ lists: [], loading: false, error: err }));
      });
  }, []);

  return state;
};

export default useLists;
