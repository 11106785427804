import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState, SettingsState } from "redux/types";
import { drawerClose, drawerOpen } from "redux/actions/drawerActions";
import { fetchNavigationModules } from "redux/asyncAcions/navigation";
import SettingsService from "services/admin/SettingsService";
import { setAllSettings } from "redux/actions/settingsActions";
import { defaultState as defaultSettings } from "redux/reducers/settingsReducer";
import { defaultPageSettings } from "redux/reducers/pageSettingsReducer";
import { PageSettingsState } from "redux/types/pageSettings";
import { pagesSetSettings } from "redux/actions/pageSettingsActions";
import { Key } from "types/settings";

// components
import Navigation from "layouts/user/navigation/Navigation";
import Header from "layouts/user/header/Header";
import Notification from "components/Notification";
import ErrorBoundary from "components/ErrorBoundary";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";

// icons
import TwitchCloseIcon from "components/icons/TwitchCloseIcon";
import TwitchOpenIcon from "components/icons/TwitchOpenIcon";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  aside: {
    height: "100vh",
    overflow: "auto",
    borderRight: "1px solid #ccc",
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  navigation: {},
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    height: "100vh",
    overflow: "hidden",
  },
  toggleButton: {
    height: 45,
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    cursor: "pointer",
  },
  menuTitle: {
    margin: 0,
    padding: 0,
  },
  alert: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "30vw",
    zIndex: theme.zIndex.drawer + 10,
  },
}));

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  const widthOpen = 280;
  const widthClose = 56;
  const classes = useStyles();
  const { open } = useSelector((state: RootState) => state.drawer);
  const dispatch = useDispatch();

  const [loadSettings, setLoadSettings] = useState(true);

  const firstRender = useRef(true);

  const handleOpen = () => {
    open ? handleDrawerClose() : handleDrawerOpen();
  };

  const handleDrawerOpen = () => {
    dispatch(drawerOpen());
  };

  const handleDrawerClose = () => {
    dispatch(drawerClose());
  };

  useEffect(() => {
    dispatch(fetchNavigationModules());
  }, [dispatch]);

  useEffect(() => {
    if (firstRender.current) {
      SettingsService.getByKye({ key: Key.PAGE_SETTINGS }).then((res) => {
        const { value } = res.data;
        try {
          const savedSettings = JSON.parse(value);
          const settings: PageSettingsState = {
            ...defaultPageSettings,
            ...savedSettings,
          };
          dispatch(pagesSetSettings(settings));
        } catch (error) {}
      });

      SettingsService.getByKye({ key: Key.SETTINGS })
        .then((res) => {
          const { value } = res.data;
          try {
            const savedSettings = JSON.parse(value);
            const settings: SettingsState = {
              ...defaultSettings,
              ...savedSettings,
            };
            dispatch(setAllSettings(settings));
          } catch (error) {}
        })
        .finally(() => {
          setLoadSettings(false);
        });

      firstRender.current = false;
    }
  }, [dispatch]);

  if (loadSettings) {
    return null;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>

      <aside style={{ width: open ? widthOpen : widthClose }} className={clsx(classes.aside, "app_aside")}>
        <Toolbar />
        <div
          onClick={handleOpen}
          className={classes.toggleButton}
          style={{ justifyContent: open ? "flex-end" : "center" }}
        >
          {open ? <TwitchCloseIcon /> : <TwitchOpenIcon />}
        </div>
        <div className={classes.navigation}>
          <Navigation />
        </div>
      </aside>

      <main className={classes.content}>
        <Toolbar />
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>

      <Notification />
    </div>
  );
};

export default Layout;
