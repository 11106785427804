import React, { FC, useState } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  textArea: {
    // backgroundColor: "#f6f6ee",
    padding: theme.spacing(1),
    border: "none",
    outline: "none",
    width: "100%",
  },
  disableTransition: {
    transition: "none",
  },
}));

export interface State {
  text: string;
}

interface Props {
  anchorEl: any;

  id?: string;
  value: string;
  // isEditStart: boolean;
  onSelection?: Function;
  params: any;
  // onLeftRight?: Function;
  // onClose?: Function;
}

const InputNumberEditComponent: FC<Props> = ({
  anchorEl,
  id,
  value,
  // isEditStart,
  onSelection,
  params,

  // onLeftRight,
  // onClose,
}) => {
  const classes = useStyles();

  let max = 1000;
  let min = 0;
  let step = 0.1;

  if (params && params.max) {
    max = params.max * 1;
  }
  if (params && params.min) {
    min = params.min * 1;
  }
  if (params && params.step) {
    step = params.step * 1;
  }
  if (params && params.step && value === "") {
    value = params.default;
  }

  const [state, setState] = useState<string>(value);

  const OnCloseCallback = () => {
    // setAnchorEl(null);
    onSelection?.(state, "editAndClose");
  };

  const onChange = (event: any) => {
    const value = event.target.value;
    setState(value);
  };

  const onBlur = () => {
    const value = parseFloat(state);

    if (value < min) {
      setState(min.toString());
    } else if (value > max) {
      setState(max.toString());
    }
  };

  const onKeyDown = (e: any) => {
    // console.log(`InputStringComponent e.key: ${e.key}`);

    if (e.key === "Enter") {
      onSelection?.(state, "edit");
    }

    if (e.key === "Escape") {
      onSelection?.(state, "editAndClose");
    }

    e.stopPropagation();
  };

  // console.log(`InputNumberEditComponent render id: ${id}. min: ${min}. max: ${max}. step: ${step}`);
  return (
    <div>
      <Popover
        className={classes.disableTransition}
        open
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          OnCloseCallback();
        }}
      >
        <TextField
          style={{ width: "200px" }}
          value={state}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          type="number"
          InputProps={{ inputProps: { min, max, step, type: "number" } }}
          variant="outlined"
          autoFocus
        />
      </Popover>
    </div>
  );
};

export default InputNumberEditComponent;
