import React, { FC, useEffect, useState } from "react";

// components
import { FilterType as TFilterType, Group as TGroup, Rule as TRule } from "types/queryBuilder";
import { EditModel } from "./types/types";
import { AdminTypeFilter } from "types/admin";
import InputComboEditComponent from "./components/InputComboEditComponent";
import RenderGroupRule from "./components/RenderGroupRule";
import { generateUuid } from "functions/common";
import { getConditionList } from "./common/helperFunctions";

export interface SearchFilterState {
  editModel: EditModel;
  isFocusMainInput: boolean;
}

interface Props {
  availableFilters: AdminTypeFilter[];
  query: TGroup;
  setQuery: Function;
  doCustomGetQuery: Function;
}
const SearchFilter: FC<Props> = ({ availableFilters, query, setQuery, doCustomGetQuery }) => {
  // const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [state, setState] = useState<SearchFilterState>({
    editModel: { id: "", editMode: -1, isEditStart: true, isEditFullItem: false },
    isFocusMainInput: false,
  });
  const [listFilters, setListFilters] = useState<string[]>([]);

  const convert2Ids = (root: TRule | TGroup) => {
    let res: string[] = [];

    if (root.type === "filter") {
      res.push(root.uuid);
      return res;
    }

    for (let i = 0; i < root.filters.length; i++) {
      const exist = convert2Ids(root.filters[i]);
      if (exist) {
        res.push(...exist);
      }
    }

    return res;
  };

  const getFilterById = (root: TRule | TGroup, id: string): TRule | TGroup | null => {
    if (root.uuid === id) {
      return root;
    }

    if (root.type === "filter") {
      return null;
    }

    // root.filters = root.filters.filter(p=>p.uuid !== id);
    for (let i = 0; i < root.filters.length; i++) {
      const exist = getFilterById(root.filters[i], id);
      if (exist) {
        return exist;
      }
    }
    return null;
  };

  const setFilterById = (root: TRule | TGroup, newFilter: TRule | TGroup): boolean => {
    if (root.uuid === newFilter.uuid) {
      return true;
    }

    if (root.type === "filter") {
      return false;
    }

    for (let i = 0; i < root.filters.length; i++) {
      const exist = getFilterById(root.filters[i], newFilter.uuid);
      if (exist) {
        root.filters[i] = newFilter;
        return true;
      }
    }
    return false;
  };

  const removeById = (root: TRule | TGroup, id: string) => {
    if (root.type === "filter") {
      return;
    }

    root.filters = root.filters.filter((p) => p.uuid !== id);
    for (let i = 0; i < root.filters.length; i++) {
      removeById(root.filters[i], id);
    }
  };

  const getLeftOrRightItem = (query: TGroup, editModel: EditModel, pos: string) => {
    const ids = convert2Ids(query);
    let newId = "-2";
    let ind = ids.indexOf(editModel.id);

    // console.log(`getLeftOrRightItem editModel.id: ${editModel.id}. ids: ${ids}. ind: ${ind}. pos: ${pos}`);

    let model = { id: "", editMode: -1, isEditStart: false, isEditFullItem: false };
    if (pos === "ArrowLeft") {
      if (editModel.editMode === 2) {
        model = { id: editModel.id, editMode: 1, isEditStart: false, isEditFullItem: true };
      }
      if (editModel.editMode === 1) {
        model = { id: editModel.id, editMode: 0, isEditStart: false, isEditFullItem: true };
      }
      if (editModel.editMode === 0) {
        ind--;
        if (ind >= 0) {
          newId = ids[ind];
        }
        model = { id: newId, editMode: 2, isEditStart: false, isEditFullItem: true };
      }
    }

    if (pos === "ArrowRight") {
      if (editModel.editMode === 0) {
        model = { id: editModel.id, editMode: 1, isEditStart: true, isEditFullItem: true };
      }
      if (editModel.editMode === 1) {
        model = { id: editModel.id, editMode: 2, isEditStart: true, isEditFullItem: true };
      }

      if (editModel.editMode === 2) {
        // if (ind === ids.length - 1) {
        newId = "-3";
        ind++;
        if (ind < ids.length) {
          newId = ids[ind];
        }

        model = { id: newId, editMode: 0, isEditStart: false, isEditFullItem: true };
        // }
      }
    }

    // console.log(`getLeftOrRightItem ids: ${ids}. now.id: ${editModel.id} now.mode: ${editModel.editMode}.  model.id: ${model.id} model.mode: ${model.editMode}`);

    return model;
  };

  const onSelection = (selectedValue: string, reason: string) => {
    // console.log(`SearchFilter OnSelectedValue: ${selectedValue}`);

    setAnchorEl(null);

    const filter = availableFilters.find((p) => p.name === selectedValue);
    if (!filter) {
      console.log(`DEBUG SearchFilter filter: '${selectedValue}' not in availableFilters. exit`);
      return;
    }

    let listConditions = getConditionList(filter.type as TFilterType);

    const newFilter: TRule = {
      uuid: generateUuid(),
      type: "filter",
      filter: filter.name,
      condition: listConditions[0],
      value: "",
    };
    // console.log(newFilter);

    const q = { ...query };
    q.filters.push(newFilter);

    const model = { id: newFilter.uuid, editMode: 0, isEditStart: false, isEditFullItem: true };
    const NewModel = getLeftOrRightItem(q, model, "ArrowRight");

    // если 1 условие, то сразу редактируем значения
    if (listConditions.length === 1 && NewModel.editMode === 1) {
      NewModel.editMode = 2;
    }

    // console.log(`OnSelectedValue: editModel change. id: ${NewModel.id}. mode: ${NewModel.editMode}`);
    setState({
      isFocusMainInput: false,
      editModel: NewModel,
    });

    // console.log(`OnSelectedValue add new filter`);
    // console.log(q);
    setQuery(q);
  };

  const onBlur = () => {
    // console.log(`onBlur change editModel. id: ${state.editModel.id} mode: ${state.editModel.editMode}`);

    // setAnchorEl(null);

    setState({
      editModel: { id: "", editMode: -1, isEditStart: false, isEditFullItem: false },
      isFocusMainInput: false,
    });
  };

  const onClickRule = (uuid: string, mode: number) => {
    // console.log(`onClickRule uuid: ${uuid}. mode: ${mode}`);

    setState({
      isFocusMainInput: false,
      editModel: { id: uuid, editMode: mode, isEditStart: false, isEditFullItem: false },
    });
  };

  const onChangeRule = (operation: string, value: TRule | TGroup) => {
    // console.log(`onChangeRule operation: ${operation}`);

    if (value.type === "group") {
      // TODO: побавить редактирования групп
      return;
    }

    let q = { ...query };
    let model = { id: "", editMode: -1, isEditStart: false, isEditFullItem: false };
    let isFocusMainInput = false;

    if (operation === "edit") {
      model = getLeftOrRightItem(q, state.editModel, "ArrowRight");
      isFocusMainInput = model.id === "-3";
      setFilterById(q, value);
    }

    if (operation === "editAndClose") {
      setFilterById(q, value);
    }

    if (operation === "delete") {
      removeById(q, value.uuid);
    }

    // console.log(`onChangeRule: editModel change. id: ${model.id}. mode: ${model.editMode}`);
    // console.log(q);
    setState({
      isFocusMainInput: isFocusMainInput,
      editModel: model,
    });

    // console.log(`onChangeRule edit or delete`);
    // console.log(q);
    setQuery(q);
  };

  const checkRule = (root: TRule | TGroup) => {
    if (root.type === "filter") {
      if (availableFilters.findIndex((p) => p.name === root.filter) === -1) {
        console.log(`DEBUG: SearchFilter remove unknown filter: ${JSON.stringify(root.filter)}`);
        removeById(query, root.uuid);
        return;
      }

      // console.log(`DEBUG: SearchFilter filter success: ${JSON.stringify(root.filter)}`);
      return;
    }

    // root.filters = root.filters.filter(p=>p.uuid !== id);
    for (let i = 0; i < root.filters.length; i++) {
      checkRule(root.filters[i]);
    }
    return;
  };

  checkRule(query);

  useEffect(() => {
    let _listFilters: string[] = [];
    for (let i = 0; i < availableFilters.length; i++) {
      _listFilters.push(availableFilters[i].name);
    }
    setListFilters(_listFilters);
  }, [availableFilters]);

  return (
    <div style={{ width: "100%", display: "grid" }}>
      <div
        style={{
          float: "left",
          display: "flex",
          flexGrow: 1,
          flexBasis: 0,
          width: "100%",
          overflowX: "auto",
          padding: "0.25rem",
        }}
      >
        <RenderGroupRule
          availableFilters={availableFilters}
          rule={query}
          editModel={state.editModel}
          onBlur={onBlur}
          onClickRule={onClickRule}
          onChangeRule={onChangeRule}
          doCustomGetQuery={doCustomGetQuery}
        />

        <div
          style={{
            height: "21px",
            width: "100%",
            cursor: "text",
            minWidth: "300px",
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        />

        {anchorEl && (
          <InputComboEditComponent
            value=""
            anchorEl={anchorEl}
            onSelection={onSelection}
            // onClose={() => { setAnchorEl(null) }}
            list={listFilters}
          />
        )}
      </div>
    </div>
  );
};

export default SearchFilter;
