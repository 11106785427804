import React, { FC } from "react";
import { Event } from "types/event";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { Group } from "../../../types/queryBuilder";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    cursor: "pointer",
    textDecoration: "underline",
    color: theme.palette.primary.main,
  },
  notViewed: {
    marginRight: 5,
    width: 62,
    height: 16,
  },
}));

const EventNameRenderer: FC<{ value: string; data: Event }> = ({ value, data }) => {
  const classes = useStyles();
  const history = useHistory();
  const { isHaveNotViewed } = data;

  const handleClick = () => {
    const { path } = routes.results;

    const group: Group = {
      type: "group",
      uuid: "1",
      operation: "AND",
      filters: [
        { type: "filter", uuid: "2", filter: "Контрольное событие", condition: "=", value: String(data.id) },
        { type: "filter", uuid: "3", filter: "Просмотрен", condition: "=", value: "Нет" },
      ],
    };

    const url = path + "?f=" + JSON.stringify(group);
    history.push(encodeURI(url));
  };

  return (
    <div className={classes.root}>
      {isHaveNotViewed && <Chip className={classes.notViewed} label="новое" color="secondary" />}
      <span className={classes.name} onClick={handleClick}>
        {value}
      </span>
    </div>
  );
};

export default EventNameRenderer;
