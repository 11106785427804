import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { routes as r } from "routes";
import { Module } from "types/admin";

// material ui
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";

let rules = [
  { title: r.entry, regexp: /^\/$/gm },
  { title: r.sources.id, regexp: r.sources.regexp },
  { title: r.tasks.id, regexp: r.tasks.regexp },
  { title: r.dictionaries.id, regexp: r.dictionaries.regexp },
  { title: r.namespaces.id, regexp: r.namespaces.regexp },
  { title: r.filters.id, regexp: r.filters.regexp },
  { title: r.events.id, regexp: r.events.regexp },
  { title: r.statistic.id, regexp: r.statistic.regexp },
  { title: r.words.id, regexp: r.words.regexp },
  { title: r.speakers.id, regexp: r.speakers.regexp },
  { title: r.objects.id, regexp: r.objects.regexp },
  { title: r.labels.id, regexp: r.labels.regexp },
  { title: r.lists.id, regexp: r.lists.regexp },
  { title: r.compare.id, regexp: r.compare.regexp },
  { title: r.lid.id, regexp: r.lid.regexp },
  { title: r.amModels.id, regexp: r.amModels.regexp },
  { title: r.lmModels.id, regexp: r.lmModels.regexp },
  { title: r.baseStations.id, regexp: r.baseStations.regexp },
  { title: r.codeCity.id, regexp: r.codeCity.regexp },
  { title: r.phoneNumbers.id, regexp: r.phoneNumbers.regexp },
  { title: r.ipAddress.id, regexp: r.ipAddress.regexp },
  { title: r.macAddress.id, regexp: r.macAddress.regexp },
  { title: r.map.id, regexp: r.map.regexp },
  { title: r.analytica.id, regexp: r.analytica.regexp },
  { title: r.results.id, regexp: r.results.regexp },
  { title: r.graph.id, regexp: r.graph.regexp },
  { title: r.wordsCloud.id, regexp: r.wordsCloud.regexp },
  { title: r.charts.id, regexp: r.charts.regexp },
];

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: theme.spacing(0, 1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
  },
}));

const Navigation: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { modules } = useSelector((state: RootState) => state.navigation);
  const [select, setSelect] = useState("");

  useEffect(() => {
    let { pathname } = location;
    rules.forEach((rule) => {
      let match = pathname.match(rule.regexp);
      if (match !== null) {
        setSelect(rule.title);
      }
    });
  }, [location]);

  return (
    <List disablePadding>
      <Divider />
      {modules
        .filter((m: Module) => m.isActive)
        .map((m: Module) => (
          <Link key={m.id} to={m.id} className={classes.link} title={m.name}>
            <ListItem button selected={select === m.id}>
              <ListItemIcon>
                <Icon>{m.icon === "" ? "featured_play_list" : m.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={m.name} />
            </ListItem>
          </Link>
        ))}
    </List>
  );
};

export default Navigation;
