import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 100px)",
    position: "relative",
    overflow: "hidden",
  },
  iframe: {
    position: "absolute",
    top: -126,
    left: 0,
    width: "100%",
    height: "calc(100vh + 24px)",
    border: "none",
  },
}));

const LmModels: FC = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography>LmModels</Typography>
    </Paper>
  );
};

export default LmModels;
