import React, { FC, useState, useEffect, ChangeEvent } from "react";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";

// components
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import FilterNameRenderer from "components/agGrid/renderers/FilterNameRenderer";
import IsCanWriteRenderer from "components/agGrid/renderers/IsCanWriteRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";

// icons
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
  disableTransition: {
    transition: "none",
  },
  table: {
    height: 450,
    width: 600,
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Идентификатор",
    field: "id",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sort: "desc",
  },
  {
    headerName: "Имя",
    field: "name",
    flex: 1,
  },
];

interface Props {
  anchorEl: any;
  id?: string;
  value: string;
  list: any[];
  onSelection?: Function;
}

const InputTableEditComponent: FC<Props> = ({ anchorEl, id, value, list, onSelection }) => {
  const classes = useStyles();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const OnClose = (event: any) => {
    if (gridApi) {
      const list = gridApi.getSelectedRows();
      onSelection?.(list.map((p) => p.id).join("\n"), "editAndClose");
    }
  };

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    const lines = value.split("\n");
    gridApi.forEachNode((rowNode) => {
      rowNode.setSelected(lines.findIndex((p) => p === rowNode.data.id.toString()) !== -1);
    });
  }, [gridApi, value]);

  // console.log(`InputTableEditComponent render anchorEl:${anchorEl}`);
  return (
    <Popover
      className={classes.disableTransition}
      open
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={OnClose}
    >
      <TextField
        placeholder="Поиск"
        onChange={onQuickFilterChanged}
        variant="outlined"
        size="small"
        style={{
          width: "100%",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <div className={clsx(classes.table, "ag-theme-balham")}>
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          rowData={list}
          columnDefs={columnDefs}
          // localeText={localization}
          rowSelection="multiple"
          enableRangeSelection
          suppressDragLeaveHidesColumns
          frameworkComponents={{
            DateRenderer,
            FilterNameRenderer,
            IsCanWriteRenderer,
          }}
        />
      </div>
    </Popover>
  );
};

export default InputTableEditComponent;
