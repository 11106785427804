import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";
import clsx from "clsx";
import Technologies from "./Technologies";
import moment from "moment";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { Group } from "types/queryBuilder";
import { ExportTask, TaskTech } from "types";

// components
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TaskExportTable from "./TaskExportTable";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
  techs: {
    paddingTop: 20,
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  filterDetail: Group;
  showCheckboxes?: boolean;
  isForce: boolean;
}

interface State {
  id: number;
  name: string;
  comment: string;
  isActive: boolean;
  isForce: boolean;
}

const initialState = (isForce: boolean) => ({
  id: Date.now(),
  name: "Задание от " + moment().format("DD-MM-YYYY HH:mm:ss"),
  comment: "",
  isActive: true,
  isForce: isForce,
});

const CreateTaskDialog: FC<Props> = ({ open, onClose, filterDetail, showCheckboxes, isForce }) => {
  const classes = useStyles();

  const { filters: availableFilters } = useAvailableFilters("records");

  const [state, setState] = useState<State>(initialState(isForce));
  const { name, comment, isActive } = state;

  const [filter, setFilter] = useState(filterDetail);
  const [technologies, setTechnologies] = useState<TaskTech[]>([]);
  const [exports, setExports] = useState<ExportTask[]>([]);

  const disabled = name.length === 0 || technologies.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (key !== "Escape") {
      event.stopPropagation();
    }
  };

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setState((prev) => ({ ...prev, isActive: checked }));
  };

  const handleCheckedIsForce = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setState((prev) => ({
      ...prev,
      isForce: checked,
    }));
  };

  const handleSave = () => {
    onClose({ ...state, techs: technologies, filter: JSON.stringify(filter), exports });
  };

  useEffect(() => {
    if (!open) return;
    setState(initialState(isForce));
    setFilter(filterDetail);
  }, [open, filterDetail, isForce]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Создать задание</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Имя"
              fullWidth
              variant="standard"
              name="name"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={name}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Фильтр</Typography>
            <div className={classes.filter}>
              <div className={classes.input}>
                <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
              </div>
              <HistoryFilters setFilter={setFilter} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={comment}
            />
          </Grid>
          {showCheckboxes && (
            <>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox checked={isActive} onChange={handleChecked} name="isActive" color="primary" />}
                  label="Активно"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox checked={state.isForce} onChange={handleCheckedIsForce} name="isForce" color="primary" />
                  }
                  label="Переобработать"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <div className={classes.techs}>
              <Technologies technologies={technologies} setTechnologies={setTechnologies} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TaskExportTable exports={exports} setExports={setExports} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTaskDialog;
