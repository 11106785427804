import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 96px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.secondary,
    fontSize: 1.7 * theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
}));

const Error404: FC = () => {
  const classes = useStyles();

  return <div className={classes.container}>Страница не найдена | 404</div>;
};

export default Error404;
