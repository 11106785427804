import React, { FC, useEffect, useState } from "react";
import { DetailFax as DetailFaxType } from "types/record";
import RecordService from "services/RecordService";
import NoData from "components/NoData";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 247px)",
    overflow: "auto",
  },
  loading: {},
  noData: {},
  img: {
    width: "100%",
  },
}));

interface State {
  loading: boolean;
  rowData: DetailFaxType[];
  error: undefined | Error;
}

const initialState: State = {
  loading: false,
  rowData: [],
  error: undefined,
};

const Fax: FC<{ detailFax: DetailFaxType }> = ({ detailFax }) => {
  const classes = useStyles();
  const [base64, setBase64] = useState("");

  useEffect(() => {
    const config = {
      responseType: "arraybuffer",
    };
    const q = `?q=[{"storageFileId":${detailFax.storageFileId}}]`;
    RecordService.getFileData("image", q, config).then((res) => {
      setBase64(Buffer.from(res.data, "binary").toString("base64"));
    });
  }, [detailFax]);

  return <img className={classes.img} src={`data:image/png;base64,${base64}`} alt="img" />;
};

interface Props {
  recordId: number;
}

const DetailFax: FC<Props> = ({ recordId }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>(initialState);
  const { rowData, loading, error } = state;

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    RecordService.getDetailFax(recordId)
      .then((res) => {
        setState(() => ({ rowData: res.data, loading: false, error: undefined }));
      })
      .catch((error) => {
        setState(() => ({ rowData: [], loading: false, error }));
      });
  }, [recordId]);

  if (loading) {
    return <div className={classes.loading}>Загрузка...</div>;
  }

  if (error) {
    return (
      <div className={classes.noData}>
        <NoData />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {rowData.map((fd, i) => (
        <Fax key={i} detailFax={fd} />
      ))}
    </div>
  );
};

export default DetailFax;
