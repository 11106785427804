import { useCallback, useEffect, useState } from "react";
import DictionaryService from "services/DictionaryService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  count: number;
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  count: 0,
  error: undefined,
};

const useDictionariesCount = (query: string) => {
  const [state, setState] = useState<State>(initialState);

  const getCount = useCallback(async () => {
    setState(() => ({ count: 0, error: undefined, loading: true }));
    DictionaryService.getCount(query)
      .then(({ data }) => {
        setState(() => ({ count: data.count, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ count: 0, loading: false, error: err }));
      });
  }, [query]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return { ...state, getCount };
};

export default useDictionariesCount;
