import React, { FC, useMemo, useEffect, useState } from "react";
import clsx from "clsx";
import { Obzor } from "types/obzor";

//agGrid
import { AgGridReact } from "ag-grid-react";
import FileRenderer from "components/agGrid/renderers/FileRenderer";
import DateRenderer from "components/agGrid/renderers/DateRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

//functions
import { humanFileSize } from "functions/common";
import localization from "components/agGrid/localization";

const useStyles = makeStyles(() => ({
  span: {},
  root: {
    height: "500px",
    fontSize: "14px",
  },
  folder: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

interface Props {
  files: Obzor[];
  setGridApi: Function;
  onSelection: Function;
  handleDirNameClick: any;
  filterValue: string;
  gridRef: any;
  tableType: string;
}

const ObzorTable: FC<Props> = ({
  files,
  setGridApi,
  onSelection,
  handleDirNameClick,
  filterValue,
  gridRef,
  tableType,
}) => {
  const classes = useStyles();
  const columnDefs = useMemo(
    () => [
      {
        field: "isDir",
        headerName: "",
        width: 50,
        cellRenderer: "FileRenderer",
      },
      {
        field: "name",
        headerName: "Имя",
        flex: 1,
        cellRenderer: "NameRenderer",
      },
      {
        field: "size",
        headerName: "Размер",
        width: 125,
        valueGetter: (params: any) => {
          const { size } = params.data;
          if (size) {
            return humanFileSize(size);
          }
          return "";
        },
      },
      {
        field: "dateChanged",
        headerName: "Дата изменения",
        width: 250,
        cellRenderer: "DateRenderer",
      },
    ],
    []
  );

  const [rowData, setRowData] = useState<Obzor[]>();

  const NameRenderer: FC<{ value: string; data: Obzor }> = ({ value, data }) => {
    const classes = useStyles();
    return (
      <span className={clsx(classes.span, { [classes.folder]: data.isDir })} onClick={() => handleDirNameClick(data)}>
        {value}
      </span>
    );
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  function onSelectionChanged() {
    onSelection();
  }

  useEffect(() => {
    //путь в SFTP разделяется "/", а в SMB '\', поэтому по разному преобразую данные
    function transformRowData(rowData: Obzor[]) {
      if (tableType === "SFTP") {
        return rowData.map((item) => {
          const name = item.path.substring(item.path.lastIndexOf("/") + 1);
          return {
            ...item,
            name,
          };
        });
      } else {
        return rowData.map((item) => {
          const name = item.path.substring(item.path.lastIndexOf("\\") + 1);
          return {
            ...item,
            name,
          };
        });
      }
    }
    if (files) {
      const transformedData = transformRowData(files);
      const noDotData = transformedData.filter((item) => !item.name.startsWith("."));
      const sortData = noDotData.sort((a, b) => {
        if (a.isDir && !b.isDir) {
          return -1; // объекты с isDir === true будут в начале
        } else if (!a.isDir && b.isDir) {
          return 1;
        } else {
          return 0;
        }
      });
      setRowData(sortData);
    } else {
      setRowData(files);
    }
  }, [files, tableType]);

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        ref={gridRef}
        onGridReady={onGridReady}
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowSelection="single"
        onSelectionChanged={onSelectionChanged}
        quickFilterText={filterValue}
        localeText={localization}
        frameworkComponents={{
          FileRenderer,
          DateRenderer,
          NameRenderer,
        }}
      />
    </div>
  );
};

export default ObzorTable;
