import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { AdminTech, AdminTechStt, AdminTechLid } from "types/admin";
import { TechType } from "types/task";

// components
import EditTechStt from "./EditTechStt";
import EditTechLid from "./EditTechLid";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(() => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
}));

interface Props {
  open: boolean;
  onClose: (tech?: AdminTech) => void;
  tech: AdminTech;
}

const EditTechDialog: FC<Props> = ({ open, onClose, tech }) => {
  const classes = useStyles();
  const [state, setState] = useState(tech);

  const { name, isActive } = state;
  const disabled = name.length === 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setState((prev) => ({ ...prev, isActive: checked }));
  };

  const handleSave = () => {
    onClose(state);
  };

  useEffect(() => {
    setState(tech);
  }, [tech]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Редактировать</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Имя"
              fullWidth
              variant="standard"
              name="name"
              onChange={handleChange}
              value={name}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isActive} onChange={handleChecked} name="checkedB" color="primary" />}
              label="Активен"
            />
          </Grid>
          <Grid item xs={12}>
            {tech.techDetail.type === TechType.STT && (
              <EditTechStt setState={setState} techStt={state.techDetail as AdminTechStt} />
            )}
            {tech.techDetail.type === TechType.LID && (
              <EditTechLid setState={setState} techLid={state.techDetail as AdminTechLid} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EditTechDialog;
