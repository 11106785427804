import React, { useEffect, useState, FC } from "react";
import { routes as r } from "routes";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

// icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import SettingsIcon from "@material-ui/icons/Settings";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LinkIcon from "@material-ui/icons/Link";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import LanguageIcon from "@material-ui/icons/Language";
import TranslateIcon from "@material-ui/icons/Translate";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DnsIcon from "@material-ui/icons/Dns";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import CategoryIcon from "@material-ui/icons/Category";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, useLocation } from "react-router-dom";
import Divider from "@material-ui/core/Divider";

let rules = [
  { title: r.admin.id, regexp: r.admin.regexp },
  { title: r.admin.techs.id, regexp: r.admin.techs.regexp },
  { title: r.admin.settings.id, regexp: r.admin.settings.regexp },
  { title: r.admin.passports.id, regexp: r.admin.passports.regexp },
  { title: r.admin.diagnostic.id, regexp: r.admin.diagnostic.regexp },
  { title: r.admin.servers.id, regexp: r.admin.servers.regexp },
  { title: r.admin.access.id, regexp: r.admin.access.regexp },
  { title: r.admin.autoCleanUp.id, regexp: r.admin.autoCleanUp.regexp },
  { title: r.admin.lmModels.id, regexp: r.admin.lmModels.regexp },
  { title: r.admin.lidModels.id, regexp: r.admin.lidModels.regexp },
  { title: r.admin.nbsCategory.id, regexp: r.admin.nbsCategory.regexp },
  { title: r.admin.sources.id, regexp: r.admin.sources.regexp },
  { title: r.admin.theme.id, regexp: r.admin.theme.regexp },
  { title: r.admin.externalApi.id, regexp: r.admin.externalApi.regexp },
];

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: theme.spacing(0, 1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
  },
}));

const AdminNavigation: FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const { services } = useSelector((state: RootState) => state.system);
  const [select, setSelect] = useState<null | string>(null);

  useEffect(() => {
    let { pathname } = location;
    rules.forEach((rule) => {
      let match = pathname.match(rule.regexp);
      if (match !== null) {
        setSelect(rule.title);
      }
    });
  }, [location]);

  return (
    <List disablePadding>
      <Divider />
      <Link to={r.admin.path} className={classes.link} title="Модули">
        <ListItem button selected={select === r.admin.id}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Модули" />
        </ListItem>
      </Link>

      <Link to={r.admin.techs.path} className={classes.link} title="Технологии">
        <ListItem button selected={select === r.admin.techs.id}>
          <ListItemIcon>
            <SettingsInputComponentIcon />
          </ListItemIcon>
          <ListItemText primary="Технологии" />
        </ListItem>
      </Link>

      <Link to={r.admin.sources.path} className={classes.link} title="Источники">
        <ListItem button selected={select === r.admin.sources.id}>
          <ListItemIcon>
            <FolderSpecialIcon />
          </ListItemIcon>
          <ListItemText primary="Источники" />
        </ListItem>
      </Link>

      <Link to={r.admin.passports.path} className={classes.link} title="Формат файлов">
        <ListItem button selected={select === r.admin.passports.id}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Формат файлов" />
        </ListItem>
      </Link>

      <Link to={r.admin.lmModels.path} className={classes.link} title="Модели перевода речи в текст">
        <ListItem button selected={select === r.admin.lmModels.id}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary="Модели перевода речи в текст" />
        </ListItem>
      </Link>

      <Link to={r.admin.lidModels.path} className={classes.link} title="Модели диалектно-языковой группы">
        <ListItem button selected={select === r.admin.lidModels.id}>
          <ListItemIcon>
            <TranslateIcon />
          </ListItemIcon>
          <ListItemText primary="Модели диалектно-языковой группы" />
        </ListItem>
      </Link>

      <Link to={r.admin.nbsCategory.path} className={classes.link} title="Категории нарушения">
        <ListItem button selected={select === r.admin.nbsCategory.id}>
          <ListItemIcon>
            <ErrorOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Категории нарушения" />
        </ListItem>
      </Link>

      <Divider />

      <Link to={r.admin.settings.path} className={classes.link} title="Настройки">
        <ListItem button selected={select === r.admin.settings.id}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Интерфейс" />
        </ListItem>
      </Link>

      <Link to={r.admin.externalApi.path} className={classes.link} title="Сервис внешних обращений">
        <ListItem button selected={select === r.admin.externalApi.id}>
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary="Сервис внешних обращений" />
        </ListItem>
      </Link>

      <Link to={r.admin.access.path} className={classes.link} title="Права">
        <ListItem button selected={select === r.admin.access.id}>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Права" />
        </ListItem>
      </Link>

      <Link to={r.admin.theme.path} className={classes.link} title="Внешний вид">
        <ListItem button selected={select === r.admin.theme.id}>
          <ListItemIcon>
            <ColorLensIcon />
          </ListItemIcon>
          <ListItemText primary="Внешний вид" />
        </ListItem>
      </Link>
      <Divider />

      <Link to={r.admin.servers.path} className={classes.link} title="Сервера">
        <ListItem button selected={select === r.admin.servers.id}>
          <ListItemIcon>
            <DnsIcon />
          </ListItemIcon>
          <ListItemText primary="Сервера" />
        </ListItem>
      </Link>

      <Link to={r.admin.diagnostic.path} className={classes.link} title="Диагностика">
        <ListItem button selected={select === r.admin.diagnostic.id}>
          <ListItemIcon>
            <DesktopMacIcon />
          </ListItemIcon>
          <ListItemText primary="Диагностика" />
        </ListItem>
      </Link>

      <Link to={r.admin.autoCleanUp.path} className={classes.link} title="Очистка диска">
        <ListItem button selected={select === r.admin.autoCleanUp.id}>
          <ListItemIcon>
            <AccessAlarmIcon />
          </ListItemIcon>
          <ListItemText primary="Очистка диска" />
        </ListItem>
      </Link>

      <Divider />

      {services.map(({ name, host, id }) => (
        <a
          href={id === "auth" ? host + "/admin" : host}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
          title={name}
          key={name}
        >
          <ListItem button>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        </a>
      ))}
    </List>
  );
};

export default AdminNavigation;
