import React, { FC, useCallback, useEffect, useState } from "react";
import Picker from "components/Picker";
import moment from "moment";
import { dateIsValid } from "functions/date";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {},
  pickers: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  from: {
    fontSize: theme.typography.fontSize,
    marginRight: 10,
  },
  to: {
    fontSize: theme.typography.fontSize,
    marginLeft: 30,
    marginRight: 10,
  },
  btns: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    marginRight: 10,
    marginBottom: 10,
    cursor: "pointer",
  },
}));

interface Props {
  value: string;
  changeValue: (v: string) => void;
}

const DateInput: FC<Props> = ({ value, changeValue }) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const onStartDateChange = (dt: Date) => {
    setStartDate(dt);
    changeValue(moment(dt).toISOString(true) + "\n" + moment(endDate).toISOString(true));
  };

  const onEndDateChange = (dt: Date) => {
    setEndDate(dt);
    changeValue(moment(startDate).toISOString(true) + "\n" + moment(dt).toISOString(true));
  };

  const setDateInterval = useCallback(
    (period: string) => {
      const now = moment();
      let startDay;
      let endDay;

      if (period === "today") {
        startDay = new Date(now.format("YYYY-MM-DD") + " 00:00:00");
        endDay = new Date(now.add(1, "day").format("YYYY-MM-DD") + " 00:00:00");
      }

      if (period === "yesterday") {
        const yesterday = now.clone().subtract(1, "days");
        startDay = new Date(yesterday.format("YYYY-MM-DD") + " 00:00:00");
        endDay = new Date(now.format("YYYY-MM-DD") + " 00:00:00");
      }

      if (period === "this week") {
        const monday = now.clone().weekday(1);
        startDay = new Date(monday.format("YYYY-MM-DD") + " 00:00:00");
        endDay = new Date(monday.add(7, "day").format("YYYY-MM-DD") + " 00:00:00");
      }

      if (period === "this month") {
        startDay = new Date(now.startOf("month").format("YYYY-MM-DD") + " 00:00:00");
        endDay = new Date(now.add(1, "month").format("YYYY-MM-DD") + " 00:00:00");
      }

      if (period === "last month") {
        const lastMonth = now.clone().subtract(1, "month");
        startDay = new Date(lastMonth.startOf("month").format("YYYY-MM-DD") + " 00:00:00");
        endDay = new Date(now.startOf("month").format("YYYY-MM-DD") + " 00:00:00");
      }

      setStartDate(startDay);
      setEndDate(endDay);
      changeValue(`${moment(startDay).toISOString(true)}\n${moment(endDay).toISOString(true)}`);
    },
    [changeValue]
  );

  useEffect(() => {
    if (value !== "") {
      // из за передачи фильтра в query параметре, браузер херит плюсы, заменяя их на пробел
      const date = value.replaceAll(" ", "+").toString().split("\n");
      if (date.length !== 2) return;
      const startDate = new Date(date[0]);
      const endDate = new Date(date[1]);
      // если начальная дата валидна
      if (dateIsValid(startDate)) {
        setStartDate(startDate);
      } else {
        setStartDate(new Date());
      }
      // если конечная дата валидна
      if (dateIsValid(endDate)) {
        setEndDate(endDate);
      } else {
        setEndDate(new Date());
      }
    } else {
      setDateInterval("today");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.pickers}>
        <div className={classes.from}>{"C"}</div>
        <Picker date={startDate} onDateChange={onStartDateChange} />
        <div className={classes.to}>{"По"}</div>
        <Picker date={endDate} onDateChange={onEndDateChange} />
      </div>
      <div className={classes.btns}>
        <Chip label="Сегодня" className={classes.chip} onClick={() => setDateInterval("today")} />
        <Chip label="Вчера" className={classes.chip} onClick={() => setDateInterval("yesterday")} />
        <Chip label="За эту неделю" className={classes.chip} onClick={() => setDateInterval("this week")} />
        <Chip label="За этот месяц" className={classes.chip} onClick={() => setDateInterval("this month")} />
        <Chip label="Прошлый месяц" className={classes.chip} onClick={() => setDateInterval("last month")} />
      </div>
    </div>
  );
};

export default DateInput;
