import { AuthAction, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, REFRESH } from "redux/types/authTypes";
import { AppDispatch } from "redux/types";
import AuthService from "services/AuthService";

export const login = (login: string, password: string) => async (dispatch: AppDispatch) => {
  try {
    const res = await AuthService.logIn(login, password);
    if (res.status === 200) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: await res.json(),
      });
    }

    if (res.status !== 200) {
      dispatch({
        type: LOGIN_FAIL,
        payload: await res.json(),
      });
    }
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: err,
    });
  }
};

export function logout(): AuthAction {
  return {
    type: LOGOUT,
  };
}

export function refresh(accessToken: string): AuthAction {
  return {
    type: REFRESH,
    payload: { accessToken },
  };
}
