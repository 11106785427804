import { Breadcrumbs, Link } from "@material-ui/core";
import React, { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  nonClickable: {
    pointerEvents: "none",
  },
  pointer: {
    cursor: "pointer",
  },
});

interface BreadcrumbsMuiProps {
  currentPath: string;
  onCrumbClick: (path: string) => void;
  handleClickMainCrumb: () => void;
}

const BreadcrumbsMuiSMB: FC<BreadcrumbsMuiProps> = ({ currentPath, onCrumbClick, handleClickMainCrumb }) => {
  const classes = useStyles();

  const handleCrumbClick = (clickedIndex: number) => {
    const paths = currentPath.split("\\").filter(Boolean);
    const newPath = `\\${paths.slice(0, clickedIndex + 1).join("\\")}`;
    onCrumbClick(newPath);
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" className={classes.pointer} onClick={handleClickMainCrumb}>
        Главная
      </Link>
      {currentPath
        .split("\\")
        .filter(Boolean)
        .map((path, index, paths) => {
          const last = index === paths.length - 1;
          const to = `\\${paths.slice(0, index + 1).join("\\")}`;
          return last ? (
            <Link key={to} color="inherit" className={classes.nonClickable}>
              {path}
            </Link>
          ) : (
            <Link key={to} color="inherit" className={classes.pointer} onClick={() => handleCrumbClick(index)}>
              {path}
            </Link>
          );
        })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsMuiSMB;
