import React, { FC } from "react";
import { RecordFile } from "types";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import BooleanRenderer from "components/agGrid/renderers/BooleanRenderer";
import DownloadFileRenderer from "components/agGrid/renderers/DownloadFileRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: 205,
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Идентификатор",
    field: "id",
    width: 70,
  },
  {
    headerName: "Скачать оригинальный файл",
    field: "name",
    width: 70,
    cellRenderer: "DownloadFileRenderer",
  },
  {
    headerName: "Имя файла",
    field: "name",
    width: 320,
  },
  {
    headerName: "Тип отображения",
    field: "viewType",
  },
  {
    headerName: "Кодек",
    field: "codec",
  },
  {
    headerName: "Требует обработки",
    field: "isNeedProcess",
    cellRenderer: "BooleanRenderer",
  },
  {
    headerName: "Наличие декодированного файла",
    field: "isExistCache",
    cellRenderer: "BooleanRenderer",
  },
  {
    headerName: "ID записи",
    field: "recordId",
  },
  {
    headerName: "ID файла в хранилище",
    field: "storageFileId",
  },
  {
    headerName: "Группа",
    field: "group",
  },
];

interface Props {
  rowData: RecordFile[];
  setGridApi: Function;
  onSelection: Function;
}

const FilesTable: FC<Props> = ({ rowData, setGridApi, onSelection }) => {
  const classes = useStyles();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  function onSelectionChanged() {
    onSelection();
  }

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        suppressCellSelection
        suppressCopyRowsToClipboard
        enableCellTextSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        frameworkComponents={{
          BooleanRenderer,
          DownloadFileRenderer,
        }}
      />
    </div>
  );
};

export default FilesTable;
