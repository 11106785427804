import React, { FC } from "react";

// material ui
import InputDialogWordsTab from "../InputDialogWordsTab";

export interface State {
  text: string;
}

interface Props {
  value: string;
  onSelection?: Function;
  onClickDelete?: Function;
}

const InputDialogComponent: FC<Props> = ({ value, onSelection, onClickDelete }) => {
  const OnCloseCallback = (text: string) => {
    onSelection?.(text, "editAndClose");
    // если ничего не выбрано и нажали "ок" или кликнули за пределы диалогового, удаляю фильтр
    if (text === "" || text === `{"strong":[],"vector":[],"phrase":[],"dictionary":[]}`) {
      onClickDelete?.();
    }
  };

  return (
    <div>
      {/* <InputDialogWordSearch onClose={OnCloseCallback} value={value} /> */}
      <InputDialogWordsTab onClose={OnCloseCallback} value={value} />
    </div>
  );
};

export default InputDialogComponent;
