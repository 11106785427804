export const HIDE_ALERT = "HIDE_ALERT";
export const SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT";
export const SHOW_SUCCESS_ALERT = "SHOW_SUCCESS_ALERT";
export const SHOW_WARNING_ALERT = "SHOW_WARNING_ALERT";

export type Severity = "error" | "info" | "warning" | "success";

export interface AlertState {
  severity: Severity;
  title: string;
  message: string;
  open: boolean;
}

export type AlertActions =
  | { type: typeof HIDE_ALERT; payload: unknown }
  | { type: typeof SHOW_ERROR_ALERT; payload: string }
  | { type: typeof SHOW_SUCCESS_ALERT; payload: string }
  | { type: typeof SHOW_WARNING_ALERT; payload: string };
