import { AxiosResponse } from "axios";
import { AdminTypeFilter, HistoryFilter } from "types/admin";
import { admin } from "http/urls";
import api from "http/api";

const { filters: route } = admin;

function FilterService() {
  function getAll(topic: string): Promise<AxiosResponse<AdminTypeFilter[]>> {
    const url = route.getAll.replace(":topic", topic);
    return api.get<AdminTypeFilter[]>(url);
  }

  function getHistory() {
    return api.get<HistoryFilter[]>(route.getHistory);
  }

  function clearHistory(ids?: string) {
    const url = route.clearHistory.replace(":ids", ids ? ids : "");
    return api.delete(url);
  }

  function doCustomGetQuery(url: string) {
    return api.get<any[]>(url);
  }

  function doCustomPostQuery(url: string, body: any) {
    return api.post(url, body);
  }

  return Object.freeze({
    getAll,
    getHistory,
    clearHistory,
    doCustomGetQuery,
    doCustomPostQuery,
  });
}

export default FilterService();
