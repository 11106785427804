export enum StatisticsDescription {
  recordsCount = "Количество записей:",
  recordsDuration = "Длительность записей:",
  speechDuration = "Длительность речи:",
  speakersCount = "Количество дикторов:",
  faxCount = "Количество факсимильных сообщений:",
  srcPhoneNumbersCount = "Количество уникальных исходящих номеров:",
  dstPhoneNumbersCount = "Количество уникальных входящих номеров:",
  srcIpAddressesCount = "Количество уникальных исходящих IP-адресов:",
  dstIpAddressesCount = "Количество уникальных входящих IP-адресов:",
  wordCount = "Количество транскрибированных слов:",
}
