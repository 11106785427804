import { AxiosResponse } from "axios";
import { Namespace } from "types/namespace";
import { spaces as route } from "http/urls";
import api from "http/api";
import { Permission } from "../types/permission";

export interface BodySpace {
  name: string;
  diskId: number;
}

function NamespaceService() {
  function getAll(): Promise<AxiosResponse<Namespace[]>> {
    const url = route.getAll;
    return api.get<Namespace[]>(url);
  }

  function getCount(query = ""): Promise<AxiosResponse> {
    let url = route.count + query;
    return api.get(url);
  }

  function create(body: BodySpace): Promise<AxiosResponse> {
    return api.post(route.create, body);
  }

  function update(body: BodySpace): Promise<AxiosResponse> {
    return api.put(route.update, body);
  }

  function upload(namespaceId: number, body: FormData, onUploadProgress: (data: any) => void): Promise<AxiosResponse> {
    const url = route.upload.replace(":namespaceId", String(namespaceId));
    return api.post(url, body, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress,
    });
  }

  function remove(ids: string): Promise<AxiosResponse> {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  // получить права пользователей для пространства
  function getNamespacePermissions(id: number): Promise<AxiosResponse<Permission[]>> {
    const url = route.getNamespaceAccess.replace(":id", String(id));
    return api.get<Permission[]>(url);
  }

  // установить права пользователей для пространства
  function setNamespacePermissions(id: number, body: Permission[]): Promise<AxiosResponse> {
    const url = route.setNamespaceAccess.replace(":id", String(id));
    return api.post(url, body);
  }

  return Object.freeze({
    getAll,
    getCount,
    create,
    update,
    upload,
    remove,
    getNamespacePermissions,
    setNamespacePermissions,
  });
}

export default NamespaceService();
