import { useCallback, useEffect, useState } from "react";
import DictionaryService from "services/DictionaryService";
import { Dictionary } from "types/dictionary";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  rowData: Dictionary[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  rowData: [],
  error: undefined,
};

const useDictionaries = (offset: number, perPage: number, query: string) => {
  const [state, setState] = useState<State>(initialState);

  const getDictionaries = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));
    DictionaryService.getAll(offset, perPage, query)
      .then(({ data }) => {
        setState(() => ({ rowData: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ rowData: [], loading: false, error: err }));
      });
  }, [offset, perPage, query]);

  useEffect(() => {
    getDictionaries();
  }, [getDictionaries]);

  return { ...state, getDictionaries };
};

export default useDictionaries;
