import React, { FC, useState, useEffect } from "react";
import { WordsCloudWord } from "types/record";

// components
import WorldCloudMainWindowToolbar from "./WorldCloudMainWindowToolbar";
import WordsTable from "./WordsTable";
import WordsCloud from "./WordsCloud";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  words: WordsCloudWord[];
}

const initialToolbarWCActions: any = {
  zoomIn: () => {},
  zoomOut: () => {},
  zoomReset: () => {},
  saveSvgToPng: () => {},
};

const WordsCloudMainWindow: FC<Props> = ({ words: w }) => {
  const classes = useStyles();

  const [words, setWords] = useState<WordsCloudWord[]>(w);

  const [mainWindowState, setMainWindowState] = useState({
    isWordsCloud: true,

    threshold: {
      isEnabled: true,
      value: 0,
    },

    displayOptions: {
      displayWordsCloud: "block",
      displayWordsTable: "none",
    },
  });

  const [toolbarWCActions, setToolbarWCActions] = useState(initialToolbarWCActions);

  const toogleView = () => {
    let mws = {};

    if (mainWindowState.isWordsCloud) {
      mws = {
        isWordsCloud: false,
        displayOptions: {
          displayWordsCloud: "none",
          displayWordsTable: "block",
        },
      };
    } else {
      mws = {
        isWordsCloud: true,
        displayOptions: {
          displayWordsCloud: "block",
          displayWordsTable: "none",
        },
      };
    }

    setMainWindowState((prevState) => {
      return { ...prevState, ...mws };
    });
  };

  const toogleThreshold = (t: any) => {
    let mws = {
      threshold: {
        isEnabled: t,
        value: mainWindowState.threshold.value,
      },
    };

    setMainWindowState((prevState) => {
      return { ...prevState, ...mws };
    });
  };

  const thresholdValueChanged = (tV: any) => {
    let mws = {
      threshold: {
        isEnabled: mainWindowState.threshold.isEnabled,
        value: tV,
      },
    };

    setMainWindowState((prevState) => {
      return { ...prevState, ...mws };
    });
  };

  useEffect(() => {
    setWords(w);
  }, [w]);

  return (
    <div className={classes.root}>
      <WorldCloudMainWindowToolbar
        toolbarWCActions={toolbarWCActions}
        toogleView={toogleView}
        mainWindowState={mainWindowState}
        toogleThreshold={toogleThreshold}
        thresholdValueChanged={thresholdValueChanged}
      />
      {words.length !== 0 && (
        <WordsCloud words={words} setToolbarWCActions={setToolbarWCActions} mainWindowState={mainWindowState} />
      )}
      <WordsTable words={words} mainWindowState={mainWindowState} />
    </div>
  );
};

export default WordsCloudMainWindow;
