import {
  SETTINGS_SET_ALL,
  SETTINGS_SET_DIARIZATION_HIGHLIGHT,
  SETTINGS_SET_KEYWORDS_LAYOUT,
  SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS,
  SETTINGS_SET_PLAYER_CHANNEL,
  SETTINGS_SET_PLAYER_GAIN,
  SETTINGS_SET_PLAYER_IS_ADE_CLICK,
  SETTINGS_SET_PLAYER_IS_ECHO,
  SETTINGS_SET_PLAYER_IS_KTCH,
  SETTINGS_SET_PLAYER_IS_NOISE,
  SETTINGS_SET_PLAYER_IS_NORMALIZE,
  SETTINGS_SET_PLAYER_IS_PAN,
  SETTINGS_SET_PLAYER_WAVEFORM_TYPE,
  SETTINGS_SET_RESULT_PANEL_SIZE,
  SETTINGS_SET_ROWS_PER_PAGE,
  SETTINGS_SET_SHOW_LAYOUT_VAD,
  SETTINGS_SET_SORT_NAME,
  SETTINGS_SET_SORT_ORDER,
  SETTINGS_SET_STT_BACKGROUND_COLOR,
  SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT,
  SETTINGS_SET_STT_EDITE_MODE,
  SETTINGS_SET_STT_FONT,
  SETTINGS_SET_STT_FONT_SIZE,
  SETTINGS_SET_STT_SHOW_KEYWORDS,
  SETTINGS_SET_STT_SHOW_TRANSLATE,
  SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER,
  SETTINGS_SET_STT_TEXT_COLOR,
  SETTINGS_SET_ZOOM,
  SettingsAction,
  SettingsState,
} from "redux/types/settingsTypes";
import { KeywordsLayout, WaveformType } from "components/audioPlayer/types";

export function setPlayerIsPan(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_PAN,
    payload,
  };
}

export function setPlayerIsAdeClick(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_ADE_CLICK,
    payload,
  };
}

export function setPlayerIsNormalize(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_NORMALIZE,
    payload,
  };
}

export function setPlayerIsNoise(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_NOISE,
    payload,
  };
}

export function setPlayerIsKTCH(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_KTCH,
    payload,
  };
}

export function setPlayerIsEcho(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_ECHO,
    payload,
  };
}

export function setPlayerChannel(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_CHANNEL,
    payload,
  };
}

export function setPlayerGain(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_GAIN,
    payload,
  };
}

export function setWaveformType(payload: WaveformType): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_WAVEFORM_TYPE,
    payload,
  };
}

export function setKeywordsLayout(payload: KeywordsLayout): SettingsAction {
  return {
    type: SETTINGS_SET_KEYWORDS_LAYOUT,
    payload,
  };
}

export function setZoom(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_ZOOM,
    payload,
  };
}

export function setRowsPerPage(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_ROWS_PER_PAGE,
    payload,
  };
}

export function setSttFont(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_STT_FONT,
    payload,
  };
}

export function setSttFontSize(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_STT_FONT_SIZE,
    payload,
  };
}

export function setSttTextColor(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_STT_TEXT_COLOR,
    payload,
  };
}

export function setSttBackGroundColor(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_STT_BACKGROUND_COLOR,
    payload,
  };
}

export function setSttSyncTextAndPlayer(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER,
    payload,
  };
}

export function setSttShowKeywords(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_STT_SHOW_KEYWORDS,
    payload,
  };
}

export function setSttShowTranslate(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_STT_SHOW_TRANSLATE,
    payload,
  };
}

export function setSttEditeMode(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_STT_EDITE_MODE,
    payload,
  };
}

export function setSttConfidenceHighlight(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT,
    payload,
  };
}

export function setResultPanelSize(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_RESULT_PANEL_SIZE,
    payload,
  };
}

export function setAllSettings(payload: SettingsState): SettingsAction {
  return {
    type: SETTINGS_SET_ALL,
    payload,
  };
}

export function setPlayOnlyVoiceSegments(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS,
    payload,
  };
}

export function setSortName(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_SORT_NAME,
    payload,
  };
}

export function setSortOrder(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_SORT_ORDER,
    payload,
  };
}

export function setDiarizationHighLight(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_DIARIZATION_HIGHLIGHT,
    payload,
  };
}

export function setIsShowLayoutVad(payload: 0 | 1): SettingsAction {
  return {
    type: SETTINGS_SET_SHOW_LAYOUT_VAD,
    payload,
  };
}
