import React, { FC, useCallback, useMemo } from "react";
import { Speaker } from "types/speaker";
import clsx from "clsx";
import { RootState } from "redux/types";
import { setPageSpeakersTableSettings } from "redux/actions/pageSettingsActions";
import { useDispatch, useSelector } from "react-redux";

// ag-grid
import { ColumnResizedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import SpeakerStatusRenderer from "components/agGrid/renderers/SpeakerStatusRenderer";
import SpeakerTypeRenderer from "components/agGrid/renderers/SpeakerTypeRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 180px)",
  },
  span: {},
  folder: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

interface Props {
  rowData: Speaker[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
  handleSpeakerNameClick: any;
  height?: number;
}

const SpeakersTable: FC<Props> = ({
  rowData,
  setGridApi,
  setColumnApi,
  onSelection,
  handleSpeakerNameClick,
  height,
}) => {
  const dispatch = useDispatch();
  const { speakers: pageSettings } = useSelector((state: RootState) => state.pageSettings);

  const style = useMemo(
    () => ({
      height: height === undefined ? "calc(100vh - 180px)" : height,
    }),
    [height]
  );

  const NameRenderer: FC<{ value: string; data: Speaker }> = ({ value, data }) => {
    const classes = useStyles();
    return (
      <span
        className={clsx(classes.span, { [classes.folder]: data.speakerType === "folder" })}
        onClick={() => handleSpeakerNameClick(data)}
      >
        {value}
      </span>
    );
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Идентификатор",
        field: "id",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 100,
      },
      {
        headerName: "Тип",
        field: "speakerType",
        cellRenderer: "SpeakerTypeRenderer",
        width: 100,
      },
      {
        headerName: "Статус",
        field: "status",
        cellRenderer: "SpeakerStatusRenderer",
        width: 100,
      },
      {
        headerName: "Сообщение",
        field: "message",
      },
      {
        headerName: "Имя",
        field: "name",
        cellRenderer: "NameRenderer",
      },
      {
        headerName: "Страна",
        field: "country",
        valueGetter: (params: any) => {
          return params.data.speakerType === "folder" ? "" : params.data.country;
        },
      },
      {
        headerName: "Город",
        field: "city",
        valueGetter: (params: any) => {
          return params.data.speakerType === "folder" ? "" : params.data.city;
        },
      },
      {
        headerName: "Адрес",
        field: "address",
        valueGetter: (params: any) => {
          return params.data.speakerType === "folder" ? "" : params.data.address;
        },
      },
      {
        headerName: "Модель телефона",
        field: "phoneModel",
        valueGetter: (params: any) => {
          return params.data.speakerType === "folder" ? "" : params.data.phoneModel;
        },
      },
      {
        headerName: "Сотовый оператор",
        field: "telecomOperator",
        valueGetter: (params: any) => {
          return params.data.speakerType === "folder" ? "" : params.data.telecomOperator;
        },
      },
      {
        headerName: "Создатель",
        field: "ownerId",
      },
      {
        headerName: "Количество моделей",
        field: "models",
        valueGetter: (params: any) => {
          return params.data.speakerType === "folder" ? "" : params.data.models.length;
        },
      },
      {
        headerName: "Количество файлов",
        field: "files",
        valueGetter: (params: any) => {
          return params.data.speakerType === "folder" ? "" : params.data.files.length;
        },
      },
      {
        headerName: "Комментарий",
        field: "comment",
      },
      {
        headerName: "Дата изменения",
        field: "dateChanged",
        cellRenderer: "DateRenderer",
      },
      {
        headerName: "Дата создания",
        field: "dateCreated",
        cellRenderer: "DateRenderer",
      },
    ],
    []
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (pageSettings.table !== undefined) {
      params.columnApi.setColumnState(pageSettings.table);
    }
  };

  function onSelectionChanged() {
    onSelection();
  }

  const getRowNodeId = (data: Speaker) => String(data.id);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageSpeakersTableSettings(colState));
      }
    },
    [dispatch]
  );

  return (
    <div style={style} className="ag-theme-balham">
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection="multiple"
        localeText={localization}
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        onColumnResized={onColumnResized}
        suppressMovableColumns
        suppressColumnMoveAnimation
        suppressCopyRowsToClipboard
        frameworkComponents={{
          DateRenderer,
          SpeakerStatusRenderer,
          SpeakerTypeRenderer,
          NameRenderer,
        }}
      />
    </div>
  );
};

export default SpeakersTable;
