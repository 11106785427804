import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import clsx from "clsx";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const columnDefs = [
  { headerName: "Слово", field: "word", width: 150 },
  {
    headerName: "Степень доверия, %",
    field: "confidence",
    valueFormatter: (params) => {
      return Number(params.value.toFixed(1));
    },
    width: 200,
  },
  { headerName: "Количество", field: "size", width: 150 },
];

const gridOptions = {
  columnDefs: columnDefs,
  defaultColDef: {
    enableRowGroup: false,
    enablePivot: false,
    width: 150,
    sortable: true,
    resizable: true,
    menuTabs: {},
    filter: false,
  },
};

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 266px)",
  },
}));

function WordsTable({ words, mainWindowState }) {
  const classes = useStyles();

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    if (mainWindowState.threshold.isEnabled) {
      externalFilterChanged(mainWindowState.threshold.value);
    } else {
      externalFilterChanged(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainWindowState]);

  const externalFilterChanged = (tV) => {
    thresholdValue = tV;
    gridApi.onFilterChanged();
  };

  const doesExternalFilterPass = (node) => {
    return node.data.confidence >= thresholdValue;
  };

  return (
    <div
      className={clsx(classes.root, "ag-theme-balham")}
      style={{ display: mainWindowState.displayOptions.displayWordsTable }}
    >
      <AgGridReact
        gridOptions={gridOptions}
        rowData={words}
        onGridReady={onGridReady}
        isExternalFilterPresent={() => {
          return true;
        }}
        doesExternalFilterPass={doesExternalFilterPass}
        suppressDragLeaveHidesColumns
      />
    </div>
  );
}

var thresholdValue = 0;

export default WordsTable;
