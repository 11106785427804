import React, { FC } from "react";
import SelectPerPage from "./SelectPerPage";
import Pagination from "./Pagination";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 32,
    minWidth: 510,
  },
  totalCount: {
    // flexGrow: 1,
    marginRight: 20,
    fontSize: theme.typography.fontSize,
  },
  selected: {
    flexGrow: 1,
    fontSize: theme.typography.fontSize,
    marginRight: 20,
  },
  perPage: {
    marginRight: 20,
  },
}));

interface Props {
  countTotal: number;
  countSelected: number;
  setOffset: Function;
}

const PaginationPanel: FC<Props> = ({ countTotal, countSelected, setOffset }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.totalCount}>Всего: {countTotal}</div>
      <div className={classes.selected}>Выбрано: {countSelected}</div>
      {countTotal > 0 && (
        <>
          <div className={classes.perPage}>
            <SelectPerPage />
          </div>
          <Pagination count={countTotal} setOffset={setOffset} />
        </>
      )}
    </div>
  );
};

export default PaginationPanel;
