import React, { FC, FocusEvent, useEffect, useMemo, useRef } from "react";
import { Keywords, RecordDetail, RecordFileViewType, Segment as TypeSegment } from "types";
import clsx from "clsx";
import Word from "./Word";
import { RootState } from "redux/types";
import { currTimeSubscriber } from "subscribers/PlayerSubscriber";
import RecordService from "services/RecordService";
import { Word as WordType } from "types/record";
import { useSelector } from "react-redux";
import { catchError } from "functions/common";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import { grey, indigo, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    marginBottom: 6,
    "&:hover": {
      backgroundColor: grey[300],
    },
  },
  time: {
    display: "flex",
    alignItems: "start",
    color: grey[500],
    fontSize: theme.typography.fontSize + 1,
    cursor: "pointer",
  },
  call: {
    margin: "0 4px",
    display: "flex",
    alignItems: "start",
    paddingTop: 2,
  },
  speakerName: {
    width: "67px",
    paddingTop: 2,
    margin: "0 4px",
    color: grey[500],
  },
  callMadeIcon: {
    fontSize: theme.typography.fontSize + 2,
    color: indigo[300],
  },
  callReceivedIcon: {
    fontSize: theme.typography.fontSize + 2,
    color: red[400],
  },
  text: {
    wordWrap: "break-word",
    lineHeight: "normal",
  },
  active: {
    backgroundColor: grey[300],
  },
  rtl: {
    direction: "rtl",
  },
  words: {
    display: "flex",
  },
  fb55: {
    flexBasis: "55%",
  },
  fb45: {
    flexBasis: "45%",
  },
  fg1: {
    flexGrow: 1,
  },
  hidden: {
    display: "none",
  },
  flexEnd: {
    justifyContent: "end",
  },
}));

const getTimeViewFormat = (t: number) => {
  const time = Math.floor(t);
  if (time === 0) {
    return "00:00";
  }
  if (time < 60) {
    return time < 10 ? `00:0${time}` : `00:${time}`;
  }
  const min = Math.floor(time / 60);
  const strMin = min < 10 ? `0${min}` : String(min);
  const sec = time - min * 60;
  const strSec = sec < 10 ? `0${sec}` : String(sec);
  return `${strMin}:${strSec}`;
};

interface Props {
  segment: TypeSegment;
  currentTime: number;
  keywords: Keywords[];
  isDiarizationNameExist: boolean;
  showTranslate: boolean;
  alwaysOnDisplay?: boolean;
  recordDetail: RecordDetail;
  updateSegment: (segment: TypeSegment) => void;
}

const Segment: FC<Props> = ({
  segment,
  currentTime,
  keywords,
  isDiarizationNameExist,
  alwaysOnDisplay,
  recordDetail,
  updateSegment,
}) => {
  const classes = useStyles();
  const { sttSyncTextAndPlayer } = useSelector((state: RootState) => state.settings);
  const { start, end, words, channelNumber } = segment;
  const ref = useRef<null | HTMLDivElement>(null);

  const isActive = useMemo(() => currentTime >= start && currentTime <= end, [currentTime, start, end]);

  // араб, дари, пушту, фарси, иврит,
  const rtlLangs = ["dari", "pashto", "farsi", "hebrew", "arabic"];
  // отображать текст с права на лево
  const isRtl = words.length === 0 ? false : rtlLangs.includes(words[0].lang);
  // является ли сеанс текстовым документом
  const isText = useMemo(() => recordDetail.viewType === RecordFileViewType.TEXT, [recordDetail]);

  const getColor = (wordId: number) => {
    for (let i = 0; i < keywords.length; i++) {
      const words = keywords[i].words;
      for (let j = 0; j < words.length; j++) {
        if (words[j].id === wordId) {
          return keywords[i].color;
        }
      }
    }
    return undefined;
  };

  const getBgColor = (wordId: number) => {
    for (let i = 0; i < keywords.length; i++) {
      const words = keywords[i].words;
      for (let j = 0; j < words.length; j++) {
        if (words[j].id === wordId) {
          return keywords[i].bgColor;
        }
      }
    }
    return undefined;
  };

  const handleClickTime = () => {
    currTimeSubscriber.next(start);
  };

  // Редактирование слова
  const onWordEdit = (event: FocusEvent<HTMLDivElement>, word: WordType) => {
    const { innerText } = event.target;
    const { recordId, id, word: w } = word;
    if (w === innerText) return;

    RecordService.updateWord(recordId, id, innerText)
      .then(({ data }) => {
        const newSegment = { ...segment };
        const words: WordType[] = [];
        for (let i = 0; i < newSegment.words.length; i++) {
          const w = newSegment.words[i];
          if (w.id === word.id) {
            words.push(...data);
            continue;
          }
          words.push(w);
        }
        newSegment.words = words;
        updateSegment(newSegment);
      })
      .catch(catchError);
  };

  useEffect(() => {
    if (!alwaysOnDisplay) return;
    if (!sttSyncTextAndPlayer) return;
    const container = document.querySelector(".app_stt-segments");
    if (ref && ref.current && isActive && container) {
      const { height: segmentHeight, top: segmentTop } = ref.current.getBoundingClientRect();
      const { height: containerHeight, top: containerTop } = container.getBoundingClientRect();
      const containerBottom = containerTop + containerHeight;
      const segmentBottom = segmentTop + segmentHeight;

      if (segmentTop < containerTop || segmentBottom > containerBottom) {
        const prevSibling: any = ref.current.previousSibling;
        if (prevSibling) {
          prevSibling.scrollIntoView();
        } else {
          ref.current.scrollIntoView();
        }
      }
    }
  }, [currentTime, isActive, sttSyncTextAndPlayer, alwaysOnDisplay]);

  return (
    <div className={clsx(classes.root, { [classes.flexEnd]: isRtl })} ref={ref}>
      <div
        className={clsx(classes.words, {
          [classes.rtl]: isRtl,
          // [classes.fg1]: !showTranslate || tWords.length === 0,
          // [classes.fb55]: showTranslate && tWords.length > 0,
        })}
      >
        <div className={clsx(classes.time, { [classes.hidden]: isText })} onClick={handleClickTime}>
          {getTimeViewFormat(segment.start)}
        </div>
        <div className={clsx(classes.call, { [classes.hidden]: isText })}>
          {channelNumber === 0 && <CallMadeIcon className={classes.callMadeIcon} />}
          {channelNumber === 1 && <CallReceivedIcon className={classes.callReceivedIcon} />}
        </div>
        {isDiarizationNameExist && <div className={classes.speakerName}>{segment.diarizationName}</div>}

        <div className={classes.text}>
          {words.map((w) => {
            const color = getColor(w.id);
            const bgColor = getBgColor(w.id);
            return (
              <Word
                key={w.id}
                word={w}
                currentTime={currentTime}
                color={color}
                bgColor={bgColor}
                onWordEdit={onWordEdit}
              />
            );
          })}
        </div>
      </div>

      {/*<div*/}
      {/*  className={clsx(classes.words, {*/}
      {/*    [classes.hidden]: !showTranslate,*/}
      {/*    [classes.fb45]: showTranslate && tWords.length > 0,*/}
      {/*  })}*/}
      {/*>*/}
      {/*  <div className={classes.text}>*/}
      {/*    {tWords.map((w) => {*/}
      {/*      const color = getColor(w.id);*/}
      {/*      const bgColor = getBgColor(w.id);*/}
      {/*      return <Word key={w.id} word={w} currentTime={currentTime} color={color} bgColor={bgColor} />;*/}
      {/*    })}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default Segment;
