import React, { useEffect, FC, useRef } from "react";
import * as echarts from "echarts";
import { colorPalette } from "../colorPalette";
import { PieSeries } from "types/chart";
import { Statistic } from "types/statistic";
// material ui
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: "auto",
    [theme.breakpoints.down(1600)]: {
      minWidth: "900px",
    },
    [theme.breakpoints.down(1300)]: {
      minWidth: "1200px",
    },
    [theme.breakpoints.down(1100)]: {
      minWidth: "800px",
    },
    width: "100%",
    minWidth: "600px",
  },
}));

type Chart = {
  name: string;
  series: PieSeries[];
  description: string;
  statistic?: Statistic;
};

const PieChart: FC<Chart> = ({ series, name, description }) => {
  const classes = useStyles();
  const chartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);

      const options = {
        title: {
          text: name,
          top: 15,
          left: "center",
          overflow: "truncate",
          margin: [10, 0, 0, 0],
        },
        animation: true,
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          zlevel: -1,
          itemWidth: 15,
          pageTextStyle: {
            overflow: "t",
          },
          left: 1,
          top: 55,
          bottom: 20,
          data: series ? series.sort((a, b) => b.value - a.value).map((item) => item.name) : [],
          formatter: (name: string) => {
            if (series) {
              const totalValue = series.reduce((sum, item) => sum + item.value, 0);
              const filteredData = series.filter((a) => a.name === name);
              if (filteredData.length > 0) {
                const value = filteredData[0].value;
                const percentage = (value / totalValue) * 100;
                return `${name}: ${value} (${percentage.toFixed(2)}%)`;
              }
            }
            return "";
          },
        },
        series: [
          {
            name: "Тип Данных",
            type: "pie",
            radius: "60%",
            data: series.map((item) => ({ name: item.name, value: item.value })),
            label: {
              show: false,
              formatter: "{b} : {c} ({d}%)",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            color: colorPalette,
          },
        ],
      };

      chart.setOption(options);
    }
  }, [series, description, name]);

  return <Paper className={classes.root} ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default PieChart;
