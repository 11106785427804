import React, { FC, useEffect, useState } from "react";
import { Permission as P } from "types/permission";
import EventService from "services/EventService";
import SetPermissionsDialog from "components/permissions/SetPermissionsDialog";
import ErrorMessageDialog from "components/ErrorMessageDialog";

interface State {
  error: undefined | Error;
  loading: boolean;
  permissions: P[];
}

interface Props {
  open: boolean;
  onClose: Function;
  id: number;
}

const SetTaskPermissions: FC<Props> = ({ open, onClose, id }) => {
  const [state, setState] = useState<State>({ error: undefined, loading: false, permissions: [] });
  const { permissions, error, loading } = state;

  useEffect(() => {
    setState({ error: undefined, loading: true, permissions: [] });
    EventService.getPermissions(id)
      .then(({ data }) => {
        setState({ error: undefined, loading: false, permissions: data });
      })
      .catch((error) => {
        setState({ error, loading: false, permissions: [] });
      });
  }, [id]);

  if (error) {
    return <ErrorMessageDialog open={open} onClose={onClose} message={error.message} />;
  }

  if (loading) {
    return null;
  }

  return <SetPermissionsDialog open={open} onClose={onClose} permissions={permissions} />;
};

export default SetTaskPermissions;
