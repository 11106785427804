import { Column, ColumnApi, GridApi, ProcessCellForExportParams, RowNode } from "ag-grid-community";
import { TableCol } from "./TableSettingsDialog";
import moment from "moment/moment";

export function updateRow(row: any, gridApi: GridApi | undefined) {
  if (gridApi) {
    gridApi.applyTransaction({
      update: [row],
    });
  }
}

export function addRow(row: any, gridApi: GridApi | undefined) {
  if (gridApi) {
    gridApi.applyTransaction({
      add: [row],
    });
  }
}

export function addRows(row: any[], gridApi: GridApi | undefined) {
  if (gridApi) {
    gridApi.applyTransaction({
      add: row,
    });
  }
}

export function removeRows(rows: any[], gridApi: GridApi | undefined) {
  if (gridApi) {
    gridApi.applyTransaction({
      remove: rows,
    });
  }
}

export function deselectAll(gridApi: GridApi | undefined) {
  if (gridApi) {
    gridApi.deselectAll();
  }
}

export function setQuickFilter(value: string, gridApi: GridApi | undefined) {
  if (gridApi) {
    gridApi.setQuickFilter(value);
  }
}

export function getUniqueIdsDownToHierarchy(selectedRows: any[], gridApi: GridApi | undefined) {
  let ids: number[] = [];
  if (!gridApi) {
    return ids;
  }

  selectedRows.forEach((word) => {
    const rowNode: RowNode = gridApi.getRowNode(String(word.id));
    const children: RowNode[] = rowNode.allLeafChildren;
    children.forEach((child) => {
      ids.push(child.data.id);
    });
  });

  return (ids = Array.from(new Set(ids)));
}

export function getRowsToRemoveFromIds(ids: number[], gridApi: GridApi | undefined) {
  const rowsToRemove: any[] = [];

  if (!gridApi) {
    return rowsToRemove;
  }

  ids.forEach((id) => {
    const rowNode: RowNode = gridApi.getRowNode(String(id));
    rowsToRemove.push(rowNode.data);
  });

  return rowsToRemove;
}

export function updateColumnOrder(data: TableCol[], columnApi: ColumnApi) {
  const allGridColumns: Column[] = columnApi.getAllGridColumns();

  const columnState: any[] = [];
  for (let i = 0; i < data.length; i++) {
    const { field } = data[i];
    allGridColumns.forEach((col) => {
      const colField = col.getColDef().field ?? "";
      if (colField === field) {
        columnState.push(col);
      }
    });
  }
  columnApi.setColumnState(columnState);
}

export function processCellForClipboard(params: ProcessCellForExportParams) {
  const colId = params.column.getColId();
  const dateFields = ["dateRecord", "dateChanged", "dateCreated"];
  if (dateFields.includes(colId)) {
    const date = moment(params.value);
    if (date.isValid()) return date.format("DD-MM-YYYY HH:mm:ss");
  }
  return params.value;
}
