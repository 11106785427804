import React, { FC } from "react";
import { FileFormatDetail, Format } from "types/admin";

// components
import SelectPassport from "components/SelectPassport";
import TypeFormatFile from "components/TypeFormatFile";

// material ui
import Grid from "@material-ui/core/Grid";

interface Props {
  fileFormatDetail: FileFormatDetail;
  setFileFormatDetail: (fileFormatDetail: FileFormatDetail) => void;
}

const SelectFileFormat: FC<Props> = ({ setFileFormatDetail, fileFormatDetail }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectPassport selectedPassportType={fileFormatDetail.type} setFileFormat={setFileFormatDetail} />
      </Grid>
      <Grid item xs={12}>
        {fileFormatDetail && fileFormatDetail.type === Format.FILES && (
          <TypeFormatFile fileFormatDetail={fileFormatDetail} setFileFormatDetail={setFileFormatDetail} />
        )}
      </Grid>
    </Grid>
  );
};

export default SelectFileFormat;
