import React, { FC } from "react";
import { AdminTechStt } from "types/admin";
import LangTable from "./LangTable";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    // display: "grid",
    // gridTemplateColumns: "1fr 1fr 1fr 1fr",
    // gridGap: 10,
  },
}));

interface Props {
  setState: Function;
  techStt: AdminTechStt;
}

const EditTechStt: FC<Props> = ({ setState, techStt }) => {
  const classes = useStyles();

  // const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { checked, name } = e.target;
  //   setState((prev: Tech) => {
  //     const detail = prev.techDetail as TechStt;
  //     const languages = detail.languages.map((l) => {
  //       if (l.name === name) {
  //         return {
  //           id: l.id,
  //           isActive: checked,
  //           name: l.name,
  //         };
  //       }
  //       return l;
  //     });
  //     return {
  //       ...prev,
  //       techDetail: {
  //         ...prev.techDetail,
  //         languages,
  //       },
  //     };
  //   });
  // };

  return (
    <div className={classes.root}>
      <LangTable rowData={techStt.languages} />
      {/*{techStt.languages.map((lang) => (*/}
      {/*  <FormControlLabel*/}
      {/*    key={lang.id}*/}
      {/*    control={<Checkbox checked={lang.isActive} onChange={handleChecked} name={lang.name} color="primary" />}*/}
      {/*    label={lang.name}*/}
      {/*  />*/}
      {/*))}*/}
    </div>
  );
};

export default EditTechStt;
