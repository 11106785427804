export interface Server {
  id: number;
  uuid: string;
  name: string;
  apps: App[];
}

export interface App {
  id: number;
  comment: string;
  host: string;
  version: string;
  appName: string;
  clusterId: string;
  status: string;
  message: string;
  lastActive: string;
  dateChanged: string;
}

export const defaultApp: App = {
  id: 0,
  comment: "",
  host: "",
  version: "",
  appName: "",
  clusterId: "",
  status: "",
  message: "",
  lastActive: "",
  dateChanged: "",
};
