import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import RecordService from "services/RecordService";
import { getColor } from "functions/common";
import moment from "moment";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  chart: {
    overflow: "hidden",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  controls: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    marginRight: 5,
  },
  label: {
    cursor: "pointer",
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
}));

const ChartLine = ({ dataSet, labels }) => {
  const classes = useStyles();

  const data = {
    labels: labels,
    datasets: dataSet,
  };
  let options = {
    legend: false,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
    },
  };

  return (
    <div className={classes.root}>
      <Line data={data} options={options} height={400} />
    </div>
  );
};

const Chart = ({ filters }) => {
  const [dataSet, setDataSet] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    (async function () {
      const d = [];
      let l = [];
      let i = 0;
      for (const filter of filters) {
        const q = "?filterv2=" + filter;
        const res = await RecordService.getAnalyticsTimeline(q);
        const dataSet = {
          label: `Фильтр ${i + 1}`,
          data: res.data.map(({ count }) => count),
          backgroundColor: getColor(i),
        };
        d.push(dataSet);
        l = res.data.map(({ date }) => moment(date).format("DD-MM-YYYY"));
        i++;
      }
      setDataSet(d);
      setLabels(l);
    })();
  }, [filters]);

  if (dataSet.length === 0 || labels.length === 0) {
    return null;
  }

  return <ChartLine dataSet={dataSet} labels={labels} />;
};

export default Chart;
