import { v4 as uuid } from "uuid";
import { Group as TGroup } from "../types/queryBuilder";
import { AxiosError } from "axios";
import { showErrorAlert } from "redux/actions/alertActions";
import { store } from "providers/ReduxProvider";

export function generateUuid(): string {
  return uuid();
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: any, si = true, dp = 1) {
  if (typeof bytes !== "number") {
    return "";
  }

  // const thresh = si ? 1000 : 1024;
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["КБ", "МБ", "ГБ", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
}

export function getColor(idx: number): string {
  const colors = [
    "rgba(63, 81, 181, .8)",
    "rgb(233, 30, 99, .8)",
    "rgb(156, 39, 176, .8)",
    "rgb(103, 58, 183, .8)",
    "rgb(33, 33, 33, .8)",
    "rgb(33, 150, 243, .8)",
    "rgb(3, 169, 244, .8)",
    "rgb(0, 188, 212, .8)",
    "rgb(0, 150, 136, .8)",
    "rgb(76, 175, 80, .8)",
    "rgb(139, 195, 74, .8)",
    "rgb(205, 220, 57, .8)",
    "rgb(255, 235, 59, .8)",
    "rgb(255, 193, 7, .8)",
    "rgb(255, 152, 0, .8)",
    "rgb(255, 87, 34, .8)",
  ];

  if (idx <= 0) {
    return colors[0];
  }

  if (idx >= colors.length) {
    return colors[colors.length - 1];
  }

  return colors[idx];
}

export function humanDuration(s: number) {
  let hours = Math.floor(s / 3600);
  let minutes = Math.floor((s - hours * 3600) / 60);
  let seconds = Math.floor(s - hours * 3600 - minutes * 60);

  let hS = hours.toString();
  let mS = minutes.toString();
  let sS = seconds.toString();

  if (hours < 10) {
    hS = "0" + hS;
  }
  if (minutes < 10) {
    mS = "0" + mS;
  }
  if (seconds < 10) {
    sS = "0" + sS;
  }
  return hS + ":" + mS + ":" + sS;
}

export function normalizeTime(duration: number): string {
  // округляем до целого
  duration = Math.round(duration);

  // количество часов, округляем до целого в меньшую сторону
  const hour = Math.floor(duration / 3600);

  // количество минут, округляем до целого в меньшую сторону
  const min = Math.floor(duration / 60) - hour * 60;

  // количество секунд
  const sec = duration - min * 60 - hour * 3600;

  let strHours: string;
  if (hour === 0) {
    strHours = "";
  } else {
    strHours = hour < 10 ? `0${hour}:` : hour + ":";
  }

  let strMinutes: string;
  if (min === 0) {
    strMinutes = "00:";
  } else {
    strMinutes = min < 10 ? `0${min}:` : min + ":";
  }

  let strSeconds: string;
  if (sec === 0) {
    strSeconds = "00";
  } else {
    strSeconds = sec < 10 ? `0${sec}` : sec + "";
  }

  return strHours + strMinutes + strSeconds;
}

const checkStr = (allowChars: string[]) => (str: string) =>
  str
    .split("")
    .filter((s) => allowChars.includes(s))
    .join("");

export const strOnlyInt = checkStr(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]);

export const strOnlyFloat = checkStr(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "."]);

export const getCyrillicLangName = (lang: string) => {
  const langs: any = {
    arabic: "Арабский",
    armenian: "Армянский",
    azerbaijani: "Азербайджанский",
    belarusian: "Белорусский",
    bulgarian: "Болгарский",
    chechen: "Чеченский",
    chinese: "Китайский (КНР)",
    dari: "Дари",
    english: "Английский",
    estonian: "Эстонский",
    farsi: "Фарси",
    finnish: "Финский",
    french: "Французский",
    georgian: "Грузинский",
    german: "Немецкий",
    hebrew: "Иврит",
    hindi: "Хинди",
    italian: "Итальянский",
    japanese: "Японский",
    kazakh: "Казахский",
    korean: "Корейский",
    latvian: "Латышский",
    lithuanian: "Литовский",
    pashto: "Пушту",
    polish: "Польский",
    portuguese: "Португальский",
    romanian: "Румынский",
    russian: "Русский",
    slovak: "Словацкий",
    slovenian: "Словенский",
    spanish: "Испанский",
    tajik: "Таджикский",
    tamil: "Тамильский",
    turkish: "Турецкий",
    ukrainian: "Украинский",
    uzbek: "Узбекский",
    vietnamese: "Вьетнамский",
    macedonian: "Македонский",
    serbian: "Сербохорватский",
    turkmen: "Туркменский",
    unknown_language: "Неизвестный",
    greek: "Греческий",
    norwegian: "Норвежский",
    serbian_text_lid: "Сербский",
    urdu: "Урду",
    croatian: "Хорватский",
    czech: "Чешский",
  };

  return langs[lang] ?? lang;
};

export function copyToClipboard(textToCopy: string): boolean {
  const textArea = document.createElement("textarea");
  textArea.style.position = "fixed";
  textArea.style.left = "-999999px";
  textArea.style.top = "-999999px";
  document.body.appendChild(textArea);
  textArea.value = textToCopy;
  textArea.textContent = textToCopy;
  const sel = getSelection();
  if (!sel) return false;

  const range = document.createRange();
  range.selectNode(textArea);
  sel.removeAllRanges();
  sel.addRange(range);

  const res = document.execCommand("copy");
  textArea.remove();
  return res;
}

export const convertFilterName = (str: string) => {
  let res = "";
  try {
    const arr: string[] = [];
    const f: TGroup = JSON.parse(str);
    f.filters.forEach((el) => {
      if (el.type === "group") {
        arr.push("группа");
      }
      if (el.type === "filter") {
        arr.push(`${el.filter}${el.condition}${el.value}`);
      }
    });
    res = arr.join(" ");
  } catch (err) {}
  return res;
};

export function isInt(n: number) {
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n: number) {
  return Number(n) === n && n % 1 !== 0;
}

export const catchError = (err: AxiosError, prefix?: string) => {
  // fix "rt not found"
  if (err.response?.status === 401) return;

  let pr = prefix === undefined ? "" : prefix;
  let message = err.response?.data?.message ?? "Неизвестная ошибка";
  store.dispatch(showErrorAlert(pr + message));
};

export const checkError = (err: AxiosError | undefined) => {
  if (err === undefined) return;
  catchError(err);
};

export function defaultFilter(): TGroup {
  return {
    type: "group",
    uuid: generateUuid(),
    operation: "AND",
    filters: [],
  };
}
