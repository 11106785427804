import { FilterType as TFilterType, Condition as TCondition } from "types/queryBuilder";

export function getConditionList(type: TFilterType) {
  let listConditions: TCondition[] = ["=", "!="];
  if (type === TFilterType.Number) {
    listConditions = ["=", "!=", ">", "<"];
  }
  if (type === TFilterType.Float) {
    listConditions = [">", "<"];
  }
  if (type === TFilterType.Boolean) {
    listConditions = ["=", "!="];
  }
  if (type === TFilterType.Date) {
    listConditions = ["=", ">", "<"];
  }

  return listConditions;
}
