import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
}));

interface State {
  name: string;
  comment: string;
  list: string;
}

const initialState: State = {
  name: "",
  comment: "",
  list: "",
};

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
}

const CreateListDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [state, setState] = useState<State>(initialState);

  const { name, comment, list } = state;

  const disabled = name.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = useCallback(() => {
    onClose(state);
  }, [onClose, state]);

  useEffect(() => {
    if (!open) {
      setState(initialState);
    }
  }, [open]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Создать список</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth name="name" onChange={handleChange} value={name} autoFocus />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Список" multiline rows={10} fullWidth name="list" onChange={handleChange} value={list} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Комментарий" fullWidth name="comment" onChange={handleChange} value={comment} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSave}
                disabled={disabled}
              >
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateListDialog;
