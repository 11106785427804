import React, { FC } from "react";

// icons
import ErrorIcon from "@material-ui/icons/Error";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { green, red, blue, orange, grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  green: {
    color: green[300],
  },
  blue: {
    color: blue[300],
  },
  orange: {
    color: orange[300],
  },
  red: {
    color: red[400],
  },
  grey: {
    color: grey[300],
  },
  message: {
    marginLeft: 5,
  },
}));

const StatusRenderer: FC<{ value: any; message?: string }> = ({ value, message }) => {
  const classes = useStyles();

  // stopped | wait | starting | started | error | ok | progress
  if (value === "wait") {
    return (
      <div className={classes.wrapper} title={message ?? "Ожидает"}>
        <FindInPageIcon className={classes.blue} />
      </div>
    );
  }

  if (value === "starting" || value === "started" || value === "progress") {
    return (
      <div className={classes.wrapper} title={message ?? "Запущен"}>
        <DoubleArrowIcon className={classes.green} />
      </div>
    );
  }

  if (value === "stopped" || value === "stoped") {
    return (
      <div className={classes.wrapper} title={message ?? "Остановлен"}>
        <PlayArrowIcon className={classes.grey} />
      </div>
    );
  }

  if (value === "ok") {
    return (
      <div className={classes.wrapper}>
        <CheckCircleIcon className={classes.green} />
      </div>
    );
  }

  return (
    <div className={classes.wrapper} title={message ?? "Ошибка"}>
      <ErrorIcon className={classes.red} />
    </div>
  );
};

export default StatusRenderer;
