import React, { FC } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { grey } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/types";

const useStyles = makeStyles((theme) => ({
  headset: {
    display: "flex",
  },
  leftHeadset: {
    width: 16,
    height: 27,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "100% 0px 0px 100% / 50% 0px 0px 50%",
    cursor: "pointer",
  },
  leftHeadsetImage: {
    width: 24,
    height: 24,
    marginLeft: -9,
  },
  rightHeadset: {
    marginLeft: 2,
    width: 16,
    height: 27,
    cursor: "pointer",
    borderRadius: "0px 100% 100% 0px / 0px 50% 50% 0px",
    backgroundColor: theme.palette.primary.main,
  },
  rightHeadsetImage: {
    marginLeft: 2,
    width: 24,
    height: 24,
  },
  bgGrey: {
    backgroundColor: grey[300] + "!important",
  },
  disabledLeft: {
    width: 16,
    height: 27,
    borderRadius: "100% 0px 0px 100% / 50% 0px 0px 50%",
    backgroundColor: grey[300],
  },
  disabledRight: {
    marginLeft: 2,
    width: 16,
    height: 27,
    borderRadius: "0px 100% 100% 0px / 0px 50% 50% 0px",
    backgroundColor: grey[300],
  },
}));

interface Props {
  setChannel: (ch: -1 | 0 | 1) => void;
  recordChannel: number;
}

const ChannelSelect: FC<Props> = ({ setChannel, recordChannel }) => {
  const classes = useStyles();
  const { playerChannel } = useSelector((state: RootState) => state.settings);

  if (recordChannel === 1) {
    return (
      <div className={classes.headset}>
        <div className={classes.disabledLeft} title="Левый канал">
          <img src="/img/left-headset.png" alt="left headset" className={classes.leftHeadsetImage} />
        </div>
        <div className={classes.disabledRight} title="Правый канал">
          <img src="/img/right-headset.png" alt="right headset" className={classes.rightHeadsetImage} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.headset}>
      <div
        className={clsx(classes.leftHeadset, { [classes.bgGrey]: playerChannel === 1 })}
        onClick={() => setChannel(0)}
        title="Левый канал"
      >
        <img src="/img/left-headset.png" alt="left headset" className={classes.leftHeadsetImage} />
      </div>
      <div
        className={clsx(classes.rightHeadset, { [classes.bgGrey]: playerChannel === 0 })}
        onClick={() => setChannel(1)}
        title="Правый канал"
      >
        <img src="/img/right-headset.png" alt="right headset" className={classes.rightHeadsetImage} />
      </div>
    </div>
  );
};

export default ChannelSelect;
