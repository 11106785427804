import { useEffect, useState } from "react";
import { Keywords } from "types/record";
import RecordService from "services/RecordService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  keywords: Keywords[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  keywords: [],
  error: undefined,
};

const useKeywords = (recordId: number | string, queryString: string) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState(() => ({ error: undefined, keywords: [], loading: true }));
    RecordService.getKeywords(recordId, queryString)
      .then((res) => {
        setState({ keywords: res.data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ keywords: [], loading: false, error: err });
      });
  }, [recordId, queryString]);

  return state;
};

export default useKeywords;
