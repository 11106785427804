import { GridApi } from "ag-grid-community";

export default function AgGridService(gridApi?: GridApi) {
  function addRows(rows: any[]) {
    if (gridApi) {
      gridApi.applyTransaction({
        add: rows,
      });
    }
  }

  function updateRow(row: any) {
    if (gridApi) {
      const node = gridApi.getRowNode(String(row.id));
      node.setData(row);
    }
  }

  function removeRows(rows: any[]) {
    if (gridApi) {
      gridApi.applyTransaction({ remove: rows });
    }
  }

  function quickFilter(value: string) {
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  }

  function deselectAll() {
    if (gridApi) {
      gridApi.deselectAll();
    }
  }

  return Object.freeze({
    addRows,
    updateRow,
    removeRows,
    quickFilter,
    deselectAll,
  });
}
