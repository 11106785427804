import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Setting } from "types/admin";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: any) => void;
  setting: Setting;
}

const UpdateSettingDialog: FC<Props> = ({ open, onClose, setting }) => {
  const classes = useStyles();

  const [state, setState] = useState(setting);
  const { userId, key, value } = state;

  const disabled = userId.length === 0 || key.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = useCallback(() => {
    onClose(state);
  }, [onClose, state]);

  useEffect(() => {
    setState(setting);
  }, [setting]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="md">
      <DialogTitle>Редактировать настройку</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="ID пользователя"
              fullWidth
              name="userId"
              onChange={handleChange}
              value={userId}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Ключ" fullWidth name="key" onChange={handleChange} value={key} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Значение" fullWidth name="value" onChange={handleChange} value={value} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button className={classes.button} size="small" onClick={() => onClose()}>
                Отмена
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSave}
                disabled={disabled}
              >
                Ок
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateSettingDialog;
