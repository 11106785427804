import { AppDispatch } from "redux/types";
import ModuleService from "services/admin/ModuleService";
import { setNavigationModules } from "redux/actions/navigationActions";

export function fetchNavigationModules() {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await ModuleService.getAll();
      dispatch(setNavigationModules(res.data));
    } catch (error) {
      console.error(error);
    }
  };
}
