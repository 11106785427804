import React, { FC } from "react";
import { Speaker } from "types/speaker";

// icons
import ErrorIcon from "@material-ui/icons/Error";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { green, red, blue, orange, grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  green: {
    color: green[300],
  },
  blue: {
    color: blue[300],
  },
  orange: {
    color: orange[300],
  },
  red: {
    color: red[300],
  },
  grey: {
    color: grey[300],
  },
  message: {
    marginLeft: 5,
  },
}));

const SpeakerStatusRenderer: FC<{ value: string; data: Speaker }> = ({ value, data }) => {
  const classes = useStyles();

  if (data.speakerType === "folder") {
    return <span> </span>;
  }

  // notProcessed | ok | warning | error
  if (value === "notProcessed") {
    return (
      <div className={classes.wrapper}>
        <QueryBuilderIcon className={classes.blue} />
      </div>
    );
  }

  if (value === "ok") {
    return (
      <div className={classes.wrapper}>
        <CheckCircleIcon className={classes.green} />
      </div>
    );
  }

  if (value === "warning") {
    return (
      <div className={classes.wrapper}>
        <WarningIcon className={classes.orange} />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <ErrorIcon className={classes.red} />
    </div>
  );
};

export default SpeakerStatusRenderer;
