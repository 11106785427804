import { HtmlSelectCondition } from "types/queryBuilder";

const conditions: HtmlSelectCondition[] = [
  { name: "equal", value: "=", printValue: "равно" },
  { name: "not equal", value: "!=", printValue: "не равно" },
  { name: "more", value: ">", printValue: "больше" },
  { name: "less", value: "<", printValue: "меньше" },
];

export default conditions;
