import React, { FC, useMemo } from "react";
import { Keywords, Segment as TypeSegment } from "types";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 5,
  },
  fc: {
    height: "fit-content",
  },
  grey: {
    color: grey[500],
  },
}));

interface Props {
  segment: TypeSegment;
  keywords: Keywords[];
}

const TextTSegment: FC<Props> = ({ segment }) => {
  const classes = useStyles();

  const text = useMemo(() => segment.words.map((w) => w.word).join(" "), [segment]);

  return <div className={classes.root}>{text}</div>;
};

export default TextTSegment;
