import React, { FC, useEffect, useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import { Obzor } from "types/obzor";
import { getBeforeLastBackSlash } from "functions/obzorFunctions";

// components
import BreadcrumbsMuiSMB from "./BreadcrumbsMuiSMB";
import ObzorTable from "./ObzorTable";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  bc: {
    marginBottom: 10,
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchWrapper: {
    flexGrow: 1,
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
  mr10: {
    marginRight: 10,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  path: {},
  button: {
    width: 100,
  },
  span: {},
  table: {
    height: "700px",
    fontSize: "14px",
  },
  folder: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  files: Obzor[];
  folderClick: (folderName: string) => void;
  handleSelect: (rootPath?: string, name?: string) => void;
  handleCrumbClick: (newPath: string) => void;
  handleMainCrumbClickSMB: (rootPath: string) => void;
  breadcrumbPath: string;
  rootPath: string;
  filterValue: string;
  handleFilterChange: (event: any) => void;
}

const ObzorSMB: FC<Props> = ({
  open,
  onClose,
  files,
  folderClick,
  handleSelect,
  handleCrumbClick,
  handleMainCrumbClickSMB,
  breadcrumbPath,
  rootPath,
  filterValue,
  handleFilterChange,
}) => {
  const classes = useStyles();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Obzor[]>([]);
  const gridRef = useRef<any>(null);
  const [newBreadcrubmPath, setNewBreadcrubmPath] = useState("");

  const onSelection = () => {
    gridApi && setSelectedRows(gridApi.getSelectedRows());
  };

  const handleDirNameClick = (files: Obzor) => {
    if (files.isDir) {
      folderClick(files.path);
      // setFilterValue("");
    }
    setSelectedRows([]);
  };

  const handleSelectClick = () => {
    if (selectedRows[0]) {
      if (selectedRows[0].isDir) {
        handleSelect(rootPath, selectedRows[0].path);
      } else {
        const path = getBeforeLastBackSlash(selectedRows[0].path);
        //для всего кроме папки, надо остаться в текущ папке
        handleSelect(rootPath, path);
      }
    } else {
      //если ничего не выбрано
      handleSelect();
    }
  };

  //для хлебных крошек
  const handleBreadCrumbClick = (path: string) => {
    const fullPath = rootPath + path;
    handleCrumbClick(fullPath);
    setSelectedRows([]);
  };

  const handleClickMainCrumb = () => {
    setSelectedRows([]);
    handleMainCrumbClickSMB(rootPath);
  };

  //обрезаю, чтобы не было дублирования рута
  useEffect(() => {
    const newPath = breadcrumbPath.split(rootPath);
    setNewBreadcrubmPath(newPath[1] ?? "");
    console.log("rootPath", rootPath, "breadcrumbPath", breadcrumbPath, "newPath", newPath);
  }, [breadcrumbPath, rootPath]);

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle>Обзор</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.wrapper}>
              <BreadcrumbsMuiSMB
                currentPath={newBreadcrubmPath}
                onCrumbClick={handleBreadCrumbClick}
                handleClickMainCrumb={handleClickMainCrumb}
              />
              <div className={classes.searchWrapper}>
                <TextField
                  id="filter-text-box"
                  className={classes.search}
                  placeholder="Поиск..."
                  onChange={handleFilterChange}
                  value={filterValue}
                  InputProps={{
                    className: classes.searchInput,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <ObzorTable
              files={files}
              setGridApi={setGridApi}
              onSelection={onSelection}
              handleDirNameClick={handleDirNameClick}
              filterValue={filterValue}
              gridRef={gridRef}
              tableType="SMB"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="small" color="primary" className={classes.button} onClick={handleSelectClick}>
          Выбрать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObzorSMB;
