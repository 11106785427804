import React, { FC, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { routes } from "routes";
import { ColumnApi, GridApi } from "ag-grid-community";
import { AdminTechSid } from "types/admin";
import SpeakerService from "services/SpeakerService";
import { showErrorAlert } from "redux/actions/alertActions";
import { Speaker } from "types/speaker";
import { useDispatch } from "react-redux";
import SpeakersTable from "pages/speakers/components/SpeakersTable";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
  bc: {
    marginBottom: 10,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  tech: AdminTechSid;
}

const SidTable: FC<Props> = ({ open, onClose, tech }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { path } = routes.speakers;

  const [pag, setPag] = useState<{ root: number; name: string }[]>([]);
  const [root, setRoot] = useState(0);
  const [speakers, setSpeakers] = useState<Speaker[]>([]);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Speaker[]>([]);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  // клик по хлебной крошке
  const handleBreadCrumbClick = (event: any, root: number) => {
    event.preventDefault();
    if (pag.length === 0) return;
    if (root === 0) {
      setRoot(0);
      setPag([]);
      return;
    }
    const q = [];
    for (let i = 0; i < pag.length; i++) {
      if (pag[i].root === root) {
        q.push(pag[i]);
        break;
      }
      q.push(pag[i]);
    }
    const last = q.slice(-1)[0];
    setPag(q);
    setRoot(last.root);
  };

  // клик по именя в таблице
  const handleSpeakerNameClick = (speaker: Speaker) => {
    const { id: root, name, speakerType } = speaker;
    if (speakerType !== "folder") return;
    setPag((prev) => [...prev, { root, name }]);
    setRoot(root);
  };

  const sortSpeakers = useCallback(() => {
    const sorting = (a: Speaker, b: Speaker) => a.id - b.id;
    const folder = speakers.filter((el) => el.speakerType === "folder").sort(sorting);
    const speaker = speakers.filter((el) => el.speakerType === "speaker").sort(sorting);
    const autoinformator = speakers.filter((el) => el.speakerType === "autoinformator").sort(sorting);
    return [...folder, ...speaker, ...autoinformator];
  }, [speakers]);

  const onSelection = () => {
    console.log(columnApi);
    gridApi && setSelectedRows(gridApi.getSelectedRows());
  };

  const handleSave = useCallback(() => {
    onClose({ ...tech, ids: selectedRows.map((s) => s.id) });
  }, [tech, onClose, selectedRows]);

  useEffect(() => {
    SpeakerService.getAll(root)
      .then(({ data }) => {
        setSpeakers(data);
        setSelectedRows([]);
      })
      .catch((err) => catchError(err.response.data));
  }, [root, catchError]);

  useEffect(() => {
    if (gridApi) {
      gridApi.forEachNode((node) => {
        if (tech.ids.includes(node.data.id)) {
          node.setSelected(true);
        }
      });
    }
  }, [tech, gridApi, speakers]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Выбрать диктора</DialogTitle>
      <DialogContent>
        <div className={classes.bc}>
          {pag.length === 0 && (
            <Breadcrumbs aria-label="breadcrumb">
              <Typography>Главная</Typography>
            </Breadcrumbs>
          )}

          {pag.length !== 0 && (
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href={path} onClick={(e: any) => handleBreadCrumbClick(e, 0)}>
                Главная
              </Link>
              {pag.map((el) => (
                <Link key={el.root} color="inherit" href={path} onClick={(e: any) => handleBreadCrumbClick(e, el.root)}>
                  {el.name}
                </Link>
              ))}
            </Breadcrumbs>
          )}
        </div>

        <SpeakersTable
          rowData={sortSpeakers()}
          setGridApi={setGridApi}
          setColumnApi={setColumnApi}
          onSelection={onSelection}
          handleSpeakerNameClick={handleSpeakerNameClick}
          height={400}
        />
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={selectedRows.length === 0}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SidTable;
